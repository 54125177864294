import onmount from 'onmount';
import Rails from '@rails/ujs';

onmount('[data-js-synchronizable-question]', function () {
  const { questionId } = this.closest('[data-js-question]').dataset;

  Rails.ajax({
    url: `/sync/questions/${questionId}`,
    type: 'GET',
    dataType: 'script'
  });
});
