import onmount from 'onmount';
import imagesLoaded from 'imagesloaded';
import { debounce } from '@modules/custom';

onmount('[data-js-show-hotspot-areas]', function () {
  // const self = this;
  const hotspot = this.closest('[data-js-hotspot]');
  const areas = this.querySelectorAll('[data-js-hotspot-area]');
  const image = hotspot.querySelector('[data-js-hotspot-image]');
  const svg = hotspot.querySelector('[data-js-image-wrapper-svg]');

  function getRatio() {
    return {
      ratioX: image.naturalWidth / image.clientWidth,
      ratioY: image.naturalHeight / image.clientHeight
    };
  }

  function getClientPosition(coord) {
    const ratio = getRatio();
    return {
      clientX: Math.round(coord.naturalX / ratio.ratioX),
      clientY: Math.round(coord.naturalY / ratio.ratioY)
    };
  }

  function processCoords(coordsString, shape) {
    const coords = [];
    const rawCoords = coordsString.split(',');

    if (shape === 'circle') {
      coords.push({
        naturalX: parseFloat(rawCoords[0]),
        naturalY: parseFloat(rawCoords[1])
      });
      coords.push({
        naturalX: parseFloat(rawCoords[0]),
        naturalY: parseFloat(rawCoords[1]) - parseFloat(rawCoords[2])
      });
    } else if (shape === 'poly') {
      rawCoords.forEach((coord, index) => {
        if (index % 2 === 0) {
          coords.push({
            naturalX: parseFloat(rawCoords[index]),
            naturalY: parseFloat(rawCoords[index + 1])
          });
        }
      });
    } else {
      coords.push({
        naturalX: parseFloat(rawCoords[0]),
        naturalY: parseFloat(rawCoords[1])
      });
      coords.push({
        naturalX: parseFloat(rawCoords[2]),
        naturalY: parseFloat(rawCoords[3])
      });
    }

    return coords;
  }

  function renderArea(area) {
    const { shape, coords, color } = area.dataset;
    const processedCoords = processCoords(coords, shape);
    let i;

    const scaledCoords = [];
    processedCoords.forEach((coord) => {
      const scaledCoord = getClientPosition(coord);
      scaledCoords.push(scaledCoord.clientX, scaledCoord.clientY);
    });

    if (shape === 'poly') {
      i = document.createElementNS('http://www.w3.org/2000/svg', 'polygon');
      i.setAttribute('points', scaledCoords.join(','));
    } else if (shape === 'circle') {
      i = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
      i.setAttribute('cx', scaledCoords[0]);
      i.setAttribute('cy', scaledCoords[1]);
      i.setAttribute('r', Math.sqrt((scaledCoords[2] - (scaledCoords[0])) ** 2 + (scaledCoords[3] - (scaledCoords[1])) ** 2)); // eslint-disable-line
    } else if (shape === 'rect') {
      i = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
      i.setAttribute('x', Math.min(scaledCoords[0], scaledCoords[2]));
      i.setAttribute('y', Math.min(scaledCoords[1], scaledCoords[3]));
      i.setAttribute('width', Math.abs(scaledCoords[2] - (scaledCoords[0])));
      i.setAttribute('height', Math.abs(scaledCoords[3] - (scaledCoords[1])));
    }

    i.setAttribute('class', `image-mapper-shape hotspot-area--${color}`);
    i.style.strokeWidth = '1';
    i.style.strokeOpacity = '1';
    i.style.fillOpacity = '0.5';
    i.style.cursor = 'pointer';
    svg.insertBefore(i, svg.firstChild);
  }

  function renderAreas() {
    while (svg.firstChild) {
      svg.removeChild(svg.firstChild);
    }

    areas.forEach((area) => {
      renderArea(area);
    });
  }
  this.renderAreas = debounce(renderAreas, 50);

  imagesLoaded(this, renderAreas);
  window.addEventListener('resize', this.renderAreas);
  document.addEventListener('splitScreenResize', this.renderAreas);
}, function () {
  window.removeEventListener('resize', this.renderAreas);
  document.removeEventListener('splitScreenResize', this.renderAreas);
});
