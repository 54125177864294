import onmount from 'onmount';

onmount('[data-js-dashboard-stats]', function () {
  const chartWrapper = this.querySelector('[data-js-column-chart], [data-js-bar-chart]');
  const activeUsersCountEl = this.querySelector('[data-js-active-users-count]');
  const deltaEl = this.querySelector('[data-js-active-users-delta]');

  function updateActiveUsersDelta(e) {
    const data = e.detail.data[1];
    const activeUsers = data[data.length - 1][1];
    activeUsersCountEl.innerHTML = activeUsers.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    const change = activeUsers - data[data.length - 2][1];
    if (change > 0) {
      deltaEl.innerHTML = `<i class='material-icons mountain-meadow mr-1'>trending_up</i>${change}`;
    } else {
      deltaEl.innerHTML = `<i class='material-icons light-coral mr-1'>trending_down</i>${change}`;
    }
  }

  chartWrapper.addEventListener('chartData:fetched', updateActiveUsersDelta);
});
