/* global Redactor */

(function ($R) {
  $R.add('plugin', 'link-plugin', {
    init(app) {
      this.app = app;
      this.toolbar = app.toolbar;
      this.inspector = app.inspector;
      this.selection = app.selection;
    },
    start() {
      this.button = this.toolbar.getButton('link');
      this.button.nodes[0].addEventListener('click', () => this.app.broadcast('toggle', this.button));
    },
    ontoggle() { this.updateButtonState(); },
    onchanged() { this.updateButtonState(); },
    onclick() { this.updateButtonState(); },
    onkeyup() { this.updateButtonState(); },
    onblur() {
      this.button.removeClass('re-button--active');
    },
    updateButtonState() {
      if (this.isLink()) {
        this.button.addClass('re-button--active');
      } else {
        this.button.removeClass('re-button--active');
      }
    },
    isLink() {
      const element = this.selection.getElement();
      const data = this.inspector.parse(element);
      return data.isLink();
    }
  });
}(Redactor));
