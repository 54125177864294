import onmount from 'onmount';

onmount('[data-js-remove-dialog-on-success]', function () {
  const dialog = this.closest('[data-mdc-dialog]');
  if (!dialog.MDCDialog) return;

  function handleFormSuccess() {
    dialog.MDCDialog.close();
  }
  this.handleFormSuccess = handleFormSuccess.bind(this);

  this.addEventListener('ajax:success', this.handleFormSuccess);
}, function () {
  this.removeEventListener('ajax:success', this.handleFormSuccess);
});
