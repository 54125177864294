/* global Channels */

import onmount from 'onmount';
import consumer from '../consumer';

onmount('[data-js-start-assignment-channel]', function () {
  const startAssignmentSubscription = consumer.subscriptions.create({
    channel: 'Taking::StartAssignmentChannel',
    assignment_id: this.dataset.assignmentId
  }, {
    received() {
      window.location.reload();
    }
  });

  Channels.startAssignmentSubscription = startAssignmentSubscription;
});
