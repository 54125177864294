import onmount from 'onmount';

onmount('body', () => {
  function updateUrl(e) {
    const { url } = e.detail;

    url.searchParams.delete('page');
    url.searchParams.delete('items');
    url.searchParams.delete('pagy');

    if (window.history.pushState) {
      window.history.pushState({ path: url.toString() }, '', url.toString());
    }
  }
  this.updateUrl = updateUrl;

  document.addEventListener('urlChanged', this.updateUrl);
}, function () {
  document.removeEventListener('urlChanged', this.updateUrl);
});
