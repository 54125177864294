export function removeKatexFromHtml(html) {
  const parser = new DOMParser();
  const htmlDoc = parser.parseFromString(html, 'text/html');
  const equations = htmlDoc.querySelectorAll('.katex');

  Array.prototype.forEach.call(equations, (e) => {
    const latex = e.querySelector('annotation').textContent;
    const span = document.createElement('span');
    span.textContent = `$$${latex}$$`;
    e.outerHTML = span.innerHTML;
  });

  return htmlDoc.body.innerHTML;
}
