import onmount from 'onmount';
import { displayUpdatingStatus, submitOrSetPending } from '@modules/quiz/saving';
import { updateActiveSubmission } from '@modules/quiz/navigating';

onmount('[data-js-certainty-degree]', function () {
  const form = this.closest('form');
  const { submissionId } = this.closest('[data-js-quiz-subquestion]').dataset;

  function handleContentChanged() {
    updateActiveSubmission(submissionId);
    form.setAttribute('data-saved', false);
    displayUpdatingStatus(form);
    submitOrSetPending(form);
  }

  this.addEventListener('change', handleContentChanged);
});
