/* eslint-disable */

import { renderKatex } from '@vendor/katex';
import Rails from '@rails/ujs';
import { serialize } from '@modules/custom';

(function($R)
{
  $R.add('plugin', 'shortcut', {
    translations: {
      en: {
        'shortcuts': 'Quick comments',
        'show shortcuts': 'Show quick comments',
        'save as shortcut': 'Save as quick comment',
        'insert': 'Insert',
        'delete': 'Delete'
      }
    },
    modals: {
      'shortcut': ''
    },
    init: function(app) {
      this.app = app;
      this.insertion = app.insertion;
      this.lang = app.lang;
      this.opts = app.opts;
      this.selection = app.selection;
      this.source = app.source;
      this.toolbar = app.toolbar;
    },

    // messages
    onmodal: {
      shortcut: {
        open: function($modal)
        {
          var _this = this;

          $modal.addClass('redactor-modal-shortcut');

          this._clearModalBody($modal);

          this._loadShortcuts().then( function(shortcuts) {
            _this.opts.commentShortcuts = shortcuts;
            _this._buildShortcutList($modal);
          })
        }
      }
    },

    // public
    start: function()
    {
      var dropdownData = this._buildListSelections();
      var buttonData = {
        title: '## shortcuts ##'
      };
      var $button = this.toolbar.addButton('shortcut', buttonData);
      var dropdown = $button.setDropdown(dropdownData);
    },
    showShortcuts: function()
    {
      var options = {
        title: this.lang.get('shortcuts'),
        width: '600px',
        name: 'shortcut',
        commands: // optional object
        {
          cancel: { title: 'Cancel' }
        }
      };

      this.app.api('module.modal.build', options);
    },
    saveAsShortcut: function()
    {
      let comment = this.selection.getHtml();
      if (!comment.length) comment = this.source.getCode();
      this._loadNewShortcutModal(comment)
    },

    // private
    _buildListSelections: function()
    {
      var list = {}

      list['show'] = {
        title: '## show shortcuts ##',
        api: 'plugin.shortcut.showShortcuts'
      }

      list['create'] = {
        title: '## save as shortcut ##',
        api: 'plugin.shortcut.saveAsShortcut'
      }

      return list;
    },
    _loadShortcuts: function()
    {
      return new Promise(function(resolve, reject) {
        Rails.ajax({
          url: '/comment_shortcuts',
          type: 'GET',
          dataType: 'json',
          success: (data) => {
            resolve(data);
          },
          error: (request) => {
            reject({
              status: request.status,
              statusText: request.statusText
            });
          }
        })
      });
    },
    _buildShortcutList: function($modal) {
      var $body = $modal.getBody();
      var $list = this._buildList();

      this._buildItems($list);

      $body.append($list);
      renderKatex($body.nodes[0]);
    },
    _clearModalBody: function($modal)
    {
      var $body = $modal.getBody();

      $body.html('');
    },
    _buildList: function()
    {
      var $list = $R.dom("<ul class='list-style-none'>");
      $list.addClass('redactor-clips-list');

      return $list;
    },
    _buildItems: function($list)
    {
      var items = this.opts.commentShortcuts;
      for (var i = 0; i < items.length; i++)
      {
        var $li = $R.dom("<li class='px-3 py-1 box-row'>");
        var $shortcut = $R.dom("<div class='shortcut'>");
        var $comment = $R.dom("<div class='comment'>");

        $shortcut.html(items[i].shortcut);
        $comment.html(items[i].comment);

        $li.attr('data-js-shortcut', i);
        $li.append($shortcut);
        $li.append($comment);

        $li.on('click', this._insert.bind(this));

        $list.append($li);
      }
    },
    _insert: function(e)
    {
      var $item = $R.dom(e.target);
      var index = $item.attr('data-js-shortcut') || $item.closest('[data-js-shortcut]').attr('data-js-shortcut');
      var html = this.opts.commentShortcuts[index].comment;

      this.app.api('module.modal.close');
      this.insertion.insertHtml(html);
    },
    _loadNewShortcutModal: function(comment)
    {
      const params = { reload: false, comment_shortcut: { comment: comment } };

      Rails.ajax({
        url: '/comment_shortcuts/new',
        type: 'GET',
        data: serialize(params),
        dataType: 'script'
      })
    }
  });
})(Redactor);
