import onmount from 'onmount';
import { MDCSelect } from '@material-overwrites/mdc.select';

onmount('[data-js-download-results]', function () {
  const studentNumberCheckBox = this.querySelector('[data-js-student-number]');
  const studentFirstNameCheckBox = this.querySelector('[data-js-student-first-name]');
  const studentLastNameCheckBox = this.querySelector('[data-js-student-last-name]');
  const sortOptionsSelector = this.querySelector('[data-js-sort-options]');
  const exportOptionsSelector = this.querySelector('[data-js-export-options]');
  const exportTypeCheckboxes = this.querySelectorAll('[data-js-export-type-checkbox] .mdc-checkbox');

  if (sortOptionsSelector && !sortOptionsSelector.MDCSelect) {
    sortOptionsSelector.MDCSelect = new MDCSelect(sortOptionsSelector);
    sortOptionsSelector.setAttribute('data-mdc-auto-init-state', 'initialized');
  }

  if (exportOptionsSelector && !exportOptionsSelector.MDCSelect) {
    exportOptionsSelector.MDCSelect = new MDCSelect(exportOptionsSelector);
    exportOptionsSelector.setAttribute('data-mdc-auto-init-state', 'initialized');
  }

  function disableOption(el) {
    el.classList.add('mdc-deprecated-list-item--disabled');
  }

  function enableOption(el) {
    el.classList.remove('mdc-deprecated-list-item--disabled');
  }

  function initSortOptions() {
    sortOptionsSelector.querySelectorAll('li').forEach((option) => {
      if (option.dataset.value.includes('first_name') && !studentFirstNameCheckBox.checked) {
        disableOption(option);
      }
      if (option.dataset.value.includes('last_name') && !studentLastNameCheckBox.checked) {
        disableOption(option);
      }
    });
  }

  function defaultToTotalPoints() {
    sortOptionsSelector.MDCSelect.setValue('total_points');
  }

  function handleStudentNumberChange(e) {
    if (!e.target.checked) {
      disableOption(sortOptionsSelector.querySelector("[data-value='student_number']"));
      if (e.target.name.includes(sortOptionsSelector.MDCSelect.value)) defaultToTotalPoints();
    } else {
      enableOption(sortOptionsSelector.querySelector("[data-value='student_number']"));
    }
  }

  function handleStudentNameChange(e) {
    const userDetail = e.target.getAttribute('name').replace('download[user_details][', '').replace(']', '');
    if (!e.target.checked) {
      sortOptionsSelector.querySelectorAll('li').forEach((option) => {
        if (option.dataset.value.includes(userDetail)) {
          disableOption(option);
          if (sortOptionsSelector.MDCSelect.value.includes(userDetail)) defaultToTotalPoints();
        }
      });
    } else {
      sortOptionsSelector.querySelectorAll('li').forEach((option) => {
        if (option.dataset.value.includes(userDetail)) {
          enableOption(option);
        }
      });
    }
  }

  function handleExportOptionsChange() {
    const selectedItem = this.MDCSelect.menu.items[this.MDCSelect.selectedIndex];
    exportTypeCheckboxes.forEach((checkbox) => {
      if (checkbox.parentElement.dataset.jsExportTypeCheckbox === selectedItem.dataset.export) {
        checkbox.MDCCheckbox.disabled = false;
      } else {
        checkbox.querySelector('input').checked = false;
        checkbox.MDCCheckbox.disabled = true;
      }
    });
  }

  if (sortOptionsSelector) initSortOptions();
  if (studentNumberCheckBox) studentNumberCheckBox.addEventListener('change', handleStudentNumberChange);
  if (studentFirstNameCheckBox) studentFirstNameCheckBox.addEventListener('change', handleStudentNameChange);
  if (studentLastNameCheckBox) studentLastNameCheckBox.addEventListener('change', handleStudentNameChange);
  if (exportOptionsSelector) exportOptionsSelector.MDCSelect.listen('MDCSelect:change', handleExportOptionsChange);
});
