/* global Redactor */

(function ($R) {
  $R.add('plugin', 'history', {
    init(app) {
      this.app = app;
      this.toolbar = app.toolbar;
      this.buffer = app.module.buffer;
    },
    start() {
      this.undo = this.toolbar.getButton('undo');
      this.redo = this.toolbar.getButton('redo');

      this.updateButtonStates();
    },
    onchanged() {
      this.updateButtonStates();
    },
    onundo() {
      this.updateUndoButtonState();
    },
    onredo() {
      this.updateRedoButtonState();
    },
    updateButtonStates() {
      this.updateUndoButtonState();
      this.updateRedoButtonState();
    },
    updateUndoButtonState() {
      if (this.buffer.undoStorage.length === 0) {
        this.undo.addClass('re-button--disabled');
      } else {
        this.undo.removeClass('re-button--disabled');
      }
    },
    updateRedoButtonState() {
      if (this.buffer.redoStorage.length === 0) {
        this.redo.addClass('re-button--disabled');
      } else {
        this.redo.removeClass('re-button--disabled');
      }
    }
  });
}(Redactor));
