/* global Pagy */

import onmount from 'onmount';

onmount('.mdc-data-table__pagination', function () {
  const table = this.closest('.mdc-data-table');
  const prevPage = this.querySelector('[data-prev-page]');
  const nextPage = this.querySelector('[data-next-page]');

  function handlePageChange() {
    if (table) table.MDCDataTable.showProgress();
  }

  if (prevPage) prevPage.addEventListener('click', handlePageChange);
  if (nextPage) nextPage.addEventListener('click', handlePageChange);
  Pagy.init(this);
});
