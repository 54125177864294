import Rails from '@rails/ujs';
import { serialize } from '@modules/custom';

export const getPresignedUrl = function (url, filename) {
  return new Promise((resolve, reject) => {
    Rails.ajax({
      url,
      type: 'GET',
      dataType: 'json',
      data: serialize({ filename }),
      success: (data) => resolve(data),
      error: (data) => reject({
        status: data.status,
        statusText: data.statusText,
        message: data.responseText
      })
    });
  });
};

export const uploadToObjectStore = function (file, url, el, progressFn, resetFn) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', url, true);

    if (typeof progressFn === 'function') {
      xhr.upload.onprogress = function (event) {
        return progressFn(event);
      };
    }

    xhr.onload = function () {
      if (this.status === 201) {
        return resolve(xhr.response);
      }

      return reject({
        status: this.status,
        statusText: xhr.statusText,
        message: xhr.responseText
      });
    };

    xhr.onerror = function () {
      return reject({
        status: this.status,
        statusText: xhr.statusText
      });
    };
    if (typeof resetFn === 'function') resetFn();
    xhr.send(file);
  });
};

export const createUpload = function (file, data, params, uploadUrl) {
  return new Promise((resolve, reject) => {
    const url = data.get_url;
    const uploadParams = {
      url,
      bucket: data.bucket,
      key: data.key,
      file_name: file.name,
      file_size: file.size,
      file_content_type: file.type
    };
    const mergedParams = { ...params, ...uploadParams };

    Rails.ajax({
      url: uploadUrl || '/uploads',
      type: 'POST',
      dataType: 'json',
      data: serialize({ upload: mergedParams }),
      success: (response) => resolve(response),
      error: (response, statusText, xhr) => (
        reject({
          status: xhr.status,
          statusText,
          message: response
        })
      )
    });
  });
};
