import onmount from 'onmount';
import { MDCSelect } from '@material-overwrites/mdc.select';

onmount('[data-js-select-menu-visibility]', function () {
  const self = this;
  const selectMenu = this.querySelector('[data-js-select-menu]');
  const values = [...selectMenu.querySelectorAll('.mdc-list-item[data-value]')].map((el) => el.dataset.value);

  if (!selectMenu.MDCSelect) {
    selectMenu.MDCSelect = new MDCSelect(selectMenu);
    selectMenu.setAttribute('data-mdc-auto-init-state', 'initialized');
  }

  function hideTarget(el) {
    el.classList.add('d-none');

    el.querySelectorAll('[data-mdc-auto-init]').forEach((component) => {
      const componentName = component.getAttribute('data-mdc-auto-init');
      if (componentName === 'MDCTextField') component[componentName].value = '';
    });
  }

  function hideTargets(selector) {
    self.querySelectorAll(selector).forEach((el) => hideTarget(el));
  }

  function showTargets(selector) {
    self.querySelectorAll(selector).forEach((el) => el.classList.remove('d-none'));
  }

  function handleChange() {
    values.forEach((value) => {
      if (value !== selectMenu.MDCSelect.value) hideTargets(value);
    });

    showTargets(selectMenu.MDCSelect.value);
  }

  selectMenu.MDCSelect.listen('MDCSelect:change', handleChange);
  handleChange();
});
