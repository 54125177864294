import { Controller } from '@hotwired/stimulus';
import { useDebounce } from 'stimulus-use';

export default class extends Controller {
  static debounces = ['recalculateChoicesPoints', 'updateChoicesPoints', 'recalculateMaximumPoints'];

  static targets = ['points', 'maximumPoints', 'selected'];

  connect() {
    useDebounce(this, { wait: 250 });
  }

  validateChoices({ detail: { value } }) {
    if (value === 'automatic_scoring') return;

    this.pointsTargets.forEach((input) => {
      const inputValue = parseFloat(input.value);
      if (Number.isNaN(inputValue)) input.numeric.setValue(0);
    });
  }

  recalculateChoicesPoints() {
    if (!parseFloat(this.maximumPointsTarget.value)) return;

    const fields = this.fieldsWithPoints();
    const value = fields.length ? parseFloat(this.maximumPointsTarget.value) / fields.length : 0;

    fields.forEach((field) => field.numeric.setValue(value));
  }

  updateChoicesPoints() {
    if (!this.hasPointsTarget) return;

    this.recalculateChoicesPoints();
  }

  recalculateMaximumPoints() {
    const value = this.fieldsWithPoints().reduce((accumulator, field) => (accumulator + parseFloat(field.value)), 0);

    this.maximumPointsTarget.numeric.setValue(value);
  }

  fieldsWithPoints() {
    return this.pointsTargets.filter((input) => parseFloat(input.value) > 0);
  }
}
