import { Controller } from '@hotwired/stimulus';
import { MDCDrawer } from '@material/drawer';
import { resetHotkeys } from '@modules/custom';

// Connects to data-controller="drawer"
export default class extends Controller {
  static targets = ['results'];

  connect() {
    this.drawer = new MDCDrawer(this.resultsTarget);
    this.drawerOpened = this.drawerOpened.bind(this);
    this.drawerClosed = this.drawerClosed.bind(this);

    this.drawer.listen('MDCDrawer:opened', this.drawerOpened);
    this.drawer.listen('MDCDrawer:closed', this.drawerClosed);
  }

  disconnect() {
    this.drawer.unlisten('MDCDrawer:opened', this.drawerOpened);
    this.drawer.unlisten('MDCDrawer:closed', this.drawerClosed);
  }

  drawerOpened() {
    this.previousHotkeysDisabled = document.body.dataset.hotkeysDisabled;
    document.body.dataset.hotkeysDisabled = 'temporary';
  }

  drawerClosed() {
    resetHotkeys(this);
  }

  open() {
    this.drawer.open = true;
  }

  close() {
    this.drawer.open = false;
  }
}
