import { Command } from './command';

export class UpdateStyling extends Command {
  constructor(tool, components, styling) {
    super(tool);
    this.components = components;

    this.styling = styling;
    this.old = [];
  }

  execute() {
    const { attrName, value } = this.styling;

    for (let i = 0; i < this.components.length; i += 1) {
      const component = this.components[i];
      this.old[i] = { attrName, value: component[attrName] };

      component[attrName] = value;
      component.updateStyling();
    }
    this.tool.dispatchEvent();
  }

  undo() {
    for (let i = 0; i < this.old.length; i += 1) {
      const component = this.components[i];
      const oldStyling = this.old[i];

      component[oldStyling.attrName] = oldStyling.value;
      component.updateStyling();
    }
    this.tool.dispatchEvent();
  }

  redo() {
    this.execute();
  }
}
