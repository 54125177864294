/* global google */

import onmount from 'onmount';
import Rails from '@rails/ujs';
import { debounce } from '@modules/custom';

onmount('[data-js-assignment-calendar]', function () {
  const { url, selectUrl } = this.dataset;
  const chartWrapper = this.querySelector('[data-js-chart]');

  function parseData(data) {
    data.forEach((row, i) => {
      const date = new Date(data[i][0]);
      data[i][0] = date;
    });
    return data;
  }

  function drawChart(data) {
    const dataTable = new google.visualization.DataTable();
    dataTable.addColumn({ type: 'date', id: 'Date' });
    dataTable.addColumn({ type: 'number', id: 'assignments' });
    dataTable.addRows(parseData(data));

    const options = {
      height: '1000',
      width: '100%',
      colorAxis: {
        minValue: 0,
        colors: ['#F3F0FF', '#190068']
      },
      calendar: {
        cellColor: {
          stroke: 'fff',
          strokeOpacity: 1,
          strokeWidth: 4
        },
        monthOutlineColor: {
          stroke: '#fff',
          strokeOpacity: 0.8,
          strokeWidth: 2
        },
        unusedMonthOutlineColor: {
          stroke: '#fff',
          strokeOpacity: 0.8,
          strokeWidth: 1
        }
      },
      noDataPattern: {
        backgroundColor: '#FAF7F6',
        color: '#FAF7F6'
      }
    };

    const chart = new google.visualization.Calendar(chartWrapper);

    google.visualization.events.addListener(chart, 'select', () => {
      if (!selectUrl) return;

      const epoch = chart.getSelection()[0].date;
      const date = new Date(epoch);
      window.location.href = `${selectUrl}?date=${date.toLocaleDateString()}`;
    });

    chart.draw(dataTable, options);
  }

  function getChartData() {
    Rails.ajax({
      url,
      type: 'GET',
      dataType: 'json',
      success: (data) => drawChart(data.data)
    });
  }
  this.getChartData = debounce(getChartData, 250);

  google.charts.load('current', { packages: ['calendar'] });
  google.charts.setOnLoadCallback(getChartData);

  window.addEventListener('resize', this.getChartData);
}, function () {
  window.removeEventListener('resize', this.getChartData);
});
