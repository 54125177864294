/* global Redactor */

import onmount from 'onmount';
import Rails from '@rails/ujs';

(function ($R) {
  $R.add('plugin', 'twemoji', {
    translations: {
      en: {
        twemoji: 'Emoji'
      }
    },
    init(app) {
      this.app = app;
      this.opts = app.opts;
      this.lang = app.lang;
      this.toolbar = app.toolbar;
      this.insertion = app.insertion;

      // local
      this.emojisets = [
        [
          '&#x1F601', '&#x1F602', '&#x1F603', '&#x1F604', '&#x1F605', '&#x1F606', '&#x1F607', '&#x1F609',
          '&#x1F60A', '&#x1F60B', '&#x1F60C', '&#x1F60D', '&#x1F60E', '&#x1F60F', '&#x1F610', '&#x1F611',
          '&#x1F612', '&#x1F613', '&#x1F614', '&#x1F615', '&#x1F616', '&#x1F617', '&#x1F618', '&#x1F619',
          '&#x1F61A', '&#x1F61B', '&#x1F61C', '&#x1F61D', '&#x1F61E', '&#x1F61F', '&#x1F620', '&#x1F621',
          '&#x1F622', '&#x1F623', '&#x1F624', '&#x1F625', '&#x1F626', '&#x1F627', '&#x1F628', '&#x1F629',
          '&#x1F62A', '&#x1F62B', '&#x1F62C', '&#x1F62D', '&#x1F62E', '&#x1F62F', '&#x1F630', '&#x1F631',
          '&#x1F632', '&#x1F633', '&#x1F634', '&#x1F635', '&#x1F636', '&#x1F637', '&#x1f910', '&#x1f911',
          '&#x1f912', '&#x1f913', '&#x1f914', '&#x1f915', '&#x1f916', '&#x1f917', '&#x1f442', '&#x1f443',
          '&#x1f444', '&#x1f445', '&#x1f446', '&#x1f447', '&#x1f448', '&#x1f449', '&#x1f44a', '&#x1f44b',
          '&#x1f44c', '&#x1f44d', '&#x1f44f', '&#x1f450', '&#x1f466', '&#x1f467', '&#x1f468', '&#x1f469',
          '&#x1f46a', '&#x1f46b', '&#x1f46c', '&#x1f46d', '&#x1f46e', '&#x1f46f', '&#x1f470', '&#x1f471',
          '&#x1f472', '&#x1f473', '&#x1f474', '&#x1f475', '&#x1f476', '&#x1f477', '&#x1f478', '&#x1f47b',
          '&#x1f47c', '&#x1f47e', '&#x1f481', '&#x1f482', '&#x1f483', '&#x1f485', '&#x1f486', '&#x1f487',
          '&#x1f4aa', '&#x1f575', '&#x1f590', '&#x1f596', '&#x1f918', '&#x270a', '&#x270b', '&#x270c', '&#x270d'
        ],
        [
          '&#x1f300', '&#x1f304', '&#x1f305', '&#x1f308', '&#x1f30a', '&#x1f30b', '&#x1f30c', '&#x1f30d',
          '&#x1f30e', '&#x1f30f', '&#x1f310', '&#x1f311', '&#x1f312', '&#x1f313', '&#x1f314', '&#x1f315',
          '&#x1f316', '&#x1f317', '&#x1f318', '&#x1f319', '&#x1f31a', '&#x1f31b', '&#x1f31c', '&#x1f31d',
          '&#x1f31e', '&#x1f31f', '&#x1f320', '&#x1f324', '&#x1f325', '&#x1f326', '&#x1f327', '&#x1f328',
          '&#x1f329', '&#x1f43a', '&#x1f32b', '&#x1f331', '&#x1f332', '&#x1f333', '&#x1f334', '&#x1f335',
          '&#x1f336', '&#x1f337', '&#x1f338', '&#x1f339', '&#x1f33a', '&#x1f33b', '&#x1f33c', '&#x1f33d',
          '&#x1f33e', '&#x1f33f', '&#x1f340', '&#x1f341', '&#x1f342', '&#x1f343', '&#x1f344', '&#x1f400',
          '&#x1f401', '&#x1f402', '&#x1f403', '&#x1f404', '&#x1f405', '&#x1f406', '&#x1f407', '&#x1f408',
          '&#x1f409', '&#x1f40a', '&#x1f40b', '&#x1f40c', '&#x1f40d', '&#x1f40e', '&#x1f40f', '&#x1f410',
          '&#x1f411', '&#x1f412', '&#x1f413', '&#x1f414', '&#x1f415', '&#x1f416', '&#x1f417', '&#x1f418',
          '&#x1f419', '&#x1f41a', '&#x1f41b', '&#x1f41c', '&#x1f41d', '&#x1f41e', '&#x1f41f', '&#x1f420',
          '&#x1f421', '&#x1f422', '&#x1f423', '&#x1f424', '&#x1f425', '&#x1f426', '&#x1f427', '&#x1f428',
          '&#x1f429', '&#x1f42a', '&#x1f42b', '&#x1f42c', '&#x1f42d', '&#x1f42e', '&#x1f42f', '&#x1f430',
          '&#x1f431', '&#x1f432', '&#x1f433', '&#x1f434', '&#x1f435', '&#x1f436', '&#x1f437', '&#x1f438',
          '&#x1f439', '&#x1f43a', '&#x1f43b', '&#x1f43c', '&#x1f43d', '&#x1f43e', '&#x1f43f'
        ],
        [
          '&#x1f32d', '&#x1f32c', '&#x1f32e', '&#x1f32f', '&#x1f330', '&#x1f344', '&#x1f345', '&#x1f347',
          '&#x1f348', '&#x1f349', '&#x1f34a', '&#x1f34b', '&#x1f34c', '&#x1f34d', '&#x1f34e', '&#x1f34f',
          '&#x1f350', '&#x1f352', '&#x1f353', '&#x1f354', '&#x1f355', '&#x1f356', '&#x1f357', '&#x1f358',
          '&#x1f359', '&#x1f35a', '&#x1f35b', '&#x1f35c', '&#x1f35d', '&#x1f35e', '&#x1f35f', '&#x1f360',
          '&#x1f361', '&#x1f362', '&#x1f363', '&#x1f364', '&#x1f365', '&#x1f366', '&#x1f367', '&#x1f368',
          '&#x1f369', '&#x1f36a', '&#x1f36b', '&#x1f36c', '&#x1f36d', '&#x1f36e', '&#x1f36f', '&#x1f370',
          '&#x1f371', '&#x1f372', '&#x1f373', '&#x1f374', '&#x1f375', '&#x1f376', '&#x1f377', '&#x1f378',
          '&#x1f379', '&#x1f37a', '&#x1f37b', '&#x1f37c', '&#x1f37d', '&#x1f37e', '&#x1f37f', '&#x1f382',
          '&#x1f9c0'
        ],
        [
          '&#x1f30d', '&#x1f30e', '&#x1f30f', '&#x1f310', '&#x1f301', '&#x1f303', '&#x1f306', '&#x1f307',
          '&#x1f309', '&#x1f3aa', '&#x1f3cd', '&#x1f3ce', '&#x1f3d4', '&#x1f3d5', '&#x1f3d6', '&#x1f3d7',
          '&#x1f3d8', '&#x1f3d9', '&#x1f3e0', '&#x1f3e1', '&#x1f3e2', '&#x1f3e3', '&#x1f3e4', '&#x1f3e5',
          '&#x1f3e6', '&#x1f3e7', '&#x1f3e8', '&#x1f3e9', '&#x1f3ea', '&#x1f3eb', '&#x1f3ec', '&#x1f3ed',
          '&#x1f3ee', '&#x1f3f0', '&#x1f54b', '&#x1f54c', '&#x1f54d', '&#x1f5fa', '&#x1f5fb', '&#x1f5fc',
          '&#x1f5fd', '&#x1f5fe', '&#x1f5ff', '&#x1f680', '&#x1f681', '&#x1f682', '&#x1f683', '&#x1f684',
          '&#x1f685', '&#x1f686', '&#x1f687', '&#x1f688', '&#x1f689', '&#x1f68a', '&#x1f68b', '&#x1f68c',
          '&#x1f68d', '&#x1f68e', '&#x1f68f', '&#x1f690', '&#x1f691', '&#x1f692', '&#x1f693', '&#x1f694',
          '&#x1f695', '&#x1f696', '&#x1f697', '&#x1f698', '&#x1f699', '&#x1f69a', '&#x1f69b', '&#x1f69c',
          '&#x1f69d', '&#x1f69e', '&#x1f69f', '&#x1f6a0', '&#x1f6a1', '&#x1f6a2', '&#x1f6a4', '&#x1f6b2',
          '&#x1f6e3', '&#x1f6e4', '&#x1f6e5', '&#x1f6e9', '&#x1f6eb', '&#x1f6ec', '&#x1f6f3', '&#x26e9',
          '&#x26ea', '&#x26f0', '&#x26f1', '&#x26f2', '&#x26f3', '&#x26f4', '&#x26f5', '&#x26fa', '&#x2708'
        ],
        [
          '&#x1f380', '&#x1f381', '&#x1f382', '&#x1f383', '&#x1f384', '&#x1f385', '&#x1f386', '&#x1f387',
          '&#x1f388', '&#x1f389', '&#x1f38a', '&#x1f38b', '&#x1f38c', '&#x1f38d', '&#x1f38e', '&#x1f38f',
          '&#x1f390', '&#x1f391', '&#x1f392', '&#x1f393', '&#x1f396', '&#x1f397', '&#x1f399', '&#x1f39a',
          '&#x1f39b', '&#x1f39e', '&#x1f39f', '&#x1f3a0', '&#x1f3a1', '&#x1f3a2', '&#x1f3a3', '&#x1f3a4',
          '&#x1f3a5', '&#x1f3a6', '&#x1f3a7', '&#x1f3a8', '&#x1f3a9', '&#x1f3aa', '&#x1f3ab', '&#x1f3ac',
          '&#x1f3ad', '&#x1f3ae', '&#x1f3af', '&#x1f3b0', '&#x1f3b1', '&#x1f3b2', '&#x1f3b3', '&#x1f3b4',
          '&#x1f3b5', '&#x1f3b6', '&#x1f3b7', '&#x1f3b8', '&#x1f3b9', '&#x1f3ba', '&#x1f3bb', '&#x1f3bc',
          '&#x1f3bd', '&#x1f3be', '&#x1f3bf', '&#x1f3c0', '&#x1f3c1', '&#x1f3c2', '&#x1f3c2', '&#x1f3c3',
          '&#x1f3c4', '&#x1f3c5', '&#x1f3c6', '&#x1f3c7', '&#x1f3c8', '&#x1f3c9', '&#x1f3ca', '&#x1f3cb',
          '&#x1f3cc', '&#x1f3cf', '&#x1f3d0', '&#x1f3d1', '&#x1f3d2', '&#x1f3d3', '&#x1f3f8', '&#x1f3f9',
          '&#x1f6b4', '&#x1f6b5', '&#x1f6b6', '&#x26f9'
        ],
        [
          '&#x1f3f7', '&#x1f3fa', '&#x1f451', '&#x1f452', '&#x1f453', '&#x1f454', '&#x1f455', '&#x1f456',
          '&#x1f457', '&#x1f458', '&#x1f459', '&#x1f45a', '&#x1f45b', '&#x1f45c', '&#x1f45d', '&#x1f45e',
          '&#x1f45f', '&#x1f460', '&#x1f461', '&#x1f462', '&#x1f488', '&#x1f489', '&#x1f48a', '&#x1f4b3',
          '&#x1f4b4', '&#x1f4b5', '&#x1f4b6', '&#x1f4b7', '&#x1f4b8', '&#x1f4b9', '&#x1f4ba', '&#x1f4bb',
          '&#x1f4bc', '&#x1f4bd', '&#x1f4be', '&#x1f4bf', '&#x1f4c0', '&#x1f4c1', '&#x1f4c2', '&#x1f4c3',
          '&#x1f4c4', '&#x1f4c5', '&#x1f4c6', '&#x1f4c7', '&#x1f4c8', '&#x1f4c9', '&#x1f4ca', '&#x1f4cb',
          '&#x1f4cc', '&#x1f4cd', '&#x1f4ce', '&#x1f4cf', '&#x1f4d0', '&#x1f4d1', '&#x1f4d2', '&#x1f4d3',
          '&#x1f4d4', '&#x1f4d5', '&#x1f4d6', '&#x1f4d7', '&#x1f4d8', '&#x1f4d9', '&#x1f4da', '&#x1f4db',
          '&#x1f4dc', '&#x1f4dd', '&#x1f4de', '&#x1f4df', '&#x1f4e0', '&#x1f4e1', '&#x1f4e2', '&#x1f4e3',
          '&#x1f4e4', '&#x1f4e5', '&#x1f4e6', '&#x1f4e7', '&#x1f4e8', '&#x1f4e9', '&#x1f4ea', '&#x1f4eb',
          '&#x1f4ec', '&#x1f4ed', '&#x1f4ef', '&#x1f4f0', '&#x1f525', '&#x1f526', '&#x1f527', '&#x1f528',
          '&#x1f529', '&#x1f52a', '&#x1f52b', '&#x1f52c', '&#x1f52d', '&#x1f52e', '&#x1f56f', '&#x1f570',
          '&#x1f573', '&#x1f576', '&#x1f579', '&#x1f57a', '&#x1f587', '&#x1f489', '&#x1f58a', '&#x1f58b',
          '&#x1f58c', '&#x1f58d', '&#x1f5a5', '&#x1f5a8', '&#x1f5b1', '&#x1f5b2', '&#x1f5bc', '&#x1f4c2',
          '&#x1f5c3', '&#x1f5c4', '&#x1f5d1', '&#x1f5d2', '&#x1f5d3', '&#x1f5dc', '&#x1f5dd', '&#x1f5de',
          '&#x1f5e1'
        ],
        [
          '&#x1f004', '&#x1f0cf', '&#x1f170', '&#x1f171', '&#x1f17e', '&#x1f17f', '&#x1f18e', '&#x1f191',
          '&#x1f192', '&#x1f193', '&#x1f194', '&#x1f195', '&#x1f196', '&#x1f197', '&#x1f198', '&#x1f199',
          '&#x1f19a', '&#x1f1e6', '&#x1f1e7', '&#x1f1e8', '&#x1f1e9', '&#x1f1ea', '&#x1f1eb', '&#x1f1ec',
          '&#x1f1ed', '&#x1f1ef', '&#x1f1f0', '&#x1f1f1', '&#x1f1f2', '&#x1f1f3', '&#x1f1f4', '&#x1f1f5',
          '&#x1f1f6', '&#x1f1f7', '&#x1f1f8', '&#x1f1f9', '&#x1f1fa', '&#x1f1fb', '&#x1f1fc', '&#x1f1fd',
          '&#x1f1fe', '&#x1f1ff', '&#x1f201', '&#x1f202', '&#x1f21a', '&#x1f22f', '&#x1f232', '&#x1f233',
          '&#x1f234', '&#x1f235', '&#x1f236', '&#x1f237', '&#x1f238', '&#x1f239', '&#x1f23a', '&#x1f493',
          '&#x1f494', '&#x1f495', '&#x1f496', '&#x1f497', '&#x1f498', '&#x1f499', '&#x1f49a', '&#x1f49b',
          '&#x1f49c', '&#x1f49d', '&#x1f49e', '&#x1f49f', '&#x1f4a0', '&#x1f4a1', '&#x1f4a2', '&#x1f4a3',
          '&#x1f4a4', '&#x1f4a5', '&#x1f4a6', '&#x1f4a7', '&#x1f4a8', '&#x1f4a9', '&#x1f4aa', '&#x1f4ab',
          '&#x1f4ac', '&#x1f4ad', '&#x1f4ae', '&#x1f4af', '&#x1f4b0', '&#x1f4b1', '&#x1f4b2'
        ],
        [
          '&#x1f1e6;&#x1f1e8;', '&#x1f1e6;&#x1f1e9;', '&#x1f1e6;&#x1f1ea;', '&#x1f1e6;&#x1f1eb;', '&#x1f1e6;&#x1f1ec;',
          '&#x1f1e6;&#x1f1ee;', '&#x1f1e6;&#x1f1f1;', '&#x1f1e6;&#x1f1f2;', '&#x1f1e6;&#x1f1f4;', '&#x1f1e6;&#x1f1f6;',
          '&#x1f1e6;&#x1f1f7;', '&#x1f1e6;&#x1f1f8;', '&#x1f1e6;&#x1f1f9;', '&#x1f1e6;&#x1f1fa;', '&#x1f1e6;&#x1f1fc;',
          '&#x1f1e6;&#x1f1fd;', '&#x1f1e6;&#x1f1ff;', '&#x1f1e7;&#x1f1e6;', '&#x1f1e7;&#x1f1e7;', '&#x1f1e7;&#x1f1e9;',
          '&#x1f1e7;&#x1f1ea;', '&#x1f1e7;&#x1f1eb;', '&#x1f1e7;&#x1f1ec;', '&#x1f1e7;&#x1f1ed;', '&#x1f1e7;&#x1f1ee;',
          '&#x1f1e7;&#x1f1ef;', '&#x1f1e7;&#x1f1f1;', '&#x1f1e7;&#x1f1f2;', '&#x1f1e7;&#x1f1f3;', '&#x1f1e7;&#x1f1f4;',
          '&#x1f1e7;&#x1f1f6;', '&#x1f1e7;&#x1f1f7;', '&#x1f1e7;&#x1f1f8;', '&#x1f1e7;&#x1f1f9;', '&#x1f1e7;&#x1f1fb;',
          '&#x1f1e7;&#x1f1fc;', '&#x1f1e7;&#x1f1fe;', '&#x1f1e7;&#x1f1ff;', '&#x1f1e8;&#x1f1e6;', '&#x1f1e8;&#x1f1e8;',
          '&#x1f1e8;&#x1f1e9;', '&#x1f1e8;&#x1f1eb;', '&#x1f1e8;&#x1f1ec;', '&#x1f1e8;&#x1f1ed;', '&#x1f1e8;&#x1f1ee;',
          '&#x1f1e8;&#x1f1f0;', '&#x1f1e8;&#x1f1f1;', '&#x1f1e8;&#x1f1f2;', '&#x1f1e8;&#x1f1f3;', '&#x1f1e8;&#x1f1f4;',
          '&#x1f1e8;&#x1f1f5;', '&#x1f1e8;&#x1f1f7;', '&#x1f1e8;&#x1f1fa;', '&#x1f1e8;&#x1f1fb;', '&#x1f1e8;&#x1f1fc;',
          '&#x1f1e8;&#x1f1fd;', '&#x1f1e8;&#x1f1fe;', '&#x1f1e8;&#x1f1ff;', '&#x1f1e9;&#x1f1ea;', '&#x1f1e9;&#x1f1ec;',
          '&#x1f1e9;&#x1f1ef;', '&#x1f1e9;&#x1f1f0;', '&#x1f1e9;&#x1f1f2;', '&#x1f1e9;&#x1f1f4;', '&#x1f1e9;&#x1f1ff;',
          '&#x1f1ea;&#x1f1e6;', '&#x1f1ea;&#x1f1e8;', '&#x1f1ea;&#x1f1ea;', '&#x1f1ea;&#x1f1ec;', '&#x1f1ea;&#x1f1ed;',
          '&#x1f1ea;&#x1f1f7;', '&#x1f1ea;&#x1f1f8;', '&#x1f1ea;&#x1f1f9;', '&#x1f1ea;&#x1f1fa;', '&#x1f1eb;&#x1f1ee;',
          '&#x1f1eb;&#x1f1ef;', '&#x1f1eb;&#x1f1f0;', '&#x1f1eb;&#x1f1f2;', '&#x1f1eb;&#x1f1f4;', '&#x1f1eb;&#x1f1f7;',
          '&#x1f1ec;&#x1f1e6;', '&#x1f1ec;&#x1f1e7;', '&#x1f1ec;&#x1f1e9;', '&#x1f1ec;&#x1f1ea;', '&#x1f1ec;&#x1f1eb;',
          '&#x1f1ec;&#x1f1ec;', '&#x1f1ec;&#x1f1ed;', '&#x1f1ec;&#x1f1ee;', '&#x1f1ec;&#x1f1f1;', '&#x1f1ec;&#x1f1f2;',
          '&#x1f1ec;&#x1f1f3;', '&#x1f1ec;&#x1f1f5;', '&#x1f1ec;&#x1f1f6;', '&#x1f1ec;&#x1f1f7;', '&#x1f1ec;&#x1f1f8;',
          '&#x1f1ec;&#x1f1f9;', '&#x1f1ec;&#x1f1fa;', '&#x1f1ec;&#x1f1fc;', '&#x1f1ec;&#x1f1fe;', '&#x1f1ed;&#x1f1f0;',
          '&#x1f1ed;&#x1f1f2;', '&#x1f1ed;&#x1f1f3;', '&#x1f1ed;&#x1f1f7;', '&#x1f1ed;&#x1f1f9;', '&#x1f1ed;&#x1f1fa;',
          '&#x1f1ee;&#x1f1e8;', '&#x1f1ee;&#x1f1e9;', '&#x1f1ee;&#x1f1ea;', '&#x1f1ee;&#x1f1f1;', '&#x1f1ee;&#x1f1f2;',
          '&#x1f1ee;&#x1f1f3;', '&#x1f1ee;&#x1f1f4;', '&#x1f1ee;&#x1f1f6;', '&#x1f1ee;&#x1f1f7;', '&#x1f1ee;&#x1f1f8;',
          '&#x1f1ee;&#x1f1f9;', '&#x1f1ef;&#x1f1ea;', '&#x1f1ef;&#x1f1f2;', '&#x1f1ef;&#x1f1f4;', '&#x1f1ef;&#x1f1f5;',
          '&#x1f1f0;&#x1f1ea;', '&#x1f1f0;&#x1f1ec;', '&#x1f1f0;&#x1f1ed;', '&#x1f1f0;&#x1f1ee;', '&#x1f1f0;&#x1f1f2;',
          '&#x1f1f0;&#x1f1f3;', '&#x1f1f0;&#x1f1f5;', '&#x1f1f0;&#x1f1f7;', '&#x1f1f0;&#x1f1fc;', '&#x1f1f0;&#x1f1fe;',
          '&#x1f1f0;&#x1f1ff;', '&#x1f1f1;&#x1f1e6;', '&#x1f1f1;&#x1f1e7;', '&#x1f1f1;&#x1f1e8;', '&#x1f1f1;&#x1f1ee;',
          '&#x1f1f1;&#x1f1f0;', '&#x1f1f1;&#x1f1f7;', '&#x1f1f1;&#x1f1f8;', '&#x1f1f1;&#x1f1f9;', '&#x1f1f1;&#x1f1fa;',
          '&#x1f1f1;&#x1f1fb;', '&#x1f1f1;&#x1f1fe;', '&#x1f1f2;&#x1f1e6;', '&#x1f1f2;&#x1f1e8;', '&#x1f1f2;&#x1f1e9;',
          '&#x1f1f2;&#x1f1ea;', '&#x1f1f2;&#x1f1eb;', '&#x1f1f2;&#x1f1ec;', '&#x1f1f2;&#x1f1ed;', '&#x1f1f2;&#x1f1f0;',
          '&#x1f1f2;&#x1f1f1;', '&#x1f1f2;&#x1f1f2;', '&#x1f1f2;&#x1f1f3;', '&#x1f1f2;&#x1f1f4;', '&#x1f1f2;&#x1f1f5;',
          '&#x1f1f2;&#x1f1f6;', '&#x1f1f2;&#x1f1f7;', '&#x1f1f2;&#x1f1f8;', '&#x1f1f2;&#x1f1f9;', '&#x1f1f2;&#x1f1fa;',
          '&#x1f1f2;&#x1f1fb;', '&#x1f1f2;&#x1f1fc;', '&#x1f1f2;&#x1f1fd;', '&#x1f1f2;&#x1f1fe;', '&#x1f1f2;&#x1f1ff;',
          '&#x1f1f3;&#x1f1e6;', '&#x1f1f3;&#x1f1e8;', '&#x1f1f3;&#x1f1ea;', '&#x1f1f3;&#x1f1eb;', '&#x1f1f3;&#x1f1ec;',
          '&#x1f1f3;&#x1f1ee;', '&#x1f1f3;&#x1f1f1;', '&#x1f1f3;&#x1f1f4;', '&#x1f1f3;&#x1f1f5;', '&#x1f1f3;&#x1f1f7;',
          '&#x1f1f3;&#x1f1fa;', '&#x1f1f3;&#x1f1ff;', '&#x1f1f4;&#x1f1f2;', '&#x1f1f5;&#x1f1e6;', '&#x1f1f5;&#x1f1ea;',
          '&#x1f1f5;&#x1f1eb;', '&#x1f1f5;&#x1f1ec;', '&#x1f1f5;&#x1f1ed;', '&#x1f1f5;&#x1f1f0;', '&#x1f1f5;&#x1f1f1;',
          '&#x1f1f5;&#x1f1f2;', '&#x1f1f5;&#x1f1f3;', '&#x1f1f5;&#x1f1f7;', '&#x1f1f5;&#x1f1f8;', '&#x1f1f5;&#x1f1f9;',
          '&#x1f1f5;&#x1f1fc;', '&#x1f1f5;&#x1f1fe;', '&#x1f1f6;&#x1f1e6;', '&#x1f1f7;&#x1f1ea;', '&#x1f1f7;&#x1f1f4;',
          '&#x1f1f7;&#x1f1f8;', '&#x1f1f7;&#x1f1fa;', '&#x1f1f7;&#x1f1fc;', '&#x1f1f8;&#x1f1e6;', '&#x1f1f8;&#x1f1e7;',
          '&#x1f1f8;&#x1f1e8;', '&#x1f1f8;&#x1f1e9;', '&#x1f1f8;&#x1f1ea;', '&#x1f1f8;&#x1f1ec;', '&#x1f1f8;&#x1f1ed;',
          '&#x1f1f8;&#x1f1ee;', '&#x1f1f8;&#x1f1ef;', '&#x1f1f8;&#x1f1f0;', '&#x1f1f8;&#x1f1f1;', '&#x1f1f8;&#x1f1f2;',
          '&#x1f1f8;&#x1f1f3;', '&#x1f1f8;&#x1f1f4;', '&#x1f1f8;&#x1f1f7;', '&#x1f1f8;&#x1f1f8;', '&#x1f1f8;&#x1f1f9;',
          '&#x1f1f8;&#x1f1fb;', '&#x1f1f8;&#x1f1fd;', '&#x1f1f8;&#x1f1fe;', '&#x1f1f8;&#x1f1ff;', '&#x1f1f9;&#x1f1e6;',
          '&#x1f1f9;&#x1f1e8;', '&#x1f1f9;&#x1f1e9;', '&#x1f1f9;&#x1f1eb;', '&#x1f1f9;&#x1f1ec;', '&#x1f1f9;&#x1f1ed;',
          '&#x1f1f9;&#x1f1ef;', '&#x1f1f9;&#x1f1f0;', '&#x1f1f9;&#x1f1f1;', '&#x1f1f9;&#x1f1f2;', '&#x1f1f9;&#x1f1f3;',
          '&#x1f1f9;&#x1f1f4;', '&#x1f1f9;&#x1f1f7;', '&#x1f1f9;&#x1f1f9;', '&#x1f1f9;&#x1f1fb;', '&#x1f1f9;&#x1f1fc;',
          '&#x1f1f9;&#x1f1ff;', '&#x1f1fa;&#x1f1e6;', '&#x1f1fa;&#x1f1ec;', '&#x1f1fa;&#x1f1f2;', '&#x1f1fa;&#x1f1f8;',
          '&#x1f1fa;&#x1f1fe;', '&#x1f1fa;&#x1f1ff;', '&#x1f1fb;&#x1f1e6;', '&#x1f1fb;&#x1f1e8;', '&#x1f1fb;&#x1f1ea;',
          '&#x1f1fb;&#x1f1ec;', '&#x1f1fb;&#x1f1ee;', '&#x1f1fb;&#x1f1f3;', '&#x1f1fb;&#x1f1fa;', '&#x1f1fc;&#x1f1eb;',
          '&#x1f1fc;&#x1f1f8;', '&#x1f1fd;&#x1f1f0;', '&#x1f1fe;&#x1f1ea;', '&#x1f1fe;&#x1f1f9;', '&#x1f1ff;&#x1f1e6;',
          '&#x1f1ff;&#x1f1f2;'
        ]
      ];
      this.twemojiurl = 'https://unpkg.com/twemoji@latest/dist/twemoji.min.js';
      this.twemojiconfig = { ext: '.png', size: 36 };
    },

    ondropdown: {
      open(e, dropdown, button) {
        if (button.name !== 'twemoji') return;
        if (dropdown.find('.mdc-tab-bar').length) return;

        this.fetchTabBar(dropdown.find('.redactor-dropdown-cells'));
        onmount();
      }
    },

    // public
    start() {
      this.fetchTwemoji();

      const btnObj = {
        title: this.lang.get('twemoji')
      };
      this.$button = this.toolbar.addButton('twemoji', btnObj);
      this.$button.setIcon('<i class="material-icons">insert_emoticon</i>');
      const $dropdown = this.buildDropdown();
      this.$button.setDropdown($dropdown);
    },

    // private
    fetchTwemoji() {
      const s = document.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = this.twemojiurl;
      const x = document.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
    },

    buildDropdown() {
      const self = this;
      const $dropdown = $R.dom('<div data-js-emoji class="redactor-dropdown-cells">');

      const func = function (e) {
        e.preventDefault();

        const $el = $R.dom(e.target);
        self.insert($el.data('emoji'));
      };

      for (let i = 0; i < this.emojisets.length; i += 1) {
        const emojiset = this.emojisets[i];
        const $set = $R.dom(`<div data-js-emoji-set class='d-none' data-set-id=${i}>`);
        for (let j = 0; j < emojiset.length; j += 1) {
          const emoji = emojiset[j];
          const $el = $R.dom('<a class="f1" href="#" style="line-height:42px; width:42px; height:42px">');
          $el.data('emoji', emoji);
          $el.html(emoji);
          $el.on('click', func);

          $set.append($el);
        }
        $dropdown.append($set);
      }

      return $dropdown;
    },

    insert(character) {
      this.insertion.insertChar(character);
    },

    fetchTabBar($body) {
      const emojiTabBarHtml = sessionStorage.getItem('emojiTabBarHtml');
      if (emojiTabBarHtml) {
        $body.prepend(emojiTabBarHtml);
        return;
      }

      Rails.ajax({
        url: '/emojis/tab_bar',
        type: 'GET',
        dataType: 'json',
        success: (data) => {
          if (data.html) {
            $body.prepend(data.html);
            sessionStorage.setItem('emojiTabBarHtml', data.html);
          }
        }
      });
    }
  });
}(Redactor));
