import onmount from 'onmount';

onmount('[data-js-clear-filters]', function () {
  const self = this;
  const url = new URL(window.location.href);
  const query = url.searchParams.get('q');
  if (query) self.classList.remove('d-none');

  function handleClick() {
    url.searchParams.delete('q');
    window.location.assign(url);
  }

  self.addEventListener('click', handleClick);
});
