import '@kollegorna/cocoon-vanilla-js';
import onmount from 'onmount';
import mdcAutoInit from '@material/auto-init';

onmount('[data-js-config-keys]', function () {
  function handleConfigKeyAdd(e) {
    mdcAutoInit();
    onmount();
    const insertedItem = e.target.querySelector('.nested-fields:not(.d-none):last-child');
    const mdcTextField = insertedItem.querySelector('.mdc-text-field');
    mdcTextField.MDCTextField.focus();
  }

  function handleConfigKeyRemove(e) {
    const removedItem = e.detail;
    removedItem.classList.add('d-none');
  }

  this.addEventListener('cocoon:after-insert', handleConfigKeyAdd);
  this.addEventListener('cocoon:after-remove', handleConfigKeyRemove);
});
