{
  "fr": {
    "js": {
      "redactor": {
        "accessibility-help-label": "Éditeur de texte enrichi",
        "add-gap": "Ajouter un trou",
        "add-head": "Ajouter un titre",
        "add-variable": "Ajouter une variable",
        "align": "Aligner",
        "align-center": "Aligner au centre",
        "align-left": "Aligner à gauche",
        "align-right": "Aligner à droite",
        "allow-microphone-access": "Autoriser l'accès au microphone",
        "audio": "Enregistrement audio",
        "background": "Fond",
        "background-color": "Couleur d'arrière-plan",
        "blockquote": "Citation",
        "bold": "Gras",
        "bold-abbr": "B",
        "bulletslist": "Liste à puces",
        "cancel": "Annuler",
        "caption": "Légende",
        "center": "Centre",
        "change": "Changer",
        "change-picture": "Changer d'image",
        "characterLimit": "Vous ne pouvez pas coller plus de 5000 caractères",
        "chars": "Caractères",
        "choose": "Choisissez",
        "close": "Fermer",
        "code": "Code",
        "delete": "Supprimer",
        "delete-column": "Supprimer la colonne",
        "delete-head": "Supprimer le titre",
        "delete-row": "Supprimer la ligne",
        "delete-table": "Supprimer le tableau",
        "deleted": "Barré",
        "deleted-abbr": "S",
        "drawing": "Dessin",
        "edit": "Éditer",
        "equation": "Équation",
        "error-accessing-microphone": "Une erreur s'est produite lors de l'accès à votre microphone",
        "file": "Fichier",
        "filename": "Nom du fichier",
        "fontcolor": "Couleur du texte",
        "format": "Format du texte",
        "fullscreen": "Plein écran",
        "gap": "Trou",
        "grade-formula-points": "Points obtenus",
        "grade-formula-total": "Total des points",
        "heading": "Titre",
        "heading1": "Titre 1",
        "heading2": "Titre 2",
        "heading3": "Titre 3",
        "heading4": "Titre 4",
        "heading5": "Titre 5",
        "heading6": "Titre 6",
        "horizontalrule": "Ligne",
        "image": "Image",
        "image-position": "Position",
        "indent": "Augmenter l'alinéa",
        "insert": "Insérer",
        "insert-column-left": "Insérer colonne à gauche",
        "insert-column-right": "Insérer colonne à droite",
        "insert-row-above": "Insérer une ligne au-dessus.",
        "insert-row-below": "Insérer une ligne en dessous",
        "insert-table": "Insérer table",
        "italic": "Italique",
        "italic-abbr": "I",
        "left": "Gauche",
        "link": "Lien",
        "link-edit": "Modifier le lien",
        "link-in-new-tab": "Ouvrir le lien dans un nouvel onglet",
        "link-insert": "Insérer un lien",
        "lists": "Listes",
        "make-recording-warning": "Faites un enregistrement d'abord",
        "maxWords": "Nombre maximum de mots",
        "no-variables": "Aucune variable définie",
        "none": "Aucun",
        "numberslist": "Liste numérotée",
        "optionList": "Liste d'options",
        "optional": "Facultatif",
        "orderedlist": "Liste ordonnée",
        "outdent": "Diminuer l'alinéa",
        "paragraph": "Texte normal",
        "pause": "Pause",
        "pre": "Code",
        "quote": "Citation",
        "record": "Enregistrer",
        "redo": "Refaire",
        "remove": "Supprimer",
        "resume": "Reprendre",
        "right": "Droit",
        "save": "Sauvegarder",
        "save as shortcut": "Enregistrer comme commentaire rapide",
        "shortcuts": "Commentaires rapides",
        "show shortcuts": "Afficher les commentaires rapides",
        "specialchars": "Caractères spéciaux",
        "stop": "Stop",
        "stream-unavailable": "Le stream n'est pas disponible",
        "subscript": "Indice",
        "subscript-abbr": "Ind",
        "superscript": "Exposant",
        "superscript-abbr": "Exp",
        "table": "Tableau",
        "take-picture": "Prendre une photo",
        "take-picture-warning": "Prendre une photo d'abord",
        "text": "Texte",
        "text-color": "Couleur du texte",
        "title": "Titre",
        "twemoji": "Emoji",
        "underline": "Souligner",
        "underline-abbr": "U",
        "undo": "Annuler",
        "unlink": "Dissocier",
        "unorderedlist": "Liste non ordonnée",
        "upload": "Télécharger",
        "upload-change-label": "Glisser une nouvelle image pour l'ajuster",
        "upload-label": "Faites glisser les fichiers ou cliquez pour les télécharger",
        "variable": "Variable",
        "webcam": "Photo de la webcam",
        "wordLimit": "Limite de mots atteinte",
        "words": "Mots"
      }
    }
  }
}
