import onmount from 'onmount';
import { MDCDrawer } from '@material/drawer';

onmount('[data-js-drawer]', function () {
  const self = this;
  const openDrawer = this.hasAttribute('data-js-drawer-open');
  const withToggle = this.hasAttribute('data-js-toggledby');

  if (!this.MDCDrawer) {
    this.MDCDrawer = new MDCDrawer(this);
    this.setAttribute('data-mdc-auto-init-state', 'initialized');
  }

  function closeDrawer(e) {
    if (e.target.hasAttribute('data-js-close-drawer')) {
      self.remove();
    } else if (e.target.hasAttribute('data-js-hide-drawer')) {
      self.MDCDrawer.open = false;
    }
  }

  function toggleDrawer() {
    self.MDCDrawer.open = !self.MDCDrawer.open;
  }

  if (openDrawer) {
    this.MDCDrawer.open = true;
  }

  if (withToggle) {
    const toggle = document.querySelector(self.getAttribute('data-js-toggledby'));
    toggle.addEventListener('click', toggleDrawer);
  }

  self.addEventListener('click', closeDrawer);
});
