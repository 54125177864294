import onmount from 'onmount';

onmount('[data-js-review-question-navigation]', function () {
  const questionId = this.dataset.id;
  const allOption = this.querySelector('[data-js-select-all]');
  const subquestions = this.querySelectorAll('[data-js-select-option]');
  const navigationOptions = this.querySelectorAll('[data-js-navigation-option]');

  let selectedSubquestions;

  function getSkipSubquestions() {
    if (selectedSubquestions.length <= 0) return '';

    const skippedSubquestions = [];
    subquestions.forEach((subquestion) => {
      const { id } = subquestion.dataset;
      if (selectedSubquestions.includes(id)) return;

      skippedSubquestions.push(id);
    });

    return skippedSubquestions.toString();
  }

  function getGroupsAndKlasses() {
    const url = new URL(window.location.href);
    return { groups: url.searchParams.get('groups'), klasses: url.searchParams.get('klasses') };
  }

  function handleAllClick() {
    selectedSubquestions = [];
    allOption.classList.add('active');

    subquestions.forEach((subquestion) => {
      subquestion.classList.remove('active');
    });

    localStorage.removeItem(`review-question-${questionId}-subquestions`);
  }

  function handleSubquestionClick() {
    const index = selectedSubquestions.indexOf(this.dataset.id);

    if (index === -1) {
      selectedSubquestions.push(this.dataset.id);
      allOption.classList.remove('active');
      this.classList.add('active');
    } else {
      selectedSubquestions.splice(index, 1);
      this.classList.remove('active');
    }

    if (selectedSubquestions.length) {
      localStorage.setItem(`review-question-${questionId}-subquestions`, JSON.stringify(selectedSubquestions));
    } else {
      localStorage.removeItem(`review-question-${questionId}-subquestions`);
    }
  }

  function handleNavigationClick() {
    const url = new URL(this.dataset.url);

    const skip = getSkipSubquestions();
    if (skip !== '') url.searchParams.set('skip', skip);

    const { groups, klasses } = getGroupsAndKlasses();
    if (groups !== null) url.searchParams.set((groups === 'none' ? 'without_group' : 'groups'), groups);
    if (klasses !== null) url.searchParams.set((klasses === 'none' ? 'without_klass' : 'klasses'), klasses);

    window.location = url.toString();
  }

  function init() {
    selectedSubquestions = JSON.parse(localStorage.getItem(`review-question-${questionId}-subquestions`)) || [];

    if (subquestions.length) {
      allOption.addEventListener('click', handleAllClick);
      if (selectedSubquestions.length) allOption.classList.remove('active');

      subquestions.forEach((subquestion) => {
        subquestion.addEventListener('click', handleSubquestionClick);

        if (selectedSubquestions.includes(subquestion.dataset.id)) {
          subquestion.classList.add('active');
        }
      });
    }

    navigationOptions.forEach((option) => {
      option.addEventListener('click', handleNavigationClick);
    });
  }

  init();
});
