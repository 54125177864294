/* global google, i18n */

import onmount from 'onmount';
import Rails from '@rails/ujs';
import { debounce } from '@modules/custom';

onmount('[data-js-longitudinal-chart]', function () {
  const self = this;
  const { url } = this.dataset;
  const chart = this.querySelector('[data-js-chart]');
  let data;

  function chartOptions() {
    return {
      vega: {
        $schema: 'https://vega.github.io/schema/vega/v5.json',
        height: 500,
        width: self.clientWidth,
        padding: 50,
        autosize: { type: 'fit', contains: 'padding' },

        config: {
          axis: {
            labelFont: 'Nunito',
            labelFontWeight: 400,
            labelFontSize: 12,
            domain: false,
            tickColor: '#D1D1D1',
            gridColor: '#D1D1D1',
            titleColor: '#464646',
            titleFontWeight: 400,
            titlePadding: 12
          }
        },

        signals: [
          {
            name: 'date_offset',
            update: 'bandwidth("x") / 2'
          },
          {
            name: 'mouseX',
            on: [
              {
                events: 'area:mousemove, line:mousemove, rule:mousemove',
                update: "invert('x', x())"
              },
              {
                events: 'area:mouseout',
                update: '{}'
              }
            ]
          }
        ],

        data: data.concat(
          {
            name: 'hover',
            source: 'student',
            transform: [
              { type: 'filter', expr: 'datum.id == mouseX' },
              {
                type: 'aggregate',
                fields: ['score', 'score'],
                ops: ['min', 'argmin'],
                as: ['min', 'argmin']
              }
            ]
          }
        ),

        scales: [
          {
            name: 'color',
            type: 'ordinal',
            domain: [
              i18n.t('js.results.domains.participant_score'),
              i18n.t('js.results.domains.median_percentile_reference_group'),
              i18n.t('js.results.domains.percentile_reference_group_range', { range: '5-95' }),
              i18n.t('js.results.domains.percentile_reference_group_range', { range: '25-75' })
            ],
            range: ['#FFF', '#FFF', '#FBD7C4', '#F18145']
          },
          {
            name: 'scores',
            type: 'linear',
            range: 'height',
            domain: [0, 1]
          },
          {
            name: 'dates',
            type: 'ordinal',
            domain: data[0].values.map((x) => x.id),
            range: data[0].values.map((x) => x.date)
          },
          {
            name: 'x',
            type: 'band',
            range: 'width',
            domain: { data: 'student', field: 'id' }
          }
        ],

        legends: [
          {
            fill: 'color',
            orient: 'bottom',
            stroke: 'color',
            columns: 4,
            columnPadding: 40,
            rowPadding: 16,
            symbolSize: 196,
            symbolType: 'square',
            labelLimit: 0,
            labelFont: 'Nunito',
            labelFontSize: 12,
            labelOffset: 8,
            encode: {
              symbols: {
                update: {
                  angle: { value: 90 },
                  stroke: [
                    { test: 'datum.index == 0', value: '#000' },
                    { test: 'datum.index == 1', value: '#D1D1D1' },
                    { signal: "scale('color', datum.label)" }
                  ],
                  opacity: [
                    { test: 'datum.index == 0 || datum.index == 1', value: 1 },
                    { value: 0.8 }
                  ],
                  shape: [
                    { test: 'datum.index == 0', value: 'circle' },
                    { test: 'datum.index == 1', value: 'M-0.25,-1H0.25V1H-0.25Z' },
                    { value: 'square' }
                  ],
                  strokeWidth: { value: 2 }
                }
              }
            }
          }
        ],

        axes: [
          {
            orient: 'bottom',
            scale: 'x',
            labelPadding: 8,
            tickSize: 8,
            title: `${i18n.t('js.results.domains.assignment_date')}`,
            encode: {
              labels: {
                interactive: true,
                update: {
                  text: { signal: "scale('dates', datum.label)" }
                }
              }
            }
          },
          {
            orient: 'left',
            scale: 'scores',
            values: [0, 0.25, 0.5, 0.75, 1],
            title: `${i18n.t('js.results.domains.score')}`,
            format: '%',
            labelPadding: 16,
            padding: 100,
            ticks: false,
            grid: true
          }
        ],

        marks: [
          {
            type: 'group',
            name: 'comparison_plot',
            marks: [
              {
                type: 'area',
                from: { data: 'comparison' },
                encode: {
                  enter: {
                    tooltip: {
                      signal: `
                        datum.name + '\\n' +
                        '${i18n.t('js.results.domains.nth_percentile', { count: 5 })}: '
                          + format(datum.min, '.00%') + '\\n' +
                        '${i18n.t('js.results.domains.nth_percentile', { count: 25 })}: '
                          + format(datum.q1, '.00%') + '\\n' +
                        '${i18n.t('js.results.domains.median')}: ' + format(datum.median, '.00%') + '\\n' +
                        '${i18n.t('js.results.domains.nth_percentile', { count: 75 })}: '
                          + format(datum.q3, '.00%') + '\\n' +
                        '${i18n.t('js.results.domains.nth_percentile', { count: 95 })}: ' + format(datum.max, '.00%')
                      `
                    },
                    x: { scale: 'x', field: 'id', offset: { signal: 'date_offset' } },
                    y: { scale: 'scores', field: 'min' },
                    y2: { scale: 'scores', field: 'q1' },
                    fill: { value: '#FBD7C4' },
                    fillOpacity: { value: 0.8 }
                  }
                }
              },
              {
                type: 'area',
                from: { data: 'comparison' },
                encode: {
                  enter: {
                    tooltip: {
                      signal: `
                        datum.name + '\\n' +
                        '${i18n.t('js.results.domains.nth_percentile', { count: 5 })}: '
                          + format(datum.min, '.00%') + '\\n' +
                        '${i18n.t('js.results.domains.nth_percentile', { count: 25 })}: '
                          + format(datum.q1, '.00%') + '\\n' +
                        '${i18n.t('js.results.domains.median')}: ' + format(datum.median, '.00%') + '\\n' +
                        '${i18n.t('js.results.domains.nth_percentile', { count: 75 })}: '
                          + format(datum.q3, '.00%') + '\\n' +
                        '${i18n.t('js.results.domains.nth_percentile', { count: 95 })}: ' + format(datum.max, '.00%')
                      `
                    },
                    x: { scale: 'x', field: 'id', offset: { signal: 'date_offset' } },
                    y: { scale: 'scores', field: 'q3' },
                    y2: { scale: 'scores', field: 'max' },
                    fill: { value: '#FBD7C4' },
                    fillOpacity: { value: 0.8 }
                  }
                }
              },
              {
                type: 'area',
                from: { data: 'comparison' },
                encode: {
                  enter: {
                    tooltip: {
                      signal: `
                        datum.name + '\\n' +
                        '${i18n.t('js.results.domains.nth_percentile', { count: 5 })}: '
                          + format(datum.min, '.00%') + '\\n' +
                        '${i18n.t('js.results.domains.nth_percentile', { count: 25 })}: '
                          + format(datum.q1, '.00%') + '\\n' +
                        '${i18n.t('js.results.domains.median')}: ' + format(datum.median, '.00%') + '\\n' +
                        '${i18n.t('js.results.domains.nth_percentile', { count: 75 })}: '
                          + format(datum.q3, '.00%') + '\\n' +
                        '${i18n.t('js.results.domains.nth_percentile', { count: 95 })}: ' + format(datum.max, '.00%')
                      `
                    },
                    x: { scale: 'x', field: 'id', offset: { signal: 'date_offset' } },
                    y: { scale: 'scores', field: 'q1' },
                    y2: { scale: 'scores', field: 'q3' },
                    fill: { value: '#F18145' },
                    fillOpacity: { value: 0.8 }
                  }
                }
              },
              {
                type: 'line',
                from: { data: 'comparison' },
                encode: {
                  enter: {
                    tooltip: {
                      signal: `
                        datum.name + '\\n' +
                        '${i18n.t('js.results.domains.nth_percentile', { count: 5 })}: '
                          + format(datum.min, '.00%') + '\\n' +
                        '${i18n.t('js.results.domains.nth_percentile', { count: 25 })}: '
                          + format(datum.q1, '.00%') + '\\n' +
                        '${i18n.t('js.results.domains.median')}: ' + format(datum.median, '.00%') + '\\n' +
                        '${i18n.t('js.results.domains.nth_percentile', { count: 75 })}: '
                          + format(datum.q3, '.00%') + '\\n' +
                        '${i18n.t('js.results.domains.nth_percentile', { count: 95 })}: ' + format(datum.max, '.00%')
                      `
                    },
                    x: { scale: 'x', field: 'id', offset: { signal: 'date_offset' } },
                    y: { scale: 'scores', field: 'median' },
                    stroke: { value: '#FFFFFF' },
                    strokeWidth: { value: 2 }
                  }
                }
              }
            ]
          },
          {
            type: 'rule',
            from: { data: 'hover' },
            encode: {
              enter: {
                strokeOpacity: { value: 1 },
                stroke: { value: 'black' }
              },
              update: {
                x: { scale: 'x', field: 'argmin.id', offset: { signal: 'date_offset' } },
                y: { signal: 'height' },
                x2: { scale: 'x', field: 'argmin.id', offset: { signal: 'date_offset' } },
                y2: { value: 0 }
              }
            }
          },
          {
            type: 'group',
            name: 'student_plot',
            marks: [
              {
                type: 'line',
                from: { data: 'student' },
                encode: {
                  enter: {
                    x: { scale: 'x', field: 'id', offset: { signal: 'date_offset' } },
                    y: { scale: 'scores', field: 'score' },
                    stroke: { value: '#2E2E2E' },
                    strokeWidth: { value: 2 }
                  }
                }
              },
              {
                type: 'symbol',
                from: { data: 'student' },
                encode: {
                  enter: {
                    x: { scale: 'x', field: 'id', offset: { signal: 'date_offset' } },
                    y: { scale: 'scores', field: 'score' },
                    tooltip: {
                      signal:
                        `datum.name + '\\n' + '${i18n.t('js.results.domains.score')}: ' + format(datum.score, '.00%')`
                    }
                  },
                  update: {
                    href: [
                      { test: 'datum.result_url == null', value: undefined },
                      { signal: `'${window.location.origin}' + datum.result_url` }
                    ],
                    cursor: [
                      { test: 'datum.result_url == null', value: 'default' },
                      { value: 'pointer' }
                    ],
                    fill: { value: 'white' },
                    size: { value: 128 },
                    stroke: { value: '#2E2E2E' },
                    strokeOpacity: { value: 1 },
                    strokeWidth: { value: 2 }
                  },
                  hover: {
                    fill: [{ test: 'datum.result_url != null', value: '#2E2E2E' }, { value: 'white' }],
                    strokeOpacity: [{ test: 'datum.result_url != null', value: 0.12 }, { value: 1 }],
                    strokeWidth: [{ test: 'datum.result_url != null', value: 12 }, { value: 2 }]
                  }
                }
              }
            ]
          }
        ]
      }
    };
  }

  function drawChart() {
    const dataTable = new google.visualization.DataTable();
    const googleChart = new google.visualization.VegaChart(chart);
    googleChart.draw(dataTable, chartOptions());
  }
  this.drawChart = debounce(drawChart, 250);

  function getData() {
    Rails.ajax({
      url,
      type: 'GET',
      dataType: 'json',
      success: (response) => {
        data = response;
        drawChart();
      }
    });
  }

  google.charts.load('current', { packages: ['vegachart'] });
  google.charts.setOnLoadCallback(getData);

  window.addEventListener('resize', this.drawChart);
}, function () {
  window.removeEventListener('resize', this.drawChart);
});
