import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input'];

  selected = null;

  connect() {
    this.selected = this.inputTargets.find((input) => input.checked);
  }

  deselectSiblingTargets(e) {
    this.inputTargets.forEach((radio) => {
      if (radio !== e.target) radio.checked = false;
    });
  }

  update(e) {
    this.deselectSiblingTargets(e);

    if (this.selected !== e.target) {
      this.element.setAttribute('data-unsaved-changes-warning-target', 'item');
    } else {
      delete this.element.dataset.unsavedChangesWarningTarget;
    }
  }
}
