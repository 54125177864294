import onmount from 'onmount';

onmount('[data-js-automatic-scoring]', function () {
  const self = this;
  const subquestion = self.closest('[data-js-subquestion]') || self.closest('[data-js-automatic-scoring-scope]');
  const productRuleToggle = subquestion.querySelector('[data-js-automatic-scoring-toggle]');
  const calcButton = subquestion.querySelector('[data-js-calculate-points]');
  const startWithZeroOption = subquestion.querySelector('[data-js-start-with-zero]');

  function setPoints(productCheckbox) {
    const productRuleWrapper = productCheckbox.closest('[data-js-automatic-scoring-wrapper]');
    const pointsInput = productRuleWrapper.querySelector('[data-js-number-field]');
    pointsInput.numeric.set(productCheckbox.checked ? 1 : 0);
  }

  const showPoints = function (el) {
    el.querySelector('[data-js-automatic-scoring-checkbox]').classList.add('d-none');
    const inputField = el.querySelector('[data-js-number-field]');
    if (inputField) {
      inputField.parentElement.classList.remove('d-none');
    } else {
      const pointsEl = el.querySelector('[data-js-item-points]');
      if (pointsEl) pointsEl.classList.remove('d-none');
    }
    if (calcButton) calcButton.classList.remove('d-none');
  };

  const hidePoints = function (el) {
    const productCheckboxWrapper = el.querySelector('[data-js-automatic-scoring-checkbox]');
    productCheckboxWrapper.classList.remove('d-none');

    const pointsInput = el.querySelector('[data-js-number-field]');
    if (pointsInput) {
      pointsInput.parentElement.classList.add('d-none');

      const productCheckbox = productCheckboxWrapper.querySelector('input');
      const parsedPointInput = parseFloat(pointsInput.value);
      if (startWithZeroOption && !startWithZeroOption.checked) {
        productCheckbox.checked = parsedPointInput >= 0;
      } else {
        productCheckbox.checked = parsedPointInput > 0;
      }

      if (pointsInput.numeric) {
        pointsInput.numeric.set(productCheckbox.checked ? 1 : 0);
      } else {
        pointsInput.value = productCheckbox.checked ? '1.0' : '0.0';
      }
    } else {
      const itemPointsElement = el.querySelector('[data-js-item-points]');
      if (itemPointsElement) itemPointsElement.classList.add('d-none');

      const productCheckbox = productCheckboxWrapper.querySelector('[data-js-automatic-scoring-checkbox-icon]');
      if (parseFloat(itemPointsElement.innerText) > 0) {
        productCheckbox.innerText = 'check_box';
        itemPointsElement.innerText = '1.0';
      } else {
        productCheckbox.innerText = 'check_box_outline_blank';
        itemPointsElement.innerText = '0.0';
      }
    }
    if (calcButton) calcButton.classList.add('d-none');
  };

  function handleAnswerElementAdd() {
    const el = this.lastElementChild.querySelector('[data-js-automatic-scoring-wrapper]');
    if (!el) return;

    if (self.productRuleState) {
      hidePoints(el);
    } else {
      showPoints(el);
    }
  }

  function handleProductRuleChange() {
    const elements = subquestion.querySelectorAll('[data-js-automatic-scoring-wrapper]');
    const productRuleEnabled = productRuleToggle ? productRuleToggle.checked : self.productRuleState;
    self.productRuleState = productRuleEnabled;
    const func = (productRuleEnabled ? hidePoints : showPoints);

    elements.forEach((el) => func(el));
    if (!startWithZeroOption) return;

    if (productRuleToggle.checked) {
      startWithZeroOption.checked = true;
      startWithZeroOption.closest('[data-js-list-item]').classList.add('d-none');
    } else {
      startWithZeroOption.closest('[data-js-list-item]').classList.remove('d-none');
    }
  }

  self.addEventListener('change', (e) => {
    if ((e.target.type === 'checkbox') &&
       (e.target.closest('[data-js-automatic-scoring-checkbox]'))) {
      setPoints(e.target);
    }
  });

  if (subquestion.hasAttribute('data-js-edit-option-list')) {
    const { subquestionId } = subquestion.dataset;
    const visibleSubquestion = document.querySelector(`[data-js-subquestion][data-subquestion-id='${subquestionId}']`);
    self.productRuleState = visibleSubquestion.querySelector('[data-js-automatic-scoring-toggle]').checked;
    handleProductRuleChange();
  } else if (productRuleToggle) {
    self.productRuleState = productRuleToggle.checked;
    productRuleToggle.addEventListener('change', handleProductRuleChange);
  }

  self.addEventListener('choice:added', handleAnswerElementAdd);
  self.addEventListener('option-item:added', handleAnswerElementAdd);
  self.addEventListener('hotspot-area:added', handleAnswerElementAdd);
  self.addEventListener('option-item:rendered-show', handleProductRuleChange);
});
