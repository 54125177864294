function paramByScope(params, column) {
  const currentColumnInParamsRegex = new RegExp(`\\b${column}\\b`);

  return (params.match(currentColumnInParamsRegex)) ? params : `${params} ${column}`;
}

function paramColumnWithValue(params, column, value) {
  const currentColumnWithAnyValueRegex = new RegExp(`\\b${column}:.*?(?: |$)`);
  const queryParam = `${column}:${value} `;

  if (params && params.match(currentColumnWithAnyValueRegex)) {
    const replacement = (value === '') ? '' : queryParam;

    return params.replace(currentColumnWithAnyValueRegex, replacement);
  }

  return `${params || ''} ${queryParam}`;
}

export function addToUrlParams(url, column, value = null) {
  const changedUrl = url;
  let currentParams = changedUrl.searchParams.get('q');

  if (value === null) {
    currentParams = paramByScope(currentParams, column);
  } else {
    currentParams = paramColumnWithValue(currentParams, column, value);
  }

  changedUrl.searchParams.set('q', currentParams.trim());
  return changedUrl;
}

export function removeFromUrlParams(url, removeParams) {
  const changedUrl = url;
  const currentParams = changedUrl.searchParams.get('q');
  let currentParamsArray = (currentParams) ? currentParams.split(' ') : [];

  currentParamsArray = currentParamsArray.filter((currentParam) => !removeParams.includes(currentParam));
  changedUrl.searchParams.set('q', currentParamsArray.join(' '));

  return changedUrl;
}
