/* global Redactor */

import onmount from 'onmount';

(function ($R) {
  $R.add('plugin', 'webcam', {
    translations: {
      en: {
        webcam: 'Webcam screenshot',
        'take-picture': 'Take picture',
        'take-picture-warning': 'Take picture first',
        'change-picture': 'Change picture',
        'stream-unavailable': 'Video stream not available.',
        save: 'Save',
        cancel: 'Cancel'
      }
    },
    modals: {
      webcam:
        "<div class='webcam-screenshot'>" +
          "<div class='camera'>" +
            "<video id='video'>## stream-unavailable ##</video>" +
          '</div>' +
          "<canvas id='canvas'></canvas>" +
          "<div class='output'>" +
            "<img id='photo'>" +
          '</div>' +
          "<div class='webcam-buttons'>" +
            "<button id='startbutton' class='outlined-button'>## take-picture ##</button>" +
            "<button id='restartbutton' class='outlined-button'>## change-picture ##</button>" +
          '</div>' +
          "<div id='warning'>## take-picture-warning ##</div>" +
        '</div>'
    },
    init(app) {
      this.app = app;
      this.lang = app.lang;
      this.opts = app.opts;
      this.toolbar = app.toolbar;
    },

    onmodal: {
      webcam: {
        open($modal) {
          this.$modal = $modal;
          this.build($modal);
        },
        opened() {
          if (this.errorLoadingStream) {
            this.app.api('module.modal.close');
          }
        },
        close() {
          this.stopWebcam();
        },
        insert() {
          this.insert();
        }
      }
    },

    // public
    start() {
      const self = this;
      this.detectWebcam((hasWebCam) => {
        if (!hasWebCam) { return; }

        const obj = {
          title: self.lang.get('webcam'),
          api: 'plugin.webcam.open'
        };

        const $button = self.toolbar.addButton('webcam', obj);
        $button.setIcon("<i class='material-icons'>videocam</i>");
        if (self.app.isReadOnly()) $button.disable();
      });
    },
    open() {
      const options = {
        title: this.lang.get('webcam'),
        width: '800px',
        name: 'webcam',
        handle: 'insert',
        commands: {
          insert: { title: this.lang.get('save') },
          cancel: { title: this.lang.get('cancel') }
        }
      };

      this.app.api('module.modal.build', options);
    },
    insert() {
      if (!this.imageBlob) {
        this.warning.style.display = 'block';
        return;
      }

      const file = new File([this.imageBlob], 'webcamscreenshot.png');
      const options = {
        url: this.opts.imageUpload,
        event: false,
        files: [file],
        name: 'imagedrop',
        metadata: { source: 'webcam', timestamp: Date.now() } // ANS - add metadata
      };
      this.app.api('module.upload.send', options);
      this.app.api('module.modal.close');
    },

    // private
    detectWebcam(callback) {
      const md = navigator.mediaDevices;
      if (!md || !md.enumerateDevices) {
        return callback(false);
      }

      return md.enumerateDevices().then((devices) => {
        callback(devices.some((device) => device.kind === 'videoinput'));
      });
    },
    build($modal) {
      this.width = 480;
      this.height = 0;

      this.errorLoadingStream = false;
      this.stream = null;
      this.streaming = false;

      this.imageBlob = null;

      const $body = $modal.getBody();
      this.video = $body.find('#video').get();
      this.canvas = $body.find('#canvas').get();
      this.photo = $body.find('#photo').get();
      this.warning = $body.find('#warning').get();
      this.startbutton = $body.find('#startbutton').get();
      this.restartbutton = $body.find('#restartbutton').get();

      this.addDraggableAttributes($body.nodes[0].parentElement);
      this.startWebcam();
      this.addVideoEventListener();
      this.addTakeScreenshotListener();
      this.addChangeScreenshotListener();
    },
    startWebcam() {
      const self = this;
      navigator.mediaDevices.getUserMedia({ video: true, audio: false })
        .then((stream) => {
          self.stream = stream;
          self.video.srcObject = stream;
          self.video.play();
        })
        .catch((err) => {
          self.errorLoadingStream = true;
          console.log(err); // eslint-disable-line no-console
          if (err.name === 'NotAllowedError' || err.name === 'PermissionDeniedError') {
            alert('Please allow access to your webcam'); // eslint-disable-line no-alert
          } else {
            alert('There was an error accessing you webcam.'); // eslint-disable-line no-alert
          }
        });
    },
    addVideoEventListener() {
      const self = this;
      this.video.addEventListener('canplay', () => {
        if (self.streaming) return;

        self.height = self.video.videoHeight / (self.video.videoWidth / self.width);

        // Firefox currently has a bug where the height can't be read from
        // the video, so we will make assumptions if this happens.

        if (Number.isNaN(self.height)) {
          self.height = self.width / (4 / 3);
        }

        self.video.setAttribute('width', self.width);
        self.video.setAttribute('height', self.height);
        self.canvas.setAttribute('width', self.width);
        self.canvas.setAttribute('height', self.height);
        self.streaming = true;

        self.$modal.updatePosition();
      }, false);
    },
    addTakeScreenshotListener() {
      const self = this;
      this.startbutton.addEventListener('click', (ev) => {
        self.takePicture();
        ev.preventDefault();
      }, false);
    },
    addChangeScreenshotListener() {
      const self = this;
      this.restartbutton.addEventListener('click', (ev) => {
        self.changePicture();
        ev.preventDefault();
      }, false);
    },
    takePicture() {
      const self = this;
      const context = this.canvas.getContext('2d');
      if (this.width && this.height) {
        this.canvas.width = this.width;
        this.canvas.height = this.height;
        context.drawImage(this.video, 0, 0, this.width, this.height);

        this.canvas.toBlob((blob) => {
          self.imageBlob = blob;
        });

        const data = this.canvas.toDataURL('image/png');
        this.photo.setAttribute('src', data);
        this.video.style.display = 'none';
        this.startbutton.style.display = 'none';
        this.photo.style.display = 'block';
        this.restartbutton.style.display = 'block';
        this.warning.style.display = 'none';
      }
    },
    changePicture() {
      this.imageBlob = null;

      this.video.style.display = 'block';
      this.startbutton.style.display = 'block';
      this.photo.style.display = 'none';
      this.restartbutton.style.display = 'none';
    },
    stopWebcam() {
      if (!this.stream) return;

      this.stream.getTracks().forEach((track) => {
        track.stop();
      });
    },
    addDraggableAttributes(element) {
      element.setAttribute('data-js-draggable-dialog', '');
      element.setAttribute('data-js-dialog-container', '');
      element.querySelector('.redactor-modal-header').setAttribute('data-js-draggable-header', '');
      onmount();
    }
  });
}(Redactor));
