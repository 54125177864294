/* global Redactor */

(function ($R) {
  $R.add('plugin', 'pre', {
    init(app) {
      this.app = app;
      this.toolbar = app.toolbar;
      this.editor = app.editor;
      this.inspector = app.inspector;
      this.selection = app.selection;
      this.block = app.block;
      this.lang = app.lang;
    },
    start() {
      const buttonData = {
        title: this.lang.get('pre'),
        api: 'plugin.pre.toggle'
      };

      this.button = this.toolbar.addButtonAfter('ul', 'pre', buttonData);
      this.button.setIcon('<i class="material-icons">code</i>');
    },
    toggle() {
      this.editor.focus();

      if (this.button.hasClass('re-button--active') || this.isPre()) {
        this.block.format('div');
        this.button.removeClass('re-button--active');
      } else {
        this.block.format('pre');
        this.button.addClass('re-button--active');
      }

      this.app.broadcast('toggle', this.button);
    },
    ontoggle(button) {
      if (button === this.button) return;

      this.updateButtonState();
    },
    onformatlist(list) {
      if (list) {
        this.button.addClass('re-button--disabled');
      } else {
        this.button.removeClass('re-button--disabled');
      }
    },
    onclick() { this.updateButtonState(); },
    onkeyup() { this.updateButtonState(); },
    onblur() {
      this.button.removeClass('re-button--active');
      this.button.removeClass('re-button--disabled');
    },
    updateButtonState() {
      if (this.isPre()) {
        this.button.addClass('re-button--active');
      } else {
        this.button.removeClass('re-button--active');
      }
    },
    isPre() {
      const element = this.selection.getElement();
      const data = this.inspector.parse(element);
      return data.isPre();
    }
  });
}(Redactor));
