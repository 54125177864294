/* global Redactor */

(function ($R) {
  $R.add('plugin', 'html', {
    init(app) {
      this.app = app;
      this.toolbar = app.toolbar;
    },
    start() {
      const button = this.toolbar.getButton('html');
      if (button) { button.setIcon('<i class="material-icons">html</i>'); }
    }
  });
}(Redactor));
