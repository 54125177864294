import { Controller } from '@hotwired/stimulus';
import { useMutation } from 'stimulus-use';

export default class extends Controller {
  static outlets = ['fill-scoring-method'];

  static targets = ['redactor', 'optionLists', 'optionList', 'restoreList', 'restoreButton'];

  connect() {
    useMutation(this, { element: this.optionListsTarget, childList: true });

    this.optionLists = {};
    this.optionListTargets.forEach((optionList) => {
      this.optionLists[optionList.dataset.id] = optionList.dataset.name;
    });
  }

  mutate(entries) {
    const mutation = entries[0];
    if (mutation.addedNodes.length && mutation.removedNodes.length) {
      this.optionListUpdated(mutation.addedNodes[0]);
    } else if (mutation.removedNodes.length) {
      this.optionListRemoved(mutation.removedNodes[0]);
    } else {
      this.optionListCreated(mutation.addedNodes[0]);
    }
  }

  optionListTargetConnected() {
    this.fillScoringMethodOutlet.updateVisibilityPointsFields();
  }

  optionListCreated(element) {
    const { id, name } = element.dataset;
    this.optionLists[id] = name;

    this.redactorTarget.redactor.plugin.fill.insert({ id, name });
    this.rebuildRestoreList();
  }

  optionListUpdated(element) {
    const { id, name } = element.dataset;
    this.optionLists[id] = name;

    const component = document.querySelector(`.redactor-component[data-id="${id}"]`);
    if (component) component.innerHTML = name;
    this.rebuildRestoreList();
    this.updateVisibility();
  }

  optionListRemoved(element) {
    const { id } = element.dataset;
    delete this.optionLists[id];

    const component = document.querySelector(`.redactor-component[data-id="${id}"]`);
    if (component) component.remove();
    this.rebuildRestoreList();
    this.updateVisibility();
  }

  restoreListTargetConnected() {
    this.rebuildRestoreList();
    this.updateVisibility();
  }

  rebuildRestoreList() {
    this.restoreListTarget.innerHTML = '';

    Object.entries(this.optionLists).forEach(([id, name]) => {
      this.restoreListTarget.innerHTML += this.buildRestoreItem(id, name);
    });
  }

  buildRestoreItem(id, name) {
    return `<li class="dropdown-item"
                      data-action="click->option-lists#restore click->dropdown#toggle"
                      data-option-lists-id-param="${id}"
                      data-option-lists-name-param="${name}">
        <span>${name}</span>
        <button type="button"
                class="mdc-icon-button material-icons ml-auto"
                data-action="click->option-lists#deleteGap"
                data-option-lists-id-param="${id}">
          <span class="mdc-icon-button__ripple"></span>
          delete
        </button>
      </li>`;
  }

  restore({ params: { id, name } }) {
    this.redactorTarget.redactor.plugin.fill.insert({ id, name });
  }

  deleteGap(e) {
    e.stopPropagation();

    const optionList = this.optionListTargets.find((el) => el.dataset.id === e.params.id.toString());
    optionList.querySelector('[data-turbo-method="delete"]').click();
  }

  usedOptionListIds() {
    return this.redactorTarget.redactor.$body.find('[data-redactor-type="optionList"]').nodes
      .map((item) => item.dataset.id);
  }

  hideOptionList(id) {
    this.optionListTargets.find((el) => el.dataset.id === id).classList.add('d-none');
    this.restoreListTarget.querySelector(`[data-option-lists-id-param="${id}"]`).classList.remove('d-none');
  }

  showOptionList(id) {
    this.optionListTargets.find((el) => el.dataset.id === id).classList.remove('d-none');
    this.restoreListTarget.querySelector(`[data-option-lists-id-param="${id}"]`).classList.add('d-none');
  }

  saveOffset() {
    this.redactorTarget.redactor.plugin.fill.saveOffset();
  }

  updateVisibility() {
    const ids = this.usedOptionListIds();
    let disableButton = true;

    Object.keys(this.optionLists).forEach((id) => {
      if (ids.includes(id)) {
        this.showOptionList(id);
      } else {
        this.hideOptionList(id);
        disableButton = false;
      }
    });
    this.restoreButtonTarget.disabled = disableButton;
  }
}
