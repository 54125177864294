import onmount from 'onmount';

onmount('[data-js-question-group-events]', () => {
  function handleQuestionGroupChange() {
    const questionGroups = document.querySelectorAll('[data-js-question-group]');

    questionGroups.forEach((questionGroup, index) => {
      const questions = questionGroup.querySelectorAll('[data-js-question]');
      questionGroup.querySelector('[data-js-question-group-position]').innerHTML = (index + 1);

      if (questions.length === 0) {
        questionGroup.querySelector('[data-js-question-amount]').classList.add('d-none');
        questionGroup.querySelector('[data-js-destroy-btn]').classList.remove('mdc-deprecated-list-item--disabled');
      } else {
        questionGroup.querySelector('[data-js-question-amount]').classList.remove('d-none');
        questionGroup.querySelector('[data-js-question-amount] [data-js-suffix]').innerHTML = questions.length;
        questionGroup.querySelector('[data-js-destroy-btn]').classList.add('mdc-deprecated-list-item--disabled');
      }
    });
  }
  this.handleQuestionGroupChange = handleQuestionGroupChange;

  [
    'questionGroupUpdated', 'questionGroupDestroyed',
    'questionUpdated'
  ].forEach((evt) => {
    document.addEventListener(evt, this.handleQuestionGroupChange);
  });
}, function () {
  [
    'questionGroupUpdated', 'questionGroupDestroyed',
    'questionUpdated'
  ].forEach((evt) => {
    document.removeEventListener(evt, this.handleQuestionGroupChange);
  });
});
