import onmount from 'onmount';
import { displayUpdatingStatus, submitOrSetPending } from '@modules/quiz/saving';

onmount('[data-js-response="order"]', function () {
  const form = this.closest('form');
  const subquestion = this.closest('[data-js-quiz-subquestion]');
  const { subquestionId } = subquestion.dataset;
  const unorderedChoices = this.querySelector('[data-js-choices]');
  const orderedChoices = this.querySelector('[data-js-ordered-choices]');

  function updatePositions() {
    form.setAttribute('data-saved', false);
    displayUpdatingStatus(form);

    unorderedChoices.querySelectorAll('[data-js-choice]').forEach((choice) => {
      choice.querySelector('[data-js-choice-position]').value = '';
    });

    orderedChoices.querySelectorAll('[data-js-choice]').forEach((choice, index) => {
      choice.querySelector('[data-js-choice-position]').value = index + 1;
    });

    submitOrSetPending(form);
  }

  async function getSortable() {
    const module = await import(/* webpackChunkName: "sortable" */ 'sortablejs');
    return module.default;
  }

  function initOrderOptionsSortable() {
    const options = {
      animation: 0,
      sort: false,
      delayOnTouchOnly: true,
      draggable: '[data-js-sortable-element]',
      group: `order-${subquestionId}`,
      handle: '[data-js-sortable-handle]'
    };

    getSortable().then((Sortable) => Sortable.create(unorderedChoices, options));
  }

  function initOrderSortable() {
    const options = {
      animation: 0,
      delayOnTouchOnly: true,
      draggable: '[data-js-sortable-element]',
      group: `order-${subquestionId}`,
      handle: '[data-js-sortable-handle]',
      onAdd: updatePositions,
      onUpdate: updatePositions,
      onRemove: updatePositions
    };

    getSortable().then((Sortable) => Sortable.create(orderedChoices, options));
  }

  function preventDefault(e) {
    e.preventDefault();
  }
  this.preventDefault = preventDefault;

  initOrderOptionsSortable();
  initOrderSortable();

  // Disable revert animation
  document.addEventListener('dragover', this.preventDefault);
}, function () {
  document.removeEventListener('dragover', this.preventDefault);
});
