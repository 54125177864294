/* global google, i18n */

import onmount from 'onmount';
import Rails from '@rails/ujs';
import { debounce } from '@modules/custom';

onmount('[data-js-donut]', function () {
  const { url } = this.dataset;
  const chart = this.querySelector('[data-js-chart]');
  let data;

  function chartOptions() {
    return {
      vega: {
        $schema: 'https://vega.github.io/schema/vega/v5.json',
        width: 360,
        height: 360,

        config: {
          text: {
            font: 'Nunito',
            fontSize: 12,
            fontWeight: 400
          }
        },

        signals: [
          { name: 'startAngle', value: 0 },
          { name: 'endAngle', update: '2 * PI' },
          { name: 'innerRadius', update: 'width * 0.25' }
        ],

        data,

        scales: [
          {
            name: 'outerArcColors',
            type: 'ordinal',
            domain: [
              'uncertain-incapable',
              'partially_certain-incapable',
              'certain-incapable',
              'uncertain-capable',
              'partially_certain-capable',
              'certain-capable'
            ],
            range: ['#FEE2E4', '#FECACE', '#FCA5AC', '#D2F9E4', '#A8F2CE', '#70E5B5']
          },
          {
            name: 'innerArcColors',
            type: 'ordinal',
            domain: ['incapable', 'capable'],
            range: ['#F8717C', '#36D195']
          },
          {
            name: 'capabilityTooltips',
            type: 'ordinal',
            domain: ['incapable', 'capable'],
            range: [i18n.t('js.results.certainty_analysis.incorrect'), i18n.t('js.results.certainty_analysis.correct')]
          }
        ],

        marks: [
          {
            type: 'arc',
            from: { data: 'outer' },
            encode: {
              enter: {
                fill: { signal: "scale('outerArcColors', datum.degree + '-' + datum.capability)" },
                x: { signal: 'width * 0.5' },
                y: { signal: 'height * 0.5' }
              },
              update: {
                startAngle: { field: 'startAngle' },
                endAngle: { field: 'endAngle' },
                innerRadius: { signal: 'innerRadius' },
                outerRadius: { signal: 'width * 0.5' },
                padAngle: { value: 0.01 }
              }
            }
          },
          {
            type: 'arc',
            from: { data: 'inner' },
            encode: {
              enter: {
                stroke: [{ test: 'datum.count !== 0', scale: 'innerArcColors', field: 'capability' }],
                strokeWidth: { value: 2 },
                x: { signal: 'width * 0.5' },
                y: { signal: 'height * 0.5' }
              },
              update: {
                fill: { value: '#FFFFFF' },
                tooltip: { scale: 'capabilityTooltips', field: 'capability' },
                startAngle: { field: 'startAngle' },
                endAngle: { field: 'endAngle' },
                innerRadius: { signal: 'innerRadius * 0.6' },
                outerRadius: { signal: 'width * 0.258' }
              },
              hover: {
                fill: { value: '#F7F7F7' }
              }
            }
          },
          {
            type: 'text',
            from: { data: 'outer' },
            encode: {
              enter: {
                x: { signal: 'width * 0.5' },
                y: { signal: 'height * 0.5' },
                text: { signal: 'format((datum.endAngle - datum.startAngle) / (2 * PI), ".00%")' },
                theta: { signal: '(datum.startAngle + datum.endAngle) * 0.5' },
                radius: { signal: 'width * 0.375' },
                align: { value: 'center' },
                baseline: { value: 'middle' }
              }
            }
          },
          {
            type: 'text',
            from: { data: 'inner' },
            encode: {
              enter: {
                fill: { value: '#656565' },
                x: { signal: 'width * 0.5' },
                y: { signal: 'height * 0.5' },
                text: [
                  {
                    test: 'datum.count !== 0',
                    signal: 'format((datum.endAngle - datum.startAngle) / (2 * PI), ".00%")'
                  }
                ],
                theta: { signal: '(datum.startAngle + datum.endAngle) * 0.5' },
                radius: { signal: 'width * 0.2' },
                align: { value: 'center' },
                baseline: { value: 'middle' }
              }
            }
          }
        ]
      }
    };
  }

  function drawChart() {
    const dataTable = new google.visualization.DataTable();
    const googleChart = new google.visualization.VegaChart(chart);
    googleChart.draw(dataTable, chartOptions());
  }
  this.drawChart = debounce(drawChart, 250);

  function getChartData() {
    Rails.ajax({
      url,
      type: 'GET',
      dataType: 'json',
      success: (response) => {
        data = response;
        drawChart();
      }
    });
  }

  google.charts.load('current', { packages: ['vegachart'] });
  google.charts.setOnLoadCallback(getChartData);

  window.addEventListener('resize', this.drawChart);
}, function () {
  window.removeEventListener('resize', this.drawChart);
});
