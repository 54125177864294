import onmount from 'onmount';
import { showFormErrors } from '@modules/form-responses';

onmount('[data-js-remote-form]', function () {
  const self = this;
  const dialog = this.closest('.mdc-dialog');
  const keepOpenDialog = this.getAttribute('data-keep-open-dialog') === 'true';
  const hasReloadOnSuccess = this.hasAttribute('data-js-reload-on-success');
  const reloadOnSuccess = hasReloadOnSuccess && this.getAttribute('data-js-reload-on-success') !== 'false';

  function formErrorListener(event) {
    if (event.target === self) showFormErrors(event);
  }

  function formSuccessListener() {
    if (reloadOnSuccess) window.location.reload();
    if (dialog && !keepOpenDialog) dialog.MDCDialog.close();
  }

  this.addEventListener('ajax:error', formErrorListener);
  this.addEventListener('ajax:success', formSuccessListener);
});
