import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  deselectElements(selector, selected = undefined) {
    this.element.querySelectorAll(selector).forEach((el) => { if (el !== selected) el.checked = false; });
  }

  deselectAll(e) {
    if (!e.target.checked) return;

    if (e.target.value.split(',')[2] === 'true') {
      this.deselectElements('[data-value$="true"]:not([data-js-external-klass]) input[type="checkbox"]', e.target);
    } else {
      this.deselectElements('[data-value]:not([data-js-external-klass]) input[type="checkbox"]', e.target);
    }
  }

  deselectSibling(e) {
    if (!e.target.checked) return;

    const extraTime = e.target.value.split(',')[2];
    const siblingValue = e.target.value.replace(extraTime, (extraTime === 'true' ? 'false' : 'true'));
    this.deselectElements(`[data-value='${siblingValue}'] input[type='checkbox']`);

    if (e.target.closest('[data-js-external-klass], [data-js-all]')) return;

    this.element.querySelector('[data-value=",,false"] input[type="checkbox"]').checked = false;
    if (extraTime === 'true') {
      this.element.querySelector('[data-value=",,true"] input[type="checkbox"]').checked = false;
    }
  }
}
