/* global Redactor */

import { handleEvents } from '@modules/redactor/autocomplete-handle-list';

(function ($R) {
  $R.add('plugin', 'shortcutAutocomplete', {
    init(app) {
      this.app = app;
      this.opts = app.opts;
      this.$doc = app.$doc;
      this.$body = app.$body;
      this.editor = app.editor;
      this.marker = app.marker;
      this.keycodes = app.keycodes;
      this.container = app.container;
      this.selection = app.selection;

      // local
      this.autocompleteTriggerKey = 51;
      this.autocompleteTrigger = '#';
      this.autocompleteStart = 1;
      this.autocompleteStr = '';
      this.autocompleteLen = 10;
      this.autocompleteRegex = new RegExp(`${this.autocompleteTrigger}(\\w*|\\d*)$`);

      this.handleScroll = this.handleScroll.bind(this);
      this.autocomplete = this.autocomplete.bind(this);
    },

    // public
    start() {
      const editorEl = this.editor.getElement();
      editorEl.on('keyup.plugin-shortcut-autocomplete', this.autocomplete);
    },
    stop() {
      const editorEl = this.editor.getElement();

      editorEl.off('.plugin-shortcut-autocomplete');
      this.$doc.off('.plugin-shortcut-autocomplete');

      $R.dom('#redactor-shortcut-autocomplete-list').remove();
    },

    // private
    autocomplete(e) {
      const key = e.which;
      const ctrl = e.ctrlKey || e.metaKey;
      const arrows = [37, 38, 39, 40];

      if (key === this.keycodes.DELETE ||
          key === this.keycodes.ESC ||
          key === this.keycodes.SHIFT ||
          ctrl ||
          (arrows.indexOf(key) !== -1)) return;

      // detect
      this.autocompleteStr = this.selection.getTextBeforeCaret(this.autocompleteLen);

      if (this.autocompleteRegex.test(this.autocompleteStr)) {
        [, this.autocompleteStr] = this.autocompleteStr.match(this.autocompleteRegex);
        this.load();
      } else if (this.isShown()) {
        this.hide();
      }
    },
    load() {
      $R.ajax.get({
        url: '/comment_shortcuts.json',
        data: `search=${this.autocompleteStr}`,
        success: this.parse.bind(this)
      });
    },
    parse(json) {
      if (json === '') return;

      const data = (typeof json === 'object') ? json : JSON.parse(json);

      this.build();
      this.buildData(data);
    },
    build() {
      this.$list = $R.dom('#redactor-shortcut-autocomplete-list');
      if (this.$list.length === 0) {
        this.$list = $R.dom("<div id='redactor-shortcut-autocomplete-list'>");
        this.$body.append(this.$list);
      }
    },
    buildData(data) {
      this.data = data;

      this.update();
      this.show();
    },
    update() {
      this.$list.html('');

      this.data.forEach((item, index) => {
        const $item = $R.dom("<a href='#'>");

        $item.html(item.shortcut);
        if (index === 0) $item.addClass('selected');

        $item.attr('data-index', index);
        $item.on('click', this.replace.bind(this));

        this.$list.append($item);
      });

      this.positionList();
    },
    positionList() {
      const pos = this.selection.getPosition();

      this.$list.css({
        top: `${(pos.top + pos.height + this.$doc.scrollTop())}px`,
        left: `${pos.left}px`
      });
    },
    isShown() {
      return (this.$list && this.$list.hasClass('open'));
    },
    show() {
      this.$list.addClass('open');
      this.$list.show();

      this.$doc.off('.plugin-shortcut-autocomplete');
      this.$doc.on('click.plugin-shortcut-autocomplete keydown.plugin-shortcut-autocomplete', handleEvents.bind(this));
      document.addEventListener('scroll', this.handleScroll, true);
    },
    handleScroll() {
      this.positionList();
    },
    hide() {
      this.$list.removeClass('open');
      this.$list.hide();
      document.removeEventListener('scroll', this.handleScroll, true);
      this.reset();
    },
    reset() {
      this.autocompleteStr = '';
    },
    replace(e) {
      e.preventDefault();

      const $item = $R.dom(e.target);
      const index = $item.attr('data-index');
      const replacement = this.data[index].comment;

      const marker = this.marker.insert('start');
      const current = marker.previousSibling;
      let currentText = current.textContent;

      currentText = currentText.replace(this.autocompleteRegex, '');
      current.textContent = currentText;

      this.app.insertion.insertHtml(replacement);
    }
  });
}(Redactor));
