/* global i18n */

import onmount from 'onmount';

onmount('[data-js-media-controls]', function () {
  const {
    id,
    subsetId,
    numberOfPlays,
    disablePausing
  } = this.dataset;
  const self = this;
  const mediaEl = this.querySelector('audio, video');
  const source = mediaEl.querySelector('source');
  const remainingPlays = this.querySelector('[data-js-remaining-plays]');
  let currentTime = 0;
  let playCount = parseInt(localStorage.getItem(`playCount-${subsetId}-${id}`), 10) || 0;
  let playing = false;

  function showRemainingPlays() {
    remainingPlays.innerHTML = i18n.t('js.quizzes.plays_remaining', { count: numberOfPlays - playCount });
    remainingPlays.classList.remove('d-none');
  }

  function disableMedia() {
    mediaEl.src = '';
    source.src = '';
  }

  function handlePlay() {
    if (playing) return;

    playCount += 1;
    localStorage.setItem(`playCount-${subsetId}-${id}`, playCount);
    showRemainingPlays();
    playing = true;
  }

  function handleTimeupdate() {
    if (!mediaEl.seeking) currentTime = mediaEl.currentTime;
  }

  function handleSeeking() {
    if (Math.abs(mediaEl.currentTime - currentTime) > 0.01) {
      mediaEl.currentTime = currentTime;
    }
  }

  function handleRateChange() {
    mediaEl.playbackRate = 1;
  }

  function handleEnded() {
    if (playCount >= numberOfPlays) {
      disableMedia();
    } else {
      currentTime = 0;
      playing = false;
    }
  }

  function handlePause() {
    if (!mediaEl.ended && self.checkVisibility()) mediaEl.play();
  }

  if (playCount >= numberOfPlays) disableMedia();

  if (numberOfPlays) {
    showRemainingPlays();
    mediaEl.addEventListener('play', handlePlay);
    mediaEl.addEventListener('timeupdate', handleTimeupdate);
    mediaEl.addEventListener('seeking', handleSeeking);
    mediaEl.addEventListener('ratechange', handleRateChange);
    mediaEl.addEventListener('ended', handleEnded);
  }

  if (disablePausing === 'true') {
    mediaEl.addEventListener('pause', handlePause);
  }
});
