import onmount from 'onmount';
import { debounce, updateUrl } from '@modules/custom';
import Rails from '@rails/ujs';

onmount('[data-js-search-filter]', function () {
  const self = this;
  const input = this.querySelector('input');
  const reset = this.querySelector('[data-js-reset]');
  const remote = this.getAttribute('data-remote') === 'true';
  const regex = /(^| )search:'(.*?)'($| )/;

  function queryUrl() {
    const url = self.getAttribute('data-url') || window.location.href;
    return new URL(url);
  }

  function updateSearchParam(url) {
    const query = url.searchParams.get('q') || '';

    let { value } = input;
    value = value.trim().replace('"', '').replace(/'/g, '');
    if (value.length) value = `search:'${value}'`;

    if (query.match(regex)) {
      if (value.length) {
        url.searchParams.set('q', query.replace(regex, `$1${value}$3`));
      } else {
        url.searchParams.set('q', query.replace(regex, ' '));
      }
    } else {
      url.searchParams.set('q', `${query} ${value}`.trim());
    }
    if (!url.searchParams.get('q')) url.searchParams.delete('q');

    return url;
  }

  function setResetVisibility() {
    if (input.value.trim()) {
      reset.classList.remove('d-none');
    } else {
      reset.classList.add('d-none');
    }
  }

  function dispatch(url) {
    const event = new CustomEvent('search:filter', { detail: { url } });
    self.dispatchEvent(event);
  }

  function inputChange() {
    const url = updateSearchParam(queryUrl());
    setResetVisibility();

    if (remote) {
      updateUrl(url);
      Rails.ajax({ url: url.toString(), type: 'GET', dataType: 'script' });
      dispatch(url.toString());
    } else {
      window.location.href = url.toString();
    }
  }

  function clearInput() {
    input.value = '';
    inputChange();
    input.focus();
  }

  function init() {
    const query = queryUrl().searchParams.get('q') || '';
    const [, , match] = query.match(regex) || [];

    if (match) {
      input.value = match;
      setResetVisibility();
    }
  }

  init();
  input.addEventListener('input', debounce(inputChange, 250));
  input.addEventListener('paste', debounce(inputChange, 250));
  reset.addEventListener('click', clearInput);
});
