/* global i18n */

import onmount from 'onmount';
import Rails from '@rails/ujs';
import { MDCSelect } from '@material-overwrites/mdc.select';

onmount('.mdc-select', function () {
  const self = this;
  const required = self.classList.contains('mdc-select--required');
  const menuEl = this.querySelector('.mdc-menu');
  const keepOpen = menuEl.hasAttribute('data-mdc-menu-keep-open');
  const unfoldLeft = menuEl.hasAttribute('data-mdc-menu-unfold-left');
  const unfoldBelow = this.hasAttribute('data-mdc-menu-unfold-below');

  if (!this.MDCSelect) {
    this.MDCSelect = new MDCSelect(this);
    this.setAttribute('data-mdc-auto-init-state', 'initialized');
  }
  this.MDCSelect.required = required;
  const collection = this.MDCSelect.menuElement.querySelector('[data-js-collection]');
  if (!collection) return;

  const allowMultiple = collection.getAttribute('data-multiple') === 'true';
  const submit = this.getAttribute('data-submit');
  const translation = this.getAttribute('data-translation') || 'js.general.selected_count';

  function setSelectedText() {
    if (!allowMultiple) return;

    const selectedCount = collection.querySelectorAll(
      '.mdc-deprecated-list-item input[type=checkbox]:checked, .mdc-list-item input[type=checkbox]:checked'
    ).length;

    const text = i18n.t(`${translation}`, { count: selectedCount });
    self.MDCSelect.foundation.adapter.setSelectedText(selectedCount > 0 ? text : '', true);
    self.MDCSelect.layout();
  }

  function handleSelectChange() {
    const input = this.querySelector('[data-js-select-input]');
    if (input && allowMultiple) input.value = ''; // clear imput to prevent sending same id twice

    if (input) {
      const event = new CustomEvent('change', { bubbles: true });
      input.dispatchEvent(event);
    }
    if (allowMultiple) setSelectedText();

    if (submit !== 'true') return;

    const form = this.closest('form');
    if (form.getAttribute('data-remote') === 'true') {
      Rails.fire(form, 'submit');
    } else {
      form.submit();
    }
  }
  this.handleSelectChange = handleSelectChange.bind(this);

  function closeSurface(skipRestoreFocus) {
    if (keepOpen) return;

    self.MDCSelect.menu.menuSurface.close(skipRestoreFocus);
  }

  function handleMenuItemAction(index) {
    this.setSelectedIndex(index, /** closeMenu */ false);
  }

  function getoriginCornerLeft() {
    return 12;
  }

  function getoriginCornerBottom() {
    return 8;
  }

  function initMultiple() {
    const selectedIndexes = [];
    [...collection.querySelectorAll('.mdc-deprecated-list-item, .mdc-list-item')].forEach((item, index) => {
      if (item.querySelector('input[type=checkbox]:checked')) selectedIndexes.push(index);
    });
    self.MDCSelect.setSelectedIndex(selectedIndexes, /** skipNotify */ true);
    setSelectedText();
  }

  if (allowMultiple) initMultiple();
  this.MDCSelect.listen('MDCSelect:change', this.handleSelectChange);
  this.MDCSelect.foundation.handleMenuItemAction = handleMenuItemAction;
  this.MDCSelect.menu.foundation.adapter.closeSurface = closeSurface;
  if (unfoldLeft) {
    this.MDCSelect.menu.menuSurface.foundation.getoriginCorner = getoriginCornerLeft;
  } else if (unfoldBelow) {
    this.MDCSelect.menu.menuSurface.foundation.getoriginCorner = getoriginCornerBottom;
  }
}, function () {
  this.MDCSelect.unlisten('MDCSelect:change', this.handleSelectChange);
  this.MDCSelect.destroy();
});
