import { Controller } from '@hotwired/stimulus';
import { removeFromUrlParams, addToUrlParams } from '@modules/url_helpers';

// Connects to data-controller="remote-filter"
export default class extends Controller {
  static targets = ['results', 'filter'];

  replaceContent({ params: { query } }) {
    const url = new URL(this.element.dataset.url);
    let changedUrl = '';

    const filterOptions = this.filterTargets.map((filterItem) => (
      filterItem.dataset.remoteFilterQueryParam.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&')
    ));

    changedUrl = removeFromUrlParams(url, filterOptions);
    changedUrl = addToUrlParams(changedUrl, query);

    this.element.setAttribute('data-url', changedUrl);
  }
}
