/* global Channels */

import onmount from 'onmount';
import consumer from '../consumer';

onmount('[data-js-assignment-synchronizing]', function () {
  const self = this;
  const { id } = self.dataset;

  const assignment = consumer.subscriptions.create({
    channel: 'Assignments::SyncChannel',
    id
  }, {
    received() {
      window.location.reload();
    }
  });

  Channels.assignment = assignment;
}, () => {
  Channels.assignment.unsubscribe();
});
