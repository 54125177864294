export function arrayWithChoiceContent(subquestion) {
  return [...subquestion.querySelectorAll('[data-js-choices] [data-js-choice]:not(.d-none)')].map((choice) => {
    const textarea = choice.querySelector('[data-js-redactor]');

    return ({
      name: textarea.id,
      value: textarea.redactor.editor.getElement().text()
    });
  });
}

export function orderChoicesBasedOnArray(subquestion, array) {
  const event = new CustomEvent('position:change');
  array.forEach((value, index) => {
    const choice = subquestion.querySelector(`#${value.name}`).closest('[data-js-choice]');
    const positionInput = choice.querySelector('input[data-js-choice-position]');
    positionInput.value = index + 1;

    choice.parentElement.appendChild(choice);
    positionInput.dispatchEvent(event);
  });
}
