import { Controller } from '@hotwired/stimulus';
import { useClickOutside } from 'stimulus-use';

export default class extends Controller {
  connect() {
    this.initialValue = this.element.value;
    useClickOutside(this);
  }

  restore() {
    this.element.value = this.initialValue;
  }
}
