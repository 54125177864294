/* global i18n */

import onmount from 'onmount';

onmount('[data-js-password-validator]', function () {
  const self = this;
  const passwordInput = this.querySelector('[data-js-new-password]');
  const passwordConfirmInput = this.querySelector('[data-js-confirm-password]');
  const updatePasswordButton = this.querySelector('[data-js-update-password]');
  const showPasswordButton = this.querySelector('[data-js-show-password]');

  const passwordNumber = self.querySelector('[data-js-password-number] .material-icons');
  const passwordLetter = self.querySelector('[data-js-password-letter] .material-icons');
  const passwordCapital = self.querySelector('[data-js-password-capital] .material-icons');
  const passwordLength = self.querySelector('[data-js-password-length] .material-icons');
  const passwordMatch = self.querySelector('[data-js-password-match] .material-icons');

  const validColor = 'green-pigment';

  function isValidPassword() {
    return !self.querySelector(`[data-js-password-requirements] .material-icons:not(.${validColor})`);
  }

  function validatePassword() {
    const pw = passwordInput.value;

    const numberRegex = /^(?=.*[0-9]).{1,}$/;
    if (numberRegex.test(pw)) {
      passwordNumber.classList.add(validColor);
    } else {
      passwordNumber.classList.remove(validColor);
    }

    const letterRegex = /^(?=.*[a-z]).{1,}$/;
    if (letterRegex.test(pw)) {
      passwordLetter.classList.add(validColor);
    } else {
      passwordLetter.classList.remove(validColor);
    }

    const capitalRegex = /^(?=.*[A-Z]).{1,}$/;
    if (capitalRegex.test(pw)) {
      passwordCapital.classList.add(validColor);
    } else {
      passwordCapital.classList.remove(validColor);
    }

    if (pw.length >= 12) {
      passwordLength.classList.add(validColor);
    } else {
      passwordLength.classList.remove(validColor);
    }

    if (passwordConfirmInput && passwordMatch) {
      const pwConfirm = passwordConfirmInput.value;

      if (pw === pwConfirm) {
        passwordMatch.classList.add(validColor);
      } else {
        passwordMatch.classList.remove(validColor);
      }
    }

    if (isValidPassword()) {
      if (updatePasswordButton) updatePasswordButton.removeAttribute('disabled');
    } else if (updatePasswordButton) {
      updatePasswordButton.setAttribute('disabled', true);
    }
  }

  function handleShowPasswordButtonClick() {
    if (passwordInput.getAttribute('type') === 'text') {
      passwordInput.setAttribute('type', 'password');
      this.innerText = i18n.t('js.users.edit.show_password');
    } else {
      passwordInput.setAttribute('type', 'text');
      this.innerText = i18n.t('js.users.edit.hide_password');
    }
  }

  function handleInputChange() {
    validatePassword();
  }

  showPasswordButton.addEventListener('click', handleShowPasswordButtonClick);
  passwordInput.addEventListener('input', handleInputChange);
  if (passwordConfirmInput) passwordConfirmInput.addEventListener('input', handleInputChange);
});
