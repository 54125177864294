/* eslint-disable */

import Rails from '@rails/ujs';

(function($R)
{
  $R.add('plugin', 'optionList', {
    translations: {
      en: {
        'optionList': 'option list',
        'gap': 'Gap',
        'insert': 'Insert',
        'delete': 'Delete',
        'add-gap': 'New gap'
      }
    },
    init: function(app) {
      this.app = app;
      this.lang = app.lang;
      this.opts = app.opts;
      this.toolbar = app.toolbar;
      this.component = app.component;
      this.insertion = app.insertion;
      this.offset = app.offset;
      this.inspector = app.inspector;
      this.selection = app.selection;
    },

    // messages
    oncontextbar: function(e, contextbar)
    {
      var data = this.inspector.parse(e.target)
      if (data.isComponentType('optionList'))
      {
        var node = data.getComponent();
        var buttons = {
          'remove': {
            title: this.lang.get('delete'),
            api: 'plugin.optionList.remove',
            args: node
          }
        };

        contextbar.set(e, node, buttons, 'bottom');
      }
    },
    ondropdown: {
      open: function(e, dropdown, button)
      {
        if (button.name === 'optionList'){
          this.saveOffset();
        }
      }
    },

    // public
    start: function()
    {
      var dropdownData = this._buildListSelections();
      var buttonData = {
        title: '## gap ##'
      };
      var button = this.toolbar.addButton('optionList', buttonData);
      var dropdown = button.setDropdown(dropdownData);
    },
    refreshDropdown: function()
    {
      var button = this.toolbar.getButton('optionList');
      var dropdownData = this._buildListSelections();
      var dropdown = button.setDropdown(dropdownData);
    },
    insert: function(item)
    {
      var name = item.html;
      var optionList = this.component.create('optionList');
      optionList.html(name);
      optionList.attr('data-id', item.id);

      if (this.insertionOffset !== undefined) {
        this.offset.set(this.insertionOffset);
      }
      this.insertion.insertRaw(optionList);
      this.refreshDropdown();
    },
    remove: function(node)
    {
      this.component.remove(node);
      this.refreshDropdown();
    },
    openNewOptionListModal: function()
    {
      var subquestionId = this.opts.subquestionId;
      var newOptionListPath = `/content/subquestions/${subquestionId}/option_lists/new`;

      Rails.ajax({
        url: newOptionListPath,
        type: 'GET',
        dataType: 'script'
      })
    },
    saveOffset: function()
    {
      if(this.selection.is()){
        this.insertionOffset = this.offset.get();
      }
    },

    // private
    _buildListSelections: function()
    {
      var list = {}
      var items = this.opts.optionLists;
      var usedItemIds = [];

      this.app.$body.find("[data-redactor-type='optionList']").nodes.forEach(function(item) {
        usedItemIds.push(item.dataset.id);
      });
      var itemsToSelect = items.filter(item => !usedItemIds.includes(item.id));

      for (var i = 0; i < itemsToSelect.length; i++)
      {
        list[itemsToSelect[i].html] = {
          title: itemsToSelect[i].html,
          api: 'plugin.optionList.insert',
          args: itemsToSelect[i]
        }
      }

      list['add answer list'] = {
        title: '## add-gap ##',
        api: 'plugin.optionList.openNewOptionListModal'
      }

      return list;
    },
  });
})(Redactor);
(function($R)
{
  $R.add('class', 'optionList.component', {
    mixins: ['dom', 'component'],
    init: function(app, el)
    {
      this.app = app;
      this.utils = app.utils;

      // init
      return (el && el.cmnt !== undefined) ? el : this._init(el);
    },
    // public
    getData: function()
    {
      return {
        type: this._getType()
      };
    },

    // private
    _init: function(el)
    {
      el = el || '<span>';

      this.parse(el);
      this._initWrapper();
    },
    _getType: function()
    {
      var text = this.text().trim();

      return this.utils.removeInvisibleChars(text);
    },
    _initWrapper: function()
    {
      this.addClass('redactor-component');
      this.attr({
        'data-redactor-type': 'optionList',
        'tabindex': '-1',
        'contenteditable': false
      });
    }
  });
})(Redactor);
