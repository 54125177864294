import onmount from 'onmount';

onmount('[data-js-criteria-comment]', function () {
  const self = this;
  const cancel = self.querySelector('[data-js-criteria-comment-cancel]');
  const newButton = self.parentElement.querySelector('[data-js-criteria-comment-new]');

  function handleCancelCriteriaCommentClick() {
    newButton.classList.remove('d-none');
    self.remove();
  }

  if (cancel) cancel.addEventListener('click', handleCancelCriteriaCommentClick);
});
