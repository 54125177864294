/* global google */

import onmount from 'onmount';
import snackbar from '@components/snackbar';
import Rails from '@rails/ujs';
import { debounce } from '@modules/custom';

onmount('[data-js-compare-chart]', function () {
  const self = this;
  const { url, chartOptions } = this.dataset;
  const chart = this.querySelector('[data-js-chart]');
  let options = {
    isStacked: true,
    legend: {
      position: 'none'
    },
    series: {
      0: { color: '#4384F4' },
      1: { color: '#72A3F7', lineDashStyle: [2, 2], lineWidth: 1 }
    }
  };
  if (chartOptions) options = { ...JSON.parse(chartOptions), ...options };
  let json;

  function drawChart() {
    if (!json) return;

    const dataTable = new google.visualization.DataTable();
    dataTable.addColumn('string', '');
    json.data[0].forEach((row) => {
      dataTable.addColumn('number', row);
    });
    dataTable.addRows(json.data[1]);

    const googleChart = new (google.visualization.LineChart)(chart);
    googleChart.draw(dataTable, options);
  }
  this.drawChart = debounce(drawChart, 250);

  function dispatchEvent() {
    const chartDataFetchedEvent = new CustomEvent('chartData:fetched', {
      detail: {
        data: json.data
      }
    });
    self.dispatchEvent(chartDataFetchedEvent);
  }

  function getChartData() {
    Rails.ajax({
      url,
      type: 'GET',
      dataType: 'json',
      success: (data) => {
        json = data;
        dispatchEvent();
        drawChart();
      },
      error: (e) => snackbar(e, true)
    });
  }

  google.charts.load('current', { packages: ['corechart'], language: 'nl' });
  google.charts.setOnLoadCallback(getChartData);

  window.addEventListener('resize', this.drawChart);
}, function () {
  window.removeEventListener('resize', this.drawChart);
});
