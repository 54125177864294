import onmount from 'onmount';
import Rails from '@rails/ujs';
import mdcAutoInit from '@material/auto-init';
import { debounce } from '@modules/custom';

onmount('[data-js-quiz-preview]', function () {
  const self = this;
  const { url } = this.dataset;

  function findVisibleQuestion() {
    return [...self.querySelectorAll('[data-js-quiz-question]')].find((question) => (
      question.getBoundingClientRect().top > 0
    ));
  }

  function scrollToQuestion(clearWindowVariables = true) {
    const question = self.querySelector(`[data-question-id='${window.currentVisibleQuestionId}']`);
    if (question) {
      question.scrollIntoView();
      if (clearWindowVariables) setTimeout(() => { window.currentVisibleQuestionId = 0; }, 1000);
    } else if (window.currentScroll) {
      self.querySelector('[data-js-quiz-panel]').scrollTop = window.currentScroll;
      if (clearWindowVariables) setTimeout(() => { window.currentScroll = null; }, 1000);
    }
  }
  this.scrollToQuestion = debounce(scrollToQuestion, 100);

  function fetchPreview() {
    window.currentScroll = self.querySelector('[data-js-quiz-panel]').scrollTop;
    const currentVisibleQuestion = findVisibleQuestion();
    window.currentVisibleQuestionId = (currentVisibleQuestion ? currentVisibleQuestion.dataset.questionId : 0);

    Rails.ajax({
      url,
      type: 'GET',
      dataType: 'script',
      success: () => {
        scrollToQuestion(false);
        mdcAutoInit();
      }
    });
  }
  this.fetchPreview = fetchPreview;

  fetchPreview();

  if (window.location.href.includes('grading_scheme') || window.location.href.includes('objectives')) {
    return;
  }

  document.addEventListener('quizPreview:loaded', this.scrollToQuestion);

  [
    'questionCreated', 'questionUpdated', 'questionDestroyed',
    'subquestionCreated', 'subquestionUpdated', 'subquestionDestroyed',
    'subelementCreated', 'subelementUpdated', 'subelementDestroyed',
    'questionGroupUpdated', 'coverUpdated', 'assignmentUpdated'
  ].forEach((evt) => {
    document.addEventListener(evt, this.fetchPreview);
  });
}, function () {
  document.removeEventListener('quizPreview:loaded', this.scrollToQuestion);

  [
    'questionCreated', 'questionUpdated', 'questionDestroyed',
    'subquestionCreated', 'subquestionUpdated', 'subquestionDestroyed',
    'subelementCreated', 'subelementUpdated', 'subelementDestroyed',
    'questionGroupUpdated', 'coverUpdated', 'assignmentUpdated'
  ].forEach((evt) => {
    document.removeEventListener(evt, this.fetchPreview);
  });
});
