import Autocomplete from 'stimulus-autocomplete';

export default class extends Autocomplete {
  onInputChange = () => {
    if (this.hasHiddenTarget) this.hiddenTarget.value = '';
    const query = this.inputTarget.value.trim();

    if (query.length >= this.minLengthValue) {
      this.fetchResults(query);
    } else {
      this.hideAndRemoveOptions();
    }
  };

  replaceResults(html) {
    if (this.inputTarget === document.activeElement) {
      super.replaceResults(html);
    } else {
      this.hideAndRemoveOptions();
    }
  }

  commit(selected) {
    super.commit(selected);
    this.onInputChange();
  }
}
