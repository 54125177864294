import { Controller } from '@hotwired/stimulus';
import { useDebounce } from 'stimulus-use';

export default class extends Controller {
  static debounces = ['submit'];

  static values = {
    saved: { type: String }
  };

  connect() {
    this.savedValue ||= this.element.value;

    useDebounce(this, { wait: 250 });
  }

  submit() {
    if (this.element.value === this.savedValue) return;

    this.element.closest('form').requestSubmit();
    this.savedValue = this.element.value;
  }
}
