import onmount from 'onmount';

onmount('[data-js-cocoon-add]', function () {
  const self = this;
  const subquestion = this.closest('[data-js-subquestion]');
  const { itemName } = this.dataset;

  function handleKeyboardInput(e) {
    if (!e) return;

    const { event, redactor } = e.detail;

    if (event.shiftKey) return;
    if (self !== redactor.element.rootElement.closest('[data-js-cocoon-add]')) return;

    subquestion.querySelector(`[data-js-add-${itemName}]`).click();
  }
  this.handleKeyboardInput = handleKeyboardInput;

  document.addEventListener('redactorEventEnter', this.handleKeyboardInput);
}, function () {
  document.removeEventListener('redactorEventEnter', this.handleKeyboardInput);
});
