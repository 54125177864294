import onmount from 'onmount';

onmount('[data-js-blueprint-show]', function () {
  const objectiveTotal = this.querySelector('[data-js-blueprint-objective-total]');
  const inputs = this.querySelectorAll('[data-js-blueprint-objective-amount]');

  function updateTotalAmount() {
    let total = 0;

    inputs.forEach((input) => {
      total += parseInt(input.value, 10);
    });

    objectiveTotal.innerText = total;
  }

  inputs.forEach((input) => {
    input.addEventListener('change', updateTotalAmount);
  });
});
