import onmount from 'onmount';
import Rails from '@rails/ujs';
import { showFormErrors } from '@modules/form-responses';
import { serialize } from '@modules/custom';

onmount('[data-js-enrich-students]', function () {
  const { userId, url } = this.dataset;

  let existingStudentNumberUserId;
  let existingEmailUserId;

  /* FUNCTIONS */
  function handleError(e) {
    const [data] = e.detail;
    const errors = JSON.parse(data);

    if (errors.student_number) {
      [, existingStudentNumberUserId] = errors.student_number;
    } else if (errors.email) {
      [, existingEmailUserId] = errors.email;
    }

    if (!existingStudentNumberUserId && !existingEmailUserId) {
      showFormErrors(e);
      return;
    }

    const params = {
      row_ids: `${userId},${existingStudentNumberUserId || existingEmailUserId}`
    };

    Rails.ajax({
      url,
      type: 'GET',
      data: serialize(params)
    });
  }

  /* EVENT HANDLERS */
  this.addEventListener('ajax:error', handleError);
});
