/* global i18n */

import onmount from 'onmount';
import TimeMe from 'timeme.js/timeme';
import Rails from '@rails/ujs';
import snackbar from '@components/snackbar';
import {
  showScore, updatePaginationTile, showEditedBy
} from '@modules/grading/scoring';

onmount('[data-js-rubrics-panel]', function () {
  const self = this;
  const {
    editable, assignmentType
  } = this.dataset;
  if (editable === 'false') return;

  const criteria = this.querySelectorAll('[data-js-criterium]');
  const undoGrading = this.querySelector('[data-js-undo-grading');

  function removeCriteriaToggling() {
    criteria.forEach((criterium) => {
      criterium.classList.remove('criterium-selector--toggling');
    });
  }

  function deselectOtherCriteria(currentCriterium = null) {
    criteria.forEach((criterium) => {
      if (criterium !== currentCriterium) {
        criterium.classList.add('criterium-selector--toggling');
        criterium.classList.remove('criterium-selector--selected');
      }
    });
  }

  function handleUndoGrading(e) {
    const [data] = e.detail;
    const score = data.score || 0;
    deselectOtherCriteria();
    showScore(data.id, score);
    updatePaginationTile(data.id, data.graded, data.score, data.points);

    if (document.activeElement) document.activeElement.blur();
    removeCriteriaToggling();
  }

  function handleCriteriumClick(e) {
    const { target } = e;
    if (!target.hasAttribute('data-js-criterium-toggle')) return;

    const criterium = target.closest('[data-js-criterium]');
    if (criterium.classList.contains('criterium-selector--toggling')) return;
    if (criterium.classList.contains('criterium-selector--selected')) return;

    deselectOtherCriteria(criterium);

    criterium.classList.add('criterium-selector--toggling');
    criterium.classList.add('criterium-selector--selected');

    if (document.activeElement) document.activeElement.blur();

    const gradingTimeInput = criterium.querySelector('[data-js-grading-time]');
    gradingTimeInput.value = parseFloat(gradingTimeInput.value || 0) + TimeMe.getTimeOnCurrentPageInSeconds();
    criterium.querySelector('[data-js-selected-input]').value = true;

    const form = criterium.querySelector('[data-js-submission-form]');
    Rails.fire(form, 'submit');
  }

  function handleSuccess(e) {
    const { target } = e;
    if (!target.hasAttribute('data-js-submission-form')) return;

    const data = e.detail[0];
    showScore(data.id, data.score);
    updatePaginationTile(data.id, data.graded, data.score, data.points);
    showEditedBy(self, data.edited_by);
    removeCriteriaToggling();
  }

  function handleError(e) {
    const { target } = e;
    if (!target.hasAttribute('data-js-submission-form')) return;

    if (navigator.onLine) {
      snackbar(i18n.t('js.gradings.failed_score_save'), true);
    } else {
      snackbar(i18n.t('js.gradings.no_internet'), true);
    }
  }

  function handleKeyUp(e) {
    if (['always', 'temporary'].includes(document.body.dataset.hotkeysDisabled)) return;
    if (self.closest('.d-none')) return;
    if (e.metaKey || e.ctrlKey || e.shiftKey || e.altKey) return;
    if (['INPUT', 'TEXTAREA', 'MATH-FIELD'].includes(e.target.tagName)) return;
    if (['radio', 'checkbox'].includes(e.target.type)) return;
    if (e.target.closest('.redactor-in')) return;
    if (e.target.closest('.x-spreadsheet')) return;

    if (['1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(e.key)) {
      const criteriumEl = criteria[parseInt(e.key, 10) - 1];
      if (criteriumEl) criteriumEl.querySelector('.criterium-toggle').click();
    }
  }
  this.handleKeyUp = handleKeyUp;

  criteria.forEach((criterium) => {
    criterium.addEventListener('click', handleCriteriumClick);
  });
  this.addEventListener('ajax:success', handleSuccess);
  this.addEventListener('ajax:error', handleError);
  if (undoGrading) undoGrading.addEventListener('ajax:success', handleUndoGrading);
  if (assignmentType !== 'Assessment' && assignmentType !== 'Appraisal') {
    document.addEventListener('keyup', this.handleKeyUp);
  }

  TimeMe.resetAllRecordedPageTimes();
  TimeMe.startTimer();
}, function () {
  document.removeEventListener('keyup', this.handleKeyUp);
});
