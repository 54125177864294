import { Controller } from '@hotwired/stimulus';
import createChip from '@components/chip';
import { useMutation } from 'stimulus-use';

export default class extends Controller {
  static targets = ['chip', 'input', 'search', 'hiddenField'];

  static values = {
    required: { type: Boolean, default: false }
  };

  static outlets = ['autocomplete'];

  connect() {
    useMutation(this, { element: this.element, childList: true });

    this.setCustomValidity();
    this.inputTarget.addEventListener('keydown', this.onKeydown);
  }

  disconnect() {
    this.inputTarget.removeEventListener('keydown', this.onKeydown);
  }

  mutate() {
    this.dispatch('change');
  }

  chipTargetConnected() {
    this.syncField();
    this.setCustomValidity();
  }

  chipTargetDisconnected() {
    this.syncField();
    this.setCustomValidity();
  }

  addChip(event) {
    this.inputTarget.value = '';
    const name = event.detail.textValue;
    const classes = ['chip-progress'];
    const chip = createChip({
      name, classes, dataset: { chipsFieldTarget: 'chip' }, close: true
    });

    const hiddenInput = this.createHiddenInput(this.hiddenFieldTarget.name, event.detail.value);
    chip.appendChild(hiddenInput);

    this.searchTarget.insertAdjacentElement('beforebegin', chip);
  }

  createHiddenInput(name, value) {
    const hiddenInput = document.createElement('input');
    hiddenInput.type = 'hidden';
    hiddenInput.name = name;
    hiddenInput.value = value;
    hiddenInput.setAttribute('multiple', true);

    return hiddenInput;
  }

  removeLastChip() {
    if (this.inputTarget.value !== '') return;

    const lastChip = this.searchTarget.previousElementSibling;
    if (lastChip) lastChip.remove();
  }

  syncField() {
    if (!this.hasAutocompleteOutlet) return;

    const values = this.chipTargets.map((chip) => chip.querySelector('input').value);
    const url = new URL(this.autocompleteOutlet.urlValue, window.location.origin);

    url.searchParams.set('exclude_ids', values);
    this.autocompleteOutlet.urlValue = url.pathname + url.search;
  }

  setCustomValidity() {
    if (!this.requiredValue) return;
    if (this.hasChipTarget) {
      this.inputTarget.setCustomValidity('');
    } else {
      this.inputTarget.setCustomValidity('required');
    }
  }

  onKeydown = (event) => {
    if (event.key === 'Enter') event.preventDefault();
  };
}
