import onmount from 'onmount';
import Rails from '@rails/ujs';
import { debounce } from '@modules/custom';

onmount('[data-js-load-on-scroll]', function () {
  const self = this;
  const viewHeight = window.innerHeight;
  const url = new URL(this.dataset.url);
  let { lastPosition } = this.dataset;
  const progressEl = this.parentElement.querySelector('[data-js-load-more-progress]');
  const lazyload = this.querySelector('[data-js-lazy-element]');
  this.container = document.querySelector('[data-js-scroll-load-container]') || window;
  const ignoreIds = [];
  let insertEl = self;
  let insertionType = 'beforeend';
  let loading = false;

  function showProgress() {
    progressEl.classList.remove('d-none');
  }

  function hideProgress() {
    progressEl.classList.add('d-none');
  }

  function searchQuery() {
    const currentUrl = new URL(window.location.href);
    return currentUrl.searchParams.get('search');
  }

  function updateLazyLoad() {
    const customEvent = new CustomEvent('lazyLoadUpdate');
    document.dispatchEvent(customEvent);
  }

  this.loadMoreOnScroll = debounce(() => {
    if (loading) return;
    if (!lastPosition) return;
    if (searchQuery()) return;

    const offsetHeight = self.offsetTop;
    const totalHeight = self.offsetHeight;
    let scrolled = window.scrollY;
    if (this.container && this.container !== window) scrolled = this.container.scrollTop;
    if (offsetHeight + totalHeight < scrolled + viewHeight) {
      showProgress();
      loading = true;

      url.searchParams.set('last_position', lastPosition);
      url.searchParams.set('ignore_ids', ignoreIds);

      Rails.ajax({
        url: url.toString(),
        type: 'GET',
        dataType: 'script',
        success: (data) => {
          loading = false;
          if (!data.last_position) this.container.removeEventListener('scroll', self.loadMoreOnScroll);

          hideProgress();
          lastPosition = data.last_position;
          insertEl.insertAdjacentHTML(insertionType, data.html);

          window.webpackOnmount();
          if (lazyload) updateLazyLoad();
        }
      });
    }
  }, 250);

  function handleNewQuestion(e) {
    const { questionId } = e.detail;
    ignoreIds.push(questionId);

    if (insertEl.hasAttribute('data-question-id')) return;

    insertEl = document.querySelector(`[data-question-id='${questionId}']`);
    insertionType = 'beforebegin';
  }
  this.handleNewQuestion = handleNewQuestion;

  document.addEventListener('questionCreated', this.handleNewQuestion);
  this.container.addEventListener('scroll', this.loadMoreOnScroll);
}, function () {
  document.removeEventListener('questionCreated', this.handleNewQuestion);
  this.container.removeEventListener('scroll', this.loadMoreOnScroll);
});
