import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input'];

  update({ target, params: { getter } }) {
    this.inputTargets.forEach((input) => {
      if (target[getter]) {
        input.disabled = false;
      } else {
        input.disabled = true;
        input.value = '';
      }
    });
  }
}
