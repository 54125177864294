const requestCache = new Map(); // Store pending requests

// Helper function to validate the response
function validateResponse(response) {
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.json();
}

// Helper function to store data in cache and localStorage
function storeInCache(key) {
  return (data) => {
    localStorage.setItem(key, JSON.stringify(data));
    requestCache.delete(key); // Remove from pending requests
    return data;
  };
}

function handleFetchError(error) {
  // eslint-disable-next-line no-console
  console.error('There was a problem with the fetch operation:', error);
  return null;
}

export default function getQuestionPositions(key, identifierId) {
  const cachedPositions = localStorage.getItem(key);
  if (cachedPositions) return Promise.resolve(JSON.parse(cachedPositions));

  // If a request for this identifier is already in progress, return the same promise
  if (requestCache.has(key)) {
    return requestCache.get(key);
  }

  // Fetch positions and store the request in cache
  const fetchPositions = fetch(`/position/identifiers/${identifierId}.json`)
    .then(validateResponse)
    .then(storeInCache(key))
    .catch(handleFetchError);

  requestCache.set(key, fetchPositions);
  return fetchPositions;
}
