import onmount from 'onmount';
import Rails from '@rails/ujs';

onmount('[data-js-filter-dialog]', function () {
  const self = this;
  const { filterType } = self.dataset;
  const apply = self.querySelector('[data-js-apply]');
  const url = new URL(window.location.href);

  function handleApply() {
    const selected = [...self.querySelectorAll('input:checked')].map((e) => e.value);

    if (selected.length) {
      url.searchParams.set(filterType, selected);
    } else {
      url.searchParams.delete(filterType);
    }

    window.location = url.toString();

    if (apply.hasAttribute('data-disable-with')) Rails.disableElement(apply);
  }

  apply.addEventListener('click', handleApply);
});
