import onmount from 'onmount';
import Rails from '@rails/ujs';
import { renderKatex } from '@vendor/katex';

onmount('[data-js-adjustment-comment-wrapper]', function () {
  // const self = this;
  const form = this.querySelector('[data-js-adjustment-comment-form]');
  const textarea = this.querySelector('[data-js-redactor]');
  const comment = this.querySelector('[data-js-adjustment-comment]');
  const show = this.querySelector('[data-js-show-adjustment-comment]');
  const add = this.querySelector('[data-js-add-adjustment-comment]');
  const edit = this.querySelector('[data-js-edit-adjustment-comment]');
  const del = this.querySelector('[data-js-delete-adjustment-comment]');
  const cancel = this.querySelector('[data-js-cancel-adjustment-comment]');

  function handleAddClick() {
    this.classList.add('d-none');
    form.classList.remove('d-none');
    textarea.redactor.editor.endFocus();
  }

  function handleEditClick() {
    show.classList.add('d-none');
    form.classList.remove('d-none');
    textarea.redactor.editor.endFocus();
  }

  function handleCancelClick() {
    form.classList.add('d-none');
    show.classList.remove('d-none');
  }

  function handleDeleteClick() {
    textarea.value = '';
    Rails.fire(form, 'submit');
  }

  function handleFormSuccess() {
    form.classList.add('d-none');
    const content = textarea.redactor.source.getCode();
    comment.innerHTML = content;
    if (textarea.value !== '') {
      show.classList.remove('d-none');
    } else {
      show.classList.add('d-none');
      add.classList.remove('d-none');
    }
    renderKatex(comment);
  }

  if (add) add.addEventListener('click', handleAddClick);
  if (edit) edit.addEventListener('click', handleEditClick);
  if (cancel) cancel.addEventListener('click', handleCancelClick);
  if (del) del.addEventListener('click', handleDeleteClick);
  if (form) form.addEventListener('ajax:success', handleFormSuccess);
});
