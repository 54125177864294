import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['button', 'panel', 'leftPanel'];

  open() {
    this.buttonTarget.classList.add('handle-button-active');
    this.panelTarget.classList.remove('d-none');

    // Force a reflow by reading a property that requires layout computation
    this.panelTarget.offsetHeight; // eslint-disable-line no-unused-expressions

    // Now apply the transition
    this.panelTarget.style.minWidth = '400px';

    setTimeout(() => {
      this.leftPanelTarget.scrollLeft = 500;
    }, 300);

    this.buttonTarget.dataset.action = 'click->annotations-panel#close';
  }

  showButton() {
    this.buttonTarget.classList.remove('d-none');
  }

  hideButton() {
    this.buttonTarget.classList.add('d-none');
    this.clearPanel();
  }

  clearPanel() {
    this.panelTarget.innerHTML = '';
  }

  close() {
    this.buttonTarget.classList.remove('handle-button-active');
    this.panelTarget.style.minWidth = '0px';

    setTimeout(() => {
      this.panelTarget.classList.add('d-none');
    }, 300);

    this.buttonTarget.dataset.action = 'click->annotations-panel#open';
  }
}
