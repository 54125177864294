/* global i18n */

import onmount from 'onmount';

onmount('[data-js-proctorio]', function () {
  this.timeoutId = null;

  const self = this;
  const proctorioHost = 'https://getproctorio.com';
  const ownHost = window.location.origin;
  const { proctorioUrl } = this.dataset;
  let proctorioActive = true;
  let failedChecks = 0;
  const redactorImageUrlRegex = new RegExp('redactor_images/.*?/download');
  const objectStoreRegex = new RegExp('objectstore.eu/');

  function listenForProctorioEvents(event) {
    if (event.origin === proctorioHost || event.origin === ownHost) {
      if (event.data.active) {
        proctorioActive = true;
        failedChecks = 0;
      } else {
        proctorioActive = false;
        failedChecks += 1;
      }
    } else {
      failedChecks += 0.1;
    }

    if (failedChecks >= 10) {
      window.location.href = proctorioUrl;
    }
  }
  this.listenForProctorioEvents = listenForProctorioEvents;

  function checkProctorioStatus() {
    window.top.postMessage(['proctorio_status'], ownHost);
    window.top.postMessage(['proctorio_status'], proctorioHost);
  }

  function clickOnSubmit() {
    if (document.activeElement.tagName === 'A' &&
      document.activeElement.href.includes('digital_test/results')) {
      return true;
    }
    if (document.activeElement.form && document.activeElement.form.action.includes('digital_test/results')) {
      return true;
    }

    return false;
  }

  function clickOnAnsDownload() {
    return (
      document.activeElement.tagName === 'A' &&
      (
        document.activeElement.href.match(redactorImageUrlRegex) || document.activeElement.href.match(objectStoreRegex)
      )
    );
  }

  function dispatchUnloadEvent() {
    if (!proctorioActive) return;
    if (!window.location.href.includes('digital_test')) return;
    if (clickOnSubmit()) return;
    if (clickOnAnsDownload()) return;

    return i18n.t('js.quizzes.proctorio_session_will_end'); // eslint-disable-line
  }

  function checkFrames(retries) {
    if (retries <= 0) {
      window.location.href = proctorioUrl;
      return;
    }

    if (window.top.frames.length === 0) {
      setTimeout(checkFrames, 200, retries - 1);
    } else {
      window.addEventListener('message', self.listenForProctorioEvents);

      window.onbeforeunload = dispatchUnloadEvent;
      self.timeoutId = setInterval(checkProctorioStatus, 1000);
    }
  }

  checkFrames(5);
}, function () {
  window.onbeforeunload = null;
  window.removeEventListener('message', this.listenForProctorioEvents);
  clearTimeout(this.timeoutId);
});
