/* global Channels */

import onmount from 'onmount';
import consumer from './consumer';

onmount('[data-js-preliminary-result]', function () {
  const self = this;
  const { id } = self.dataset;

  const result = consumer.subscriptions.create({
    channel: 'ResultChannel',
    id
  }, {
    received(data) {
      if (data.total_points) {
        window.location.reload();
      }
    }
  });

  Channels.result = result;
}, () => {
  Channels.result.unsubscribe();
});
