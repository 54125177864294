import interact from 'interactjs';

function onmove(event) {
  const { target } = event;
  const x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx;
  const y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;
  target.style.webkitTransform = `translate(${x}px, ${y}px)`;
  target.style.transform = `translate(${x}px, ${y}px)`;
  target.setAttribute('data-x', x);
  return target.setAttribute('data-y', y);
}

function draggable(component) {
  return component.draggable({
    allowFrom: '[data-js-draggable-header]',
    onmove,
    inertia: false,
    modifiers: [
      interact.modifiers.restrict({
        restriction: 'body',
        elementRect: {
          top: 0,
          left: 0,
          bottom: 1,
          right: 1
        }
      })
    ]
  });
}

function resizable(component) {
  return component.resizable({
    edges: {
      left: true,
      right: true,
      bottom: true,
      top: false
    },
    modifiers: [
      interact.modifiers.restrictEdges({
        outer: 'body',
        endOnly: true
      }), interact.modifiers.restrictSize({
        min: {
          width: 600,
          height: 400
        },
        max: {
          width: 1200,
          height: 1000
        }
      })
    ],
    inertia: false
  }).on('resizemove', (event) => {
    const { target } = event;
    let x = parseFloat(target.getAttribute('data-x')) || 0;
    let y = parseFloat(target.getAttribute('data-y')) || 0;

    target.style.width = `${event.rect.width}px`;
    target.style.height = `${event.rect.height}px`;

    x += event.deltaRect.left;
    y += event.deltaRect.top;

    target.style.webkitTransform = `translate(${x}px, ${y}px)`;
    target.style.transform = `translate(${x}px, ${y}px)`;
    target.setAttribute('data-x', x);
    target.setAttribute('data-y', y);
  });
}

export function interactJs(element, { plugins }) {
  let component = interact(element);

  plugins.forEach((plugin) => {
    switch (plugin) {
      case 'draggable':
        component = draggable(component);
        break;
      case 'resizable':
        component = resizable(component);
        break;
      default:
        break;
    }
  });
}
