/* global Redactor */

(function ($R) {
  $R.add('plugin', 'upload', {
    translations: {
      en: {
        upload: 'Upload'
      }
    },
    init(app) {
      this.app = app;
      this.toolbar = app.toolbar;
      this.lang = app.lang;
      this.opts = app.opts;
    },
    start() {
      const buttonData = {
        title: this.lang.get('upload'),
        api: 'plugin.upload.open'
      };

      const $button = this.toolbar.addButtonAfter('ul', 'upload', buttonData);
      $button.setIcon('<i class="material-icons">attach_file</i>');
    },
    open() {
      const form = document.createElement('form');
      form.setAttribute('enctype', 'multipart/form-data');
      form.setAttribute('method', 'post');

      const fileInput = document.createElement('input');
      fileInput.setAttribute('type', 'file');
      fileInput.setAttribute('multiple', 'true');
      fileInput.setAttribute('name', 'files');

      fileInput.addEventListener('change', (event) => {
        const files = Array.from(event.target.files);
        const allAreImages = files.every((file) => file.type.startsWith('image/'));
        const name = allAreImages ? 'imagedrop' : 'filedrop';

        const options = {
          url: this.opts.imageUpload,
          event,
          files,
          name
        };

        this.app.api('module.upload.send', options);
      });

      form.appendChild(fileInput);
      document.body.appendChild(form);

      fileInput.click();
    }
  });
}(Redactor));
