import onmount from 'onmount';
import { woots } from '@modules/custom';

onmount('[data-js-number-field]', function () {
  const self = this;
  const { min, max } = this;
  const {
    decimals, suffix, leadingZero, unit
  } = this.dataset;

  async function getAutoNumeric() {
    const module = await import(/* webpackChunkName: "auto-numeric" */ 'autonumeric');
    return module.default;
  }

  let options = {
    allowDecimalPadding: false,
    decimalCharacter: '.',
    decimalPlaces: decimals || 6,
    digitGroupSeparator: '',
    leadingZero: leadingZero || 'deny',
    maximumValue: max || '100000000000000000000000000000000',
    minimumValue: min || -10000000000000,
    modifyValueOnWheel: false,
    onInvalidPaste: 'clamp'
  };

  if (woots()) {
    options.decimalCharacter = ',';
    options.unformatOnSubmit = true;
  }

  const unitOptions = {
    percentage: { rawValueDivisor: 100, unformatOnSubmit: true, ...options }
  };

  if (suffix) {
    const suffixOptions = {
      currencySymbol: suffix,
      currencySymbolPlacement: 's'
    };
    options = { ...options, ...suffixOptions };
  }

  if (unit) options = unitOptions[unit];

  getAutoNumeric().then((AutoNumeric) => {
    self.numeric = new AutoNumeric(self, options);
  });
});
