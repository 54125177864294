import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['blueprintType', 'tagMinimum'];

  updateMinInput(tagMinimum) {
    if (this.blueprintTypeTarget.MDCSelect.value === 'fixed') {
      tagMinimum.MDCTextField.foundation.setDisabled(false);
    } else {
      tagMinimum.MDCTextField.foundation.setDisabled(true);
    }
  }

  tagMinimumTargetConnected(tagMinimum) {
    this.updateMinInput(tagMinimum);
  }

  blueprintTypeChange() {
    this.tagMinimumTargets.forEach((tagMinimum) => {
      this.updateMinInput(tagMinimum);
    });
  }
}
