/* eslint-disable */
(function($R)
{
  $R.add('plugin', 'color-plugin', {
    init: function(app) {
      this.app = app;
      this.opts = app.opts;
      this.lang = app.lang;
      this.inline = app.inline;
      this.toolbar = app.toolbar;
      this.editor = app.editor;
      this.caret = app.caret;
      this.inspector = app.inspector;
      this.selection = app.selection;

      // local
      this.textColors = ['#ba6b03', '#b91c29', '#4a20e2', '#0969da', '#067654', '#444f5a'];
      this.textColorButtons = [];
      this.backgroundColors = ['#fff986', '#fee2e4', '#dbdfff', '#d7f1ff', '#d2f9e4', '#e8e8e8'];
      this.backgroundColorButtons = [];
    },
    start: function() {
      var btnObj = {
        title: this.lang.get('fontcolor')
      };

      var $dropdown = this._buildDropdown();

      this.button = this.toolbar.addButton('color', btnObj);
      this.button.setIcon('<i class="material-icons">format_color_fill</i>');
      this.button.setDropdown($dropdown);
    },
    onformat() { this._updateButtonState() },
    onclick() { this._updateButtonState() },
    onkeyup() { this._updateButtonState() },
    _updateButtonState() {
      const enabledTextColor = this._enabledTextColor();
      const enabledBackgroundColor = this._enabledBackgroundColor();
      if (enabledTextColor.length > 0 || enabledBackgroundColor.length > 0) {
        this.button.addClass('re-button--active');
      } else {
        this.button.removeClass('re-button--active');
      }
    },
    _enabledTextColor() {
      var hex = '';
      const element = this.selection.getElement();
      if (element && element.style.color) {
        var hex = this._rgbToHex(element.style.color);
      }

      var textColor = '';
      this.textColorButtons.forEach((button) => {
        if (button.attr('rel') == hex) {
          textColor = hex;
          this._setTextColorButtonToActive(button);
        } else {
          this._setTextColorButtonToInactive(button);
        };
      });

      return textColor;
    },
    _setTextColorButtonToActive(button) {
      this.textColorButtons.forEach((textColorButton) => {

        if (textColorButton.is(button)) {
          button.addClass('redactor-dropdown-button--active');
          button.html('<i class="f5 material-icons">done</i>');
        } else {
          this._setTextColorButtonToInactive(textColorButton);
        }
      });
    },
    _setTextColorButtonToInactive(button) {
      button.removeClass('redactor-dropdown-button--active');
      button.html('Ab');
    },
    _enabledBackgroundColor() {
      var hex = '';
      const element = this.selection.getElement();
      if (element && element.style.backgroundColor) {
        var hex = this._rgbToHex(element.style.backgroundColor);
      }

      var backgroundColor = '';
      this.backgroundColorButtons.forEach((button) => {
        if (button.attr('rel') == hex) {
          backgroundColor = hex;
          this._setBackgroundColorButtonToActive(button);
        } else {
          this._setBackgroundColorButtonToInactive(button);
        };
      });

      return backgroundColor;
    },
    _setBackgroundColorButtonToActive(button) {
      this.backgroundColorButtons.forEach((backgroundColorButton) => {
        if (backgroundColorButton.is(button)) {
          button.addClass('redactor-dropdown-button--active');
          const circle = this._circle(button.attr('rel'), true);
          button.html(circle);
        } else {
          this._setBackgroundColorButtonToInactive(backgroundColorButton);
        }
      });
    },
    _setBackgroundColorButtonToInactive(button) {
      button.removeClass('redactor-dropdown-button--active');
      const circle = this._circle(button.attr('rel'), false);
      button.html(circle);
    },
    _circle(backgroundColor, selected) {
      const circle = $R.dom('<div>');
      circle.css({
        'background-color': backgroundColor,
        'width': '20px',
        'height': '20px',
        'border-radius': '360px',
        'justify-content': 'center',
        'display': 'flex'
      });
      if (selected) circle.append('<i class="f5 material-icons">done</i>');
      return circle
    },
    _rgbToHex(rgb) {
      if (rgb === 'inherit') return '';

      let [r, g, b] = rgb.match(/\d+/g).map(Number);
      return `#${((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1)}`;
    },
    _buildDropdown: function() {
      const $textColorPicker = $R.dom("<div class='d-flex flex-column gap-1'>");
      const $textColorTitle = this._buildTitle(this.lang.get('text'));
      const $textColorButtonWrapper = this._buildToolbarButtonWrapper();
      this.textColors.forEach((color) => {
        const textColorSelector = this._buildTextColorSelector(color);
        this.textColorButtons.push(textColorSelector);
        $textColorButtonWrapper.append(textColorSelector);
      });
      $textColorPicker.append($textColorTitle);
      $textColorPicker.append($textColorButtonWrapper);

      const $backgroundColorPicker = $R.dom("<div class='d-flex flex-column gap-1'>");
      const $backgroundColorTitle = this._buildTitle(this.lang.get('background'));
      const $backgroundColorButtonWrapper = this._buildToolbarButtonWrapper();
      this.backgroundColors.forEach((color) => {
        const backgroundColorSelector = this._buildBackgroundColorSelector(color);
        this.backgroundColorButtons.push(backgroundColorSelector);
        $backgroundColorButtonWrapper.append(backgroundColorSelector);
      });
      $backgroundColorPicker.append($backgroundColorTitle);
      $backgroundColorPicker.append($backgroundColorButtonWrapper);

      const $dropdown = $R.dom("<div class='d-flex flex-column gap-2 p-3'>");
      $dropdown.append($textColorPicker);
      $dropdown.append($backgroundColorPicker);
      $dropdown.width(204);

      return $dropdown;
    },
    _buildTitle: function(title) {
      var $title = $R.dom('<div>');
      $title.html(title);
      $title.addClass('redactor-dropdown-not-close');
      $title.addClass('text-support');
      $title.addClass('f5');

      return $title;
    },
    _buildToolbarButtonWrapper() {
      var $wrapper = $R.dom('<div>');
      $wrapper.addClass('d-flex');
      $wrapper.addClass('gap-1');

      return $wrapper;
    },
    _buildTextColorSelector(color) {
      var $selector = $R.dom('<div>');
      $selector.addClass('redactor-dropdown-button');
      $selector.attr('rel', color).html('Ab');
      $selector.attr({'data-rule': 'color'});
      $selector.css({'color': color});
      $selector.on('mousedown', this._handlePicker.bind(this));

      return $selector;
    },
    _buildBackgroundColorSelector(color) {
      var $selector = $R.dom('<div>');
      $selector.addClass('redactor-dropdown-button');
      $selector.attr('rel', color)
      $selector.attr({'data-rule': 'background-color'});

      var $circle = $R.dom('<div>');
      $circle.css({'background-color': color, 'width': '20px', 'height': '20px', 'border-radius': '360px'});
      $selector.append($circle)
      $selector.on('mousedown', this._handlePicker.bind(this));

      return $selector;
    },
    _handlePicker(e) {
      e.preventDefault();
      const dropdownButton = e.target.closest('.redactor-dropdown-button');
      var $el = $R.dom(dropdownButton);

      var style = {}
      if (this.selection.isCollapsed() && this.caret.isEnd()) {
        const element = this.selection.getInline();
        if (element) {
          this.caret.setAfter(element);
          style['background-color'] = element.style.backgroundColor;
          style['color'] = element.style.color;
        }
      }

      const removeStyle = $el.hasClass('redactor-dropdown-button--active');
      if (removeStyle) {
        if (this.selection.isCollapsed()) {
          style[$el.data('rule')] = 'inherit';
          this.inline.format({tag: 'span', type: 'toggle', style: style})
        } else {
          this.inline.remove({style: $el.data('rule')});
        }
      } else {
        if (!this.selection.isCollapsed()) {
          this.inline.remove({style: $el.data('rule')});
        }

        style[$el.data('rule')] = $el.attr('rel');
        this.inline.format({tag: 'span', type: 'toggle', style: style});
      }

      this._setButtonState($el, !removeStyle);
      this.button.toggle();
    },
    _setButtonState(button, active) {
      if (button.data('rule') === 'background-color') {
        this._setBackgroundColorButtonState(button, active);
      } else {
        this._setTextColorButtonState(button, active);
      }
    },
    _setBackgroundColorButtonState(button, active) {
      if (active) {
        this._setBackgroundColorButtonToActive(button);
      } else {
        this._setBackgroundColorButtonToInactive(button);
      }
    },
    _setTextColorButtonState(button, active) {
      if (active) {
        this._setTextColorButtonToActive(button);
      } else {
        this._setTextColorButtonToInactive(button);
      }
    }
  });
})(Redactor);
