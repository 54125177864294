import onmount from 'onmount';

onmount('[data-js-accessibility-toggle]', function () {
  const self = this;
  const { setting } = this.dataset;
  const root = document.documentElement;

  function toggleSetting() {
    localStorage.setItem(setting, self.checked);

    if (self.checked) {
      root.classList.add(setting);
    } else {
      root.classList.remove(setting);
    }
  }

  self.checked = localStorage.getItem(setting) === 'true';
  toggleSetting();

  self.addEventListener('click', toggleSetting);
});
