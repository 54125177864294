import '@kollegorna/cocoon-vanilla-js';
import onmount from 'onmount';
import mdcAutoInit from '@material/auto-init';

onmount('[data-js-edit-option-list]', function () {
  const self = this;
  const { subquestionId } = this.dataset;
  const subquestion = document.querySelector(`[data-js-subquestion-edit][data-subquestion-id='${subquestionId}']`);
  const optionItemWrappers = this.querySelectorAll('[data-js-option-items]');
  const marginToggle = this.querySelector('[data-js-toggle-margin]');
  const widthToggle = this.querySelector('[data-js-toggle-width]');
  const options = this.querySelector('[data-js-options]');

  function toggleMargin() {
    self.querySelector('[data-js-margin] [data-js-number-field]').numeric.set('0');
    const inputs = self.querySelectorAll('[data-js-item-content]');
    if (this.checked) {
      inputs.forEach((input) => input.setAttribute('type', 'number'));
    } else {
      inputs.forEach((input) => input.setAttribute('type', 'text'));
    }
  }

  function toggleWidth() {
    const target = self.querySelector(this.dataset.toggleTarget);
    const input = target.querySelector('input');
    if (this.checked) {
      const contents = [...self.querySelectorAll('[data-js-option-item]:not(.d-none) [data-js-item-content]')];
      let value = '';
      if (contents.length) {
        const contentLengthArray = contents.map((content) => content.value.toString().length);
        const maxLength = Math.max(...contentLengthArray);
        value = Math.min(80, maxLength);
      }

      input.numeric.set(value);
      target.MDCTextField.value = value;
    } else {
      input.numeric.set('');
    }
  }

  function setTabIndex() {
    let counter = 0;
    const optionItems = self.querySelectorAll('[data-js-option-item]');

    [...optionItems].forEach((item) => {
      counter += 1;
      item.querySelector('[data-js-item-content]').tabindex = counter;
      counter += 1;
      item.querySelector('[data-js-item-points]').tabindex = counter;
    });
  }

  function handleKeyDown(e) {
    if (e.key === 'Enter') {
      const items = [...document.querySelectorAll('[data-js-item-content]')];
      if (items.find((el) => !el.value.trim())) return;

      e.preventDefault();
      self.querySelector('[data-js-add-option-item]').click();
    }
  }

  function handleKeyboardInput() {
    const optionItems = self.querySelectorAll('[data-js-option-item]');

    [...optionItems].forEach((item) => {
      item.querySelector('[data-js-item-content]').addEventListener('keydown', handleKeyDown);
    });
  }

  function removeFromOtherAnswers(optionItem) {
    const wrapper = optionItem.closest('[data-js-option-list]');
    if (!wrapper) return;

    const otherAnswers = wrapper.querySelectorAll('[data-js-other-answers] [data-js-answer-option]:not(.d-none)');
    const answerContent = optionItem.querySelector('[data-js-item-content]').value;

    otherAnswers.forEach((answer) => {
      if (answer.querySelector('[data-js-answer-content]').dataset.jsAnswerContent === answerContent) {
        answer.classList.add('d-none');
        answer.classList.remove('d-inline-block');
      }
    });
  }

  function addToOtherAnswers(optionList, optionItem) {
    const hiddenAnswers = optionList.querySelectorAll('[data-js-other-answers] [data-js-answer-option].d-none');
    const answerFieldContent = optionItem.querySelector('[data-js-item-content]').value;

    hiddenAnswers.forEach((hiddenAnswer) => {
      const hiddenAnswerContent = hiddenAnswer.querySelector('[data-js-answer-content]').dataset.jsAnswerContent;
      if (hiddenAnswerContent === answerFieldContent) {
        hiddenAnswer.classList.add('d-inline-block');
        hiddenAnswer.classList.remove('d-none');
      }
    });
  }

  function handleItemAdd(e) {
    mdcAutoInit();
    setTabIndex();
    const lastChild = e.currentTarget.lastElementChild;
    const input = lastChild.querySelector('[data-js-item-content]');
    if (marginToggle) input.setAttribute('type', (marginToggle.checked ? 'number' : 'text'));
    input.focus();
    if (this.closest('[data-js-option-list]')) removeFromOtherAnswers(lastChild);
    return handleKeyboardInput();
  }

  function handleItemRemove(e) {
    const removedItem = e.detail;
    removedItem.classList.add('d-none');

    const optionList = this.closest('[data-js-option-list]');
    if (optionList) addToOtherAnswers(optionList, removedItem);

    return removedItem.querySelector('input').removeAttribute('required');
  }

  function initOptions() {
    if (subquestion.querySelector('[data-js-fill-dropdown-toggle]').checked) {
      options.classList.add('d-none');
    } else {
      options.classList.remove('d-none');
    }
  }

  setTabIndex();
  handleKeyboardInput();
  if (options) initOptions();

  if (optionItemWrappers) {
    optionItemWrappers.forEach((list) => {
      list.addEventListener('cocoon:after-insert', handleItemAdd);
      list.addEventListener('cocoon:after-remove', handleItemRemove);
    });
  }
  if (marginToggle) marginToggle.addEventListener('change', toggleMargin);
  if (widthToggle) widthToggle.addEventListener('change', toggleWidth);
});
