import { Controller } from '@hotwired/stimulus';
import { MDCTextField } from '@material/textfield';

export default class extends Controller {
  connect() {
    this.updateInputLabel();
  }

  updateInputLabel() {
    const label = this.element.querySelector('.mdc-floating-label');
    label.classList.add('mdc-floating-label--float-above');

    if (!this.element.MDCTextField) {
      this.element.MDCTextField = new MDCTextField(this.element);
      this.element.setAttribute('data-mdc-auto-init-state', 'initialized');
    }

    this.element.MDCTextField.foundation.notchOutline(true);
  }
}
