import { formatNumber } from '../helpers';
import { Component } from './component';

export class Path extends Component {
  constructor(...args) {
    super(...args);
    this.element = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  }

  init() {
    super.init();

    this.element.setAttribute('fill', 'none');
    this.element.setAttribute('stroke-linecap', 'round');
    this.element.setAttribute('stroke-linejoin', 'round');

    this.path = `M ${formatNumber(this.origin.x)} ${formatNumber(this.origin.y)}`;
    this.lastPosition = { c: this.origin.x, d: this.origin.y, ...this.origin };

    return this;
  }

  addPoint(position) {
    const c = (this.lastPosition.x + position.x) / 2;
    const d = (this.lastPosition.y + position.y) / 2;

    const dx1 = (this.lastPosition.x - this.lastPosition.c);
    const dy1 = (this.lastPosition.y - this.lastPosition.d);
    const dx = (c - this.lastPosition.c);
    const dy = (d - this.lastPosition.d);

    this.path = `${this.path} q${formatNumber(dx1)} ${formatNumber(dy1)} ${formatNumber(dx)} ${formatNumber(dy)}`;
    this.element.setAttribute('d', this.path);

    this.lastPosition = { c, d, ...position };
  }

  endPath(position) {
    const dx = (position.x - this.lastPosition.c);
    const dy = (position.y - this.lastPosition.d);

    this.path = `${this.path} l${formatNumber(dx)} ${formatNumber(dy)}`;
    this.element.setAttribute('d', this.path);
  }
}
