import onmount from 'onmount';

onmount('[data-js-fifteen-minute-alarm]', function () {
  const self = this;
  const fifteenMinuteAlarmDefault = true;
  const fifteenMinuteDialog = document.querySelector('[data-js-time-warning-dialog]');

  let fifteenMinuteAlarm;

  function triggerFifteenMinuteAlarm() {
    fifteenMinuteDialog.MDCDialog.open();
  }

  function updateFifteenMinuteAlarm(showAlarm) {
    self.checked = showAlarm;
    sessionStorage.setItem('fifteen-minute-alarm', showAlarm);
  }

  function quizDurationListener(e) {
    if (e.detail < 15 * 60 && self.checked) {
      triggerFifteenMinuteAlarm();
      updateFifteenMinuteAlarm(false);
    }
  }
  this.quizDurationListener = quizDurationListener;

  function setFifteenMinuteAlarm() {
    if (sessionStorage.getItem('fifteen-minute-alarm')) {
      fifteenMinuteAlarm = sessionStorage.getItem('fifteen-minute-alarm') === 'true';
    } else {
      fifteenMinuteAlarm = fifteenMinuteAlarmDefault;
    }

    updateFifteenMinuteAlarm(fifteenMinuteAlarm);
  }

  function handleFifteenMinuteAlarmClick() {
    updateFifteenMinuteAlarm(self.checked);
  }

  setFifteenMinuteAlarm();
  self.addEventListener('click', handleFifteenMinuteAlarmClick);
  document.addEventListener('quizDurationChanged', this.quizDurationListener);
}, function () {
  document.removeEventListener('quizDurationChanged', this.quizDurationListener);
});
