import onmount from 'onmount';

onmount('[data-js-date-filter]', function () {
  const { attribute } = this.dataset;
  const dateInput = this.querySelector('[data-js-flatpickr-date]');
  const queryButton = this.querySelector('[data-js-date]');

  function handleDateChange() {
    queryButton.disabled = false;
    queryButton.setAttribute('data-query', `${attribute}>='${dateInput.value}'`);
  }

  dateInput.addEventListener('input', handleDateChange);
});
