import onmount from 'onmount';

onmount('[data-js-indicator]', function () {
  const self = this;
  const {
    event, selector, secondaryEvent, secondarySelector
  } = this.dataset;

  function updateIndicator(e) {
    let indicatorClass = 'indicator';
    let query = selector;

    if (e.type === secondaryEvent) {
      indicatorClass = 'indicator--secondary';
      query = secondarySelector;
    }

    if (document.querySelector(query)) {
      self.classList.add(indicatorClass);
    } else {
      self.classList.remove(indicatorClass);
    }
  }
  this.updateIndicator = updateIndicator;

  if (event && selector) document.addEventListener(event, this.updateIndicator);
  if (secondaryEvent && secondarySelector) document.addEventListener(secondaryEvent, this.updateIndicator);
}, function () {
  const { event, secondaryEvent } = this.dataset;
  if (event) document.removeEventListener(event, this.updateIndicator);
  if (secondaryEvent) document.removeEventListener(secondaryEvent, this.updateIndicator);
});
