import onmount from 'onmount';
import snackbar from '@components/snackbar';

onmount('[data-js-notice]', function () {
  let message = this.innerHTML.trim();
  try {
    message = decodeURIComponent(escape(message));
  } catch (err) {
    console.warn(err); // eslint-disable-line no-console
  }

  const { actionLink, actionLabel } = this.dataset;
  const alert = this.hasAttribute('data-alert');

  if (message.length) {
    snackbar(message, alert, actionLink, actionLabel);
  }
});
