import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['url'];

  updateUrls({ detail: { url } }) {
    this.urlTargets.forEach((target) => {
      target.dataset.url = url;
    });
  }
}
