import onmount from 'onmount';
import { MDCTabBar } from '@material/tab-bar';

onmount('[data-js-emoji]', function () {
  const self = this;
  const sets = this.querySelectorAll('[data-js-emoji-set]');

  function handleTabBarChanged(e) {
    sets.forEach((set) => {
      if (set.dataset.setId === e.detail.index.toString()) {
        set.classList.remove('d-none');
      } else {
        set.classList.add('d-none');
      }
    });
  }

  function handleTabBarClick(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  function initTabBar() {
    const tabBarEl = self.querySelector('.mdc-tab-bar');
    if (!tabBarEl) {
      setTimeout(initTabBar, 250);
      return;
    }

    if (!tabBarEl.MDCTabBar) {
      tabBarEl.MDCTabBar = new MDCTabBar(tabBarEl);
      tabBarEl.setAttribute('data-mdc-auto-init-state', 'initialized');
    }
    tabBarEl.addEventListener('click', handleTabBarClick);
    tabBarEl.MDCTabBar.listen('MDCTabBar:activated', handleTabBarChanged);
    tabBarEl.MDCTabBar.activateTab(0);
  }

  initTabBar();
});
