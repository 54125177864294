import onmount from 'onmount';
import { updateFlaggedSubmission, updateActiveSubmission, syncDrawer } from '@modules/quiz/navigating';
import { activeRequests } from '@modules/quiz/wait-for-saving';

onmount('[data-js-quiz-navigation]', function () {
  const self = this;
  const btnPrev = this.querySelector('[data-js-prev]');
  const btnNext = this.querySelector('[data-js-next]');
  const goToTiles = this.querySelectorAll('[data-js-go-to]');
  const requireAnswer = this.hasAttribute('data-js-require-answer');

  function handleSubsetChangeClick(e) {
    const { submissionId } = e.target.dataset;
    const submissionEl = document.querySelector(`[data-js-quiz-subquestion][data-submission-id='${submissionId}']`);
    if (submissionEl) {
      window.location.hash = `#sub-${submissionId}`;
      updateActiveSubmission(submissionId);
      syncDrawer();

      e.preventDefault();
      e.stopPropagation();

      const scrollToActiveEvent = new CustomEvent('navigation:scrollToActive');
      self.dispatchEvent(scrollToActiveEvent);
      return;
    }

    if (activeRequests.call(this)) {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  function updateNavigation() {
    const currentSubset = document.querySelector('[data-js-current-subset]');
    const lockableSubsets = document.querySelectorAll(
      '[data-js-subquestion-group]:not([data-js-blocked-for-student]):not([data-js-current-subset])'
    );
    let openLocks = true;

    [...currentSubset.querySelectorAll('.subquestion-selector-item')].forEach((submission) => {
      if (submission.dataset.answeredSubmission === 'false') {
        openLocks = false;
      }
    });

    [...lockableSubsets].forEach((subset) => {
      const submissions = subset.querySelectorAll('.subquestion-selector-item');
      if (openLocks) {
        [...submissions].forEach((submission) => submission.classList.remove('subquestion-selector-item--locked'));
      } else {
        [...submissions].forEach((submission) => submission.classList.add('subquestion-selector-item--locked'));
      }
    });
    if (openLocks) {
      if (btnPrev) btnPrev.disabled = false;
      if (btnNext) btnNext.disabled = false;
    } else {
      if (btnPrev) btnPrev.disabled = true;
      if (btnNext) btnNext.disabled = true;
    }
  }
  this.updateNavigation = updateNavigation;

  function handleSubmissionFlaggedEvent(e) {
    const { submissionId, flagged } = e.detail;

    updateFlaggedSubmission(submissionId, flagged);
  }
  this.handleSubmissionFlaggedEvent = handleSubmissionFlaggedEvent;

  if (btnPrev) btnPrev.addEventListener('click', handleSubsetChangeClick);
  if (btnNext) btnNext.addEventListener('click', handleSubsetChangeClick);

  goToTiles.forEach((tile) => {
    tile.addEventListener('click', handleSubsetChangeClick);
  });

  if (requireAnswer) document.addEventListener('submission:answered', this.updateNavigation);
  document.addEventListener('submission:flagged', this.handleSubmissionFlaggedEvent);
}, function () {
  document.removeEventListener('submission:answered', this.updateNavigation);
  document.removeEventListener('submission:flagged', this.handleSubmissionFlaggedEvent);
});
