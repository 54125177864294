import onmount from 'onmount';

onmount('[data-js-subsets-drawer]', function () {
  const groupsWrapper = this.querySelector('[data-js-groups]');
  const answeredTrackerEl = this.querySelector('[data-js-answered-tracker]');
  const pointsTrackerEl = this.querySelector('[data-js-points-tracker]');
  const totalPointsEl = this.querySelector('[data-js-total-points]');

  function buildSubmission(submission) {
    const {
      submissionId, category, points, numeral
    } = submission.dataset;

    const submissionEl = `
      <div class='py-1 d-flex flex-items-center grouped-subquestions'>
        <div class='text-semi-bold mx-2 px-1' data-js-tile data-submission-id='${submissionId}'>
          ${numeral}
        </div>

        <div class='truncate flex-basis-100 d-flex flex-items-center flex-justify-between'>
          <div class='truncate'>${category}</div>
          <div class='f6 mr-3'>${points}p</div>
        </div>
      </div>
    `;

    return submissionEl;
  }

  function buildGroup(group) {
    const submissions = group.querySelectorAll('[data-js-go-to][data-submission-id]');
    if (!submissions.length) return '';

    const groupEl = `
      <li class='mb-3 py-2 group rounded-1'>
        ${[...submissions].map((submission) => buildSubmission(submission)).reduce((a, b) => a + b)}
      </li>
    `;

    return groupEl;
  }

  function syncTrackers() {
    let answeredCount = 0;
    let answeredPoints = 0;
    let totalPoints = 0;

    const submissions = document.querySelectorAll('[data-js-go-to][data-submission-id]');
    submissions.forEach((submissionTile) => {
      const { answeredSubmission, points } = submissionTile.dataset;
      totalPoints += parseFloat(points);
      if (answeredSubmission === 'false') return;

      answeredCount += 1;
      answeredPoints += parseFloat(points);
    });

    answeredTrackerEl.innerHTML = ((answeredCount / submissions.length) * 100).toFixed(2);
    pointsTrackerEl.innerHTML = answeredPoints;
    totalPointsEl.innerHTML = totalPoints;
  }

  function buildDrawer() {
    const navBar = document.querySelector('[data-js-quiz-navigation]');

    navBar.querySelectorAll('[data-js-question-group]').forEach((group) => {
      groupsWrapper.insertAdjacentHTML('beforeend', buildGroup(group));
    });
    syncTrackers();
  }

  buildDrawer();
  this.addEventListener('drawer:sync', syncTrackers);
});
