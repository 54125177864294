export function formatTime(seconds, showHour = true) {
  const date = new Date(1970, 0, 1);
  date.setSeconds(seconds);

  const hrs = date.getHours();
  const min = (`0${date.getMinutes()}`).slice(-2);
  const sec = (`0${date.getSeconds()}`).slice(-2);

  if (showHour) {
    return `${hrs}:${min}:${sec}`;
  }

  return `${min}:${sec}`;
}
