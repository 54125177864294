export function inputContentWidth(input) {
  // Create a hidden element for measuring text width
  const span = document.createElement('span');
  document.body.appendChild(span);

  // Apply the input's styles to the span
  const style = window.getComputedStyle(input);
  span.style.font = style.font;
  span.style.padding = style.padding;
  span.style.position = 'absolute';
  span.style.visibility = 'hidden';
  span.style.whiteSpace = 'pre'; // Ensures spaces are measured accurately

  // Set the span's text to the input's value
  span.textContent = input.value;

  // Measure the span's width
  const width = span.offsetWidth;

  // Clean up the span
  document.body.removeChild(span);

  return width;
}
