export const MATRIX_ROWS = [
  [
    {
      class: 'w20 small',
      latex: '\\begin{bmatrix} #0 & #0 \\\\ #0 & #0 \\end{bmatrix}'
    },
    {
      class: 'separator w5'
    },
    {
      label: '<span class="material-icons mirror90">add_road</span>',
      command: 'addRowBefore'
    },
    {
      label: '<span class="material-icons mirror">add_road</span>',
      command: 'addColumnBefore'
    }
  ],
  [
    {
      class: 'w20 small',
      latex: '\\begin{pmatrix} #0 & #0 \\\\ #0 & #0 \\end{pmatrix}'
    },
    {
      class: 'separator w5'
    },
    {
      label: '<span class="material-icons rotate90">add_road</span>',
      command: 'addRowAfter'
    },
    {
      label: '<span class="material-icons">add_road</span>',
      command: 'addColumnAfter'
    }
  ],
  [
    {
      class: 'w20 small',
      latex: '\\begin{Bmatrix} #0 & #0 \\\\ #0 & #0 \\end{Bmatrix}'
    },
    {
      class: 'separator w5'
    },
    {
      label: '<span class="material-icons rotate90">remove_road</span>',
      command: 'removeRow'
    },
    {
      label: '<span class="material-icons">remove_road</span>',
      command: 'removeColumn'
    }
  ]
];

// export const MATRIX_KEYBOARD = {
// 'matrix-keyboard': {
//   label: '[&square;&square;]',
//   tooltip: 'Matrix',
//   layer: 'matrix-layer'
// }
// };
