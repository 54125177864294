import onmount from 'onmount';
import Rails from '@rails/ujs';
import { displayUpdatingStatus } from '@modules/quiz/saving';
import { clearTimeouts } from '@modules/custom';

onmount('[data-js-auto-save]', function () {
  this.timeOuts = [];
  const INTERVAL = 30 * 1000;

  const self = this;
  const form = this.closest('form');
  const timeAgo = self.querySelector('[data-js-time-ago]');
  const redactorEvents = ['keyup', 'inserted', 'image.changed', 'image.deleted', 'image.resized', 'format'];
  let redactor = null;

  function submit() {
    if (form.getAttribute('data-saved') === 'false') {
      form.setAttribute('data-saved', true);
      displayUpdatingStatus(form);
      Rails.fire(form, 'submit');
    }

    clearTimeouts(self.timeOuts);
    self.timeOuts.push(setTimeout(submit, INTERVAL));
  }

  function handleSuccess() {
    const event = new CustomEvent('timeago:restart');
    timeAgo.dispatchEvent(event);
  }

  function handleContentChanged() {
    form.setAttribute('data-saved', false);
  }

  function addEventHandlers() {
    redactor.callback.add('blur', submit);
    redactorEvents.forEach((e) => redactor.callback.add(e, handleContentChanged));
  }

  function waitForRedactor() {
    if (self.querySelector('.redactor-in')) {
      ({ redactor } = self.querySelector('[data-js-redactor]'));
      addEventHandlers();
      return;
    }
    setTimeout(waitForRedactor, 100);
  }

  waitForRedactor();
  this.timeOuts.push(setTimeout(submit, INTERVAL));
  form.addEventListener('ajax:success', handleSuccess);
}, function () {
  clearTimeouts(this.timeOuts);
});
