import onmount from 'onmount';
import { MDCSelect } from '@material-overwrites/mdc.select';

onmount('[data-js-subquestion-edit-grading-options]', function () {
  const self = this;
  const variableToggle = this.querySelector('[data-js-toggle-variable]');
  const addKprimeButton = this.querySelector('[data-js-add-kprime]');
  const gradingTypeSelect = this.querySelector('[data-js-grading-type-select]');
  const rubricTypeSelect = this.querySelector('[data-js-rubric-type-select]');
  const weightField = this.querySelector('[data-js-weight-field]');

  function toggleVariable() {
    const target = self.querySelector(this.dataset.jsToggleVariable);
    const numericValue = self.querySelector('[data-js-numeric-value]');
    const variableSelect = target.querySelector('.mdc-select');

    if (this.checked) {
      target.classList.remove('d-none');
      numericValue.classList.add('d-none');
    } else {
      target.classList.add('d-none');
      numericValue.classList.remove('d-none');
      variableSelect.MDCSelect.setValue('');
    }
  }

  function setCorrectCountAndPoints(rule, correctCount, points) {
    const correctCountEl = rule.querySelector('[data-js-rule-correct-count]');
    if (!correctCountEl.MDCSelect) {
      correctCountEl.MDCSelect = new MDCSelect(correctCountEl);
      correctCountEl.setAttribute('data-mdc-auto-init-state', 'initialized');
    }
    correctCountEl.MDCSelect.value = correctCount.toString();

    const pointsEl = rule.querySelector('[data-js-rule-points]');
    if (pointsEl.numeric) {
      pointsEl.numeric.set(points);
    } else {
      pointsEl.value = points;
    }
  }

  function addKprimeRules() {
    const { matchRows } = addKprimeButton.dataset;
    const points = self.querySelector('[data-js-subquestion-points]').value;
    const addRulesBtn = self.querySelector('[data-js-add-response-rule]');

    addRulesBtn.click();
    const firstRule = self.querySelector('[data-js-response-rules] [data-js-response-rule]');
    setCorrectCountAndPoints(firstRule, matchRows, points);

    addRulesBtn.click();
    const secondRule = self.querySelector('[data-js-response-rules] [data-js-response-rule]:last-child');
    setCorrectCountAndPoints(secondRule, parseInt(matchRows, 10) - 1, parseFloat(points) / 2);

    addKprimeButton.classList.add('d-none');
  }

  function handleGradingTypeChange() {
    if (this.MDCSelect.value === 'rubrics') {
      weightField.classList.remove('d-none');
      weightField.querySelector('.mdc-text-field').MDCTextField.disabled = false;

      if (rubricTypeSelect) {
        rubricTypeSelect.classList.remove('d-none');
      }
    } else {
      weightField.classList.add('d-none');
      weightField.querySelector('.mdc-text-field').MDCTextField.disabled = true;

      if (rubricTypeSelect) {
        rubricTypeSelect.classList.add('d-none');
      }
    }
  }

  /* EVENT HANDLERS */
  if (variableToggle) variableToggle.addEventListener('change', toggleVariable);
  if (addKprimeButton) addKprimeButton.addEventListener('click', addKprimeRules);
  if (gradingTypeSelect) gradingTypeSelect.addEventListener('change', handleGradingTypeChange);
});
