import onmount from 'onmount';
import Rails from '@rails/ujs';

onmount('[data-js-switch-confirm]', function () {
  const self = this;
  const form = self.closest('form');
  const confirmMessage = self.getAttribute('data-confirm');

  function handleClick(e) {
    if (this.dataset.jsSwitchConfirm === 'false') return;

    if (self.hasAttribute('data-confirm') && !Rails.confirm(confirmMessage, self)) {
      e.preventDefault();
      return;
    }

    self.setAttribute('data-confirm', confirmMessage);
  }

  function handleChange() {
    Rails.fire(form, 'submit');
  }

  self.addEventListener('click', handleClick);
  self.addEventListener('change', handleChange);
});
