import Dialog from '@stimulus-components/dialog';

export default class extends Dialog {
  static targets = ['backdrop'];

  dialogTargetConnected(element) {
    if (element.dataset.dialogOpen === 'true') {
      this.open();
    }
  }

  open() {
    this.#showBackdrop();
    this.dialogTarget.show();
  }

  closeOnSuccess(event) {
    const { success } = event.detail;
    if (success) this.close();
  }

  close() {
    super.close();
    this.backdropTarget.remove();
    this.dialogTarget.innerHTML = '';
  }

  forceClose() {
    if (this.hasDialogTarget) this.dialogTarget.close();
  }

  #showBackdrop() {
    const backdrop = document.createElement('div');
    backdrop.classList.add('dialog__backdrop');
    backdrop.setAttribute('data-dialog-target', 'backdrop');
    backdrop.setAttribute('data-action', 'click->dialog#close');
    this.dialogTarget.before(backdrop);
  }
}
