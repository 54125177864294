import onmount from 'onmount';

onmount('[data-js-two-factor]', function () {
  const self = this;
  const boxes = this.querySelectorAll('[data-js-code-input]');
  const codeInput = this.querySelector('#code');

  function combinedCode() {
    return [...self.querySelectorAll('[data-js-code-input]')].map((input) => input.value.toString()).join('');
  }

  function handleCodeInput() {
    if (!this.value) {
      return;
    }

    codeInput.value = combinedCode();
    const nextEl = this.nextElementSibling;

    if (nextEl) {
      nextEl.focus();
    } else {
      this.form.submit();
    }
  }

  function handleKeyDown(e) {
    if (e.metaKey || e.ctrlKey || e.shiftKey || e.altKey) {
      return;
    }

    if (parseInt(e.key, 10) in [...Array(10).keys()]) {
      return;
    }

    if (e.key === 'Backspace') {
      const prevEl = this.previousElementSibling;

      if (prevEl) {
        prevEl.value = '';
        prevEl.focus();
      } else {
        this.focus();
      }
    } else {
      e.preventDefault();
    }
  }

  function handlePaste(e) {
    e.stopPropagation();
    e.preventDefault();

    const clipboardData = e.clipboardData || window.clipboardData || e.originalEvent.clipboardData;
    const code = clipboardData.getData('Text');

    code.split('').forEach((number, index) => {
      boxes[index].value = number;
    });

    codeInput.value = code;

    if (code.length === 6) {
      this.form.submit();
    }
  }

  /*
    EVENT HANDLERS
  */
  if (boxes.length) {
    for (let iter = 0; iter < boxes.length; iter += 1) {
      boxes[iter].addEventListener('change', handleCodeInput);
      boxes[iter].addEventListener('input', handleCodeInput);
      boxes[iter].addEventListener('keydown', handleKeyDown);
      boxes[iter].addEventListener('paste', handlePaste);
    }
  }
});
