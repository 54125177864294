import { formatNumber } from '../helpers';
import { Component } from './component';

export class Line extends Component {
  constructor(...args) {
    super(...args);
    this.element = document.createElementNS('http://www.w3.org/2000/svg', 'line');
  }

  init() {
    super.init();

    this.element.setAttribute('stroke-linecap', 'round');
    this.element.setAttribute('stroke-linejoin', 'round');

    this.element.setAttribute('x1', formatNumber(this.origin.x));
    this.element.setAttribute('y1', formatNumber(this.origin.y));
    this.element.setAttribute('x2', formatNumber(this.origin.x));
    this.element.setAttribute('y2', formatNumber(this.origin.y));

    return this;
  }

  addPoint(position) {
    this.element.setAttribute('x2', formatNumber(position.x));
    this.element.setAttribute('y2', formatNumber(position.y));
  }

  endPath(position) {
    this.addPoint(position);
  }
}
