import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['chip', 'feedback', 'feedbackMistake'];

  connect() {
    this.update();
  }

  update() {
    this.chipTarget.innerText = this.feedbackCount();
    this.toggleChipVisibility(this.feedbackCount());
  }

  toggleChipVisibility(feedbackCount) {
    if (feedbackCount) {
      this.showChip();
    } else {
      this.hideChip();
    }
  }

  showChip() {
    this.chipTarget.classList.remove('d-none');
  }

  hideChip() {
    this.chipTarget.classList.add('d-none');
  }

  isHidden(field) {
    return this.feedbackMistakeTarget.classList.contains('d-none') && this.feedbackMistakeTarget.contains(field);
  }

  feedbackCount() {
    return this.feedbackTargets.filter((field) => {
      if (this.isHidden(field)) return false;

      return (field.value && field.value !== '<div></div>');
    }).length;
  }
}
