{
  "en": {
    "js": {
      "redactor": {
        "accessibility-help-label": "Rich text editor",
        "add-gap": "New gap",
        "add-head": "Add head",
        "add-variable": "Add variable",
        "align": "Align",
        "align-center": "Align Center",
        "align-left": "Align Left",
        "align-right": "Align Right",
        "allow-microphone-access": "Please allow access to your microphone",
        "audio": "Audio recording",
        "background": "Background",
        "background-color": "Background color",
        "blockquote": "Quote",
        "bold": "Bold",
        "bold-abbr": "B",
        "bulletslist": "Bullets",
        "cancel": "Cancel",
        "caption": "Caption",
        "center": "Center",
        "change": "Change",
        "change-picture": "Change picture",
        "characterLimit": "You cannot paste more than 5000 characters",
        "chars": "Chars",
        "choose": "Choose",
        "close": "Close",
        "code": "Code",
        "delete": "Delete",
        "delete-column": "Delete column",
        "delete-head": "Delete head",
        "delete-row": "Delete row",
        "delete-table": "Delete table",
        "deleted": "Strikethrough",
        "deleted-abbr": "S",
        "drawing": "Drawing",
        "edit": "Edit",
        "equation": "Equation",
        "error-accessing-microphone": "There was an error accessing your microphone",
        "file": "File",
        "filename": "Name",
        "fontcolor": "Text Color",
        "format": "Format",
        "fullscreen": "Fullscreen",
        "gap": "Gap",
        "grade-formula-points": "Obtained points",
        "grade-formula-total": "Total points",
        "heading": "Heading",
        "heading1": "Heading 1",
        "heading2": "Heading 2",
        "heading3": "Heading 3",
        "heading4": "Heading 4",
        "heading5": "Heading 5",
        "heading6": "Heading 6",
        "horizontalrule": "Line",
        "image": "Image",
        "image-position": "Position",
        "indent": "Indent",
        "insert": "Insert",
        "insert-column-left": "Insert column left",
        "insert-column-right": "Insert column right",
        "insert-row-above": "Insert row above",
        "insert-row-below": "Insert row below",
        "insert-table": "Insert table",
        "italic": "Italic",
        "italic-abbr": "I",
        "left": "Left",
        "link": "Link",
        "link-edit": "Edit Link",
        "link-in-new-tab": "Open link in new tab",
        "link-insert": "Insert Link",
        "lists": "Lists",
        "make-recording-warning": "Please make a recording first",
        "maxWords": "Max words",
        "no-variables": "No variables defined",
        "none": "None",
        "numberslist": "Numbers",
        "optionList": "Option list",
        "optional": "Optional",
        "orderedlist": "Ordered List",
        "outdent": "Outdent",
        "paragraph": "Normal text",
        "pause": "Pause",
        "pre": "Code",
        "quote": "Quote",
        "record": "Record",
        "redo": "Redo",
        "remove": "Remove",
        "resume": "Resume",
        "right": "Right",
        "save": "Save",
        "save as shortcut": "Save as quick comment",
        "shortcuts": "Quick comments",
        "show shortcuts": "Show quick comments",
        "specialchars": "Special Characters",
        "stop": "Stop",
        "stream-unavailable": "Video stream not available.",
        "subscript": "Subscript",
        "subscript-abbr": "Sub",
        "superscript": "Superscript",
        "superscript-abbr": "Sup",
        "table": "Table",
        "take-picture": "Take picture",
        "take-picture-warning": "Take picture first",
        "text": "Text",
        "text-color": "Text color",
        "title": "Alt",
        "twemoji": "Emoji",
        "underline": "Underline",
        "underline-abbr": "U",
        "undo": "Undo",
        "unlink": "Unlink",
        "unorderedlist": "Unordered List",
        "upload": "Upload",
        "upload-change-label": "Drop a new image here to edit",
        "upload-label": "Drop files here or click to upload",
        "variable": "Variable",
        "webcam": "Webcam screenshot",
        "wordLimit": "Word limit reached",
        "words": "Words"
      }
    }
  }
}
