import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['grade'];

  update(e) {
    if (e.target.value === 'completed') {
      this.gradeTarget.MDCTextField.disabled = true;
      this.gradeTarget.MDCTextField.value = '';
    } else {
      this.gradeTarget.MDCTextField.prefixText = e.target.getAttribute('data-requirements-form-prefix');
      this.gradeTarget.MDCTextField.disabled = false;
    }
  }
}
