/* global Ace */

import onmount from 'onmount';
import Rails from '@rails/ujs';

onmount('[data-js-ace-editor]', function () {
  const self = this;
  const data = this.value;
  const url = this.getAttribute('data-url');
  const readOnly = this.getAttribute('readOnly') || this.getAttribute('disabled') || false;
  const wrap = this.dataset.wrap === 'true';
  let type = this.getAttribute('data-type') || '.py';
  const aceConfig = {
    showPrintMargin: false,
    readOnly,
    minLines: 10,
    maxLines: Infinity,
    wrap
  };

  function getFileContent() {
    Rails.ajax({
      url,
      type: 'GET',
      success: (res) => self.aceEditor.getSession().setValue(res)
    });
  }

  function updateInput() {
    self.value = self.aceEditor.getSession().getValue();

    const event = new CustomEvent('ace-editor:changed');
    self.dispatchEvent(event);
  }

  function createWrapper() {
    const aceWrapper = document.createElement('div');
    aceWrapper.style.height = '300px';
    aceWrapper.style.width = 'auto';
    self.parentElement.append(aceWrapper);

    return aceWrapper;
  }

  function setLanguage() {
    switch (type) {
      case '.py':
        type = 'python';
        break;
      case '.m':
        type = 'matlab';
        break;
      case '.latex':
        type = 'latex';
        break;
      default:
        type = type || 'python';
        break;
    }

    self.aceEditor.session.setMode(`ace/mode/${type}`);
  }

  function changeLanguage(e) {
    const { language } = e.detail;
    if (language === type) return;

    type = language;
    setLanguage();
  }

  function init() {
    if (typeof Ace === 'undefined') {
      return setTimeout(init, 100);
    }

    const aceWrapper = createWrapper();

    self.aceEditor = Ace.edit(aceWrapper);
    self.aceEditor.setOptions(aceConfig);
    self.aceEditor.setTheme('ace/theme/monokai');
    setLanguage();
    self.aceEditor.getSession().setValue(data);
    self.aceEditor.getSession().on('change', updateInput);

    self.aceEditor.container.querySelector('textarea').setAttribute('aria-label', 'Code editor');

    self.setAttribute('data-ace-initialized', true);
    if (url) getFileContent();

    return true;
  }

  init();
  this.addEventListener('ace-editor:change-language', changeLanguage);
}, function () {
  this.aceEditor.destroy();
  this.remove();
});
