/* global i18n */

import onmount from 'onmount';
import snackbar from '@components/snackbar';
import Rails from '@rails/ujs';
import { getPresignedUrl, uploadToObjectStore, createUpload } from '@modules/upload-files';
import { MDCLinearProgress } from '@material/linear-progress';

onmount('[data-js-inline-file-upload]', function () {
  const self = this;
  const { url } = this.dataset;
  const params = JSON.parse(this.dataset.params);

  const progressEl = this.querySelector('.mdc-linear-progress');
  if (progressEl && !progressEl.MDCLinearProgress) {
    progressEl.MDCLinearProgress = new MDCLinearProgress(progressEl);
    progressEl.setAttribute('data-mdc-auto-init-state', 'initialized');
  }

  const input = this.querySelector('input[type="file"]');
  const label = this.querySelector('label[for="upload"]');
  const removeBtn = this.querySelector('[data-js-remove-upload]');
  const fileEl = this.querySelector('[data-js-file]');

  function showProgress() {
    progressEl.classList.remove('d-none');
  }

  function hideProgress() {
    progressEl.classList.add('d-none');
  }

  function resetProgress() {
    progressEl.MDCLinearProgress.foundation.setProgress(0);
    fileEl.classList.remove('d-none');

    showProgress();
  }

  function updateProgress(event) {
    const progress = event.loaded / event.total;
    progressEl.MDCLinearProgress.foundation.setProgress(progress);
    if (progress > 0.99) hideProgress();
  }

  function sanitizeFile(file) {
    return new File([file], file.name.normalize('NFKD').replace(/\p{Diacritic}/gu, ''), { type: file.type });
  }

  function showRemoveUpload(response) {
    removeBtn.setAttribute('data-upload-id', response.id);
    removeBtn.classList.remove('d-none');
  }

  function handleChange(e) {
    const targetFile = e.target.files[0];
    if (!targetFile) return true;

    if (targetFile.size > 25000000) return snackbar(i18n.t('js.uploads.file_too_large', { filesize: '25 MB' }));

    const file = sanitizeFile(targetFile);
    fileEl.querySelector('[data-js-file-name]').innerHTML = file.name;
    label.classList.add('d-none');
    return getPresignedUrl(url, file.name)
      .then(
        (data) => uploadToObjectStore(file, data.put_url, self, updateProgress, resetProgress)
          .then(
            () => createUpload(file, data, params, null)
              .then((response) => showRemoveUpload(response))
          )
      ).catch((err) => {
        progressEl.classList.add('d-none');
        let message;
        if (err.message) {
          message = Object.entries(err.message).map(([key, value]) => `${key} ${value}`).join('');
        }
        snackbar(message || i18n.t('js.uploads.failed'), true);
      });
  }

  function removeUpload() {
    const { confirm } = this.dataset;
    if (confirm && !Rails.confirm(confirm, this)) return;

    const { uploadId } = this.dataset;
    Rails.ajax({
      url: `/uploads/${uploadId}`,
      type: 'DELETE',
      dataType: 'json',
      success: () => {
        removeBtn.classList.add('d-none');
        removeBtn.setAttribute('data-confirm', confirm);
        fileEl.classList.add('d-none');
        label.classList.remove('d-none');
      }
    });
  }

  input.addEventListener('change', handleChange);
  if (removeBtn) removeBtn.addEventListener('click', removeUpload);
});
