import onmount from 'onmount';
import { AnalyticsBrowser } from '@segment/analytics-next';

onmount('[data-js-segment]', function () {
  const { apiKey, userId } = this.dataset;
  const analytics = AnalyticsBrowser.load({ writeKey: apiKey });

  if (!sessionStorage.getItem('identifyCalled')) {
    analytics.identify(userId, {}, { context: { ip: '0.0.0.0' } });
    sessionStorage.setItem('identifyCalled', true);
  }
});
