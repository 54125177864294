import onmount from 'onmount';
import Rails from '@rails/ujs';

onmount('[data-js-quiz-grading]', function () {
  const self = this;
  const viewFileButtons = this.querySelectorAll('[data-js-view-file]');
  const answers = this.querySelectorAll('[data-js-review-response]');

  function handleViewFileButtonClick() {
    const uploadId = this.dataset.jsViewFile;
    const fileTab = document.querySelector(`[data-js-file-tab][data-upload-id='${uploadId}']`);
    if (fileTab) fileTab.click();
  }

  function handleReviewResponseClick() {
    if (this.classList.contains('active-submission')) return;

    const { url } = this.dataset;
    if (!url) return;

    const newUrl = new URL(window.location.origin + url);
    const currentUrl = new URL(window.location.href);

    currentUrl.searchParams.forEach((value, key) => {
      if (newUrl.searchParams.get(key)) {
        newUrl.searchParams.append(key, value);
      } else {
        newUrl.searchParams.set(key, value);
      }
    });

    Rails.ajax({
      url: newUrl.toString(),
      type: 'GET',
      dataType: 'script'
    });
  }

  function showActiveSubmission() {
    if (!self.parentElement) return; // submissionChangedEvent is fired before offmount is called

    const { submissionId } = document.querySelector('[data-js-review-ids]').dataset;
    const activeSubmission = self.querySelector('.active-submission');
    if (activeSubmission) activeSubmission.classList.remove('active-submission');

    const submission = self.querySelector(`[data-js-quiz-subquestion][data-submission-id='${submissionId}'] [data-js-review-response]`); // eslint-disable-line
    if (!submission) return;

    submission.classList.add('active-submission');
    submission.addEventListener('click', function (e) {
      if (e.target.closest('a')) return true;
      if (this.classList.contains('active-submission')) return false;

      return true;
    });

    self.scrollTop = submission.offsetTop - 10;
  }

  function preloadNextResponse() {
    if (window.location.href.indexOf('review') === -1) return;
    if (document.querySelector('[data-js-review-ids]').dataset.gradingMethod !== 'review') return;

    Rails.ajax({
      url: window.location.href.replace('review', 'preload_responses'),
      type: 'GET'
    });
  }

  function handleSubmissionChange() {
    showActiveSubmission();
  }
  this.handleSubmissionChange = handleSubmissionChange;

  viewFileButtons.forEach((button) => {
    button.addEventListener('click', handleViewFileButtonClick);
  });

  answers.forEach((answer) => {
    answer.addEventListener('click', handleReviewResponseClick);
  });

  document.addEventListener('grading:submissionChangedEvent', this.handleSubmissionChange);

  showActiveSubmission();
  preloadNextResponse();
}, function () {
  document.removeEventListener('grading:submissionChangedEvent', this.handleSubmissionChange);
});
