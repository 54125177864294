/* global ImageMapper */

import '@kollegorna/cocoon-vanilla-js';
import onmount from 'onmount';
import mdcAutoInit from '@material/auto-init';
import imagesLoaded from 'imagesloaded';
import '@vendor/image-mapper';

onmount('[data-js-edit-hotspot-areas]', function () {
  const self = this;
  const hotspotAreaWrapper = this.querySelector('[data-js-hotspot-areas]');
  let removedItemIndex;

  function getUnusedColor(insertedItem) {
    const colorSelectEls = self.querySelectorAll('[data-js-hotspot-area]:not(.d-none) [data-js-hotspot-area-color]');
    const selectedColors = [...colorSelectEls].map((colorSelect) => (
      colorSelect.MDCSelect.value
    ));

    const colorSelectEl = insertedItem.querySelector('[data-js-hotspot-area-color]');
    const colors = colorSelectEl.MDCSelect.foundation.adapter.getMenuItemValues();
    const newColor = colors.find((color) => !selectedColors.includes(color));
    return newColor || 'red';
  }

  function setColor(insertedItem, newColor) {
    const colorSelectEl = insertedItem.querySelector('[data-js-hotspot-area-color]');
    colorSelectEl.MDCSelect.value = newColor;
  }

  function handleItemAdd(e) {
    const insertedItem = e.target.querySelector('[data-js-hotspot-area]:not(.d-none):last-child');
    mdcAutoInit();
    onmount();
    const color = getUnusedColor(insertedItem);
    ImageMapper(self.querySelector('[data-js-image-mapper]'), 'addArea', 'rect', color, insertedItem);
    setColor(insertedItem, color);
  }

  function handleItemBeforeRemove(e) {
    const removedItem = e.detail;
    removedItemIndex = [...self.querySelectorAll('[data-js-hotspot-area]:not(.d-none)')].indexOf(removedItem);
  }

  function handleItemRemove(e) {
    const removedItem = e.detail;
    removedItem.classList.add('d-none');
    if (typeof removedItemIndex !== 'undefined') {
      ImageMapper(self.querySelector('[data-js-image-mapper]'), 'removeArea', removedItemIndex);
      removedItemIndex = null;
    }
  }

  function initImageMapper() {
    const imageMapper = new ImageMapper(self.querySelector('[data-js-image-mapper]')); // eslint-disable-line no-unused-vars
  }

  hotspotAreaWrapper.addEventListener('cocoon:after-insert', handleItemAdd);
  hotspotAreaWrapper.addEventListener('cocoon:before-remove', handleItemBeforeRemove);
  hotspotAreaWrapper.addEventListener('cocoon:after-remove', handleItemRemove);
  imagesLoaded(this, initImageMapper);
});
