/* global i18n */

import onmount from 'onmount';
import Rails from '@rails/ujs';
import Fuse from 'fuse.js';
import mdcAutoInit from '@material/auto-init';
import { debounce, escapeHtml } from '@modules/custom';

onmount('[data-js-search-items]', function () {
  const self = this;
  const reset = this.querySelector('[data-js-reset]');
  const multiple = this.dataset.multiple === 'true';
  const inputSelector = (multiple ? 'checkbox' : 'radio');
  const maxItems = parseInt(this.getAttribute('data-max-items') || 50, 10);
  const submitButton = this.querySelector('[data-js-search-submit]');
  let input = this.querySelector('[data-js-search-input]');
  let itemsList = this.querySelector('[data-js-items-list]');
  let { url } = this.dataset;
  let selectedIds = JSON.parse(this.dataset.selectedIds || '[]');
  let template;

  const options = {
    isCaseSensitive: false,
    threshold: 0.1,
    minMatchCharLength: 1,
    ignoreLocation: true,
    useExtendedSearch: true
  };

  let search;

  function renderTemplate(result) {
    let item = template;

    item = item.replace('$checked', (selectedIds.includes(result.id) ? 'checked' : ''));
    Object.entries(result).forEach(([key, value]) => {
      if (value === null) {
        item = item.replaceAll(`$${key}`, `<div style="color:#666666">${i18n.t('js.general.no_content')}</div>`);
      } else {
        item = item.replaceAll(`$${key}`, escapeHtml(value));
      }
    });

    itemsList.insertAdjacentHTML('beforeend', item);
  }

  function disableSubmit() {
    if (!submitButton) return;

    submitButton.disabled = true;
  }

  function enableSubmit() {
    if (!submitButton) return;

    submitButton.disabled = false;
  }

  function renderItemResults(results) {
    let count = 0;

    results.some((result) => {
      if (count === maxItems) return true;
      if (self.querySelector(`li[data-id='${result.id}']`)) return false;

      renderTemplate(result);
      count += 1;
      return false;
    });

    mdcAutoInit();

    const customEvent = new CustomEvent('limit-items:refresh');
    document.dispatchEvent(customEvent);

    if (count > 0 || self.querySelector(`input[type=${inputSelector}]:checked`)) {
      enableSubmit();
    } else {
      disableSubmit();
    }
  }

  function inputChange() {
    self.querySelectorAll(`input[type=${inputSelector}]:not(:checked)`).forEach((checkbox) => {
      checkbox.closest('li').remove();
    });

    selectedIds = [];
    self.querySelectorAll(`input[type=${inputSelector}]:checked`).forEach((checkbox) => {
      selectedIds.push(parseInt(checkbox.value, 10));
    });

    let results;
    if (input.value.trim().length > 0) {
      reset.classList.remove('d-none');
      results = search.fuse.search(input.value).map((x) => x.item);
    } else {
      reset.classList.add('d-none');
      results = search.fuse._docs; // eslint-disable-line no-underscore-dangle
    }
    renderItemResults(results);
  }

  function resetSearch() {
    input.value = '';
    inputChange();
    input.focus();
  }

  function getData() {
    Rails.ajax({
      url,
      type: 'GET',
      dataType: 'json',
      success: (response) => {
        ({ template } = response);

        if (response.data.length) options.keys = Object.keys(response.data[0]);
        search = { fuse: new Fuse(response.data, options) };

        if (input.value) {
          inputChange();
        } else {
          renderItemResults(response.data);
        }

        input.addEventListener('input', debounce(inputChange, 250));
        input.addEventListener('paste', debounce(inputChange, 250));

        self.querySelector('[data-js-progress]').remove();
      }
    });
  }

  function templateChanged() {
    ({ url } = self.dataset);
    itemsList = self.querySelector('[data-js-items-list]');
    input = self.querySelector('[data-js-search-input]');
    getData();
  }
  this.templateChanged = templateChanged;

  getData();
  if (reset) reset.addEventListener('click', resetSearch);

  document.addEventListener('template:changed', this.templateChanged);
}, function () {
  document.removeEventListener('template:changed', this.templateChanged);
});
