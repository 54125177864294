import '@kollegorna/cocoon-vanilla-js';
import onmount from 'onmount';
import { orderChoicesBasedOnArray, arrayWithChoiceContent } from '@modules/choice-ordering';

onmount('[data-js-choices]', function () {
  const self = this;

  function numberOfChoices() {
    return self.querySelectorAll('[data-js-choice]:not(.d-none)').length;
  }

  function setPosition(choice) {
    const position = choice.querySelector('[data-js-choice-position]');
    position.value = numberOfChoices();
  }

  function setPositions() {
    [...self.querySelectorAll('[data-js-choice]:not(.d-none)')].forEach((choice, index) => {
      const event = new CustomEvent('position:change');
      const position = choice.querySelector('[data-js-choice-position]');
      position.value = index + 1;
      choice.querySelector('[data-js-choice-position]').dispatchEvent(event);
    });
  }

  function setPoints(insertedChoice) {
    [...self.querySelectorAll('[data-js-choice]:not(.d-none)')].forEach((choice) => {
      if (choice === insertedChoice) return;

      const pointsInput = choice.querySelector('[data-js-choice-points]');
      if (pointsInput && parseFloat(pointsInput.value) > 0) {
        insertedChoice.querySelector('[data-js-choice-points]').value = 0;
      }
    });
  }

  function handleChoiceAdd(e) {
    onmount();
    const insertedItem = e.target.querySelector('[data-js-choice]:not(.d-none):last-child');
    setPosition(insertedItem);
    setPoints(insertedItem);

    const event = new CustomEvent('choice:added');
    self.dispatchEvent(event);
  }

  function handleChoiceRemove(e) {
    const removedItem = e.detail;
    removedItem.classList.add('d-none');
    removedItem.querySelector('textarea').removeAttribute('required');
    removedItem.querySelector('input').removeAttribute('required');
    setPositions();
  }

  function handleMoveUp(e) {
    const subquestion = e.target.closest('[data-js-subquestion]');
    const currentChoice = e.target.closest('[data-js-choice]');
    const choiceArray = arrayWithChoiceContent(subquestion);

    choiceArray.some((choice, index) => {
      if (index > 0 && choice.name === currentChoice.querySelector('textarea').id) {
        choiceArray.splice(index - 1, 0, choiceArray.splice(index, 1)[0]);
        return true;
      }
      return false;
    });

    orderChoicesBasedOnArray(subquestion, choiceArray);
  }

  function handleMoveDown(e) {
    const subquestion = e.target.closest('[data-js-subquestion]');
    const currentChoice = e.target.closest('[data-js-choice]');
    const choiceArray = arrayWithChoiceContent(subquestion);

    choiceArray.some((choice, index) => {
      if (choice.name === currentChoice.querySelector('textarea').id) {
        choiceArray.splice(index + 1, 0, choiceArray.splice(index, 1)[0]);
        return true;
      }
      return false;
    });
    orderChoicesBasedOnArray(subquestion, choiceArray);
  }

  function handleMoveClick(e) {
    if (e.target.closest('[data-js-move-up]')) handleMoveUp(e);
    if (e.target.closest('[data-js-move-down]')) handleMoveDown(e);
  }

  this.addEventListener('cocoon:after-insert', handleChoiceAdd);
  this.addEventListener('cocoon:after-remove', handleChoiceRemove);
  this.addEventListener('click', handleMoveClick);
});
