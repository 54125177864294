import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['element'];

  static values = {
    name: { type: String }
  };

  toggle() {
    this.elementTargets.forEach((element) => {
      if (element.tagName === 'INPUT' && element.name) {
        this.element.querySelectorAll(`input[name="${element.name}"]`).forEach((input) => {
          input.toggleAttribute(this.nameValue);
        });
      } else {
        element.toggleAttribute(this.nameValue);
      }
    });
  }
}
