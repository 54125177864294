/* global google */

import onmount from 'onmount';
import snackbar from '@components/snackbar';
import Rails from '@rails/ujs';
import { debounce } from '@modules/custom';

onmount('[data-js-column-chart]', function () {
  const self = this;
  const { url, chartOptions } = this.dataset;
  const chartWrapper = this.closest('[data-js-graph-wrapper');
  const chart = this.querySelector('[data-js-chart]');
  let options = {
    backgroundColor: 'transparent',
    chartArea: {
      width: '80%'
    },
    colors: ['#5830FF'],
    legend: {
      position: 'none'
    }
  };
  if (chartOptions) options = { ...JSON.parse(chartOptions), ...options };
  let json;

  function setMinMeanMax() {
    if (!chartWrapper) return;

    const min = chartWrapper.querySelector('[data-js-min]');
    if (min) min.innerText = json.min || 0;

    const median = chartWrapper.querySelector('[data-js-median]');
    if (median) median.innerText = json.median || 0;

    const mean = chartWrapper.querySelector('[data-js-mean]');
    if (mean) mean.innerText = json.mean || 0;

    const max = chartWrapper.querySelector('[data-js-max]');
    if (max) max.innerText = json.max || 0;
  }

  function drawChart() {
    if (!json) return;

    setMinMeanMax();

    const dataTable = new google.visualization.DataTable();
    dataTable.addColumn('string', '');
    json.data[0].forEach((row) => {
      dataTable.addColumn('number', row);
    });
    dataTable.addRows(json.data[1]);

    const googleChart = new google.visualization.AreaChart(chart);
    googleChart.draw(dataTable, options);
  }
  this.drawChart = debounce(drawChart);

  function dispatchEvent() {
    const chartDataFetchedEvent = new CustomEvent('chartData:fetched', {
      detail: {
        data: json.data
      }
    });
    self.dispatchEvent(chartDataFetchedEvent);
  }

  function getChartData() {
    Rails.ajax({
      url,
      type: 'GET',
      dataType: 'json',
      success: (response) => {
        json = response;
        dispatchEvent();
        drawChart();
      },
      error: (e) => snackbar(e, true)
    });
  }

  google.charts.load('current', { packages: ['corechart'] });
  google.charts.setOnLoadCallback(getChartData);

  window.addEventListener('resize', this.drawChart);
}, function () {
  window.removeEventListener('resize', this.drawChart);
});
