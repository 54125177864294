import onmount from 'onmount';
import Rails from '@rails/ujs';

onmount('[data-js-print-order-form]', function () {
  const self = this;
  const input = self.querySelector('[data-js-address-select] [data-js-select-input]');

  function handleAddressChange() {
    if (!this.value) return;

    const baseUrl = window.location.href.substring(0, window.location.href.indexOf('courses'));

    Rails.ajax({
      url: `${baseUrl}addresses/${this.value}.json`,
      type: 'GET',
      dataType: 'json',
      success: (data) => {
        ['street', 'postal_code', 'city'].forEach((attr) => {
          self.querySelector(`[data-js-${attr}]`).value = data[attr];
        });
      }
    });
  }

  input.addEventListener('change', handleAddressChange);
});
