import { Controller } from '@hotwired/stimulus';
import { useMutation } from 'stimulus-use';

// Connects to data-controller="table-toolbar"
export default class extends Controller {
  static targets = ['toolbar', 'selectAllCheckbox'];

  connect() {
    useMutation(this, { attributes: true, subtree: true });
  }

  mutate() {
    if (!this.hasSelectAllCheckboxTarget) return;

    if ((this.selectAllCheckboxTarget.checked || this.selectAllCheckboxTarget.ariaChecked === 'mixed')) {
      this.toolbarTarget.style.zIndex = 1;
      this.toolbarTarget.style.opacity = 1;
    } else {
      this.toolbarTarget.style.opacity = 0;

      setTimeout(() => {
        this.toolbarTarget.style.zIndex = -1;
      }, 500);
    }
  }
}
