/* global google */

import onmount from 'onmount';
import Rails from '@rails/ujs';
import { debounce } from '@modules/custom';

onmount('[data-js-dashboard-live]', () => {
  const onlineUsersChartWrapper = document.querySelector('[data-js-online-users-chart]');
  let onlineUsersChart;
  let onlineUsersData;
  let onlineUsersOptions;

  const tasksChartWrapper = document.querySelector('[data-js-tasks-chart]');
  let tasksChart;
  let tasksData;
  let tasksOptions;

  const taskPerformanceChartWrapper = document.querySelector('[data-js-task-performance-chart]');
  let taskPerformanceChart;
  let taskPerformanceData;
  let taskPerformanceOptions;

  function minutesToMiliseconds(minutes) {
    return minutes * 60 * 1000;
  }

  function replaceDate(data) {
    data.forEach((row) => {
      row[0] = new Date(row[0]);
    });
    return data;
  }

  function replaceZero(data) {
    data.forEach((row) => {
      if (row[1] === 0) row[1] = 0.1;
      if (row[2] === 0) row[2] = 0.1;
    });
    return data;
  }

  function removeOldRows(data, deviation) {
    const d = new Date();
    d.setHours(d.getHours() - deviation);

    while (data.getValue(0, 0) < d) {
      data.removeRow(0);
    }
  }

  function drawOnlineUsersChart(data) {
    const dataTable = new google.visualization.DataTable();
    dataTable.addColumn('date', 'Time');
    dataTable.addColumn('number', 'Users Woots');
    dataTable.addColumn('number', 'Users Ans');
    dataTable.addRows(replaceDate(data));

    onlineUsersData = dataTable;

    onlineUsersOptions = {
      backgroundColor: { fill: 'transparent' },
      chartArea: { width: '80%' },
      colors: ['#2664EF', '#5830FF'],
      hAxis: {
        format: 'HH:mm',
        textStyle: { color: '#fff', opacity: 0.6 },
        baselineColor: { color: '#333' },
        lineColor: { color: '#333' },
        gridlines: { color: '#333' },
        minorGridlines: { color: '#333' }
      },
      isStacked: true,
      legend: { position: 'none' },
      vAxis: {
        title: 'Online users',
        viewWindow: { min: 0 },
        titleTextStyle: { color: '#FFF', italic: false, opacity: 0.6 },
        textStyle: { color: '#FFF', opacity: 0.6 },
        baselineColor: { color: '#333' },
        lineColor: { color: '#333' },
        gridlines: { color: '#333' },
        minorGridlines: { color: '#333' }
      }
    };

    onlineUsersChart = new google.visualization.AreaChart(onlineUsersChartWrapper);
    onlineUsersChart.draw(onlineUsersData, onlineUsersOptions);
  }

  function getOnlineUsersData() {
    Rails.ajax({
      url: `${window.location.origin}/dashboard/online_users.json`,
      type: 'GET',
      success: (data) => {
        drawOnlineUsersChart(data);

        const latest = data[data.length - 1];
        document.querySelector('[data-js-online-users-count]').innerHTML = parseInt(latest[1] + latest[2], 10);
      }
    });
  }

  function updateOnlineUsersData(e) {
    const { wootsUsers, ansUsers } = e.detail;
    document.querySelector('[data-js-online-users-count]').innerHTML = ansUsers + wootsUsers;

    removeOldRows(onlineUsersData, 24);
    onlineUsersData.addRow([new Date(), wootsUsers, ansUsers]);
    onlineUsersChart.draw(onlineUsersData, onlineUsersOptions);
  }
  this.updateOnlineUsersData = updateOnlineUsersData;

  function drawTasksChart(data) {
    const dataTable = new google.visualization.DataTable();
    dataTable.addColumn('date', 'Time');
    dataTable.addColumn('number', 'Tasks');
    dataTable.addColumn('number', 'Servers');
    dataTable.addRows(replaceZero(replaceDate(data)));

    tasksData = dataTable;

    tasksOptions = {
      backgroundColor: { fill: 'transparent' },
      chartArea: { width: '80%' },
      colors: ['#FFA574', '#3BBA98'],
      legend: { position: 'none' },
      series: {
        0: { targetAxisIndex: 0 },
        1: { targetAxisIndex: 1 }
      },
      hAxis: {
        format: 'HH:mm',
        textStyle: { color: '#FFF', opacity: 0.6 },
        baselineColor: { color: '#333' },
        lineColor: { color: '#333' },
        gridlines: { color: '#333' },
        minorGridlines: { color: '#333' }
      },
      vAxes: {
        0: { title: 'Tasks' },
        1: { title: 'Servers' }
      },
      vAxis: {
        viewWindow: { min: 0.1, max: Math.max(10, tasksData.getColumnRange(1).max) },
        titleTextStyle: { color: '#FFF', italic: false, opacity: 0.6 },
        textStyle: { color: '#FFF', opacity: 0.6 },
        scaleType: 'log',
        baselineColor: { color: '#333' },
        lineColor: { color: '#333' },
        gridlines: { color: '#333' },
        minorGridlines: { color: '#333' }
      }
    };

    tasksChart = new google.visualization.LineChart(tasksChartWrapper);
    tasksChart.draw(tasksData, tasksOptions);
  }

  function getTasksData() {
    Rails.ajax({
      url: `${window.location.origin}/dashboard/tasks.json`,
      type: 'GET',
      success: (data) => {
        drawTasksChart(data);
        document.querySelector('[data-js-tasks-count]').innerHTML = parseInt(data[data.length - 1][1], 10);
      }
    });
  }

  function updateTasksChart(tasksCount, serversCount) {
    removeOldRows(tasksData, 12);

    const tasks = (tasksCount === 0 ? 0.1 : tasksCount);
    const servers = (serversCount === 0 ? 0.1 : serversCount);

    if (tasks >= 1 && tasksData.getValue(tasksData.getNumberOfRows() - 1, 1) === 0.1) {
      tasksData.addRow([new Date(), 0.1, tasksData.getValue(tasksData.getNumberOfRows() - 1, 2)]);
    }
    tasksData.addRow([new Date(), tasks, servers]);

    tasksOptions.vAxis.viewWindow.max = Math.max(10, tasksData.getColumnRange(1).max);
    tasksChart.draw(tasksData, tasksOptions);
  }

  function updateTasksData(e) {
    if (tasksData.getNumberOfRows() > 1000) getTasksData();

    const { tasksCount } = e.detail;
    document.querySelector('[data-js-tasks-count]').innerHTML = tasksCount;

    const serversCount = parseInt(document.querySelector('[data-js-servers-count]').innerHTML, 10);

    updateTasksChart(tasksCount, serversCount);
  }
  this.updateTasksData = updateTasksData;

  function drawTaskPerformanceChart(data) {
    const dataTable = new google.visualization.DataTable();
    dataTable.addColumn('date', 'Time');
    dataTable.addColumn('number', 'Throughput');
    dataTable.addColumn('number', 'Waiting time');
    dataTable.addRows(replaceZero(replaceDate(data)));

    taskPerformanceData = dataTable;

    taskPerformanceOptions = {
      backgroundColor: 'transparent',
      chartArea: { width: '80%' },
      colors: ['#FFC100', '#DD2C3B'],
      legend: { position: 'none' },
      series: {
        0: { targetAxisIndex: 0 },
        1: { targetAxisIndex: 1 }
      },
      hAxis: {
        format: 'HH:mm',
        textStyle: { color: '#FFF', opacity: 0.6 },
        baselineColor: { color: '#333' },
        lineColor: { color: '#333' },
        gridlines: { color: '#333' },
        minorGridlines: { color: '#333' }
      },
      vAxes: {
        0: { title: 'Throughput (tasks/min)' },
        1: { title: 'Waiting time (s)' }
      },
      vAxis: {
        viewWindow: {
          min: 0.1,
          max: Math.max(10, taskPerformanceData.getColumnRange(1).max)
        },
        titleTextStyle: { color: '#FFF', italic: false, opacity: 0.6 },
        textStyle: { color: '#FFF', opacity: 0.6 },
        baselineColor: { color: '#333' },
        lineColor: { color: '#333' },
        gridlines: { color: '#333' },
        minorGridlines: { color: '#333' }
      }
    };

    taskPerformanceChart = new google.visualization.LineChart(taskPerformanceChartWrapper);
    taskPerformanceChart.draw(taskPerformanceData, taskPerformanceOptions);
  }

  function getTaskPerformanceData() {
    Rails.ajax({
      url: `${window.location.origin}/dashboard/task_performance.json`,
      type: 'GET',
      success: (data) => {
        drawTaskPerformanceChart(data);
        document.querySelector('[data-js-throughput]').innerHTML = parseInt(data[data.length - 1][1], 10);
      }
    });
  }

  function updateTaskPerformanceData(e) {
    let { throughput, waitingTime } = e.detail;
    const { waitingTimeInWords } = e.detail;
    document.querySelector('[data-js-throughput]').innerHTML = throughput;
    document.querySelector('[data-js-waiting-time]').innerHTML = waitingTimeInWords;

    removeOldRows(taskPerformanceData, 12);

    if (throughput === 0) throughput = 0.1;
    if (waitingTime === 0) waitingTime = 0.1;
    taskPerformanceData.addRow([new Date(), throughput, waitingTime]);

    taskPerformanceOptions.vAxis.viewWindow.max = Math.max(10, taskPerformanceData.getColumnRange(1).max);
    taskPerformanceChart.draw(taskPerformanceData, taskPerformanceOptions);
  }
  this.updateTaskPerformanceData = updateTaskPerformanceData;

  function updateServersCount(e) {
    const { serversCount } = e.detail;
    document.querySelector('[data-js-servers-count]').innerHTML = serversCount;

    const tasksCount = parseInt(document.querySelector('[data-js-tasks-count]').innerHTML, 10);

    updateTasksChart(tasksCount, serversCount);
  }
  this.updateServersCount = updateServersCount;

  google.charts.load('current', { packages: ['corechart'] });
  google.charts.setOnLoadCallback(getOnlineUsersData);
  google.charts.setOnLoadCallback(getTasksData);
  google.charts.setOnLoadCallback(getTaskPerformanceData);

  function handleResize() {
    onlineUsersChart.draw(onlineUsersData, onlineUsersOptions);
    tasksChart.draw(tasksData, tasksOptions);
    taskPerformanceChart.draw(taskPerformanceData, taskPerformanceOptions);
  }
  this.handleResize = debounce(handleResize, 500);

  window.addEventListener('resize', this.handleResize);
  document.addEventListener('updateOnlineUsersData', this.updateOnlineUsersData);
  document.addEventListener('updateTasksData', this.updateTasksData);
  document.addEventListener('updateTaskPerformanceData', this.updateTaskPerformanceData);
  document.addEventListener('updateServersCount', this.updateServersCount);

  setTimeout(() => {
    window.location.reload();
  }, minutesToMiliseconds(60 * 24));
}, function () {
  window.removeEventListener('resize', this.handleResize);
  document.removeEventListener('updateOnlineUsersData', this.updateOnlineUsersData);
  document.removeEventListener('updateTasksData', this.updateTasksData);
  document.removeEventListener('updateTaskPerformanceData', this.updateTaskPerformanceData);
  document.removeEventListener('updateServersCount', this.updateServersCount);
});
