import onmount from 'onmount';

onmount('[data-js-radio-group]', function () {
  const self = this;
  const { disableTarget } = this.dataset;

  function handleInputChange(e) {
    if (e.target.getAttribute('type') !== 'radio') return;

    [...self.querySelectorAll("input[type='radio']")].forEach((input) => {
      if (input !== e.target) {
        input.checked = false;
      }

      if (disableTarget) {
        input.closest('[data-js-radio-wrapper]').querySelectorAll(disableTarget).forEach((target) => {
          target.disabled = !input.checked;
        });
      }
    });
  }

  self.addEventListener('change', handleInputChange);
});
