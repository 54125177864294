export function showScore(submissionId, score) {
  const submissionScoreEl = document.querySelector(`[data-js-submission-score][data-submission-id='${submissionId}']`);
  if (submissionScoreEl) submissionScoreEl.innerText = score || '-';
}

export function updatePaginationTile(submissionId, graded, score, points) {
  const paginationEl = document.querySelector(`[data-js-pagination-item][data-submission-id='${submissionId}']`);
  if (!paginationEl) return;

  paginationEl.classList.remove(
    'subquestion-selector-item--correct',
    'subquestion-selector-item--incorrect',
    'subquestion-selector-item--mistake'
  );

  if (!graded) return;

  if (parseFloat(score) === 0) {
    paginationEl.classList.add('subquestion-selector-item--incorrect');
  } else if (parseFloat(score) === parseFloat(points)) {
    paginationEl.classList.add('subquestion-selector-item--correct');
  } else {
    paginationEl.classList.add('subquestion-selector-item--mistake');
  }
}

export function showEditedBy(panel, graderText) {
  if (!panel) return;

  const editedBy = panel.querySelector('[data-js-edited-by]');
  if (!editedBy) return;

  editedBy.querySelector('[data-js-grader]').innerHTML = graderText;

  const timeAgo = editedBy.querySelector('[data-js-time-ago]');
  const timerEvent = new CustomEvent('timeago:restart');
  timeAgo.dispatchEvent(timerEvent);

  editedBy.classList.remove('d-none');
}
