/* eslint-disable */

import { removeKatexFromHtml } from '@redactor/remove-katex';

(function ($R) {
  $R.add('plugin', 'limiter', {
    init(app) {
      this.app = app;
      this.lang = app.lang;
      this.utils = app.utils;
      this.opts = app.opts;
      this.opts.wordLimit = parseInt(this.opts.limiter, 10) || 0;
      this.opts.pasteCharacterLimit = 5000;
      this.editor = app.editor;
      this.keycodes = app.keycodes;
      this.statusbar = app.statusbar;
    },

    // public
    start() {
      this._editorElement = this.editor.getElement(); // Cache the editor element
      this._editorElement.on('keydown.redactor-plugin-limiter', this._limit.bind(this));

      if (this.opts.wordLimit === 0) return;
      this.statusbar.add('maxWords', `${this.lang.get('maxWords')}: ${this.opts.limiter}`);
    },

    stop() {
      this._editorElement.off('.redactor-plugin-limiter');
      this.opts.input = true;
      this.statusbar.remove('maxWords');
      this.statusbar.remove('characterLimit');
      this.statusbar.remove('wordLimit');
    },

    onpasting(pastedHtml, e) {
      const { words, chars } = this._getTextMetrics(this._getHtml());
      const { words: pastedWords, chars: pastedChars } = this._getTextMetrics(pastedHtml);
      const totalWordCount = words + pastedWords;
      const totalCharCount = chars + pastedChars;

      if (this._isCharLimitExceeded(totalCharCount)) {
        if (e) e.preventDefault();
        this.opts.input = false;
        this._setStatusBarMessage('characterLimit');

        return false;
      }

      if (this._isWordLimitExceeded(totalWordCount)) {
        if (e) e.preventDefault();
        this.opts.input = false;
        this._setStatusBarMessage('wordLimit');

        return false;
      }

      this.opts.input = true;
      this.statusbar.remove('characterLimit');
      this.statusbar.remove('wordLimit');
    },

    // Helper functions
    _limit(e) {
      const key = e.which;
      const ctrl = e.ctrlKey || e.metaKey;
      const allowedKeys = [
        this.keycodes.BACKSPACE,
        this.keycodes.DELETE,
        this.keycodes.ESC,
        this.keycodes.SHIFT,
        37, 38, 39, 40, // Arrow keys
      ];

      if (ctrl && [65, 88, 82, 116].includes(key)) return; // Ctrl + A, X, R, F5
      if (allowedKeys.includes(key)) return;

      if (key === this.keycodes.ENTER) {
        this._validate(e);
        if (!this.opts.input) e.preventDefault();

        return;
      }

      this._validate(e);
    },

    _validate(e) {
      const { words } = this._getTextMetrics(this._getHtml());

      if (this._isWordLimitExceeded(words)) {
        if (e) e.preventDefault();
        this.opts.input = false;
        this._setStatusBarMessage('wordLimit');

        return false;
      }

      this.opts.input = true;
      this.statusbar.remove('characterLimit');
      this.statusbar.remove('wordLimit');
    },

    _isWordLimitExceeded(totalWords) {
      return this.opts.wordLimit !== 0 && totalWords > this.opts.wordLimit;
    },

    _isCharLimitExceeded(totalChars) {
      return totalChars !== 0 && totalChars >= this.opts.pasteCharacterLimit;
    },

    _setStatusBarMessage(type) {
      this.statusbar.add(type, `${this.lang.get(type)}`);
    },

    _getTextMetrics(html) {
      html = this._clean(html);
      return {
        words: html ? html.split(/\s+/).length : 0,
        chars: html.length
      };
    },

    _clean(html) {
      html = removeKatexFromHtml(html);
      return this.utils.removeInvisibleChars(
        html
          .replace(/<\/?[^>]+(>|$)/g, ' ') // Remove HTML tags
          .replace(/\t/g, '')
          .replace(/\n|\r/g, ' ')
          .replace(/&nbsp;/g, ' ')
          .replace(/&#xFEFF;/g, '')
          .trim()
      );
    },

    _getHtml() {
      return this._editorElement.html();
    }
  });
}(Redactor));
