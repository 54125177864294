import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['toggleButton', 'feedbackField', 'feedback', 'clearInput'];

  connect() {
    if (this.feedbackTargets.filter((field) => field.value || field.checked).length) {
      this.toggleButtonTarget.checked = true;
    } else {
      this.toggleButtonTarget.checked = false;
    }

    this.toggleFeedbackField();
  }

  toggleFeedbackField() {
    if (this.toggleButtonTarget.checked) {
      this.showFeedbackField();
    } else {
      this.hideFeedbackField();
    }
  }

  showFeedbackField() {
    this.feedbackFieldTargets.forEach((feedbackField) => {
      feedbackField.classList.remove('d-none');
    });
    this.removeDisabled();
  }

  hideFeedbackField() {
    this.feedbackFieldTargets.forEach((feedbackField) => {
      feedbackField.classList.add('d-none');
    });
    this.clearValueOnHide();
    this.setDisabled();
  }

  setDisabled() {
    this.clearInputTargets.forEach((inputField) => {
      // eslint-disable-next-line no-underscore-dangle
      if (inputField._flatpickr) {
        // eslint-disable-next-line no-underscore-dangle
        inputField._flatpickr.altInput.setAttribute('disabled', 'true');
        inputField.setAttribute('readonly', 'true');
      }
    });
  }

  removeDisabled() {
    this.clearInputTargets.forEach((inputField) => {
      // eslint-disable-next-line no-underscore-dangle
      if (inputField._flatpickr) {
        // eslint-disable-next-line no-underscore-dangle
        inputField._flatpickr.altInput.removeAttribute('disabled');
        inputField.removeAttribute('readonly');
      }
    });
  }

  clearValueOnHide() {
    this.clearInputTargets.forEach((inputField) => {
      // eslint-disable-next-line no-underscore-dangle
      if (inputField._flatpickr) {
        // eslint-disable-next-line no-underscore-dangle
        inputField._flatpickr.clear();
      } else {
        inputField.value = null;
      }
    });
  }
}
