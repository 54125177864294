import onmount from 'onmount';
import Rails from '@rails/ujs';
import { clearTimeouts, serialize } from '@modules/custom';

onmount('[data-js-quiz-incidents]', function () {
  const iframe = document.querySelector('iframe');
  let iframeContent;
  if (iframe) {
    iframeContent = iframe.contentWindow;
  }

  this.saveTimeouts = [];
  const SAVE_INTERVAL = 30 * 1000;

  const self = this;
  const {
    blur, paste, url, resultId, serverTime
  } = this.dataset;
  let blurIncident;

  const storageKey = `#${resultId}-incidents`;
  let incidents = JSON.parse(sessionStorage.getItem(storageKey));
  if (incidents === null) {
    sessionStorage.setItem(storageKey, '[]');
    incidents = [];
  }

  function currentTimeUnix() {
    return Math.floor(Date.now() / 1000);
  }
  const correction = serverTime - currentTimeUnix();

  function correctedCurrentTime() {
    return new Date((currentTimeUnix() + correction) * 1000);
  }

  function restartTimer() {
    clearTimeouts(self.saveTimeouts);
    self.saveTimeouts.push(setTimeout(createIncidents, SAVE_INTERVAL)); /* eslint-disable-line */
  }

  function createIncidents() {
    if (incidents.length) {
      const params = {};
      params.result = { incidents_attributes: incidents };

      Rails.ajax({
        url,
        type: 'POST',
        data: serialize(params),
        dataType: 'json'
      });

      incidents = [];
      sessionStorage.setItem(storageKey, '[]');
    }
    restartTimer();
  }

  function handleBlur() {
    if (iframe && document.activeElement === iframe) return;

    blurIncident = { created_at: correctedCurrentTime().toString(), incident_type: 'blur' };
  }
  this.handleBlur = handleBlur;

  function handleIframeBlur() {
    if (document.activeElement === document.body) return;

    blurIncident = { created_at: correctedCurrentTime().toString(), incident_type: 'blur' };
  }

  function handleFocus() {
    if (!blurIncident) return;

    blurIncident.metadata = { duration: correctedCurrentTime() - Date.parse(blurIncident.created_at) };
    incidents.push(blurIncident);
    sessionStorage.setItem(storageKey, JSON.stringify(incidents));
    blurIncident = undefined;
  }
  this.handleFocus = handleFocus;

  function handleIframeVisibility(e) {
    if (e.target.visibilityState === 'hidden') return;
    if (document.activeElement === document.body) return;

    handleFocus();
  }

  function handlePaste(e) {
    const metadata = { content: e.clipboardData.getData('Text') };

    const submissionEl = e.target.closest('[data-js-submission-form]');
    if (submissionEl) metadata.submission_id = submissionEl.dataset.submissionId;

    incidents.push({
      created_at: correctedCurrentTime().toString(),
      incident_type: 'paste',
      metadata
    });
    sessionStorage.setItem(storageKey, JSON.stringify(incidents));
  }
  this.handlePaste = handlePaste;

  handleFocus();
  if (paste === 'true') window.addEventListener('paste', this.handlePaste);
  if (blur === 'true') {
    if (iframeContent) {
      iframeContent.addEventListener('blur', handleIframeBlur);
      iframeContent.addEventListener('visibilitychange', handleIframeVisibility);
    }

    window.addEventListener('blur', this.handleBlur);
    window.addEventListener('focus', this.handleFocus);
  }

  this.saveTimeouts.push(setTimeout(createIncidents, SAVE_INTERVAL));
}, function () {
  window.removeEventListener('paste', this.handlePaste);
  window.removeEventListener('blur', this.handleBlur);
  window.removeEventListener('focus', this.handleFocus);

  clearTimeouts(this.saveTimeouts);
});
