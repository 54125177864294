import onmount from 'onmount';

onmount('[data-js-scroll-retainer]', function () {
  const self = this;
  const parent = self.parentElement;
  const scrollBar = self.querySelector('[data-js-scrollbar]');

  function scrollToActive() {
    let scrollToElement = self.querySelector('.subquestion-selector-item--current');
    if (!scrollToElement) scrollToElement = self.querySelector('.question-button--current');

    const scrollBarWidthCenter = self.clientWidth / 2;

    if (scrollBar) {
      scrollBar.scrollLeft = scrollToElement.offsetLeft + (scrollToElement.clientWidth / 2) - scrollBarWidthCenter;
    } else {
      scrollToElement.scrollIntoView({ block: 'center' });
    }
  }

  function updateNavigation(e) {
    const scrollY = self.scrollTop;
    self.outerHTML = e.detail.html;

    const scrollRetainer = parent.querySelector('[data-js-scroll-retainer]');
    scrollRetainer.scrollTo(0, scrollY);
  }

  self.addEventListener('updateNavigation', updateNavigation);
  self.addEventListener('navigation:scrollToActive', scrollToActive);
  scrollToActive();
});
