import { Controller } from '@hotwired/stimulus';
import { MDCIconButtonToggle } from '@material/icon-button';

export default class extends Controller {
  static targets = ['input'];

  connect() {
    if (!this.element.MDCIconButtonToggle) {
      this.element.MDCIconButtonToggle = new MDCIconButtonToggle(this.element);
      this.element.setAttribute('data-mdc-auto-init-state', 'initialized');
    }
    this.element.MDCIconButtonToggle.listen('MDCIconButtonToggle:change', this.handleChange.bind(this));
  }

  handleChange(e) {
    this.inputTarget.value = e.detail.isOn;
  }
}
