import onmount from 'onmount';

const changeEvent = document.createEvent('Event');

onmount('[data-js-strikethrough]', function () {
  const self = this;
  const items = [...self.querySelectorAll('[data-js-strikethrough-item]')];
  const warning = self.querySelector('[data-js-strikethrough-warning]');

  const isStrikethrough = (item) => item.classList.contains('text-strikethrough');

  function isChecked(item) {
    const { selector } = item.dataset;
    const target = self.querySelector(selector);
    return target.checked;
  }

  function toggleWarning() {
    if (items.some(isStrikethrough) && !items.some(isChecked)) {
      warning.classList.remove('d-none', 'rs_skip_always');
    } else {
      warning.classList.add('d-none', 'rs_skip_always');
    }
  }

  function uncheckTarget(target) {
    target.checked = false;
    changeEvent.initEvent('change', false, true);
    target.dispatchEvent(changeEvent);
  }

  function storeStrikethrough(sessionStorageKey, value) {
    if (value === true) {
      sessionStorage.setItem(sessionStorageKey, true);
    } else {
      sessionStorage.removeItem(sessionStorageKey);
    }
  }

  function initializeStrikethroughItem(item) {
    const { id, event, selector } = item.dataset;
    const sessionStorageKey = `strikethrough-${id}`;
    const target = self.querySelector(selector);

    function handleTargetEvent() {
      if (target.checked) {
        item.classList.remove('text-strikethrough');
        storeStrikethrough(sessionStorageKey, false);
        toggleWarning();
      }
    }

    function toggleStrikethrough() {
      item.classList.toggle('text-strikethrough');
      storeStrikethrough(sessionStorageKey, item.classList.contains('text-strikethrough'));
      if (target.checked) uncheckTarget(target);
      toggleWarning();
    }

    item.addEventListener('click', toggleStrikethrough);
    if (sessionStorage.getItem(sessionStorageKey) === 'true') item.classList.add('text-strikethrough');
    if (target) target.addEventListener(event, handleTargetEvent);
  }

  items.forEach(initializeStrikethroughItem);
  toggleWarning();
});
