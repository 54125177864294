import onmount from 'onmount';
import { MDCCheckbox } from '@material/checkbox';
import Rails from '@rails/ujs';

onmount('.mdc-checkbox', function () {
  const self = this;
  const element = this.querySelector('input[type=checkbox]');
  const isIndeterminate = element.getAttribute('data-indeterminate') === 'true';
  const checkbox = new MDCCheckbox(this);
  element.setAttribute('data-indeterminate', isIndeterminate);

  function enableTarget(mdcComponent, button, action, value) {
    if (action === 'readonly') {
      mdcComponent.input.removeAttribute('readonly');
    } else if (action === 'enable') {
      if (mdcComponent) mdcComponent.disabled = false;
      if (button) button.disabled = false;
      if (value) mdcComponent.value = value;
    }
  }

  function deselectMultiple(mdcComponent) {
    const collection = mdcComponent.menuElement.querySelector('[data-js-collection]');
    const allowMultiple = collection.getAttribute('data-multiple') === 'true';
    if (!allowMultiple) return;

    collection.querySelectorAll('.mdc-checkbox').forEach((input) => {
      input.MDCCheckbox.checked = false;
    });
    mdcComponent.emit('MDCSelect:change');
  }

  function clearTargetInput(target, mdcComponent, type, value) {
    if (type !== 'MDCCheckbox') mdcComponent.value = value || '';

    if (type === 'MDCTextField' && mdcComponent.input.numeric) {
      mdcComponent.input.numeric.set(value || '');
    } else if (type === 'MDCSelect') {
      deselectMultiple(mdcComponent);
    } else if (type === 'MDCCheckbox') {
      mdcComponent.checked = false;

      const evt = new CustomEvent('change');
      target.querySelector("input[type='checkbox']").dispatchEvent(evt);
    }
  }

  function disableTarget(mdcComponent, button, action) {
    if (action === 'readonly') {
      mdcComponent.input.setAttribute('readonly', true);
    } else if (action === 'enable') {
      if (mdcComponent) mdcComponent.disabled = true;
      if (button) button.disabled = true;
    }
  }

  function findButton(target) {
    if (target.tagName === 'BUTTON' || (target.tagName === 'INPUT' && target.type === 'submit')) {
      return target;
    }
    return target.parentElement.querySelector('button');
  }

  function toggleElement() {
    const targets = document.querySelectorAll(this.getAttribute('data-toggle-target'));
    const action = this.getAttribute('data-toggle-action');
    const submit = this.getAttribute('data-toggle-submit');

    targets.forEach((target) => {
      const type = target.getAttribute('data-mdc-auto-init');
      const mdcComponent = target[type];
      const button = findButton(target);
      const { enabledValue, disabledValue } = target.dataset;

      if (checkbox.checked) {
        enableTarget(mdcComponent, button, action, enabledValue);
        if (target.redactor) target.redactor.disableReadOnly();
      } else if (mdcComponent) {
        clearTargetInput(target, mdcComponent, type, disabledValue);
      }
    });

    const customEvent = new CustomEvent('checkboxChanged');
    self.dispatchEvent(customEvent);

    if (submit) Rails.fire(self.closest('form'), 'submit');

    if (checkbox.checked) return;

    targets.forEach((target) => {
      const mdcComponent = target[target.getAttribute('data-mdc-auto-init')];
      const button = findButton(target);

      if (target.redactor) {
        target.redactor.enableReadOnly();
        target.redactor.source.setCode('');
      } else {
        disableTarget(mdcComponent, button, action);
      }
    });
  }

  if (element.hasAttribute('data-toggle-target')) {
    element.addEventListener('change', toggleElement);
  }
  if (element.hasAttribute('data-enable-on-load')) {
    checkbox.disabled = false;
  }
});
