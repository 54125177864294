import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['frame'];

  remove(event) {
    event.preventDefault();

    if (this.hasFrameTarget) {
      this.frameTarget.remove();
    } else {
      this.element.remove();
    }
  }

  select() {
    this.element.select();
  }
}
