/* global i18n */

import onmount from 'onmount';
import snackbar from '@components/snackbar';
import Rails from '@rails/ujs';
import { serialize } from '@modules/custom';

onmount('[data-js-component-sortable]', function () {
  const self = this;
  const url = this.dataset.jsSortableUrl;

  function dispatchSubquestionChangeEvent(questionId, subquestionId) {
    const subquestionUpdatedEvent = new CustomEvent('subquestionUpdated', {
      detail: {
        subquestionId,
        questionId
      }
    });
    document.dispatchEvent(subquestionUpdatedEvent);
  }

  function updateQuestionId(question, component) {
    return new Promise((resolve, reject) => {
      const element = component.dataset.jsSortableElement;
      const componentId = component.dataset[`${element}Id`];
      const { questionId } = question.closest('[data-js-question]').dataset;

      const params = {};
      params[element] = { question_id: questionId };

      Rails.ajax({
        url: `/position/${element}s/${componentId}`,
        type: 'PATCH',
        data: serialize(params),
        dataType: 'json',
        success: () => resolve(),
        error: (data) => reject({
          status: data.status,
          statusText: data.statusText,
          message: data.responseText
        })
      });
    });
  }

  function updatePositions(question, dispatch = false) {
    const components = question.querySelectorAll('[data-js-sortable-element]');
    if (components.length === 0) return;

    const params = { subquestions_attributes: [], subelements_attributes: [] };
    let subquestionPosition = 0;
    let subelementPosition = 0;

    [...components].forEach((component, index) => {
      const element = component.dataset.jsSortableElement;

      if (element === 'subquestion') {
        subquestionPosition += 1;
        params.subquestions_attributes.push({
          id: component.dataset.subquestionId,
          position: subquestionPosition,
          parent_position: index + 1
        });
      } else {
        subelementPosition += 1;
        params.subelements_attributes.push({
          id: component.dataset.subelementId,
          position: subelementPosition,
          parent_position: index + 1
        });
      }
    });

    Rails.ajax({
      url,
      type: 'PATCH',
      data: serialize({ question: params }),
      dataType: 'json',
      success: (data) => {
        if (dispatch) dispatchSubquestionChangeEvent(data.question_id, data.id);
      },
      error: (_data, _responseText, xhr) => {
        Rails.fire(document.body, 'ajax:complete', [xhr]);
      }
    });
  }

  function handleAdd(e) {
    updateQuestionId(e.to, e.item).then(() => {
      updatePositions(e.to, true);
    }).catch((err) => {
      snackbar(i18n.t('js.general.sort_failed', { message: err.message }));
    });
  }

  function handleRemove(e) {
    updatePositions(e.from);
  }

  function handleUpdate(e) {
    updatePositions(e.to, true);
  }

  async function getSortable() {
    const module = await import(/* webpackChunkName: "sortable" */ 'sortablejs');
    return module.default;
  }

  function preventDefault(e) {
    e.preventDefault();
  }
  this.preventDefault = preventDefault;

  const options = {
    animation: 0,
    draggable: '[data-js-sortable-element]',
    group: 'components',
    handle: '[data-js-sortable-handle]',
    onAdd: handleAdd,
    onRemove: handleRemove,
    onUpdate: handleUpdate
  };

  getSortable().then((Sortable) => Sortable.create(self, options));

  // Disable revert animation
  document.addEventListener('dragover', this.preventDefault);
}, function () {
  document.removeEventListener('dragover', this.preventDefault);
});
