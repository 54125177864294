/* global google */

import onmount from 'onmount';
import snackbar from '@components/snackbar';
import Rails from '@rails/ujs';
import { debounce } from '@modules/custom';

onmount('[data-js-bar-chart]', function () {
  const self = this;
  const { url, chartOptions } = this.dataset;
  const chart = this.querySelector('[data-js-chart]');
  let options = {
    backgroundColor: 'transparent',
    chartArea: {
      width: '80%'
    },
    isStacked: true,
    legend: { position: 'none' },
    seriesType: 'bars',
    vAxes: { 0: { title: '' } }
  };
  if (chartOptions) options = { ...options, ...JSON.parse(chartOptions) };
  let json;

  function drawChart() {
    if (!json) return;

    const dataTable = new google.visualization.DataTable();
    dataTable.addColumn('string', '');
    json.data[0].forEach((row) => {
      dataTable.addColumn('number', row);
    });
    dataTable.addRows(json.data[1]);

    const googleChart = new (google.visualization.ComboChart)(chart);
    googleChart.draw(dataTable, options);
  }
  this.drawChart = debounce(drawChart, 250);

  function dispatchEvent() {
    const chartDataFetchedEvent = new CustomEvent('chartData:fetched', {
      detail: {
        data: json.data
      }
    });
    self.dispatchEvent(chartDataFetchedEvent);
  }

  function getChartData() {
    Rails.ajax({
      url,
      type: 'GET',
      dataType: 'json',
      success: (data) => {
        json = data;
        dispatchEvent();
        drawChart();
      },
      error: (e) => snackbar(e, true)
    });
  }

  google.charts.load('current', { packages: ['corechart'], language: 'nl' });
  google.charts.setOnLoadCallback(getChartData);

  window.addEventListener('resize', this.drawChart);
}, function () {
  window.removeEventListener('resize', this.drawChart);
});
