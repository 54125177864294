import onmount from 'onmount';

onmount('[data-js-verify-quiz-start]', function () {
  const self = this;
  this.timeoutId = null;

  const integrityCheckbox = document.querySelector('[data-js-integrity-checkbox]');
  const proctorioBtn = document.querySelector('[data-js-verify-proctorio]');
  const proctorioHost = 'https://getproctorio.com';
  const ownHost = window.location.origin;

  let integrityVerified = !integrityCheckbox;
  let proctorioVerified = !proctorioBtn;
  let failedChecks = 0;

  function enableStartQuiz() {
    if (integrityVerified && proctorioVerified) {
      self.disabled = false;
    }
  }

  function listenForProctorioEvents(event) {
    if (event.origin === proctorioHost || event.origin === ownHost) {
      if (event.data.active) {
        proctorioVerified = true;
        enableStartQuiz();
        clearTimeout(self.timeoutId);
      } else {
        proctorioVerified = false;
        failedChecks += 1;
      }
    } else {
      failedChecks += 0.5;
    }

    if (failedChecks >= 10) {
      proctorioBtn.click();
    }
  }
  this.listenForProctorioEvents = listenForProctorioEvents;

  function checkProctorioStatus() {
    window.top.postMessage(['proctorio_status'], ownHost);
    window.top.postMessage(['proctorio_status'], proctorioHost);
  }

  function verifyProctorio() {
    window.addEventListener('message', self.listenForProctorioEvents);

    if (window.top.frames.length === 0) {
      proctorioBtn.click();
      return;
    }

    self.timeoutId = setInterval(checkProctorioStatus, 100);
  }

  function toggleIntegrity(e) {
    if (e.target.checked) {
      integrityVerified = true;
      enableStartQuiz();
    } else if (!e.target.checked && self) {
      self.disabled = true;
    }
  }

  if (proctorioBtn) verifyProctorio();
  if (integrityCheckbox) integrityCheckbox.addEventListener('change', toggleIntegrity);
}, function () {
  window.removeEventListener('message', this.listenForProctorioEvents);
});
