import '@kollegorna/cocoon-vanilla-js';
import onmount from 'onmount';

onmount('[data-js-hotspot-areas]', function () {
  const self = this;

  function handleHotspotAreaAdd() {
    const event = new CustomEvent('hotspot-area:added');
    self.dispatchEvent(event);
  }

  this.addEventListener('cocoon:after-insert', handleHotspotAreaAdd);
});
