import { Controller } from '@hotwired/stimulus';
import { updateSeparators } from '@modules/section-separators';

export default class extends Controller {
  static targets = ['top', 'bottom'];

  static values = {
    event: String
  };

  connect() {
    document.addEventListener(this.eventValue, this.update.bind(this));
  }

  update() {
    const sections = document.querySelectorAll('[data-controller="section-separators-controller"] > ul');
    updateSeparators(this.topTarget, this.bottomTarget, sections);
  }
}
