import { Controller } from '@hotwired/stimulus';
import { showValidationMessages } from '@modules/form-responses';
import { useMutation } from 'stimulus-use';

export default class extends Controller {
  static targets = ['checkboxes', 'submitButton'];

  connect() {
    if (this.hasCheckboxesTarget) useMutation(this, { element: this.checkboxesTarget, childList: true });
    this.updateSubmitDisabledState();
  }

  updateSubmitDisabledState() {
    if (!this.hasSubmitButtonTarget) return;

    if (this.element.checkValidity() && this.mdcSelectsValid() && this.checkboxesValid()) {
      this.submitButtonTarget.removeAttribute('disabled');
    } else {
      this.submitButtonTarget.setAttribute('disabled', 'disabled');
    }
  }

  mdcSelectsValid() {
    return Array.from(this.element.querySelectorAll('[data-mdc-auto-init="MDCSelect"]')).every((select) => {
      if (select.MDCSelect) return select.MDCSelect.valid;

      return false;
    });
  }

  checkboxesValid() {
    if (this.hasCheckboxesTarget) {
      return this.checkboxesTarget.querySelector('input[type="checkbox"]:checked');
    }
    return true;
  }

  displayValidationErrors(event) {
    if (event.detail.fetchResponse.response.status !== 422) return;

    event.detail.fetchResponse.response.json().then((errors) => {
      showValidationMessages(errors, this.element.closest('form'));
    });
  }

  mutate(entries) {
    entries.forEach((mutation) => {
      if (mutation.type === 'childList') {
        mutation.addedNodes.forEach((node) => {
          if (typeof node.querySelector === 'undefined') return;

          const checkbox = node.querySelector('input[type="checkbox"]');
          if (checkbox) checkbox.setAttribute('data-action', 'change->form-validation#updateSubmitDisabledState');
        });
      }
    });
  }
}
