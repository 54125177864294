import onmount from 'onmount';
import Rails from '@rails/ujs';

onmount('[data-js-load-filter]', function () {
  const url = new URL(this.dataset.url || window.location.href);

  function storedFilters() {
    return sessionStorage.getItem(url.pathname);
  }

  function saveFilters() {
    sessionStorage.setItem(url.pathname, url.search);
  }

  function loadFilters() {
    url.search = storedFilters();

    if (this.getAttribute('data-is-remote') === 'true') {
      Rails.ajax({
        url: url.toString(),
        dataType: 'script',
        type: 'GET'
      });
    } else {
      window.location.href = url.toString();
    }
  }

  if (url.search.length) {
    saveFilters();
  } else if (storedFilters()) {
    this.classList.remove('d-none');
    this.classList.add('d-inline-block');
  }

  this.addEventListener('click', loadFilters);
});
