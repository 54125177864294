/* global i18n */
import onmount from 'onmount';
import Spreadsheet from 'x-data-spreadsheet';
import nl from 'x-data-spreadsheet/dist/locale/nl';
import de from 'x-data-spreadsheet/dist/locale/de';

onmount('[data-js-spreadsheet]', function () {
  const self = this;
  const { contextMenu, viewOnly } = this.dataset;

  if (i18n.locale === 'nl') {
    Spreadsheet.locale('nl', nl);
  } else if (i18n.locale === 'de') {
    Spreadsheet.locale('de', de);
  }

  function createWrapper() {
    const spreadsheetWrapper = document.createElement('div');
    self.parentElement.append(spreadsheetWrapper);

    return spreadsheetWrapper;
  }

  function dispatchEvent(eventName, details) {
    const event = new CustomEvent(`spreadsheet:${eventName}`, {
      detail: details
    });
    self.dispatchEvent(event);
  }

  function getWidth() {
    return self.clientWidth ||
      self.parentElement.clientWidth ||
      self.parentElement.parentElement.clientWidth ||
      self.parentElement.parentElement.parentElement.clientWidth;
  }

  const options = {
    autoFocus: false,
    mode: (viewOnly === 'true' ? 'read' : 'edit'),
    showToolbar: (viewOnly !== 'true'),
    showGrid: true,
    showContextmenu: contextMenu || false,
    view: {
      height: () => 500,
      width: () => getWidth()
    },
    row: {
      len: 100,
      height: 25
    },
    col: {
      len: 26,
      width: 100,
      indexWidth: 60,
      minWidth: 60
    }
  };

  const spreadsheetWrapper = createWrapper();
  this.spreadsheet = new Spreadsheet(spreadsheetWrapper, options);
  try {
    this.spreadsheet.loadData(JSON.parse(self.value) || {});
  } catch (TypeError) {
    this.spreadsheet.loadData({});
  }

  this.spreadsheet.on('change', (data) => {
    self.value = JSON.stringify(data);
    dispatchEvent('change', { data });
  });

  this.spreadsheet.on('cell-selected', (cell, ri, ci) => {
    dispatchEvent('cell-selected', { cell, ri, ci });
  });

  this.spreadsheet.on('cell-edited', (text, ri, ci) => {
    dispatchEvent('cell-edited', { text, ri, ci });
  });
});
