import { Controller } from '@hotwired/stimulus';

function gradingSchemeErrors(errors) {
  if (errors.length === 0) return '';

  return `
    <div class="d-flex flex-column left-align gap-1">
      ${errors.map((error) => `<div>${error}</div>`).join('')}
    </div>
  `;
}

export default class extends Controller {
  static targets = ['count', 'max', 'chip', 'subquestion', 'tooltip'];

  hideChip() {
    this.chipTarget.classList.add('d-none');
  }

  showChip() {
    this.chipTarget.classList.remove('d-none');
  }

  toggleChipVisibility() {
    if (this.subquestionTargets.length === 0) {
      this.hideChip();
    } else {
      this.showChip();
    }
  }

  toggleChipColor(completed) {
    if (completed === this.subquestionTargets.length) {
      this.chipTarget.classList.replace('chip-danger', 'chip-progress');
    } else {
      this.chipTarget.classList.replace('chip-progress', 'chip-danger');
    }
  }

  subquestionTargetConnected() {
    this.update();
  }

  subquestionTargetDisconnected() {
    this.update();
  }

  update() {
    let completed = 0;
    const errors = [];

    this.subquestionTargets.forEach((subquestion) => {
      const { gradingScheme, gradingSchemeError } = subquestion.dataset;
      if (gradingScheme === 'true') {
        completed += 1;
      } else {
        errors.push(gradingSchemeError);
      }
    });

    this.tooltipTarget.innerHTML = gradingSchemeErrors(errors);
    this.toggleChipVisibility(completed);
    this.countTarget.innerText = completed;
    this.maxTarget.innerText = this.subquestionTargets.length;
    this.toggleChipColor(completed);
  }
}
