import onmount from 'onmount';
import Rails from '@rails/ujs';
import { displayUpdatingStatus } from '@modules/quiz/saving';
import { lockSubmission, unlockSubmission } from '@modules/quiz/locking';
import { updateActiveSubmission } from '@modules/quiz/navigating';

onmount('[data-js-response="numeric"]', function () {
  const self = this;
  const form = this.closest('form');
  const { submissionId } = this.closest('[data-js-quiz-subquestion]').dataset;

  function handleFocus() {
    lockSubmission.call(self);

    updateActiveSubmission(submissionId);
  }

  function handleBlur() {
    unlockSubmission.call(self);

    if (form.getAttribute('data-saved') === 'false') {
      form.setAttribute('data-saved', true);
      displayUpdatingStatus(form);
      Rails.fire(form, 'submit');
    }
  }

  function handleContentChanged() {
    form.setAttribute('data-saved', false);
  }

  function addInputEventHandlers(input) {
    input.addEventListener('focus', handleFocus);
    input.addEventListener('blur', handleBlur);
    input.addEventListener('input', handleContentChanged);
  }

  this.querySelectorAll('input').forEach((input) => {
    addInputEventHandlers(input);
  });
});
