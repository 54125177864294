import onmount from 'onmount';
import Rails from '@rails/ujs';

onmount('[data-js-generate-poll-status]', function () {
  const url = this.dataset.jsGeneratePollStatus;
  this.timeoutId = null;

  function pollGenerateStatus() {
    Rails.ajax({
      url,
      type: 'GET',
      dataType: 'script'
    });
  }

  this.timeoutId = setTimeout(pollGenerateStatus, 5000);
}, function () {
  if (this.timeoutId) clearTimeout(this.timeoutId);
});
