import onmount from 'onmount';
import Rails from '@rails/ujs';
import { serialize } from '@modules/custom';

onmount('[data-js-sortable]', function () {
  const self = this;
  const sortEvent = self.getAttribute('data-js-sort-event');

  function executeEvent(e) {
    const customEvent = new CustomEvent(sortEvent, {
      detail: { evt: e }
    });
    document.dispatchEvent(customEvent);
  }

  function updatePosition(e) {
    const url = e.item.dataset.jsSortableUrl;
    const element = e.item.dataset.jsSortableElement;

    const params = {};
    params[element] = { position: e.newDraggableIndex + 1 };

    Rails.ajax({
      url,
      type: 'PATCH',
      data: serialize(params),
      dataType: 'script',
      complete: (xhr) => {
        Rails.fire(document.body, 'ajax:complete', [xhr]);
      }
    });
  }

  function handleUpdate(e) {
    if (e.item.hasAttribute('data-js-sortable-url')) {
      updatePosition(e);
    } else if (sortEvent) {
      executeEvent(e);
    }
  }

  async function getSortable() {
    const module = await import(/* webpackChunkName: "sortable" */ 'sortablejs');
    return module.default;
  }

  function preventDefault(e) {
    e.preventDefault();
  }
  this.preventDefault = preventDefault;

  const options = {
    animation: 0,
    filter: '[data-js-subquestion-edit-grading-options], [data-js-criterium-edit], .redactor-toolbar-wrapper-air',
    preventOnFilter: false,
    handle: '[data-js-sortable-handle]',
    draggable: '[data-js-sortable-element]',
    onUpdate: handleUpdate
  };

  getSortable().then((Sortable) => Sortable.create(self, options));

  // Disable revert animation
  document.addEventListener('dragover', this.preventDefault);
}, function () {
  document.removeEventListener('dragover', this.preventDefault);
});
