import onmount from 'onmount';

onmount('[data-js-calculate-points]', function () {
  const subquestion = this.closest('[data-js-subquestion]');
  const wrapper = subquestion.parentElement;

  function accumulatePoints() {
    let points = 0;
    subquestion.querySelectorAll('[data-js-choice-points]').forEach((choice) => {
      points += Math.max(0, parseFloat(choice.value));
    });

    return points;
  }

  function calculateChoice() {
    if (!subquestion.querySelector('[data-js-choices] [data-js-choice]')) return 0;
    if (subquestion.querySelector('[data-js-choices]').dataset.choiceType === 'checkbox') return accumulatePoints();

    const pointsList = [...subquestion.querySelectorAll('[data-js-choice-points]')].map((c) => parseFloat(c.value));
    pointsList.push(0);
    return Math.max(...pointsList);
  }

  function calculateFill() {
    if (!subquestion.querySelector('[data-js-option-item]:not(.d-none) [data-js-item-points]')) return 0;

    let points = 0;
    subquestion.querySelectorAll('[data-js-option-list]').forEach((list) => {
      const pointsList = [...list.querySelectorAll('[data-js-option-item]:not(.d-none) [data-js-item-points]')];
      const itemPoints = pointsList.map((answer) => parseFloat(answer.value));
      itemPoints.push(0);
      points += Math.max(...itemPoints);
    });

    return points;
  }

  function calculateMatch() {
    if (!subquestion.querySelector('[data-js-match-row]')) return 0;

    if (subquestion.querySelector('[data-js-match-row]').dataset.choiceType === 'checkbox') {
      return accumulatePoints();
    }

    let points = 0;
    subquestion.querySelectorAll('[data-js-match-row]').forEach((row) => {
      const pointsList = [...row.querySelectorAll('[data-js-choice-points]')].map((c) => parseFloat(c.value));
      pointsList.push(0);
      points += Math.max(...pointsList);
    });

    return points;
  }

  function calculateHotspot() {
    if (!subquestion.querySelector('[data-js-choice-points]:not(.d-none)')) return 0;

    const areaList = [...subquestion.querySelectorAll('[data-js-hotspot-area]:not(.d-none) [data-js-choice-points]')];
    const pointsList = areaList.map((c) => parseFloat(c.value));
    pointsList.push(0);

    const markers = parseInt(subquestion.querySelector('#subquestion_marker_count').value, 10);
    if (markers <= 1) return Math.max(...pointsList);
    if (markers > pointsList.length) return accumulatePoints();

    let points = 0;
    while ((pointsList.length - 1) > (areaList.length - markers)) {
      const highestVal = Math.max(...pointsList);
      points += highestVal;
      pointsList.splice(pointsList.indexOf(highestVal), 1);
    }

    return points;
  }

  function calculateKprime() {
    const elements = [...subquestion.querySelectorAll('[data-js-response-rule]:not(.d-none) [data-js-rule-points]')];
    const pointsList = elements.map((el) => parseFloat(el.value));
    pointsList.push(0);
    return Math.max(...pointsList);
  }

  function calculatePoints() {
    let points = 0;
    const criteria = [...wrapper.querySelectorAll('[data-js-criterium-points]')];

    if (subquestion.querySelector('[data-js-select-input').value === 'rubrics') {
      const pointsList = criteria.map((l) => parseFloat(l.dataset.points));
      pointsList.push(0);
      return Math.max(...pointsList) * parseFloat(subquestion.querySelector('#subquestion_weight').value);
    }

    criteria.forEach((c) => {
      points += Math.max(0, parseFloat(c.dataset.points));
    });

    return points;
  }

  function handleCalculatePoints() {
    const subquestionPointsEl = subquestion.querySelector('[data-js-subquestion-points]');
    const type = subquestion.dataset.subquestionType;

    switch (type) {
      case 'choice':
        subquestionPointsEl.numeric.set(calculateChoice());
        break;

      case 'fill':
        subquestionPointsEl.numeric.set(calculateFill());
        break;

      case 'match':
        subquestionPointsEl.numeric.set(calculateMatch());
        break;

      case 'hotmatch':
        subquestionPointsEl.numeric.set(accumulatePoints());
        break;

      case 'hotspot':
        subquestionPointsEl.numeric.set(calculateHotspot());
        break;

      case 'kprime':
        subquestionPointsEl.numeric.set(calculateKprime());
        break;

      default:
        subquestionPointsEl.numeric.set(calculatePoints());
        break;
    }
  }

  this.addEventListener('click', handleCalculatePoints);
});
