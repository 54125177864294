/* global i18n */

import onmount from 'onmount';

onmount('[data-js-limit-items]', function () {
  const self = this;
  const maxItems = parseInt(self.dataset.maxItems, 10);
  const remainingItemsText = document.querySelector('[data-js-items-remaining-text]');
  let checkboxes;
  let checked;

  function setItemsRemainingText() {
    checked = self.querySelectorAll('input[type=checkbox]:checked').length;

    const itemsRemaining = maxItems - checked;
    const translation = (itemsRemaining > 0 ? 'items_remaining' : 'no_items_remaining');
    const count = (itemsRemaining > 0 ? itemsRemaining : maxItems);

    remainingItemsText.innerHTML = i18n.t(`js.limit_items.${translation}`, { count });
  }

  function lockBoxes() {
    checkboxes.forEach((item) => (item).setAttribute('disabled', 'true'));
    self.querySelectorAll('input[type=checkbox]:checked').forEach((item) => (item).removeAttribute('disabled'));
  }

  function unlockBoxes() {
    checkboxes.forEach((item) => (item).removeAttribute('disabled'));
  }

  function checkStatus() {
    setItemsRemainingText();

    if (checked >= maxItems) {
      lockBoxes();
    } else {
      unlockBoxes();
    }
  }

  function refreshList() {
    checkboxes = self.querySelectorAll('input[type=checkbox]');
    setItemsRemainingText();
    const lock = (checked >= maxItems);

    checkboxes.forEach((item) => {
      item.addEventListener('change', checkStatus);

      if (lock && !item.checked) {
        item.setAttribute('disabled', true);
      }
    });
  }
  this.refreshList = refreshList;

  document.addEventListener('limit-items:refresh', this.refreshList);
}, function () {
  document.removeEventListener('limit-items:refresh', this.refreshList);
});
