/* global i18n, google */

import onmount from 'onmount';
import Rails from '@rails/ujs';
import { debounce } from '@modules/custom';

onmount('[data-js-comparison-chart]', function () {
  const { url } = this.dataset;
  const chartWrapper = this.querySelector('[data-js-chart]');
  let json;

  function drawChart() {
    if (!json) return;

    const dataTable = new google.visualization.DataTable();
    dataTable.addColumn('string', '');
    json.data[0].forEach((row) => {
      dataTable.addColumn('number', row);
    });
    dataTable.addRows(json.data[1]);
    const options = {
      isStacked: true,
      legend: {
        position: 'none'
      },
      series: {
        0: { color: '#4384F4' },
        1: { color: '#72A3F7', lineDashStyle: [2, 2], lineWidth: 1 }
      },
      vAxes: {
        0: { title: json.title }
      }
    };

    const chart = new (google.visualization.LineChart)(chartWrapper);
    chart.draw(dataTable, options);
  }
  this.drawChart = debounce(drawChart, 250);

  function getChartData() {
    Rails.ajax({
      url,
      type: 'GET',
      dataType: 'json',
      success: (data) => {
        json = data;
        drawChart();
      }
    });
  }

  google.charts.load('current', { packages: ['corechart'], language: i18n.locale });
  google.charts.setOnLoadCallback(getChartData);

  window.addEventListener('resize', this.drawChart);
}, function () {
  window.removeEventListener('resize', this.drawChart);
});
