import onmount from 'onmount';

onmount('[data-js-toggle-equation-solver]', function () {
  const form = this.closest('form');
  const equationSolverToggle = this;
  const equationOperationToggle = form.querySelector('[data-js-toggle-equation-operation]');

  function toggleEquationSolver() {
    const target = form.querySelector(this.dataset.jsToggleEquationSolver);
    if (this.MDCSelect.value === 'auto') {
      target.classList.remove('d-none');
      target.querySelector('[data-js-equation-symbols]').setAttribute('required', true);
    } else {
      target.classList.add('d-none');
      target.querySelector('[data-js-equation-symbols]').removeAttribute('required');
    }
  }

  function toggleEquationOperation() {
    const target = form.querySelector('[data-js-equation-symbol-field]');
    if (['solve', 'differentiate'].includes(this.MDCSelect.value)) {
      target.classList.remove('d-none');
      target.querySelector('[data-js-equation-symbols]').setAttribute('required', true);
    } else {
      target.classList.add('d-none');
      target.querySelector('[data-js-equation-symbols]').removeAttribute('required');
    }
  }

  if (equationSolverToggle) equationSolverToggle.addEventListener('change', toggleEquationSolver);
  if (equationOperationToggle) equationOperationToggle.addEventListener('change', toggleEquationOperation);
});
