import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['header', 'pagination'];

  connect() {
    this.setStickyColumns();
    this.updatePaginationPosition();
  }

  setStickyColumns() {
    const stickyColumns = this.headerTargets;
    let offset = 0;

    stickyColumns.forEach((col) => {
      col.classList.add('mdc-data-table__sticky');
      col.style.setProperty('--left-offset', `${offset}px`);
      col.setAttribute('data-cy', 'result-column');
      offset += col.offsetWidth;
    });
  }

  updatePaginationPosition() {
    if (!this.hasPaginationTarget) return;

    const paginationTrailing = this.paginationTarget.querySelector('.mdc-data-table__pagination-trailing');

    if (paginationTrailing) {
      paginationTrailing.classList.add('mdc-data-table__pagy');
    }
  }
}
