import onmount from 'onmount';
import { Schets } from '@modules/drawing';
import { displayUpdatingStatus } from '@modules/quiz/saving';
import { debounce } from '@modules/custom';
import Rails from '@rails/ujs';

onmount('[data-js-response="drawing"]', function () {
  const self = this;
  const form = this.closest('form');
  const input = this.querySelector('[data-js-response-content]');
  const drawingArea = this.querySelector('[data-js-drawing-area]');
  this.drawingTool = new Schets(drawingArea);

  function handleChange() {
    input.value = self.drawingTool.value();
    form.setAttribute('data-saved', false);
  }

  function handleChanged() {
    if (form.getAttribute('data-saved') === 'false') {
      form.setAttribute('data-saved', true);
      displayUpdatingStatus(form);
      Rails.fire(form, 'submit');
    }
  }

  this.drawingTool.init(input.value);
  drawingArea.addEventListener('drawing:changed', handleChange);
  drawingArea.addEventListener('drawing:changed', debounce(handleChanged, 3000));
}, function () {
  this.drawingTool.removeEventListeners();
});
