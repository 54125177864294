import '@kollegorna/cocoon-vanilla-js';
import onmount from 'onmount';
import mdcAutoInit from '@material/auto-init';

onmount('[data-js-item-positions]', function () {
  const self = this;
  const { itemName } = this.dataset;

  function numberOfItems() {
    return self.querySelectorAll(`[data-js-${itemName}]:not(.d-none)`).length;
  }

  function setPosition(item) {
    const input = item.querySelector(`[data-js-${itemName}-position]`);
    input.value = numberOfItems();
  }

  function setPositions() {
    [...self.querySelectorAll(`[data-js-${itemName}]:not(.d-none)`)].forEach((item, index) => {
      const position = item.querySelector(`[data-js-${itemName}-position]`);
      position.value = index + 1;
    });
  }

  function handleItemAdd(e) {
    onmount();
    const insertedItem = e.target.querySelector(`[data-js-${itemName}]:not(.d-none):last-child`);
    setPosition(insertedItem);
    const textarea = insertedItem.querySelector('[data-js-redactor]');
    if (textarea) textarea.setAttribute('data-js-focus', true);
    mdcAutoInit();
  }

  function handleItemRemove(e) {
    const removedItem = e.detail;
    removedItem.classList.add('d-none');
    removedItem.querySelector('input').removeAttribute('required');
    const textarea = removedItem.querySelector('textarea');
    if (textarea) textarea.removeAttribute('required');
    setPositions();
  }

  this.addEventListener('cocoon:after-insert', handleItemAdd);
  this.addEventListener('cocoon:after-remove', handleItemRemove);
});
