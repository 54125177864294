import { Controller } from '@hotwired/stimulus';
import buildDialog from '@components/unsaved-changes-dialog';

export default class extends Controller {
  static targets = ['button'];

  static values = { scope: String };

  confirmed = false;

  connect() {
    this.handleConfirm = this.handleConfirm.bind(this);
  }

  handleConfirm() {
    this.confirmed = true;
    this.element.click();
  }

  showWarning() {
    if (document.querySelector('[data-js-unsaved-changes-warning]')) return;

    document.body.insertAdjacentHTML('beforeend', buildDialog());

    const confirmButton = document.querySelector('[data-js-unsaved-changes-warning] [data-js-confirm]');
    confirmButton.addEventListener('click', this.handleConfirm);
  }

  warn(e) {
    if (this.confirmed) return;
    if (!document.querySelector(`${this.scopeValue} [data-unsaved-changes-warning-target='item']`)) return;

    this.showWarning();
    e.preventDefault();
    e.stopPropagation();
  }
}
