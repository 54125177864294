function getSelectionState(collection, selector) {
  const allChecked = !collection.querySelector(`${selector}:not([data-js-select-all]) input[type=checkbox]:not(:checked)`); // eslint-disable-line max-len
  const isIndeterminate = (!allChecked && (collection.querySelector(`${selector}:not([data-js-select-all]) input[type=checkbox]:checked`) != null || // eslint-disable-line max-len
    collection.querySelector(`${selector}:not([data-js-select-all]) input[type=checkbox]:indeterminate`)));

  return { isIndeterminate, allChecked };
}

export function handleMultiSelect(collection, selectedValue, selector) {
  const allCheckbox = collection.querySelector('[data-js-select-all] input');
  if (!allCheckbox) return;

  const allCheckboxComponent = allCheckbox.closest('.mdc-checkbox').MDCCheckbox;
  const { isIndeterminate, allChecked } = getSelectionState(collection, selector);

  if (selectedValue === 'all') {
    const setTo = !allChecked;

    allCheckboxComponent.indeterminate = false;
    collection.querySelectorAll('input[type=checkbox]').forEach((checkbox) => {
      checkbox.checked = setTo;
      checkbox.indeterminate = false;
    });
  } else {
    allCheckboxComponent.indeterminate = isIndeterminate;
    allCheckboxComponent.checked = (!isIndeterminate && allChecked);
  }
}

export function initSelectAll(collection, selector) {
  const allCheckbox = collection.querySelector('[data-js-select-all] input');
  if (!allCheckbox) return;

  const { isIndeterminate, allChecked } = getSelectionState(collection, selector);

  if (isIndeterminate) allCheckbox.setAttribute('data-indeterminate', true);
  allCheckbox.checked = (!isIndeterminate && allChecked);
}
