import mdcAutoInit from '@material/auto-init';
import onmount from 'onmount';
import Rails from '@rails/ujs';
import { MDCSlider } from '@material/slider';
import { debounce } from '@modules/custom';

onmount('[data-js-range-filter]', function () {
  const self = this;
  const { attribute, url } = this.dataset;
  const pageUrl = new URL(window.location.href);
  const minQuery = new RegExp(`${attribute}[>=]+(-?\\d*\\.?\\d*)\\s?`);
  const maxQuery = new RegExp(`${attribute}[<=]+(-?\\d*\\.?\\d*)\\s?`);
  const menu = self.querySelector('.mdc-menu');
  const queryButton = this.querySelector('[data-js-range]');

  let slider;
  let bars;
  let minInput;
  let maxInput;

  function format(n) {
    return parseFloat(n.toFixed(2));
  }

  function updateGraphic() {
    const minDist = format(Math.abs(slider.MDCSlider.foundation.min - slider.MDCSlider.getValueStart()));
    const maxDist = format(Math.abs(slider.MDCSlider.foundation.min - slider.MDCSlider.getValue()));
    const abDist = format(Math.abs(slider.MDCSlider.foundation.min - slider.MDCSlider.foundation.max));

    bars.forEach((bar, index) => {
      if ((index + 1) / bars.length <= (minDist / abDist) || index / bars.length >= maxDist / abDist) {
        bar.style.opacity = 0.24;
      } else {
        bar.style.opacity = 1;
      }
    });
  }

  function updateQuery() {
    const min = slider.MDCSlider.getValueStart();
    const max = slider.MDCSlider.getValue();

    if (max === slider.MDCSlider.foundation.max || min === max) {
      queryButton.setAttribute('data-query', `${attribute}>=${min} ${attribute}<=${max}`);
    } else {
      queryButton.setAttribute('data-query', `${attribute}>=${min} ${attribute}<${max}`);
    }
  }

  function sliderInputChange(e) {
    const { thumb, value } = e.detail;
    const input = (thumb === 1 ? minInput : maxInput);

    input.numeric.set(value);
    updateGraphic();
    updateQuery();
  }

  function textInputChange() {
    const { value } = this;
    if (!value) return;

    if (this.hasAttribute('data-js-min-input')) {
      slider.MDCSlider.setValueStart(value);
    } else {
      slider.MDCSlider.setValue(value);
    }
    updateGraphic();
    updateQuery();
  }

  function initTextfieldInput(input, regex, methodName) {
    input.addEventListener('input', debounce(textInputChange, 250));
    input.addEventListener('paste', debounce(textInputChange, 250));

    const query = pageUrl.searchParams.get('q') || '';
    const [, value] = query.match(regex) || [];
    if (!value) return;

    input.value = value;
    slider.MDCSlider[methodName](value);
  }

  function init() {
    bars = self.querySelectorAll('[data-js-bar]');
    slider = self.querySelector('.mdc-slider');
    minInput = self.querySelector('[data-js-min-input]');
    maxInput = self.querySelector('[data-js-max-input]');

    if (!slider.MDCSlider) {
      slider.MDCSlider = new MDCSlider(slider);
      slider.setAttribute('data-mdc-auto-init-state', 'initialized');
    }

    slider.MDCSlider.listen('MDCSlider:input', sliderInputChange);
    initTextfieldInput(minInput, minQuery, 'setValueStart');
    initTextfieldInput(maxInput, maxQuery, 'setValue');
    updateGraphic();
    updateQuery();
  }

  function requestHistogram() {
    Rails.ajax({
      url,
      type: 'GET',
      dataType: 'json',
      success: (data) => {
        self.querySelector('[data-js-content]').outerHTML = data.html;
        init();
        onmount();
        mdcAutoInit();
      }
    });
    menu.removeEventListener('menu:opened', self.requestHistogram);
  }
  self.requestHistogram = requestHistogram;

  if (url) {
    menu.addEventListener('menu:opened', self.requestHistogram);
  } else {
    init();
  }
});
