/* global Turbo */

import { Controller } from '@hotwired/stimulus';
import buildDialog from '@components/unsaved-changes-dialog';

function handleConfirm() {
  Turbo.visit(this);
}

export default class extends Controller {
  static targets = ['item'];

  warn(e) {
    if (this.hasItemTarget) this.showWarning(e);
  }

  showWarning(e) {
    if (document.querySelector('[data-js-unsaved-changes-warning]')) return;

    e.preventDefault();
    if (e.type === 'beforeunload') return;

    document.body.insertAdjacentHTML('beforeend', buildDialog());

    const confirmButton = document.querySelector('[data-js-unsaved-changes-warning] [data-js-confirm]');
    confirmButton.addEventListener('click', handleConfirm.bind(e.detail.url));
  }
}
