import onmount from 'onmount';
import Rails from '@rails/ujs';
import imagesLoaded from 'imagesloaded';
import interact from 'interactjs';
import { displayUpdatingStatus } from '@modules/quiz/saving';
import { lockSubmission, unlockSubmission } from '@modules/quiz/locking';
import { debounce } from '@modules/custom';
import { updateActiveSubmission } from '@modules/quiz/navigating';

onmount('[data-js-response="hotspot"], [data-js-response="hotmatch"]', function () {
  const self = this;
  const form = this.closest('form');
  const { submissionId } = this.closest('[data-js-quiz-subquestion]').dataset;
  const imageWrapper = this.querySelector('[data-js-hotspot-image-wrapper]');
  const markers = this.querySelectorAll('[data-js-hotspot-marker]');
  const placeholders = this.querySelectorAll('[data-js-hotspot-placeholder]');
  let dragStartPosition = {};

  function handleFocus() {
    lockSubmission.call(self);

    updateActiveSubmission(submissionId);
  }

  function handleBlur() {
    unlockSubmission.call(self);
  }

  function getRatio() {
    const img = imageWrapper.querySelector('img');
    return {
      ratioX: img.naturalWidth / img.clientWidth,
      ratioY: img.naturalHeight / img.clientHeight
    };
  }

  function initMarkers() {
    const ratio = getRatio();

    [...markers].forEach((marker) => {
      const coords = marker.querySelector('[data-js-hotspot-coords]').value;

      if (coords.length) {
        imageWrapper.insertBefore(marker, imageWrapper.firstChild);

        const left = coords.split(',')[0] / ratio.ratioX - marker.offsetWidth / 2;
        const top = coords.split(',')[1] / ratio.ratioY - marker.offsetHeight / 2;

        marker.style.left = `${left}px`;
        marker.style.top = `${top}px`;
      }
    });
  }
  this.initMarkers = debounce(initMarkers, 10);

  function saveCoords(marker, undo) {
    const coordsInput = marker.querySelector('[data-js-hotspot-coords]');
    const ratio = getRatio();
    let coords = '';

    if (!undo) {
      const left = Math.floor((marker.offsetLeft + marker.offsetWidth / 2) * ratio.ratioX);
      const top = Math.floor((marker.offsetTop + marker.offsetHeight / 2) * ratio.ratioY);
      coords = `${left},${top}`;
    }

    coordsInput.value = coords;

    Rails.fire(form, 'submit');
    displayUpdatingStatus(form);
  }

  function handleImageWrapperClick(e) {
    if (e.target.hasAttribute('data-js-hotspot-marker')) return;

    const selectedMarker = self.querySelector('[data-js-hotspot-marker].selected');

    if (!selectedMarker) return;

    const offset = this.getBoundingClientRect();
    const left = Math.floor(e.clientX - offset.left - selectedMarker.offsetWidth / 2);
    const top = Math.floor(e.clientY - offset.top - selectedMarker.offsetHeight / 2);
    this.insertBefore(selectedMarker, this.firstChild);

    selectedMarker.style.left = `${left}px`;
    selectedMarker.style.top = `${top}px`;
    saveCoords(selectedMarker);
  }

  function removeSelection() {
    while (self.querySelector('[data-js-hotspot-marker].selected')) {
      self.querySelector('[data-js-hotspot-marker].selected').classList.remove('selected');
    }
    imageWrapper.style.cursor = 'default';
    [...placeholders].forEach((placeholder) => {
      placeholder.style.cursor = 'default';
    });
  }

  function handleMarkerClick() {
    if (this.classList.contains('selected')) {
      removeSelection();
      handleBlur();
    } else {
      handleFocus();
      while (self.querySelector('[data-js-hotspot-marker].selected')) {
        self.querySelector('[data-js-hotspot-marker].selected').classList.remove('selected');
      }
      this.classList.add('selected');
      imageWrapper.style.cursor = 'pointer';
      [...placeholders].forEach((placeholder) => {
        placeholder.style.cursor = 'pointer';
      });
    }
  }

  function handlePlaceholderClick() {
    const selectedMarker = imageWrapper.querySelector('[data-js-hotspot-marker].selected');

    if (!selectedMarker) return;

    if (this.getAttribute('data-hotspot-area-id') === selectedMarker.getAttribute('data-hotspot-area-id')) {
      this.insertBefore(selectedMarker, this.firstChild);
      selectedMarker.style.left = '0px';
      selectedMarker.style.top = '0px';
      selectedMarker.classList.remove('selected');

      saveCoords(selectedMarker, true);
    }

    imageWrapper.style.cursor = 'default';
    [...placeholders].forEach((placeholder) => {
      placeholder.style.cursor = 'default';
    });
  }

  function dragStartListener(event) {
    removeSelection();

    const { target } = event;
    dragStartPosition = {
      left: target.style.left,
      top: target.style.top
    };
  }

  function handleDragEnter(event) {
    event.relatedTarget.setAttribute('data-in-dropzone', true);
  }

  function handleDragLeave(event) {
    event.relatedTarget.removeAttribute('data-in-dropzone');
  }

  function dragMoveListener(event) {
    const { target } = event;
    imageWrapper.insertBefore(target, imageWrapper.firstChild);

    const offset = imageWrapper.getBoundingClientRect();
    const left = Math.floor(event.clientX - offset.left - target.offsetWidth / 2);
    const top = Math.floor(event.clientY - offset.top - target.offsetHeight / 2);

    target.style.left = `${left}px`;
    target.style.top = `${top}px`;
  }

  function dragEndListener(event) {
    const { target } = event;

    if (target.hasAttribute('data-in-dropzone')) return;

    target.style.left = dragStartPosition.left;
    target.style.top = dragStartPosition.top;
  }

  function handleImageDrop(event) {
    const parent = event.target;
    const marker = event.relatedTarget;
    const offset = imageWrapper.getBoundingClientRect();
    const left = Math.floor(event.clientX - offset.left - marker.offsetWidth / 2);
    const top = Math.floor(event.clientY - offset.top - marker.offsetHeight / 2);
    parent.insertBefore(marker, parent.firstChild);

    marker.style.left = `${left}px`;
    marker.style.top = `${top}px`;
    saveCoords(marker);
  }

  function handlePlaceholderDrop(event) {
    const parent = event.target;
    const marker = event.relatedTarget;
    parent.insertBefore(marker, parent.firstChild);

    marker.style.left = '0px';
    marker.style.top = '0px';
    saveCoords(marker, true);
  }

  function initDropzones() {
    interact(imageWrapper).dropzone({
      accept: '[data-js-hotspot-marker]',
      ondrop: handleImageDrop,
      ondragenter: handleDragEnter,
      ondragleave: handleDragLeave
    });

    [...placeholders].forEach((placeholder) => {
      const { hotspotAreaId } = placeholder.dataset;
      interact(placeholder).dropzone({
        accept: `[data-js-hotspot-marker][data-hotspot-area-id='${hotspotAreaId}']`,
        ondrop: handlePlaceholderDrop,
        ondragenter: handleDragEnter,
        ondragleave: handleDragLeave
      });
    });
  }

  function handleDrag(marker) {
    interact(marker).draggable({
      onstart: dragStartListener,
      onmove: dragMoveListener,
      onend: dragEndListener,
      modifiers: [
        interact.modifiers.restrict({
          restriction: self,
          elementRect: {
            top: 0,
            left: 0,
            bottom: 1,
            right: 1
          }
        })
      ],
      inertia: false,
      autoScroll: true
    });
  }

  imagesLoaded(this, initMarkers);
  window.addEventListener('resize', this.initMarkers);
  document.addEventListener('splitScreenResize', this.initMarkers);

  if (self.getAttribute('data-js-hotspot-editable') === 'true') {
    initDropzones();
    imageWrapper.addEventListener('click', handleImageWrapperClick);
    [...markers].forEach((marker) => marker.addEventListener('click', debounce(handleMarkerClick, 10)));
    [...markers].forEach((marker) => handleDrag(marker));
    [...placeholders].forEach((placeholder) => placeholder.addEventListener('click', handlePlaceholderClick));
  }
}, function () {
  window.removeEventListener('resize', this.initMarkers);
  document.removeEventListener('splitScreenResize', this.initMarkers);
});
