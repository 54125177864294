/* global i18n, google */

import onmount from 'onmount';
import Rails from '@rails/ujs';
import { debounce } from '@modules/custom';

onmount('[data-js-login-chart]', function () {
  const { url } = this.dataset;
  const chartWrapper = this.querySelector('[data-js-chart]');

  function drawChart(data) {
    const dataTable = new google.visualization.DataTable();
    dataTable.addColumn('string', '');
    dataTable.addColumn('number', i18n.t('js.dashboard.login_activity.staff_logins'));
    dataTable.addColumn('number', i18n.t('js.dashboard.login_activity.student_logins'));
    dataTable.addRows(data);

    const options = {
      colors: ['#5830FF', '#3BBA98'],
      pointSize: 5,
      vAxis: {
        title: i18n.t('js.dashboard.login_activity.logins')
      },
      legend: {
        position: 'none'
      }
    };

    const chart = new (google.visualization.LineChart)(chartWrapper);
    chart.draw(dataTable, options);
  }

  function getChartData() {
    Rails.ajax({
      url,
      type: 'GET',
      dataType: 'json',
      success: (data) => drawChart(data.data)
    });
  }
  this.getChartData = debounce(getChartData, 250);

  google.charts.load('current', { packages: ['corechart'] });
  google.charts.setOnLoadCallback(getChartData);

  window.addEventListener('resize', this.getChartData);
}, function () {
  window.removeEventListener('resize', this.getChartData);
});
