import onmount from 'onmount';
import { throttle } from '@modules/custom';

onmount('[data-js-split-screen]', function () {
  this.handle = this.querySelector('[data-js-handle]');

  const leftPanel = this.querySelector('[data-js-left-panel]');
  const { x } = leftPanel.getBoundingClientRect();
  const handleWidth = this.querySelector('[data-js-handle]').offsetWidth;
  const minWidth = parseInt(leftPanel.dataset.jsMinWidth || 0, 10) + x;

  const dispatchResizeEvent = throttle(() => {
    const splitScreenResizeEvent = new CustomEvent('splitScreenResize');
    document.dispatchEvent(splitScreenResizeEvent);
  }, 25);

  function maxWidth() {
    return window.innerWidth - 100;
  }

  function handlepointerMove(e) {
    if (e.isPrimary === false) return;
    if (e.clientX < minWidth) return;
    if (e.clientX > maxWidth()) return;

    leftPanel.style.width = `${e.clientX - x - (handleWidth / 2)}px`;
    dispatchResizeEvent();
  }

  function handlepointerUp() {
    const iframe = document.querySelector('iframe');
    if (iframe) iframe.style.pointerEvents = 'auto';

    document.removeEventListener('pointermove', handlepointerMove);
    document.removeEventListener('pointerup', handlepointerUp);
  }

  function handlepointerDown() {
    const iframe = document.querySelector('iframe');
    if (iframe) iframe.style.pointerEvents = 'none';

    document.addEventListener('pointermove', handlepointerMove);
    document.addEventListener('pointerup', handlepointerUp);
  }
  this.handlepointerDown = handlepointerDown.bind(this);

  this.handle.addEventListener('pointerdown', this.handlepointerDown);
}, function () {
  this.handle.removeEventListener('pointerdown', this.handlepointerDown);
});
