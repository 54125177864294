import onmount from 'onmount';
import Rails from '@rails/ujs';

onmount('[data-js-filter-list]', function () {
  const links = this.querySelectorAll('[data-js-merge-query-params]');

  function handleLinkClick(e) {
    e.stopPropagation();
    e.preventDefault();

    const href = this.getAttribute('href');
    const url = new URL(href);

    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.forEach((value, param) => {
      if (param === 'items' || param === 'page' || !url.searchParams.get(param)) {
        url.searchParams.set(param, value);
      }
    });

    if (this.getAttribute('data-remote') === 'true') {
      Rails.ajax({
        type: 'GET',
        dataType: 'script',
        url: url.toString()
      });
    } else {
      window.location.href = url.toString();
    }
  }

  links.forEach((link) => {
    link.addEventListener('click', handleLinkClick);
  });
});
