import onmount from 'onmount';
import { debounce, updateUrl } from '@modules/custom';
import Rails from '@rails/ujs';

onmount('[data-js-server-search-field]', function () {
  const self = this;
  const input = this.querySelector('input');
  const reset = this.querySelector('[data-js-reset]');
  const hasUrl = this.hasAttribute('data-url');
  let url = new URL(this.getAttribute('data-url') || window.location.href);

  function inputChange() {
    url = new URL(self.getAttribute('data-url') || window.location.href);
    const value = input.value.trim();

    if (value.length) {
      url.searchParams.set('search', value);
      if (reset) reset.classList.remove('d-none');
    } else {
      url.searchParams.delete('search');
      if (reset) reset.classList.add('d-none');
    }

    if (!hasUrl) updateUrl(url);

    Rails.ajax({
      url: url.toString(),
      type: 'GET',
      dataType: 'script'
    });
  }

  function resetSearch() {
    input.value = '';
    inputChange();
    input.focus();
  }

  const search = url.searchParams.get('search');
  if (search) input.value = search;

  input.addEventListener('input', debounce(inputChange, 250));
  input.addEventListener('paste', debounce(inputChange, 250));

  if (reset) reset.addEventListener('click', resetSearch);
});
