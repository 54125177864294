import onmount from 'onmount';

onmount('[data-js-readspeaker]', () => {
  const displayReadspeakerDefault = true;
  const displayReadspeaker = document.querySelector('[data-js-display-readspeaker]');
  const readspeakerButtons = document.querySelector('[data-js-readspeaker-buttons]');
  const tables = document.querySelectorAll('table');

  let showReadspeaker;

  function updateDisplayReadspeaker(display) {
    displayReadspeaker.checked = display;
    if (display) {
      readspeakerButtons.classList.remove('d-none');
      tables.forEach((table) => table.classList.add('RS_CONTENT'));
    } else {
      readspeakerButtons.classList.add('d-none');
    }
  }

  function setDisplayReadspeaker() {
    if (localStorage.getItem('show-readspeaker')) {
      showReadspeaker = localStorage.getItem('show-readspeaker') === 'true';
    } else {
      showReadspeaker = displayReadspeakerDefault;
    }

    updateDisplayReadspeaker(showReadspeaker);
  }

  function handleDisplayReadspeakerClick() {
    localStorage.setItem('show-readspeaker', displayReadspeaker.checked);
    updateDisplayReadspeaker(displayReadspeaker.checked);
  }

  setDisplayReadspeaker();
  displayReadspeaker.addEventListener('click', handleDisplayReadspeakerClick);
});
