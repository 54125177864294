import onmount from 'onmount';
import { MDCSelect } from '@material-overwrites/mdc.select';

onmount('[data-js-copy-question-list]', function () {
  const self = this;

  const assignmentSelect = this.querySelector('[data-js-assignment-select]');
  if (assignmentSelect && !assignmentSelect.MDCSelect) {
    assignmentSelect.MDCSelect = new MDCSelect(assignmentSelect);
    assignmentSelect.setAttribute('data-mdc-auto-init-state', 'initialized');
  }

  function handleAssignmentSelect() {
    const selectedEl = this.MDCSelect.menuElement.querySelectorAll('li')[this.MDCSelect.selectedIndex];
    const questionsCount = parseInt(selectedEl.dataset.questionsCount, 10);
    const positionEl = self.querySelector('[data-js-position]');
    positionEl.value = questionsCount + 1;
  }

  if (assignmentSelect) assignmentSelect.MDCSelect.listen('MDCSelect:change', handleAssignmentSelect);
});
