/* global i18n, Channels, webpackOnmount */

import snackbar from '@components/snackbar';
import { renderKatex } from '@vendor/katex';
import { renderCompiledResponse } from '@modules/quiz/compiling';
import { updateAnsweredSubmissions, updateFlaggedSubmission } from '@modules/quiz/navigating';
import consumer from './consumer';

function initSubscription(quizPanel) {
  const quizResponses = consumer.subscriptions.create(
    {
      channel: 'QuizResponsesChannel',
      result_id: quizPanel.dataset.resultId
    },
    {
      connected() {
        this.currentUserId = quizPanel.dataset.currentUserId;
      },

      lock(submissionId) {
        this.perform('lock', { submission_id: submissionId });
      },

      unlock(submissionId) {
        this.perform('unlock', { submission_id: submissionId });
      },

      received(data) {
        /*  START FUNCTIONS  */
        function lockFailed(subquestion) {
          subquestion.setAttribute('data-locked', true);
          document.activeElement.blur();
          const textarea = subquestion.querySelector('[data-js-redactor]');
          if (textarea) renderCompiledResponse.call(textarea);
          snackbar(i18n.t('js.quizzes.already_locked'));
        }

        function showLock(subquestion) {
          subquestion.setAttribute('data-locked', data.locked);
          const paginationItemSelector = `[data-js-go-to][data-submission-id='${data.submission_id}']`;
          const paginationItem = document.querySelector(paginationItemSelector);
          const lockedBy = subquestion.querySelector('[data-js-locked-by]');
          if (lockedBy) {
            lockedBy.classList.remove('d-none');
            lockedBy.querySelector('[data-js-locking-user-name]').innerText = data.locked_by;
          }
          if (paginationItem) paginationItem.classList.add('subquestion-selector-item--locked');
        }

        function hideLock(subquestion) {
          subquestion.removeAttribute('data-locked');
          const paginationItemSelector = `[data-js-go-to][data-submission-id='${data.submission_id}']`;
          const paginationItem = document.querySelector(paginationItemSelector);
          const lockedBy = subquestion.querySelector('[data-js-locked-by]');

          if (lockedBy) {
            lockedBy.classList.add('d-none');
            lockedBy.querySelector('[data-js-locking-user-name]').innerText = '';
          }
          if (paginationItem) paginationItem.classList.remove('subquestion-selector-item--locked');
        }

        function replaceForm(subquestion) {
          const div = document.createElement('div');
          div.innerHTML = data.template;
          subquestion.querySelector('[data-js-submission-form]').innerHTML = div.querySelector('form').innerHTML;
          webpackOnmount();
        }

        function addEquation(subquestion) {
          subquestion.querySelector('[data-js-equations]').insertAdjacentHTML('beforeend', data.content);
          renderKatex(subquestion);
        }

        function updateEquation(subquestion) {
          const response = subquestion.querySelector(`[data-js-response][data-response-id="${data.response_id}"]`);
          if (response) response.outerHTML = data.content;
          renderKatex(subquestion);
        }

        function deleteEquation(subquestion) {
          const response = subquestion.querySelector(`[data-js-response][data-response-id='${data.response_id}']`);
          if (response) response.remove();
        }
        /*  END FUNCTIONS  */

        const subquestionSelector = `[data-js-quiz-subquestion][data-submission-id='${data.submission_id}']`;
        const subquestion = document.querySelector(subquestionSelector);

        if (data.user_id.toString() === this.currentUserId.toString() && data.action_type === 'lock_failed') {
          lockFailed(subquestion, data);
          return;
        }
        if (data.user_id.toString() === this.currentUserId.toString()) {
          return;
        }

        // Don't update if subquestion is not in current page
        if (!subquestion) return;

        if (data.action_type === 'lock') {
          showLock(subquestion);
          return;
        }

        if (data.action_type === 'unlock') {
          hideLock(subquestion);
          return;
        }

        if (data.action_type === 'flag') {
          updateFlaggedSubmission(data.submission_id, data.flagged);
          return;
        }

        // Save current focused element
        const currentFocus = document.activeElement;

        switch (data.category) {
          case 'equation': {
            switch (data.action_type) {
              case 'equation:destroyed':
                deleteEquation(subquestion);
                break;
              case 'equation:updated':
                updateEquation(subquestion);
                break;
              case 'equation:created':
                addEquation(subquestion);
                break;
              default:
                break;
            }
            break;
          }
          default:
            replaceForm(subquestion);
            break;
        }

        updateAnsweredSubmissions(data);

        // Set focus back
        currentFocus.focus();
      }
    }
  );
  Channels.quizResponses = quizResponses;
}

document.addEventListener('DOMContentLoaded', () => {
  const quizPage = window.location.href.includes('digital_test');
  const quizPanel = document.querySelector('[data-js-quiz-panel]');
  if (quizPage && quizPanel && quizPanel.getAttribute('data-group-assignment') === 'true') {
    initSubscription(quizPanel);
  }
});
