/* global Redactor, myLazyLoad */

import Rails from '@rails/ujs';

(function ($R) {
  $R.add('plugin', 'imagemanager', {
    translations: {
      en: {
        choose: 'Choose'
      }
    },
    init(app) {
      this.app = app;
      this.lang = app.lang;
      this.opts = app.opts;
      this.opts.imageManagerUrl = null;

      if (typeof this.opts.imageManagerJson === 'function') {
        this.opts.imageManagerUrl = this.opts.imageManagerJson(this);
      } else {
        this.opts.imageManagerUrl = this.opts.imageManagerJson;
      }
    },

    // messages
    onmodal: {
      image: {
        open($modal) {
          if (!this.opts.imageManagerUrl) return;

          this.load($modal);
        }
      }
    },

    // private
    load($modal) {
      const $body = $modal.getBody();

      this.$box = $R.dom('<div>');
      this.$box.attr('data-title', this.lang.get('choose'));
      this.$box.addClass('redactor-modal-tab');
      this.$box.hide();
      this.$box.css({
        overflow: 'auto',
        height: '300px',
        'line-height': 1
      });

      $body.append(this.$box);

      Rails.ajax({
        type: 'GET',
        url: this.opts.imageManagerUrl,
        success: (res) => this.parse(res)
      });
    },
    parse(data) {
      const $wrapper = $R.dom('<div>');
      $wrapper.addClass('d-flex flex-wrap');
      this.$box.append($wrapper);

      data.forEach((obj) => {
        const $div = $R.dom('<div>');
        $div.addClass('d-flex flex-items-center flex-justify-center');
        $div.css({
          width: '242px',
          height: '100px',
          margin: '0 10px 10px 0'
        });

        const $img = $R.dom('<img>');
        const url = obj.download_url;

        const objData = {
          id: obj.id,
          url: obj.download_url,
          title: obj.file_name
        };

        $img.attr('src', '');
        $img.data('src', url);
        $img.addClass('responsive-img lazy');
        $img.attr('data-params', encodeURI(JSON.stringify(objData)));
        $img.css({
          cursor: 'pointer',
          'max-height': '100px'
        });

        $img.on('click', this.insert.bind(this));

        $div.append($img);
        $wrapper.append($div);
      });
      myLazyLoad.update();
    },
    insert(e) {
      e.preventDefault();

      const $el = $R.dom(e.target);
      const data = JSON.parse(decodeURI($el.attr('data-params')));

      this.app.api('module.image.insert', { image: data });
    }
  });
}(Redactor));
