/* global Channels */

import onmount from 'onmount';
import consumer from '../consumer';

onmount('[data-js-results-chat-channel]', function () {
  const self = this;
  const btn = this.querySelector('[data-js-chat-button]');
  const menu = this.querySelector('.mdc-menu');
  const messagePreview = this.querySelector('[data-js-message-preview]');

  function showMessagePreview(html) {
    messagePreview.innerHTML = html;

    const openEvent = new CustomEvent('menu:open');
    menu.dispatchEvent(openEvent);

    btn.classList.add('indicator');
  }

  const chatResponses = consumer.subscriptions.create({
    channel: 'Results::ChatChannel',
    result_id: this.dataset.resultId
  }, {
    received(data) {
      const panel = document.querySelector('[data-js-chat-panel]');
      if (panel) {
        const chatEvent = new CustomEvent('chat:message', { detail: [data] });
        panel.dispatchEvent(chatEvent);

        if (!data.read_by_student) this.perform('mark_chat_as_read', { result_id: self.dataset.resultId });
      } else {
        showMessagePreview(data.html);
      }
    }
  });

  Channels.chatResponses = chatResponses;
}, () => {
  Channels.chatResponses.unsubscribe();
});
