import { Controller } from '@hotwired/stimulus';
import { activeRequests } from '@modules/quiz/wait-for-saving';

export default class extends Controller {
  wait(e) {
    if (activeRequests.call(this.element)) {
      e.preventDefault();
      e.stopPropagation();
    }
  }
}
