/* global i18n */

import onmount from 'onmount';
import snackbar from '@components/snackbar';

onmount('[data-js-quiz-feedback]', function () {
  const quizPanel = this.closest('[data-js-quiz-panel]');
  const subquestion = this.closest('[data-js-quiz-subquestion]');
  let mdcSnackbar;

  function showSnackbar() {
    if (!mdcSnackbar || !mdcSnackbar.isOpen) {
      mdcSnackbar = snackbar(i18n.t('js.quizzes.saving_in_progress'), true);
    }
  }

  function waitForRequests() {
    showSnackbar();

    if (quizPanel.requestCount === 0) {
      if (mdcSnackbar) mdcSnackbar.close();
      this.click();
    } else {
      setTimeout(waitForRequests.bind(this), 250);
    }
  }

  function waitForUnsavedForm() {
    showSnackbar();

    if (!subquestion.querySelector('form[data-saved="false"]')) {
      if (mdcSnackbar) mdcSnackbar.close();
      this.click();
    } else {
      setTimeout(waitForUnsavedForm.bind(this), 250);
    }
  }

  function activeRequests() {
    if (quizPanel.requestCount > 0) {
      setTimeout(waitForRequests.bind(this), 250);
      return true;
    }

    if (subquestion.querySelector('form[data-saved="false"]')) {
      setTimeout(waitForUnsavedForm.bind(this), 250);
      return true;
    }
    return false;
  }

  function handleFeedbackButtonClick(e) {
    if (activeRequests.call(this)) {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  this.addEventListener('click', handleFeedbackButtonClick);
});
