import onmount from 'onmount';
import { formatTime } from '@modules/format-time';

onmount('[data-js-countdown]', function () {
  const self = this;
  const { remainingSeconds } = self.dataset;
  let secondsUntilStart;

  function currentTime() {
    return Date.now() / 1000;
  }

  const startTime = currentTime() + parseFloat(remainingSeconds);

  function endTimer() {
    window.location.reload();
  }

  function resyncTimer() {
    secondsUntilStart = startTime - currentTime();
  }

  function updateTimer() {
    resyncTimer();

    if (secondsUntilStart <= 0) {
      endTimer();
      return;
    }

    const formattedTime = formatTime(secondsUntilStart, false);
    if (self.innerText !== formattedTime) {
      self.innerText = formattedTime;
    }

    self.animationFrameId = requestAnimationFrame(updateTimer);
  }

  if (self.hasAttribute('data-remaining-seconds')) {
    resyncTimer();
    this.animationFrameId = requestAnimationFrame(updateTimer);
  }
}, function () {
  window.cancelAnimationFrame(this.animationFrameId);
});
