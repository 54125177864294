export class Component {
  constructor(id, origin, strokeColor, strokeWidth) {
    this.id = id;
    this.origin = origin;
    this.strokeColor = strokeColor;
    this.strokeWidth = strokeWidth;
    this.transform = { x: 0, y: 0 };
  }

  init() {
    this.element.setAttribute('id', this.id);
    this.element.setAttribute('stroke', this.strokeColor);
    this.element.setAttribute('stroke-width', this.strokeWidth);
  }

  updateStyling() {
    this.element.setAttribute('stroke', this.strokeColor);
    this.element.setAttribute('stroke-width', this.strokeWidth);
  }

  isEmpty() {
    return false;
  }
}
