const choiceMargins = {
  top: 2,
  bottom: 6
};

function clearExistingIndicators(PDFDOM, currentPage) {
  PDFDOM.querySelectorAll(`.page[data-page-number='${currentPage}'] .textLayer .indicator`).forEach((indicator) => {
    indicator.remove();
  });
}

function rotateCoordinates(rotate, startX, startY, endY, elWidth, pageWidth, pageHeight) {
  let top;
  let left;
  let width;
  let height;

  switch (rotate) {
    case 0:
      top = startY;
      left = startX;
      width = elWidth;
      height = endY - startY;
      break;

    case 90:
      top = startX;
      left = pageHeight - (endY - startY) - startY;
      height = elWidth;
      width = endY - startY;
      break;

    case 180:
      top = pageHeight - (endY - startY) - startY;
      left = startX;
      width = elWidth;
      height = endY - startY;
      break;

    case 270:
      top = pageWidth - elWidth - startX;
      left = startY;
      height = elWidth;
      width = endY - startY;
      break;
    default:
      break;
  }

  return {
    top,
    left,
    height,
    width
  };
}

function indicatorClass(PDFDOM) {
  let klass = 'indicator';

  if (PDFDOM.querySelector('#annotate .active') &&
      !PDFDOM.querySelector('#annotate .active').classList.contains('cursor')) {
    klass += ' disable-hover';
  }

  return klass;
}

function uniquePages(pages) {
  return pages.filter((item, i, ar) => ar.indexOf(item) === i);
}

function url(id) {
  const reviewIds = document.querySelector('[data-js-review-ids]');
  if (!reviewIds) return '';

  const {
    universityId, courseId, assignmentId, resultId
  } = reviewIds.dataset;
  if (!assignmentId) return '';

  const uri = new URL(window.location.href);
  const requestUri = new URL(`${window.location.origin}/universities/${universityId}/courses/${courseId}/assignments/${assignmentId}/grading/go_to_subquestion?result_id=${resultId}&subquestion_id=${id}`); // eslint-disable-line max-len

  if (uri.searchParams.get('nav')) {
    requestUri.searchParams.set('nav', uri.searchParams.get('nav'));
  }

  if (uri.searchParams.get('mode')) {
    requestUri.searchParams.set('mode', uri.searchParams.get('mode'));
  }

  if (uri.searchParams.get('skip')) {
    requestUri.searchParams.set('skip', uri.searchParams.get('skip'));
  }

  if (uri.searchParams.get('groups')) {
    requestUri.searchParams.set('groups', uri.searchParams.get('groups'));
  }

  if (uri.searchParams.get('without_group')) {
    requestUri.searchParams.set('without_group', uri.searchParams.get('without_group'));
  }

  if (uri.searchParams.get('without_klass')) {
    requestUri.searchParams.set('without_klass', uri.searchParams.get('without_klass'));
  }
  return requestUri.toString();
}

function addIndicatorToTextLayer(PDFDOM, page, id, coordinates, cb) {
  const textLayer = PDFDOM.querySelector(`.page[data-page-number='${page}'] .textLayer`);
  if (!textLayer) return;

  const p = document.createElement('p');
  p.className = indicatorClass(PDFDOM);
  p.dataset.id = id;
  p.dataset.url = url(id);
  p.style.top = `${coordinates.top}px`;
  p.style.left = `${coordinates.left}px`;
  p.style.height = `${coordinates.height}px`;
  p.style.width = `${coordinates.width}px`;

  if (typeof cb === 'function') {
    p.addEventListener('click', cb);
  }

  textLayer.append(p);
}

function renderBubbleSheetChoiceIndicator(PDFDOM, subquestion, pageOffset, rotate, pageHeight, pageWidth, cb) {
  const startY = subquestion.position_start[0] * (pageHeight / 100);
  const endY = subquestion.position_end[0] * (pageHeight / 100);
  const startX = subquestion.position_start[1] * (pageWidth / 100);
  const endX = subquestion.position_start[subquestion.position_start.length - 1] * (pageWidth / 100);
  const coordinates = rotateCoordinates(
    rotate,
    startX,
    startY - choiceMargins.top,
    endY + choiceMargins.bottom,
    endX - startX,
    pageWidth,
    pageHeight
  );

  return addIndicatorToTextLayer(PDFDOM, subquestion.page[0] + pageOffset, subquestion.subquestion_id, coordinates, cb);
}

function renderChoiceIndicator(PDFDOM, subquestion, pageOffset, rotate, pageHeight, pageWidth, cb, withCertainty) {
  const startY = subquestion.position_start[0] * (pageHeight / 100);
  const endPosition = withCertainty ? subquestion.certainty_level_position_end[0] : subquestion.position_end[0];
  const endY = endPosition * (pageHeight / 100);
  const startX = pageWidth * 0.079;
  const width = 0.84 * pageWidth;
  const coordinates = rotateCoordinates(
    rotate,
    startX,
    startY - choiceMargins.top,
    endY + choiceMargins.bottom,
    width,
    pageWidth,
    pageHeight
  );

  return addIndicatorToTextLayer(PDFDOM, subquestion.page[0] + pageOffset, subquestion.subquestion_id, coordinates, cb);
}

function renderBoxIndicator(PDFDOM, subquestion, currentPage, pageOffset, rotate, pageHeight, pageWidth, cb) {
  const startX = pageWidth * 0.079;
  const width = 0.84 * pageWidth;
  const unique = uniquePages(subquestion.page);
  const results = [];
  for (let iter = 0; iter < unique.length; iter += 1) {
    const page = unique[iter];

    if (page === currentPage - pageOffset) {
      const startY = subquestion.position_start[iter] * (pageHeight / 100);
      const endY = subquestion.position_end[iter] * (pageHeight / 100);
      const coordinates = rotateCoordinates(rotate, startX, startY, endY, width, pageWidth, pageHeight);

      results.push(addIndicatorToTextLayer(PDFDOM, page + pageOffset, subquestion.subquestion_id, coordinates, cb));
    }
  }

  return results;
}

export default function renderIndicators(iframe, currentPage, data, pageOffset, cb) {
  const PDFDOM = iframe.contentDocument;
  const { PDFViewerApplication } = iframe.contentWindow;
  const rotate = PDFViewerApplication.pdfViewer._pagesRotation; // eslint-disable-line no-underscore-dangle
  const pageEl = PDFDOM.querySelector('.page');
  if (!pageEl) return [];

  let pageHeight;
  let pageWidth;

  clearExistingIndicators(PDFDOM, currentPage);

  if (rotate % 180 === 0) {
    pageHeight = pageEl.clientHeight;
    pageWidth = pageEl.clientWidth;
  } else {
    pageHeight = pageEl.clientWidth;
    pageWidth = pageEl.clientHeight;
  }
  const results = [];

  for (let i = 0; i < data.length; i += 1) {
    const subquestion = data[i];
    const multipleChoice = subquestion.position_start.length > 1 && uniquePages(subquestion.page).length === 1;
    const bubbleSheet = iframe.dataset.assignmentType === 'BubbleSheet';
    const withCertainty = subquestion.certainty_level_position_end.length > 1;

    if (multipleChoice && bubbleSheet) {
      results.push(
        renderBubbleSheetChoiceIndicator(PDFDOM, subquestion, pageOffset, rotate, pageHeight, pageWidth, cb)
      );
    } else if (multipleChoice) {
      results.push(
        renderChoiceIndicator(PDFDOM, subquestion, pageOffset, rotate, pageHeight, pageWidth, cb, withCertainty)
      );
    } else {
      results.push(
        renderBoxIndicator(PDFDOM, subquestion, currentPage, pageOffset, rotate, pageHeight, pageWidth, cb)
      );
    }
  }

  return results;
}
