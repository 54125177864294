/* eslint-disable */
import onmount from 'onmount';

onmount('[data-js-stepper]', function () {
  const steps = this.querySelectorAll('[data-js-step]');

  function invalidInput(step) {
    return [...step.querySelectorAll('input')].some((input) => !input.reportValidity());
  }

  function nextClick(e) {
    const currentStep = e.target.closest('[data-js-step]');
    nextStep(currentStep);
  }

  function nextStep(currentStep) {
     if (invalidInput(currentStep)) return;

    currentStep.classList.add('d-none');
    currentStep.nextElementSibling.classList.remove('d-none');
  }

  function handleKeyDown(e) {
    if (e.key !== 'Enter') return;

    const currentStep = document.querySelector('[data-js-step]:not(.d-none)');
    if (currentStep && currentStep.querySelector('[data-js-next]')) {
      nextStep(currentStep);
    }
  }
  this.handleKeyDown = handleKeyDown;

  this.querySelectorAll('[data-js-next]').forEach((btn) => btn.addEventListener('click', nextClick));
  document.addEventListener('keydown', this.handleKeyDown);
}, function () {
  document.removeEventListener('keydown', this.handleKeyDown);
});
