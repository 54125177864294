import onmount from 'onmount';
import { MDCDialog } from '@material/dialog';

onmount('[data-js-grading-header]', function () {
  const settingsDialog = document.querySelector('[data-js-grading-settings]');
  if (settingsDialog && !settingsDialog.MDCDialog) {
    settingsDialog.MDCDialog = new MDCDialog(settingsDialog);
    settingsDialog.setAttribute('data-mdc-auto-init-state', 'initialized');
  }

  const settingsTrigger = this.querySelector('[data-js-grading-settings-trigger]');

  function handleSettingsTriggerClick() {
    settingsDialog.MDCDialog.open();
  }

  if (settingsTrigger) settingsTrigger.addEventListener('click', handleSettingsTriggerClick);
});
