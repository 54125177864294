import onmount from 'onmount';
import Rails from '@rails/ujs';
import Fuse from 'fuse.js';
import { debounce, escapeHtml } from '@modules/custom';
import { arrowNavigation } from '@modules/list-arrow-navigation';

onmount('[data-js-autocomplete]', function () {
  const url = new URL(this.dataset.url);
  const input = this.querySelector("input[type='text']");
  const collection = this.querySelector('[data-js-collection]');
  const { loadEvent } = this.dataset;
  const options = {
    isCaseSensitive: false,
    threshold: 0.1,
    minMatchCharLength: 1,
    ignoreLocation: true,
    keys: ['name']
  };

  let search;

  function handleSearchBlur() {
    setTimeout(() => {
      collection.classList.add('d-none');
    }, 250);
  }

  function handleOptionClick(e) {
    let item = e.target;
    if (item.classList.contains('mdc-deprecated-list-item__text')) item = item.closest('.mdc-deprecated-list-item');

    const { name } = item.dataset;
    const component = input.closest('.mdc-text-field').MDCTextField;

    component.value = name;
  }

  function renderItem(result) {
    const item = `<li class='mdc-deprecated-list-item' data-name='${escapeHtml(result.name)}'>
                    <div class='mdc-deprecated-list-item__ripple'></div>
                    <span class='mdc-deprecated-list-item__text'>${escapeHtml(result.name)}</span>
                  </li>`;
    collection.insertAdjacentHTML('beforeend', item);
  }

  function renderSearchResults(results) {
    results.forEach((result) => renderItem(result));

    const firstItem = collection.querySelector('.mdc-deprecated-list-item');
    if (firstItem) firstItem.classList.add('mdc-deprecated-list-item--focused');
  }

  function inputChange() {
    collection.innerHTML = '';

    let results;
    if (input.value.trim().length > 0) {
      results = search.fuse.search(input.value).map((x) => x.item);
    } else {
      results = search.fuse._docs; // eslint-disable-line no-underscore-dangle
    }

    if (results.length === 0) {
      collection.classList.add('d-none');
    } else {
      renderSearchResults(results);
      collection.classList.remove('d-none');
    }
  }

  function setSearchParams(params) {
    Object.entries(params).forEach(([key, value]) => {
      if (value) {
        url.searchParams.set(key, value);
      } else {
        url.searchParams.delete(key);
      }
    });
  }

  function getData(e = null) {
    if (loadEvent) setSearchParams(e.detail);

    Rails.ajax({
      url,
      type: 'GET',
      dataType: 'json',
      success: (response) => {
        search = { fuse: new Fuse(response, options) };
        if (loadEvent) inputChange();

        ['focus', 'input', 'paste'].forEach((evt) => input.addEventListener(evt, debounce(inputChange, 250)));
        input.addEventListener('blur', handleSearchBlur);

        collection.addEventListener('click', handleOptionClick);
      }
    });
  }

  this.addEventListener('keydown', arrowNavigation.bind(collection));
  if (loadEvent) {
    this.addEventListener(loadEvent, getData);
  } else {
    getData();
  }
});
