import onmount from 'onmount';

const changeEvent = document.createEvent('Event');

onmount("[data-js-quiz-panel] input[type='radio']", function () {
  const self = this;

  function unCheckRadio() {
    self.checked = false;
    changeEvent.initEvent('change', false, true);
    self.dispatchEvent(changeEvent);
  }

  function handleMouseUp() {
    if (self.checked) setTimeout(unCheckRadio, 250);
  }

  self.addEventListener('mouseup', handleMouseUp);
});
