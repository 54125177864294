import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['marginValue'];

  setMarginMax(e) {
    if (e.target.value === 'absolute') {
      this.marginValueTarget.numeric.update({ maximumValue: 99999.99999 });
    } else {
      if (this.marginValueTarget.value > 100) this.marginValueTarget.numeric.set(0);
      this.marginValueTarget.numeric.update({ maximumValue: 100 });
    }
  }
}
