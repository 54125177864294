/* eslint-disable */

!function() {
  var b = 0;
  var c = {};
  var wrapper = null;
  var d = {
    defaults: {
      file: '',
      style: {
        strokeWidth: '1',
        strokeOpacity: '1',
        fillOpacity: '0.5',
        cursor: 'pointer'
      },
      pointStyle: {
        fill: '#EA03B3',
        stroke: '#EA03B3',
        strokeWidth: '1',
        opacity: '1',
        cursor: 'pointer'
      },
      event: {
        init: function() {},
        update: function() {},
        addArea: function() {},
        removeArea: function() {}
      },
      input: {
        active: {
          selector: 'input[name^="subquestion["][name$="][active]"]',
          fn: function() {
            var parent = this.closest('[data-js-hotspot-areas]')
            return [[...parent.querySelectorAll('[data-js-hotspot-area]:not(.d-none)')].indexOf(this.closest('[data-js-hotspot-area]')), 'active']
          }
        },
        shape: {
          selector: 'input[name^="subquestion["][name$="][shape]"]',
          fn: function() {
            var parent = this.closest('[data-js-hotspot-areas]')
            return [[...parent.querySelectorAll('[data-js-hotspot-area]:not(.d-none)')].indexOf(this.closest('[data-js-hotspot-area]')), 'shape']
          }
        },
        color: {
          selector: 'input[name^="subquestion["][name$="][color]"]',
          fn: function() {
            var parent = this.closest('[data-js-hotspot-areas]')
            return [[...parent.querySelectorAll('[data-js-hotspot-area]:not(.d-none)')].indexOf(this.closest('[data-js-hotspot-area]')), 'color']
          }
        }      }
    },
    init: function(e, options) {
      var g = this
      var imageWrapper = this.closest('#image-map-wrapper')
      var areaWrapper = imageWrapper.parentElement.querySelector('[data-js-hotspot-areas]')
      var options = options || {};
      if (!e) {
        options = Object.assign({}, d.defaults, options),
        c[b] = {
          state: {
            isLoaded: true,
            areaIndex: null,
            areaLength: 0
          },
          areas: [],
          options: options,
          image: imageWrapper,
          area: areaWrapper
        },
        e = b,
        this.setAttribute('data-image-mapper', b++),
        d.bindEvents.apply(this, [e]),
        d.bindInputs.apply(this, [e])
        d.initAreas(this, e),
        typeof c[e].options.event.init === 'function' && c[e].options.event.init.apply(this)
        d.bindValues(e)
      }
    },
    update: function(b, options) {
      var imageWrapper = this.closest('#image-map-wrapper')
      var areaWrapper = imageWrapper.parentElement.querySelector('[data-js-hotspot-areas]')
      var options = options || {};
      if (b >= 0) {
        options = Object.assign({}, c[b].options, options),
        c[b] = {
          state: {
            isLoaded: true,
            areaIndex: null,
            areaLength: 0
          },
          areas: [],
          options: options,
          image: imageWrapper,
          area: areaWrapper
        };
        var h = this.querySelector('.image-mapper-img');
        var collectionItem = this.closest('form').querySelector('[data-js-hotspot-areas] [data-js-hotspot-area]:first-child');
        var color = collectionItem.querySelector('[data-js-hotspot-area-color]').MDCSelect.value;
        d.addArea(b, 'rect', color, collectionItem);
        d.refresh.apply(this, [b]);
        typeof c[b].options.event.update === 'function' && c[b].options.event.update.apply(this);
      }
    },
    bindEvents: function(b) {
      var e = this;

      window.addEventListener('resize', function() {
        d.refresh.apply(e, [b])
      });

      document.addEventListener('splitScreenResize', function() {
        d.refresh.apply(e, [b])
      });

      this.addEventListener('click', function(a) {
        var f = d.getPosition.apply(e, [b, a]);
        d.addPoint.apply(e, [b, f]),
        'function' === typeof c[b].options.event.update && c[b].options.event.update.apply(e)
      });

      var activeArea, h;
      function handleAreaMouseMove(f) {
        if (!activeArea) {
          return !0;
        }
        var i = typeof f.touches != 'undefined' || false;
        var j = d.getPosition.apply(e, [b, f, i]);
        var k = d.getClientPosition.apply(e, [b, j]);
        var l = d.getRatio.apply(e);
        var m = parseInt(activeArea.dataset.areaIndex, 10);
        if (typeof m !== 'number' || isNaN(m)) {
          return;
        }

        var n = e.querySelector('.image-mapper-img');
        var coords = [];
        var p = false;
        c[b].areas[m].coords.forEach(function(b, a) {
          var c = {
            naturalX: b.naturalX + Math.round((k.clientX - h.clientX) * l.ratioX),
            naturalY: b.naturalY + Math.round((k.clientY - h.clientY) * l.ratioY)
          };
          if (c.naturalX < 0 || c.naturalX >= n.naturalWidth) {
            p = true
          } else if (c.naturalY < 0 || c.naturalY >= n.naturalHeight) {
            p = true
          }
          coords[a] = c
        })

        if (p || (c[b].areas[m].coords = coords)) {
          d.refresh.apply(e, [b])
        }
        h = k,
        f.preventDefault(),
        f.stopImmediatePropagation()
      };
      this.addEventListener('mousemove', handleAreaMouseMove);
      this.addEventListener('touchmove', handleAreaMouseMove);

      function handleAreaMouseUp(e) {
        var b = typeof e.touches != 'undefined' || false;
        if (activeArea && (e.button === 0 || b)) {
          activeArea = false;
        };
      };
      this.addEventListener('mouseup', handleAreaMouseUp);
      this.addEventListener('touchend', handleAreaMouseUp);
      this.addEventListener('mouseleave', handleAreaMouseUp);
      this.addEventListener('touchleave', handleAreaMouseUp);

      function handleAreaMouseDown(event) {
        var f = typeof event.touches != 'undefined' || false;
        if (event.target.classList.contains('image-mapper-shape') && event.button === 0 || f) {
          var i = d.getPosition.apply(e, [b, event, f]);
          var j = d.getClientPosition.apply(e, [b, i]);
          activeArea = event.target;
          var areaIndex = parseInt(activeArea.dataset.areaIndex, 10);
          h = j;

          if (typeof c[b].state.areaIndex !== 'number') {
            d.startEdit.apply(e, [b, areaIndex]);
          }
        }
      };
      this.addEventListener('mousedown', handleAreaMouseDown);
      this.addEventListener('touchstart', handleAreaMouseDown);

      var activePoint;
      function handlePointMouseMove(a) {
        if (!activePoint) {
          return true;
        }
        var f = typeof a.touches != 'undefined' || false
        var g = d.getPosition.apply(e, [b, a, f]);
        var h = d.getClientPosition.apply(e, [b, g]);
        c[b].areas[activePoint.dataset.areaIndex].coords[activePoint.dataset.coordIndex] = g;
        activePoint.setAttribute('cx', h.clientX);
        activePoint.setAttribute('cy', h.clientY);
        d.renderSVG.apply(e, [b]);
        d.saveCoords.apply(e, [b]);
        a.preventDefault();
        a.stopImmediatePropagation()
      };
      this.addEventListener('mousemove', handlePointMouseMove)
      this.addEventListener('touchmove', handlePointMouseMove)

      function handlePointMouseUp(a) {
        var b = typeof a.touches != 'undefined' || false;
        if (activePoint && (a.button === 0 || b)) {
          activePoint = false;
        }
      };
      this.addEventListener('mouseup', handlePointMouseUp);
      this.addEventListener('touchend', handlePointMouseUp);
      this.addEventListener('mouseleave', handlePointMouseUp);
      this.addEventListener('touchleave', handlePointMouseUp);

      // Select point
      function handlePointMouseDown(b) {
        var c = typeof b.touches != 'undefined' || false;
        if (event.target.classList.contains('image-mapper-point') && b.button === 0 || c) {
          activePoint = b.target;
        };
      };

      this.addEventListener('mousedown', handlePointMouseDown);
      this.addEventListener('touchstart', handlePointMouseDown);
      this.addEventListener('click', function(a) {
        if (event.target.classList.contains('image-mapper-point')) {
          a.preventDefault();
          a.stopImmediatePropagation();
        }
      });

      // Remove one point
      function handlePointDelete(f) {
        if (event.target.classList.contains('image-mapper-point') && f.button === 2) {
          c[b].areas[f.target.dataset.areaIndex].coords.splice(f.target.dataset.coordIndex, 1);
          d.refresh.apply(e, [b]);
        }
      };
      this.addEventListener('mouseup', handlePointDelete);
      this.addEventListener('touchend', handlePointDelete);

      // Cancel edit mode
      function handleCancelMouseDown(f) {
        if (f.button === 0) {
          d.cancelEdit.apply(e, [b]);
          this.classList.add('mdc-button--disabled');
        }
      };
      var cancel = this.closest('#image-map-wrapper').querySelector('[data-js-cancel-edit]')
      cancel.addEventListener('mousedown', handleCancelMouseDown);
      cancel.addEventListener('touchstart', handleCancelMouseDown);

      this.addEventListener('contextmenu', function(a) {
        a.preventDefault()
      })
    },
    bindValues: function(b) {
      Object.keys(c[b].options.input).forEach(function(key) {
        const e = c[b].options.input[key];
        [...wrapper.querySelectorAll(e.selector)].forEach(function(input) {
          var d = e.fn.apply(input);
          if (d[1] === 'active') {
            input.checked = d[0] === c[b].state.areaIndex;
          } else if (d[1] === 'shape') {
            input.closest('.mdc-select').MDCSelect.value = c[b].areas[d[0]][d[1]];
          } else if (d[1] === 'color') {
            input.closest('.mdc-select').MDCSelect.value = c[b].areas[d[0]][d[1]];
          }
        })
      })
    },
    bindInputs: function(b) {
      var e = this;
      Object.keys(c[b].options.input).forEach(function(key) {
        const g = c[b].options.input[key];

        wrapper.addEventListener('change', function(event) {
          if (!event.target.matches(g.selector)) return;

          var h = g.fn.apply(event.target);
          var value = event.target.value;
          if (h[1] === 'active') {
            [...wrapper.querySelectorAll(g.selector)].forEach(function(input) {
              input.checked = false;
            });
            d.startEdit.apply(e, [b, h[0]]);
            // c[b].state.areaIndex = h[0]
            // d.refresh.apply(e, [b]);
          } else if (h[1] === 'shape') {
            var area = c[b].areas[h[0]];
            if (area.shape === value) return;

            area.shape = value;
            area.el = false;
            area.coords = [];
            d.refresh.apply(e, [b]);
          } else if (h[1] === 'color') {
            var area = c[b].areas[h[0]];
            if (area.color === value) return;

            area.color = value;
            d.refresh.apply(e, [b]);
          }
        });
      })
    },
    getData: function(a) {
      return c[a]
    },
    getRatio: function() {
      var b = this.querySelector('.image-mapper-img');
      return {
        ratioX: b.naturalWidth / b.clientWidth,
        ratioY: b.naturalHeight / b.clientHeight
      }
    },
    getPosition: function(b, c, e) {
      var f = this.querySelector('.image-mapper-img');
      var g = f.getBoundingClientRect();
      var h = d.getRatio.apply(this, [b])
      var i = {
        naturalX: 0,
        naturalY: 0
      };
      if (e) {
        i.naturalX = Math.round((c.targetTouches[0].pageX - g.left) * h.ratioX)
        i.naturalY = Math.round((c.targetTouches[0].pageY - g.top) * h.ratioY)
      } else {
        i.naturalX = Math.round((c.clientX - g.left) * h.ratioX)
        i.naturalY = Math.round((c.clientY - g.top) * h.ratioY)
      }
      return i
    },
    getClientPosition: function(b, c) {
      var e = this.querySelector('.image-mapper-img');
      var f = d.getRatio.apply(this, [b]);
      var g = {
        clientX: 0,
        clientY: 0
      };
      g.clientX = Math.round(c.naturalX / f.ratioX)
      g.clientY = Math.round(c.naturalY / f.ratioY)
      return g
    },
    refresh: function(a) {
      d.renderSVG.apply(this, [a]),
      d.renderPoints.apply(this, [a])
      d.saveCoords.apply(this, [a])
    },
    addPoint: function(a, b) {
      d.addCoord(a, b),
      d.refresh.apply(this, [a])
    },
    initAreas: function(imageMap, b) {
      var areas = imageMap.closest('form').querySelectorAll('[data-js-hotspot-areas] [data-js-hotspot-area]');
      areas.forEach(function(area, index) {
        var shape = area.querySelector('[data-js-hotspot-area-shape]').MDCSelect.value;
        var color = area.querySelector('[data-js-hotspot-area-color]').MDCSelect.value;
        var coords = [];
        var coordsString = area.querySelector('[data-js-hotspot-area-coords]').value;
        if (coordsString.length) {
          var rawCoords = coordsString.split(',');
          if (shape === 'circle') {
            coords.push({
              naturalX: parseFloat(rawCoords[0]),
              naturalY: parseFloat(rawCoords[1])
            })
            coords.push({
              naturalX: parseFloat(rawCoords[0]),
              naturalY: parseFloat(rawCoords[1]) - parseFloat(rawCoords[2])
            })
          } else if (shape === 'poly') {
            rawCoords.forEach(function(rawCoord, index) {
              if (index % 2 === 0) {
                coords.push({
                  naturalX: parseFloat(rawCoord),
                  naturalY: parseFloat(rawCoords[index + 1])
                })
              }
            })
          } else {
            coords.push({
              naturalX: parseFloat(rawCoords[0]),
              naturalY: parseFloat(rawCoords[1])
            })
            coords.push({
              naturalX: parseFloat(rawCoords[2]),
              naturalY: parseFloat(rawCoords[3])
            })
          }
        };

        c[b].areas[c[b].state.areaLength] = {
          collectionItem: area,
          el: false,
          shape: shape,
          color: color,
          coords: coords
        }
        c[b].state.areaLength++
      });

      if (c[b].state.areaLength === 1) {
        d.startEdit.apply(imageMap, [b, 0])
      }

      d.refresh.apply(imageMap, [b])
    },
    addArea: function(b, shape, color, collectionItem) {
      b = this.dataset.imageMapper;
      if (arguments.length === 1) {
        shape = 'rect';
      };

      c[b].areas[c[b].state.areaLength] = {
        collectionItem: collectionItem,
        el: false,
        shape: shape,
        color: color,
        coords: []
      }
      c[b].state.areaLength += 1;
      [...wrapper.querySelectorAll(c[b].options.input.active.selector)].forEach(function(input) {
        input.checked = false;
      });
      d.startEdit.apply(this, [b, c[b].state.areaLength - 1]);
      typeof c[b].options.event.addArea === 'function' && c[b].options.event.addArea.apply(this)
    },
    removeArea: function(a, areaIndex) {
      c[a].areas.splice(areaIndex, 1)
      c[a].state.areaLength--
      d.cancelEdit.apply(this, [a])
      d.refresh.apply(this, [a])
      typeof c[a].options.event.removeArea === 'function' && c[a].options.event.removeArea.apply(this)
    },
    addCoord: function(a, b) {
      var d = c[a].state.areaIndex;
      if (typeof d !== 'number') {
        return;
      }
      var e = c[a].areas[d].shape;
      (['circle', 'rect'].indexOf(e) === -1 || c[a].areas[d].coords.length != 2) && c[a].areas[d].coords.push(b)
    },
    startEdit: function(b, areaIndex) {
      c[b].state.areaIndex = areaIndex;
      var active = c[b].areas[areaIndex].collectionItem.querySelector(c[b].options.input.active.selector);
      active.checked = true;
      c[b].image.querySelector('[data-js-cancel-edit]').classList.remove('mdc-button--disabled')
      c[b].image.querySelector('[data-js-cancel-edit]').innerText = i18n.t('js.hotspot.done_editing');
      d.refresh.apply(this, [b]);
    },
    cancelEdit: function(b) {
      c[b].state.areaIndex = null;
      [...c[b].area.querySelectorAll(c[b].options.input.active.selector)].forEach(function(input) {
        input.checked = false;
      });
      c[b].image.querySelector('[data-js-cancel-edit]').classList.add('mdc-button--disabled');
      c[b].image.querySelector('[data-js-cancel-edit]').innerText = i18n.t('js.hotspot.select_area');
      d.refresh.apply(this, [b]);
    },
    renderSVG: function(b) {
      var e = this
      var f = this.querySelector('.image-mapper-svg');
      f.style.width = '100%';
      while (f.querySelector('.image-mapper-shape')) {
        f.querySelector('.image-mapper-shape').remove();
      }
      c[b].areas.forEach(function(h, g) {
        var i, j = [];
        h.coords.forEach(function(c, a) {
          var f = d.getClientPosition.apply(e, [b, c]);
          j.push(f.clientX, f.clientY)
        });
        h.el && (i = h.el)
        if (h.shape === 'poly') {
          i || (i = document.createElementNS('http://www.w3.org/2000/svg', 'polygon'))
          i.setAttribute('points', j.join(','))
        } else if (h.shape === 'circle') {
          if (j.length >= 4) {
            i || (i = document.createElementNS('http://www.w3.org/2000/svg', 'circle'))
            i.setAttribute('cx', j[0]);
            i.setAttribute('cy', j[1]);
            i.setAttribute('r', Math.sqrt(Math.pow(j[2] - j[0], 2) + Math.pow(j[3] - j[1], 2)))
          }
        } else {
          if (j.length >= 4) {
            i || (i = document.createElementNS('http://www.w3.org/2000/svg', 'rect'))
            i.setAttribute('x', Math.min(j[0], j[2]));
            i.setAttribute('y', Math.min(j[1], j[3]));
            i.setAttribute('width', Math.abs(j[2] - j[0]));
            i.setAttribute('height', Math.abs(j[3] - j[1]));
          }
        }
        if (i) {
          i.setAttribute('class', `image-mapper-shape hotspot-area--${h.color}`);
          i.setAttribute('data-area-index', g);
          Object.keys(c[b].options.style).forEach(function(key) {
            i.style[key] = c[b].options.style[key];
          })
          f.prepend(i);
          c[b].areas[g].el = i
        }
      })
    },
    renderPoints: function(b) {
      var e = this
      var f = this.querySelector('.image-mapper-svg');
      while (f.querySelector('.image-mapper-point')) f.querySelector('.image-mapper-point').remove();
      var g = c[b].state.areaIndex;
      if (typeof g !== 'number') {
        return;
      }

      var h = c[b].areas[g];
      h.coords.forEach(function(i, h) {
        var j = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
        var k = d.getClientPosition.apply(e, [b, i]);
        j.setAttribute('cx', k.clientX);
        j.setAttribute('cy', k.clientY);
        j.setAttribute('r', 5);
        j.setAttribute('class', 'image-mapper-point');
        j.setAttribute('data-area-index', g);
        j.setAttribute('data-coord-index', h);
        Object.keys(c[b].options.pointStyle).forEach(function(key) {
          j.style[key] = c[b].options.pointStyle[key];
        })
        f.append(j)
      })
    },
    saveCoords: function(b) {
      const changeEvent = new CustomEvent('image-mapper:change');

      c[b].areas.forEach(function(e, d) {
        var f = [];
        e.coords.forEach(function(b, a) {
          f.push(b.naturalX, b.naturalY)
        })
        if (e.shape === 'circle') {
          var h = Math.round(Math.sqrt(Math.pow(f[2] - f[0], 2) + Math.pow(f[3] - f[1], 2)));
          f = f.slice(0, 2),
          f.push(h)
        }
        if (e.collectionItem) {
          const input = e.collectionItem.querySelector('[data-js-hotspot-area-coords]');
          input.value = f.join(',');
          input.dispatchEvent(changeEvent);
        }
      })
    }
  };

  function ImageMapper(elem) {
    wrapper = elem.closest('[data-js-edit-hotspot-areas]');
    var b = 'string' === typeof arguments[1] ? arguments[1] : 'init'
    var e = Array.prototype.slice.call(arguments, 2)
    var f = elem.dataset.imageMapper;
    if (b === 'getData') {
      return d.getData(f);
    } else {
      e.unshift(f)
      typeof d[b] === 'function' && d[b].apply(elem, e)
    }
  }
  window.ImageMapper = ImageMapper;
}()
