import onmount from 'onmount';
import { showFormErrors } from '@modules/form-responses';

onmount('[data-js-publication-form]', function () {
  const form = this.querySelector('form');
  const restrictIP = this.querySelector('[data-js-restrict-ip-access]');

  function formErrorListener(event) {
    if (event.target === form) showFormErrors(event);
  }

  function handleRestrictIpChange() {
    const locationsDiv = document.querySelector('[data-js-location-options]');
    if (locationsDiv) locationsDiv.classList.toggle('d-none');
  }

  if (form) form.addEventListener('ajax:error', formErrorListener);
  if (restrictIP) restrictIP.addEventListener('change', handleRestrictIpChange);
});
