import onmount from 'onmount';
import Rails from '@rails/ujs';

onmount('[data-js-load-more]', function () {
  const self = this;
  let url = new URL(this.dataset.url);
  const progressEl = this.parentElement.querySelector('[data-js-load-more-progress]');
  const { lazyloadEvent } = this.dataset;
  let { lastPosition } = this.dataset;
  let insertEl = document.querySelector(this.dataset.target);
  let ignoreIds = [];
  if (this.dataset.ignoreIds) {
    ignoreIds = this.dataset.ignoreIds.split(',');
  }
  let insertionType = 'beforeend';

  function showProgress() {
    progressEl.classList.remove('d-none');
  }

  function hideProgress() {
    progressEl.classList.add('d-none');
  }

  function refreshLazyLoad() {
    const customEvent = new CustomEvent('lazyLoadRefresh', {
      detail: { element: self }
    });
    document.dispatchEvent(customEvent);
  }

  function loadMore() {
    showProgress();
    url.searchParams.set('last_position', lastPosition || self.dataset.lastPosition);
    url.searchParams.set('ignore_ids', ignoreIds);

    Rails.ajax({
      url: url.toString(),
      type: 'GET',
      dataType: 'json',
      success: (data) => {
        if (!data.last_position) self.classList.add('d-none');
        if (!insertEl.parentElement) insertEl = document.querySelector(self.dataset.target);

        hideProgress();
        lastPosition = data.last_position || self.dataset.lastPosition;
        if (data.ignore_ids) {
          data.ignore_ids.split(',').forEach((item) => {
            ignoreIds.push(item);
          });
        }
        insertEl.insertAdjacentHTML(insertionType, data.html);

        window.webpackOnmount();
        refreshLazyLoad();
      }
    });
  }

  this.loadMore = loadMore;

  function handleNewQuestion(e) {
    const { questionId } = e.detail;
    ignoreIds.push(questionId);

    if (insertEl.hasAttribute('data-question-id')) return;

    insertEl = document.querySelector(`[data-question-id='${questionId}']`);
    insertionType = 'beforebegin';
  }
  this.handleNewQuestion = handleNewQuestion;

  function reset(e) {
    ({ lastPosition } = e.detail);
    insertEl = document.querySelector(this.dataset.target);
    insertionType = 'beforeend';
    ignoreIds = [];
    self.classList.remove('d-none');
    ({ url } = e.detail);
    refreshLazyLoad();
  }

  document.addEventListener('questionCreated', this.handleNewQuestion);
  document.addEventListener(lazyloadEvent, this.loadMore);
  self.addEventListener('load-more:reset', reset);
}, function () {
  const { lazyloadEvent } = this.dataset;
  document.removeEventListener('questionCreated', this.handleNewQuestion);
  document.removeEventListener(lazyloadEvent, this.loadMore);
});
