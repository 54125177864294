import onmount from 'onmount';
import { handleMultiSelect, initSelectAll } from '@modules/select-all-checkbox';

onmount('[data-js-pick-from-collection]', function () {
  const submit = this.querySelector('[data-js-pick-from-collection-submit]');
  const required = submit.dataset.required === 'true';
  const collection = this.querySelector('[data-js-collection]');
  const inputs = this.querySelectorAll('input[type="radio"], input[type="checkbox"]');

  function inputChecked() {
    return [...inputs].some((input) => input.checked);
  }

  function handleInputChange(e) {
    handleMultiSelect(collection, e.target.value, '.mdc-form-field');

    if (required) submit.disabled = !inputChecked();
  }

  initSelectAll(collection, '.mdc-form-field');
  inputs.forEach((input) => {
    input.addEventListener('change', handleInputChange);
  });
});
