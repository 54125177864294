import { Command } from './command';

export class Destroy extends Command {
  constructor(tool, components) {
    super(tool);
    this.components = components;
  }

  execute() {
    this.components.forEach((component) => {
      if (component) {
        component.element.remove();
        this.tool.Objects[component.id] = null;
      }
    });
    this.tool.dispatchEvent();
  }

  undo() {
    this.components.forEach((component) => {
      if (component) {
        this.tool.selectManager.wrapper.before(component.element);
        this.tool.Objects[component.id] = component;
      }
    });
    this.tool.dispatchEvent();
  }

  redo() {
    this.execute();
  }
}
