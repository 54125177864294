import onmount from 'onmount';
import { displayUpdatingStatus, submitOrSetPending } from '@modules/quiz/saving';
import { updateActiveSubmission } from '@modules/quiz/navigating';
import { debounce } from '@modules/custom';

onmount('[data-js-response="match-matrix"]', function () {
  const form = this.closest('form');
  const { submissionId } = this.closest('[data-js-quiz-subquestion]').dataset;
  const { choiceType } = this.dataset;

  function handleInputChange() {
    updateActiveSubmission(submissionId);
    form.setAttribute('data-saved', false);
    displayUpdatingStatus(form);

    const row = this.closest('[data-js-match-row]');

    if (choiceType === 'radio') {
      [...row.querySelectorAll('[data-js-match-checkbox] input')].forEach((input) => {
        if (input !== this) {
          input.checked = false;
        }
      });
    }

    submitOrSetPending(form);
  }

  [...this.querySelectorAll('[data-js-match-checkbox] input')].forEach((input) => {
    input.addEventListener('change', debounce(handleInputChange, 250));
  });
});
