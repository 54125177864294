import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static outlets = ['feedback-counter'];

  static targets = [
    'gradingTypeField', 'partialScoringField', 'link', 'points', 'optionList', 'dropdownRadio', 'feedbackMistake'
  ];

  static values = { gapType: String };

  updateOptionLists() {
    this.optionListTargets.forEach((target) => {
      const { optionListFormGapTypeValue } = target.dataset;
      const otherController = this.application.getControllerForElementAndIdentifier(target, 'option-list-form');
      otherController.update(
        { detail: { gradingType: this.gradingTypeFieldTarget.value, gapType: optionListFormGapTypeValue } }
      );
    });
  }

  update({ params: { gradingType, partialScoring } }) {
    this.gradingTypeFieldTarget.value = gradingType;
    this.partialScoringFieldTarget.value = partialScoring;

    if (this.hasPointsTarget) this.updateVisibilityPointsFields();
    if (this.hasLinkTarget) this.updateUrls(gradingType);

    this.updateVisibilityMistakeFeedbackField();
    this.feedbackCounterOutlet.update();
  }

  addParamsToUrl(url, gradingType) {
    url.searchParams.set('grading_type', gradingType);
    return url.toString();
  }

  updateUrls(gradingType) {
    this.linkTargets.forEach((link) => this.updateUrl(link, gradingType));
  }

  updateUrl(link, gradingType) {
    const url = new URL(link.href);
    link.href = this.addParamsToUrl(url, gradingType);
  }

  linkTargetConnected(element) {
    this.updateUrl(element, this.gradingTypeFieldTarget.value);
  }

  updateVisibilityPointsFields() {
    if (this.gradingTypeFieldTarget.value === 'auto') {
      this.showPoints();
      this.hideRadio();
    } else {
      this.hidePoints();
      this.showRadio();
    }
  }

  updateVisibilityMistakeFeedbackField() {
    if (this.partialScoringFieldTarget.value === 'true') {
      this.feedbackMistakeTarget.classList.remove('d-none');
    } else {
      this.feedbackMistakeTarget.classList.add('d-none');
    }
  }

  showPoints() {
    this.pointsTargets.forEach((target) => target.classList.remove('d-none'));
  }

  hidePoints() {
    this.pointsTargets.forEach((target) => target.classList.add('d-none'));
  }

  showRadio() {
    this.dropdownRadioTargets.forEach((target) => target.classList.remove('d-none'));
  }

  hideRadio() {
    this.dropdownRadioTargets.forEach((target) => target.classList.add('d-none'));
  }
}
