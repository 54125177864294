import onmount from 'onmount';
import Rails from '@rails/ujs';
import { handleMultiSelect, initSelectAll } from '@modules/select-all-checkbox';

onmount('[data-js-assign-reviewers]', function () {
  const url = new URL(window.location.origin + this.dataset.url);
  const collection = this.querySelector('[data-js-collection]');
  const submit = this.querySelector('[data-js-submit]');

  function handleCollectionChange(e) {
    if (!e.target.hasAttribute('data-js-assign-reviewers-input') && !e.target.closest('[data-js-select-all]')) return;

    handleMultiSelect(collection, e.target.value, '.mdc-form-field');
  }

  function parseInputData() {
    const params = { assignedIds: [], unassignedIds: [] };

    const inputs = collection.querySelectorAll('[data-js-assign-reviewers-input]');
    inputs.forEach((input) => {
      if (input.indeterminate) return;

      const { value } = input.dataset;
      if (input.checked) {
        params.assignedIds.push(value);
      } else {
        params.unassignedIds.push(value);
      }
    });

    return params;
  }

  function handleSubmit() {
    if (submit.hasAttribute('data-disable-with')) Rails.disableElement(submit);

    const { assignedIds, unassignedIds } = parseInputData();
    url.searchParams.set('assigned_ids', assignedIds);
    url.searchParams.set('unassigned_ids', unassignedIds);

    Rails.ajax({
      url: url.toString(),
      type: 'POST',
      dataType: 'script'
    });
  }

  initSelectAll(collection, '.mdc-form-field');
  collection.addEventListener('change', handleCollectionChange);
  submit.addEventListener('click', handleSubmit);
});
