/* global i18n, google */

import onmount from 'onmount';
import Rails from '@rails/ujs';
import { debounce } from '@modules/custom';

onmount('[data-js-instructor-chart]', function () {
  const { url } = this.dataset;
  const chartWrapper = this.querySelector('[data-js-chart]');
  const { title } = chartWrapper.dataset;

  function drawChart(data) {
    const dataTable = new google.visualization.DataTable();
    dataTable.addColumn('string', '');
    dataTable.addColumn('number', i18n.t('js.dashboard.instructor_charts.instructors'));
    dataTable.addRows(data);

    const options = {
      title,
      colors: ['#2664EF'],
      hAxis: {
        title: i18n.t('js.dashboard.instructor_charts.instructors'),
        minValue: 0
      },
      legend: {
        position: 'none'
      }
    };

    const chart = new (google.visualization.BarChart)(chartWrapper);
    chart.draw(dataTable, options);
  }

  function getChartData() {
    Rails.ajax({
      url,
      type: 'GET',
      dataType: 'json',
      success: (data) => drawChart(data.data)
    });
  }
  this.getChartData = debounce(getChartData, 250);

  google.charts.load('current', { packages: ['corechart'] });
  google.charts.setOnLoadCallback(getChartData);

  window.addEventListener('resize', this.getChartData);
}, function () {
  window.removeEventListener('resize', this.getChartData);
});
