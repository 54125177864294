import onmount from 'onmount';
import Rails from '@rails/ujs';
import { displayUpdatingStatus } from '@modules/quiz/saving';
import { lockSubmission, unlockSubmission } from '@modules/quiz/locking';
import { updateActiveSubmission } from '@modules/quiz/navigating';

onmount('[data-js-response="code"]', function () {
  const self = this;
  const form = this.closest('form');
  const { submissionId } = this.closest('[data-js-quiz-subquestion]').dataset;
  const input = this.querySelector('[data-js-ace-editor]');
  let editor = null;

  function handleFocus() {
    lockSubmission.call(self);

    updateActiveSubmission(submissionId);
  }

  function handleBlur() {
    unlockSubmission.call(self);

    if (form.getAttribute('data-saved') === 'false') {
      form.setAttribute('data-saved', true);
      displayUpdatingStatus(form);
      Rails.fire(form, 'submit');
    }
  }

  function handleAceChange() {
    form.setAttribute('data-saved', false);
  }

  function addAceEventHandlers() {
    editor.on('focus', handleFocus);
    editor.on('change', handleAceChange);
    editor.on('blur', handleBlur);
  }

  function waitForAce() {
    if (input.hasAttribute('data-ace-initialized')) {
      editor = input.aceEditor;
      addAceEventHandlers();
      return;
    }
    setTimeout(waitForAce, 100);
  }

  waitForAce();
});
