/* global i18n */

import onmount from 'onmount';
import snackbar from '@components/snackbar';
import Rails from '@rails/ujs';
import { serialize } from '@modules/custom';

onmount('[data-js-question-group-sortable]', function () {
  const self = this;

  function updateQuestionGroupId(list, question) {
    return new Promise((resolve, reject) => {
      const { url } = question.dataset;
      const { questionGroupId } = list.closest('[data-js-question-group]').dataset;

      const params = { question_group_id: questionGroupId };

      Rails.ajax({
        url,
        type: 'PATCH',
        data: serialize({ question: params }),
        dataType: 'json',
        success: () => {
          const questionUpdatedEvent = document.createEvent('Event');
          questionUpdatedEvent.initEvent('questionUpdated', true, true);
          document.dispatchEvent(questionUpdatedEvent);

          resolve();
        },
        error: (data) => reject({
          status: data.status,
          statusText: data.statusText,
          message: data.responseText
        })
      });
    });
  }

  function updatePositions(list) {
    const questions = list.querySelectorAll('[data-js-sortable-element]');
    if (questions.length === 0) return;

    const url = list.closest('[data-js-question-group-sortable]').dataset.jsSortableUrl;
    const params = { questions_attributes: [] };
    questions.forEach((question, index) => {
      params.questions_attributes.push({
        id: question.dataset.questionId,
        group_position: index + 1
      });
    });

    Rails.ajax({
      url,
      type: 'PATCH',
      data: serialize({ question_group: params }),
      dataType: 'json'
    });
  }

  function handleAdd(e) {
    updateQuestionGroupId(e.to, e.item).then(() => {
      updatePositions(e.to);
    }).catch((err) => {
      snackbar(i18n.t('js.general.sort_failed', { message: err.message }));
    });
  }

  function handleRemove(e) {
    updatePositions(e.from);
  }

  function handleUpdate(e) {
    updatePositions(e.to);
  }

  async function getSortable() {
    const module = await import(/* webpackChunkName: "sortable" */ 'sortablejs');
    return module.default;
  }

  function preventDefault(e) {
    e.preventDefault();
  }
  this.preventDefault = preventDefault;

  const options = {
    animation: 0,
    delayOnTouchOnly: true,
    draggable: '[data-js-sortable-element]',
    group: 'components',
    handle: '[data-js-sortable-handle]',
    onAdd: handleAdd,
    onRemove: handleRemove,
    onUpdate: handleUpdate
  };

  getSortable().then((Sortable) => Sortable.create(self, options));

  // Disable revert animation
  document.addEventListener('dragover', this.preventDefault);
}, function () {
  document.removeEventListener('dragover', this.preventDefault);
});
