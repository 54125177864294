import onmount from 'onmount';
import { interactJs } from '@modules/interact';

onmount('[data-js-draggable-dialog]', function () {
  const self = this;
  const dialog = this.closest('.mdc-dialog');
  const draggable = self.querySelector('[data-js-draggable-header]');

  function handleClick(e) {
    if (e.target.dataset.mdcDialogAction === 'close') return;

    const draggables = document.querySelectorAll('[data-js-draggable-header');
    if (draggables[draggables.length - 1] === draggable) return;

    document.body.appendChild(dialog);
  }

  function initDialog() {
    self.addEventListener('pointerdown', handleClick);
    dialog.style.zIndex = 999;
  }

  interactJs(self, { plugins: ['draggable'] });

  if (dialog) initDialog();
});
