import { Controller } from '@hotwired/stimulus';
import dialog from '@components/confirm-sentence-dialog';

export default class extends Controller {
  confirmed = false;

  static values = {
    title: String, warning: String, instruction: String, sentence: String
  };

  static targets = ['input'];

  connect() {
    this.handleConfirm = this.handleConfirm.bind(this);
  }

  handleConfirm() {
    this.confirmed = true;
    this.element.click();
  }

  showWarning() {
    document.getElementById('dialog').innerHTML = dialog(
      this.titleValue,
      this.warningValue,
      this.instructionValue,
      this.sentenceValue
    );

    const confirmButton = document.querySelector('[data-js-confirm]');
    confirmButton.addEventListener('click', this.handleConfirm);
  }

  sanitizeInput(text) {
    return text.toLowerCase().replace(/\s/g, '');
  }

  updateSubmitDisabledState() {
    const confirmButton = this.element.querySelector('[data-js-confirm]');

    if (this.sanitizeInput(this.inputTarget.value) === this.sanitizeInput(this.sentenceValue)) {
      confirmButton.removeAttribute('disabled');
    } else {
      confirmButton.setAttribute('disabled', 'disabled');
    }
  }

  warn(e) {
    if (this.confirmed) return;

    this.showWarning();
    e.preventDefault();
    e.stopPropagation();
  }
}
