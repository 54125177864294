/* global Turbo */

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['pdfIframe'];

  connect() {
    window.addEventListener('message', (event) => {
      // Ensure the message is from a trusted source
      if (event.origin !== window.location.origin) return;

      if (event.data.type === 'annotation-selected') {
        this.#annotationSelected(event.data.data);
      } else if (event.data.type === 'annotation-created') {
        this.#annotationCreated(event.data.data);
      } else if (event.data.type === 'annotation-updated') {
        this.#annotationUpdated(event.data.data);
      } else if (event.data.type === 'annotation-edit-comment') {
        this.#editAnnotationComment(event.data.data);
      } else if (event.data.type === 'annotation-deleted') {
        this.#annotationDeleted(event.data.data);
      }
    });
  }

  refreshPanel(e) {
    const { uploadId } = e.target.closest('[data-upload-id]').dataset;
    Turbo.fetch(`/uploads/${uploadId}/annotations`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', accept: 'text/vnd.turbo-stream.html' }
    })
      .then((res) => res.text())
      .then((html) => Turbo.renderStreamMessage(html));
  }

  showButtons() {
    const annotateToolbar = this.pdfIframeTarget.contentDocument.querySelector('#annotate');
    if (annotateToolbar) {
      annotateToolbar.classList.remove('d-none');
    } else {
      setTimeout(this.showButtons.bind(this), 100);
    }
  }

  hideButtons() {
    const annotateToolbar = this.pdfIframeTarget.contentDocument.querySelector('#annotate');
    if (annotateToolbar) {
      annotateToolbar.classList.add('d-none');
    } else {
      setTimeout(this.hideButtons.bind(this), 100);
    }
  }

  selectInPdf(e) {
    return new Promise((resolve) => {
      this.selectedInPdf = true;
      const { annotationId, pageNumber } = e.target.closest('[data-annotation-id]').dataset;
      const pdfWindow = this.pdfIframeTarget.contentWindow;
      const pdfDocument = this.pdfIframeTarget.contentDocument;
      this.#scrollToPageAndWaitForAnnotations(pdfWindow, pdfDocument, pageNumber).then(() => {
        const target = pdfDocument.querySelector(`[data-pdf-annotate-id='${annotationId}']`);
        if (target) {
          pdfWindow.emitter.emit('annotation:click', target);
          resolve();
        }
      });
    });
  }

  destroy(e) {
    this.selectInPdf(e).then(() => {
      const editOverlay = this.pdfIframeTarget.contentDocument.querySelector('#pdf-annotate-edit-overlay');
      if (editOverlay) editOverlay.querySelector('.delete-annotation').dispatchEvent(new PointerEvent('pointerup'));
    });
  }

  #annotationSelected(data) {
    if (this.selectedInPdf) {
      this.selectedInPdf = false;
      return;
    }

    this.#openPanel();
    this.#highlightAnnotation(data.annotationId);
  }

  #annotationCreated(data) {
    this.#openPanel();

    Turbo.fetch(`/uploads/${data.documentId}/annotations`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', accept: 'text/vnd.turbo-stream.html' }
    })
      .then((res) => res.text())
      .then((html) => {
        Turbo.renderStreamMessage(html);
        this.#highlightAnnotation(data.annotationId);
        this.#editAnnotationComment(data);
      });
  }

  #annotationUpdated(data) {
    Turbo.fetch(`/annotations/${data.annotationId}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', accept: 'text/vnd.turbo-stream.html' }
    })
      .then((res) => res.text())
      .then((html) => Turbo.renderStreamMessage(html));
  }

  #editAnnotationComment(data) {
    this.#openPanel();

    Turbo.fetch(`/annotations/${data.annotationId}/annotation_comments/edit`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', accept: 'text/vnd.turbo-stream.html,' }
    })
      .then((res) => res.text())
      .then((html) => Turbo.renderStreamMessage(html));
  }

  #annotationDeleted(data) {
    const { annotationId } = data;

    const annotationElement = this.element.querySelector(`#annotation_${annotationId}`);
    if (annotationElement) {
      annotationElement.remove();

      const chipEl = this.element.querySelector('#annotations .chip');
      const chipCount = parseInt(chipEl.innerText, 10) - 1;
      chipEl.innerText = chipCount;
    }
  }

  #openPanel() {
    const annotationsPanelController = this.application.getControllerForElementAndIdentifier(this.element, 'annotations-panel'); // eslint-disable-line max-len
    annotationsPanelController.open();
  }

  #highlightAnnotation(annotationId) {
    setTimeout(() => {
      const annotation = this.element.querySelector(`#annotation_${annotationId}`);
      if (annotation) {
        annotation.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        const box = annotation.querySelector('.box');
        box.classList.add('light-up');
        setTimeout(() => { box.classList.remove('light-up'); }, 2500);
      }
    }, 100);
  }

  #scrollToPageAndWaitForAnnotations(pdfWindow, pdfDocument, pageNumber) {
    return new Promise((resolve) => {
      if (pdfWindow.PDFViewerApplication.pdfViewer.currentPageNumber.toString() === pageNumber.toString()) {
        resolve();
      }
      const waitForLoaded = () => {
        if (pdfDocument.querySelector(`#viewerContainer [data-page-number='${pageNumber}']`).dataset.annotationsLoaded === 'true') { // eslint-disable-line max-len
          resolve();
        } else {
          setTimeout(waitForLoaded, 100);
        }
      };

      const onPageChanged = (event) => {
        if (event.pageNumber.toString() === pageNumber.toString()) {
          pdfWindow.PDFViewerApplication.pdfViewer.eventBus.off('pagechanging', onPageChanged);
          setTimeout(waitForLoaded, 100);
        }
      };

      pdfWindow.PDFViewerApplication.pdfViewer.eventBus.on('pagechanging', onPageChanged);
      pdfWindow.PDFViewerApplication.pdfViewer.scrollPageIntoView({ pageNumber: parseInt(pageNumber, 10) });
    });
  }
}
