import onmount from 'onmount';

onmount('[data-js-select-background-worker]', function () {
  const hiddenField = document.querySelector('[data-js-source-tool]');

  function handleBackgroundWorkerChange(e) {
    const selectedEl = this.MDCSelect.menu.getOptionByIndex(e.detail.index);
    const { sourceTool } = selectedEl.dataset;
    hiddenField.value = sourceTool || '';
  }

  this.handleBackgroundWorkerChange = handleBackgroundWorkerChange.bind(this);
  this.MDCSelect.listen('MDCSelect:change', this.handleBackgroundWorkerChange);
}, function () {
  this.MDCSelect.unlisten('MDCSelect:change', this.handleBackgroundWorkerChange);
  this.MDCSelect.destroy();
});
