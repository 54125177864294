/* global google, i18n */

import onmount from 'onmount';
import Rails from '@rails/ujs';
import { debounce } from '@modules/custom';

onmount('[data-js-domains-radar]', function () {
  const self = this;
  const { url } = this.dataset;
  const chart = this.querySelector('[data-js-chart]');
  let data;

  function chartOptions() {
    return {
      vega: {
        $schema: 'https://vega.github.io/schema/vega/v5.json',
        width: (self.clientWidth - 128),
        height: 500,
        padding: 50,
        autosize: { type: 'none', contains: 'content' },

        signals: [
          { name: 'radius', update: self.clientWidth > 500 ? '200' : 'width / 2' }
        ],

        data,

        scales: [
          {
            name: 'angular',
            type: 'point',
            range: { signal: '[-PI * 0.5, PI * 1.5]' },
            align: 0,
            padding: 0.5,
            domain: { data: 'student', field: 'id' }
          },
          {
            name: 'radial',
            type: 'linear',
            range: { signal: '[0, radius]' },
            zero: true,
            nice: false,
            domain: [0, 1]
          },
          {
            name: 'color',
            type: 'ordinal',
            domain: [i18n.t('js.results.domains.participant_score')],
            range: ['#FFF']
          }
        ],

        encode: {
          update: {
            x: { signal: 'width * 0.5' },
            y: { signal: 'radius' }
          }
        },

        legends: [
          {
            fill: 'color',
            orient: 'none',
            symbolSize: 196,
            symbolType: 'square',
            labelLimit: 0,
            labelFont: 'Nunito',
            labelFontSize: 12,
            labelOffset: 8,
            legendX: { signal: '-width * 0.5' },
            legendY: { signal: 'radius * 1.5' },
            encode: {
              symbols: {
                update: {
                  stroke: { value: '#000' },
                  shape: { value: 'circle' },
                  strokeWidth: { value: 2 }
                }
              }
            }
          }
        ],

        marks: [
          {
            type: 'group',
            name: 'individual-plot',
            zindex: 3,
            marks: [
              {
                type: 'line',
                name: 'plot-outer-line',
                from: { data: 'student' },
                encode: {
                  enter: {
                    interpolate: { value: 'linear-closed' },
                    x: { signal: "scale('radial', datum.score) * cos(scale('angular', datum.id))" },
                    y: { signal: "scale('radial', datum.score) * sin(scale('angular', datum.id))" },
                    stroke: { value: 'black' },
                    strokeWidth: { value: 2 }
                  }
                }
              },
              {
                type: 'symbol',
                name: 'score-bullets',
                from: { data: 'student' },
                encode: {
                  enter: {
                    href: { signal: `'${window.location.href.split('#')[0]}#' + datum.id` },
                    cursor: { value: 'pointer' },
                    x: { signal: "scale('radial', datum.score) * cos(scale('angular', datum.id))" },
                    y: { signal: "scale('radial', datum.score) * sin(scale('angular', datum.id))" },
                    tooltip: { signal: `{'${i18n.t('js.results.domains.score')}': format(datum.score, '.00%')}` }
                  },
                  update: {
                    fill: { value: 'white' },
                    size: { value: 128 },
                    stroke: { value: '#2E2E2E' },
                    strokeOpacity: { value: 1 },
                    strokeWidth: { value: 2 }
                  },
                  hover: {
                    fill: { value: '#2E2E2E' },
                    size: { value: 128 },
                    stroke: { value: '#2E2E2E' },
                    strokeOpacity: { value: 0.12 },
                    strokeWidth: { value: 12 }
                  }
                }
              }
            ]
          },
          {
            type: 'text',
            name: 'domain-label',
            from: { data: 'student' },
            zindex: 1,
            encode: {
              enter: {
                font: { value: 'Nunito' },
                x: { signal: "(radius + 5) * cos(scale('angular', datum.id))" },
                y: { signal: "(radius + 5) * sin(scale('angular', datum.id))" },
                dy: [
                  { test: "scale('angular', datum.id) == -PI * 0.5", value: -8 },
                  { test: "scale('angular', datum.id) == PI * 0.5", value: 8 }
                ],
                text: { field: 'name' },
                align: [
                  { test: "abs(scale('angular', datum.id)) > PI / 2", value: 'right' },
                  { test: "abs(scale('angular', datum.id)) == PI * 0.5", value: 'center' },
                  { value: 'left' }
                ],
                baseline: [
                  { test: "scale('angular', datum.id) > 0", value: 'top' },
                  { test: "scale('angular', datum.id) == 0", value: 'middle' },
                  { value: 'bottom' }
                ]
              },
              update: {
                tooltip: { field: 'name' }
              }
            }
          },
          {
            type: 'rule',
            name: 'radial-grid',
            from: { data: 'student' },
            encode: {
              enter: {
                x: { value: 0 },
                y: { value: 0 },
                x2: { signal: "radius * cos(scale('angular', datum.id))" },
                y2: { signal: "radius * sin(scale('angular', datum.id))" },
                stroke: { value: '#D1D1D1' }
              }
            }
          },
          {
            type: 'line',
            name: 'outer-line',
            from: { data: 'radial-grid' },
            encode: {
              enter: {
                interpolate: { value: 'linear-closed' },
                x: { signal: 'datum.x2' },
                y: { signal: 'datum.y2' },
                stroke: { value: '#D1D1D1' },
                strokeWidth: { value: 1 }
              }
            }
          },
          {
            type: 'line',
            name: '75%',
            from: { data: 'radial-grid' },
            encode: {
              enter: {
                interpolate: { value: 'linear-closed' },
                x: { signal: 'datum.x2*0.75' },
                y: { signal: 'datum.y2*0.75' },
                stroke: { value: '#D1D1D1' },
                strokeWidth: { value: 1 }
              }
            }
          },
          {
            type: 'line',
            name: '50%',
            from: { data: 'radial-grid' },
            encode: {
              enter: {
                interpolate: { value: 'linear-closed' },
                x: { signal: 'datum.x2*0.50' },
                y: { signal: 'datum.y2*0.50' },
                stroke: { value: '#D1D1D1' },
                strokeWidth: { value: 1 }
              }
            }
          },
          {
            type: 'line',
            name: '25%',
            from: { data: 'radial-grid' },
            encode: {
              enter: {
                interpolate: { value: 'linear-closed' },
                x: { signal: 'datum.x2*0.25' },
                y: { signal: 'datum.y2*0.25' },
                stroke: { value: '#D1D1D1' },
                strokeWidth: { value: 1 }
              }
            }
          },
          {
            type: 'text',
            from: { data: 'distribution-scale' },
            name: 'label',
            encode: {
              enter: {
                font: { value: 'Nunito' },
                dx: { value: 8 },
                fill: { value: '#464646' },
                fontSize: { value: 10 },
                fontWeight: { value: 400 },
                text: { signal: "format(datum.data, '.00%')" },
                y: { signal: '(-radius * datum.data)' }
              }
            }
          }
        ]
      }
    };
  }

  function drawChart() {
    const dataTable = new google.visualization.DataTable();
    const googleChart = new google.visualization.VegaChart(chart);
    googleChart.draw(dataTable, chartOptions());
  }
  this.drawChart = debounce(drawChart, 250);

  function getChartData() {
    Rails.ajax({
      url,
      type: 'GET',
      dataType: 'json',
      success: (response) => {
        data = response.concat({ name: 'distribution-scale', values: [0, 0.25, 0.5, 0.75, 1] });
        drawChart();
      }
    });
  }

  google.charts.load('current', { packages: ['vegachart'] });
  google.charts.setOnLoadCallback(getChartData);

  window.addEventListener('resize', this.drawChart);
}, function () {
  window.removeEventListener('resize', this.drawChart);
});
