import { Command } from './command';
import { formatNumber } from '../helpers';

export class Move extends Command {
  constructor(tool, components, transform) {
    super(tool);
    this.components = components;

    this.transform = transform;
  }

  execute() {
    this.components.forEach((component) => {
      component.transform = {
        x: component.transform.x + this.transform.x,
        y: component.transform.y + this.transform.y
      };
      component.element.setAttribute(
        'transform',
        `translate(${formatNumber(component.transform.x)} ${formatNumber(component.transform.y)})`
      );
    });
    this.tool.dispatchEvent();
  }

  undo() {
    this.components.forEach((component) => {
      component.transform = {
        x: component.transform.x - this.transform.x,
        y: component.transform.y - this.transform.y
      };
      component.element.setAttribute(
        'transform',
        `translate(${formatNumber(component.transform.x)} ${formatNumber(component.transform.y)})`
      );
    });
    this.tool.dispatchEvent();
  }

  redo() {
    this.execute();
  }
}
