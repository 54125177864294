import { Controller } from '@hotwired/stimulus';
import { useDebounce } from 'stimulus-use';

export default class extends Controller {
  static targets = ['answer', 'input'];

  static debounces = ['showUnusedAnswers'];

  connect() {
    useDebounce(this, { wait: 250 });
  }

  showUnusedAnswers() {
    const usedAnswers = this.inputTargets.map((input) => input.value);
    this.answerTargets.forEach((target) => {
      if (usedAnswers.includes(target.dataset.answer)) {
        target.classList.add('d-none');
      } else {
        target.classList.remove('d-none');
      }
    });
  }
}
