import { Controller } from '@hotwired/stimulus';
import { useDebounce } from 'stimulus-use';

export default class extends Controller {
  static targets = ['selected', 'points'];

  static debounces = ['updateSelected', 'updatePoints'];

  connect() {
    useDebounce(this, { wait: 250 });
  }

  updateSelected() {
    const value = parseFloat(this.pointsTarget.value);

    this.selectedTarget.checked = value > 0;
    this.dispatch('pointsChanged');
  }

  updatePoints() {
    this.pointsTarget.numeric.setValue(this.selectedTarget.checked ? 1 : 0);
    this.dispatch('selectedChanged', { detail: { element: this.pointsTarget } });
  }
}
