import '@kollegorna/cocoon-vanilla-js';
import onmount from 'onmount';

onmount('[data-js-option-items]', function () {
  const self = this;

  function handleOptionItemAdd() {
    const event = new CustomEvent('option-item:added');
    self.dispatchEvent(event);
  }

  this.addEventListener('cocoon:after-insert', handleOptionItemAdd);
});
