/* global google, i18n */

import onmount from 'onmount';
import { debounce } from '@modules/custom';

onmount('[data-js-centration]', function () {
  const self = this;
  const { centration } = this.dataset;
  const chart = this.querySelector('[data-js-chart]');

  function chartOptions() {
    return {
      vega: {
        $schema: 'https://vega.github.io/schema/vega/v5.json',
        description: 'A basic bar chart example, with value labels shown upon mouse hover.',
        width: self.clientWidth,
        height: 80,
        autosize: { type: 'fit-x', contains: 'content' },

        data: [
          { name: 'centration', values: [centration] }
        ],

        scales: [
          {
            name: 'xscale',
            domain: [-100, 100],
            range: 'width',
            zero: false,
            round: false
          },
          { name: 'yscale', type: 'band', range: 'height' },
          { name: 'legend', type: 'ordinal', domain: [i18n.t('js.results.certainty_analysis.centration')] }
        ],

        legends: [
          {
            fill: 'legend',
            orient: 'bottom',
            symbolSize: 196,
            labelLimit: 0,
            labelFont: 'Nunito',
            labelFontSize: 12,
            labelOffset: 8,
            encode: {
              symbols: {
                update: {
                  stroke: { value: '#000' },
                  opacity: { value: 1 },
                  shape: { value: 'circle' },
                  strokeWidth: { value: 2 }
                }
              }
            }
          }
        ],

        axes: [
          {
            orient: 'bottom',
            scale: 'xscale',
            domain: false,
            grid: true,
            gridColor: '#E8E8E8',
            labelFont: 'Nunito',
            labelFontSize: 12,
            labelPadding: 16,
            ticks: false,
            values: [-100, -75, -50, -25, 0, 25, 50, 75, 100],
            tickRound: false
          }
        ],

        marks: [
          {
            type: 'rule',
            from: { data: 'centration' },
            encode: {
              enter: {
                x: { scale: 'xscale', value: 0 },
                x2: { scale: 'xscale', signal: 'datum.data * 100' },
                y: { signal: 'height/2' },
                stroke: { value: '#2E2E2E' },
                strokeOpacity: { value: 1 },
                strokeWidth: { value: 2 }
              }
            }
          },
          {
            type: 'symbol',
            from: { data: 'centration' },
            encode: {
              enter: {
                x: { scale: 'xscale', signal: 'datum.data * 100' },
                y: { signal: 'height/2' }
              },
              update: {
                fill: { value: 'white' },
                size: { value: 128 },
                stroke: { value: '#2E2E2E' },
                strokeOpacity: { value: 1 },
                strokeWidth: { value: 2 }
              }
            }
          },
          {
            type: 'text',
            from: { data: 'centration' },
            encode: {
              enter: {
                align: { value: 'center' },
                baseline: { value: 'bottom' },
                font: { value: 'Nunito' },
                fontSize: { value: 12 },
                x: { scale: 'xscale', signal: 'datum.data * 100' },
                y: { signal: '(height / 2) - 12' },
                text: { signal: "format(datum.data * 100, '.2f')" }
              }
            }
          },
          {
            type: 'text',
            encode: {
              enter: {
                baseline: { value: 'bottom' },
                font: { value: 'Nunito' },
                fontSize: { value: 12 },
                y: { signal: 'height * -0.1' },
                text: { value: `${i18n.t('js.results.certainty_analysis.centration_under')}` }
              }
            }
          },
          {
            type: 'text',
            encode: {
              enter: {
                align: { value: 'right' },
                baseline: { value: 'bottom' },
                font: { value: 'Nunito' },
                fontSize: { value: 12 },
                x: { signal: 'width' },
                y: { signal: 'height * -0.1' },
                text: { value: `${i18n.t('js.results.certainty_analysis.centration_over')}` }
              }
            }
          }
        ]
      }
    };
  }

  function drawChart() {
    const dataTable = new google.visualization.DataTable();
    const googleChart = new google.visualization.VegaChart(chart);
    googleChart.draw(dataTable, chartOptions());
  }
  this.drawChart = debounce(drawChart, 250);

  google.charts.load('current', { packages: ['vegachart'] });
  google.charts.setOnLoadCallback(drawChart);

  window.addEventListener('resize', this.drawChart);
}, function () {
  window.removeEventListener('resize', this.drawChart);
});
