import onmount from 'onmount';
import { formatTime } from '@modules/format-time';

onmount('[data-js-subset-timer]', function () {
  const self = this;
  const { subsetId } = this.dataset;

  let deadline;
  let remainingTime;

  function currentTime() {
    return Date.now() / 1000;
  }

  function lockSubmissions() {
    const firstSubmission = document.querySelector(`[data-js-go-to][data-subset-id="${subsetId}"]`);
    if (firstSubmission) firstSubmission.parentElement.setAttribute('data-js-blocked-for-student', '');

    document.querySelectorAll(`[data-js-go-to][data-subset-id="${subsetId}"]`).forEach((sub) => {
      sub.classList.add('subquestion-selector-item--locked');
    });
  }

  function endTimer() {
    self.innerText = '0:00:00';
    lockSubmissions();
  }

  function setDeadline() {
    remainingTime = self.getAttribute('data-time-remaining');
    const duration = parseFloat(self.dataset.timeRemaining);
    deadline = currentTime() + duration;
  }

  function updateTimer() {
    if (self.getAttribute('data-time-remaining') !== remainingTime) {
      setDeadline();
    }

    const remaining = deadline - currentTime();
    if (remaining <= 0) {
      endTimer();
      return;
    }

    const formattedTime = formatTime(remaining);
    if (self.innerText !== formattedTime) {
      self.innerText = formattedTime;
    }

    self.animationFrameId = requestAnimationFrame(updateTimer);
  }

  if (self.hasAttribute('data-time-remaining')) {
    setDeadline();
    this.animationFrameId = requestAnimationFrame(updateTimer);
  }
}, function () {
  window.cancelAnimationFrame(this.animationFrameId);
});
