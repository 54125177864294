import onmount from 'onmount';
import { MDCSelect } from '@material-overwrites/mdc.select';

onmount('[data-js-move-component-dialog]', function () {
  const self = this;

  const questionSelect = this.querySelector('[data-js-question-select]');
  if (questionSelect && !questionSelect.MDCSelect) {
    questionSelect.MDCSelect = new MDCSelect(questionSelect);
    questionSelect.setAttribute('data-mdc-auto-init-state', 'initialized');
  }

  function handleQuestionSelect() {
    const selectedEl = this.MDCSelect.menuElement.querySelectorAll('li')[this.MDCSelect.selectedIndex];
    const subquestionsCount = parseInt(selectedEl.dataset.componentsCount, 10);
    const parentPosition = self.querySelector('[data-js-parent-position]');
    parentPosition.value = subquestionsCount + 1;
  }

  if (questionSelect) questionSelect.MDCSelect.listen('MDCSelect:change', handleQuestionSelect);
});
