import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['toggleButton', 'body'];

  static values = {
    isOpen: { type: Boolean, default: false },
    openIcon: { type: String, default: 'expand_more' },
    closeIcon: { type: String, default: 'expand_less' }
  };

  toggle(event) {
    if (event.target.closest('[data-controller="dropdown"]')) return;

    if (this.isOpenValue) {
      this.close();
    } else {
      this.open();
    }
  }

  open() {
    this.isOpenValue = true;
    this.bodyTarget.classList.remove('d-none');
    this.toggleButtonTarget.innerText = this.closeIconValue;
  }

  close() {
    this.isOpenValue = false;
    this.bodyTarget.classList.add('d-none');
    this.toggleButtonTarget.innerText = this.openIconValue;
  }
}
