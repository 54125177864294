import onmount from 'onmount';
import LazyLoad from 'vanilla-lazyload';

onmount('[data-js-lazy-load]', function () {
  const self = this;
  const { lazyLoadStrategy } = this.dataset;

  function executeEvent(eventName) {
    const customEvent = new CustomEvent(eventName);
    document.dispatchEvent(customEvent);
  }

  function initLoading(element) {
    if (element.dataset.lazyloadEvent) executeEvent(element.dataset.lazyloadEvent);
    if (element.href) element.click();
  }

  function getLazyElements() {
    return self.querySelectorAll('[data-js-lazy-element]');
  }

  if (lazyLoadStrategy === 'auto') {
    getLazyElements().forEach((element) => initLoading(element));
  } else {
    this.lazyload = new LazyLoad(
      { unobserve_entered: true, callback_enter: initLoading },
      getLazyElements()
    );
  }

  function update() {
    self.lazyload.update(getLazyElements());
  }
  this.update = update;

  function refreshElement(e) {
    const { element } = e.detail;

    LazyLoad.resetStatus(element);
    update();
  }
  this.refreshElement = refreshElement;

  document.addEventListener('lazyLoadUpdate', this.update);
  document.addEventListener('lazyLoadRefresh', this.refreshElement);
}, function () {
  this.lazyload.destroy();
  document.removeEventListener('lazyLoadUpdate', this.update);
  document.removeEventListener('lazyLoadRefresh', this.refreshElement);
});
