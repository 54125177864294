import onmount from 'onmount';
import Rails from '@rails/ujs';
import { renderKatex } from '@vendor/katex';
import { serialize } from '@modules/custom';

onmount('[data-js-equation-dialog-trigger]', function () {
  const { url } = this.dataset;
  const input = this.querySelector('[data-js-equation-input]');
  const show = this.querySelector('[data-js-equation-show');

  let { equation } = this.dataset;
  let dialog;
  let mathfieldEl;

  function inputChanged() {
    const event = new CustomEvent('equation:changed');
    input.dispatchEvent(event);
    onmount();
  }

  function handleInsert() {
    equation = mathfieldEl.getValue('latex-expanded');
    if (input) input.value = equation;
    if (show) {
      show.innerText = `$$${equation}$$`;
      renderKatex(show);
    }

    inputChanged();
  }

  function initDialog() {
    dialog = document.querySelector('[data-js-equation-dialog]');
    mathfieldEl = dialog.querySelector('math-field');

    dialog.querySelector('[data-js-insert').addEventListener('click', handleInsert);
  }

  function handleTriggerClick() {
    const params = serialize({ equation });

    Rails.ajax({
      url: `${url}?${params}`,
      type: 'GET',
      dataType: 'script',
      success: () => {
        initDialog();
      }
    });
  }

  this.addEventListener('click', handleTriggerClick);
});
