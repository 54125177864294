import onmount from 'onmount';

onmount('[data-js-key-value-search]', function () {
  const keyInput = this.querySelector('[data-js-key-input]');
  const valueAutocomplete = this.querySelector('[data-js-value-autocomplete]');
  const input = valueAutocomplete.querySelector("input[type='text']");
  let value;

  function handleFocus() {
    const name = keyInput.value;
    if (value === name) return;

    value = name;
    const evt = new CustomEvent('tag_values:load', {
      detail: { tag_key: name }
    });
    valueAutocomplete.dispatchEvent(evt);
  }

  input.addEventListener('focus', handleFocus);
});
