import onmount from 'onmount';
import Rails from '@rails/ujs';
import { serialize } from '@modules/custom';
import { rotateImage } from '@modules/rotate-image';

onmount('[data-js-rotation-wrapper]', function () {
  const self = this;
  const imageWrapper = this.parentElement.querySelector('[data-js-image-wrapper]');
  let image = imageWrapper.querySelector('img');
  const rotateLeft = this.querySelector('[data-js-rotate-left]');
  const rotateRight = this.querySelector('[data-js-rotate-right]');
  const eventTarget = document.querySelector(this.dataset.eventTarget);
  const editable = this.dataset.editable !== 'false';

  function triggerLoading() {
    if (!eventTarget) return;

    const uploadingEvent = new CustomEvent('file:loading');
    eventTarget.dispatchEvent(uploadingEvent);
  }

  function triggerSuccess() {
    if (!eventTarget) return;

    const savedEvent = new CustomEvent('file:success');
    eventTarget.dispatchEvent(savedEvent);
  }

  function getRotation() {
    return parseInt(self.dataset.rotation, 10);
  }

  function calculateRotation(deg) {
    return (getRotation() + parseInt(deg, 10)) % 360;
  }

  function updateServer(uploadId) {
    triggerLoading();

    Rails.ajax({
      url: `/uploads/${uploadId}.json`,
      type: 'PUT',
      data: serialize({ upload: { image_rotation: getRotation() } }),
      success: () => {
        triggerSuccess();
        rotateImage(image, imageWrapper, getRotation());
      }
    });
  }

  function updateClient(uploadId) {
    sessionStorage.setItem(`rotation-${uploadId}`, getRotation());
    rotateImage(image, imageWrapper, getRotation());
  }

  function handleRotateButton() {
    image = imageWrapper.querySelector('img');
    self.setAttribute('data-rotation', calculateRotation(this.dataset.deg));

    const { uploadId } = self.dataset;
    if (editable) {
      updateServer(uploadId);
    } else {
      updateClient(uploadId);
    }
  }

  /*
    EVENT HANDLERS
  */
  if (image) rotateImage(image, imageWrapper, getRotation());
  if (rotateLeft) rotateLeft.addEventListener('click', handleRotateButton);
  if (rotateRight) rotateRight.addEventListener('click', handleRotateButton);
});
