/* global Desmos */

import onmount from 'onmount';
import { interactJs } from '@modules/interact';

onmount('[data-js-calculator-wrapper]', function () {
  const self = this;
  const calculatorTrigger = document.querySelector('[data-js-trigger-calculator]');
  const closeCalculator = document.querySelector('[data-js-close-calculator]');
  const calculatorEl = this.querySelector('[data-js-calculator]');
  const draggable = self.querySelector('[data-js-draggable-header]');
  const mode = calculatorEl.dataset.jsCalculator;
  const invertedColors = sessionStorage.getItem('calculatorInvertedColors') !== 'false';
  let calculator;
  let state = sessionStorage.getItem('calculatorState');

  const calculatorOptions = {
    links: false,
    invertedColors
  };

  function initDesmos() {
    if (typeof Desmos === 'undefined') {
      return setTimeout(initDesmos, 100);
    }

    if (mode === 'scientific') {
      calculator = Desmos.ScientificCalculator(calculatorEl, calculatorOptions);
    } else {
      calculator = Desmos.FourFunctionCalculator(calculatorEl, calculatorOptions);
    }

    if (state) calculator.setState(JSON.parse(state));

    calculator.settings.observe('invertedColors', () => {
      sessionStorage.setItem('calculatorInvertedColors', calculator.settings.invertedColors);
    });
    return true;
  }
  initDesmos();

  function dispatchUnloadEvent() {
    state = calculator.getState();
    sessionStorage.setItem('calculatorState', JSON.stringify(state));
  }

  function handleClick(e) {
    if (e.target === closeCalculator) return;

    const draggables = document.querySelectorAll('[data-js-draggable-header');
    if (draggables[draggables.length - 1] === draggable) return;

    document.body.appendChild(self);
  }

  function handleCalculatorClick() {
    document.body.appendChild(self);
    self.classList.remove('d-none');
  }

  function hideCalculator() {
    self.classList.add('d-none');
  }

  interactJs(self, { plugins: ['draggable', 'resizable'] });

  self.addEventListener('pointerdown', handleClick);
  if (calculatorTrigger) calculatorTrigger.addEventListener('click', handleCalculatorClick);
  if (closeCalculator) closeCalculator.addEventListener('click', hideCalculator);
  window.onbeforeunload = dispatchUnloadEvent;
}, () => {
  window.onbeforeunload = null;
});
