import onmount from 'onmount';

onmount('[data-js-assignment-preset]', function () {
  this.radioButton = this.querySelector('input[type=radio]');

  function handlePresetChange() {
    const groupSize = this.closest('[data-group-size]').getAttribute('data-group-size');
    document.querySelector('[data-js-group-input]').value = groupSize;
    document.querySelector('[data-js-grading-method-input]').value = this.getAttribute('data-grading');
  }
  this.handlePresetChange = handlePresetChange.bind(this);

  this.radioButton.addEventListener('change', this.handlePresetChange);
}, function () {
  this.radioButton.removeEventListener('change', this.handlePresetChange);
});
