import onmount from 'onmount';

onmount('[data-js-dialog-trigger]', function () {
  const selector = this.getAttribute('data-js-dialog-trigger');

  function handleClick(e) {
    const dialog = document.querySelector(selector);
    if (!dialog) return;

    e.preventDefault();
    e.stopPropagation();
    document.body.appendChild(dialog);
  }

  this.addEventListener('click', handleClick);
});
