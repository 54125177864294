/* global i18n */

import Rails from '@rails/ujs';

import onmount from 'onmount';
import { getRedactor } from '@redactor/helpers';
import { woots } from '@modules/custom';

onmount('[data-js-grade-formula]', function () {
  const self = this;
  const { url } = this.dataset;

  function syncing(html) {
    let correctedHtml = html;

    if (woots()) correctedHtml = html.replaceAll(/(\d),(\d)/g, '$1.$2');
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(correctedHtml, 'text/html');

    [...htmlDoc.querySelectorAll('[data-redactor-type="grade-formula-variable"]')].forEach((el) => {
      el.outerHTML = el.dataset.value;
    });

    const { firstChild } = htmlDoc.body;
    if (firstChild) return firstChild.innerText;

    return '';
  }

  function started() {
    this.broadcast('hardsync');
  }

  const options = {
    autoparseStart: false,
    buttons: [],
    enterKey: false,
    lang: i18n.locale,
    plugins: ['gradeFormula'],
    callbacks: { syncing, started }
  };
  if (options.lang === 'nl_vo') options.lang = 'nl';

  function initRedactor(variables) {
    options.variables = variables;

    getRedactor().then((Redactor) => {
      self.redactor = Redactor(self, options);

      if (self.getAttribute('disabled')) self.redactor.enableReadOnly();
    });
  }

  function getVariables() {
    if (!url) {
      initRedactor([{ value: 'points' }, { value: 'total' }]);
      return;
    }

    Rails.ajax({ url, type: 'GET', success: (data) => initRedactor(data) });
  }

  getVariables();
}, function () {
  this.redactor.stop();
});
