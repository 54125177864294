/* global i18n, google */

import onmount from 'onmount';
import Rails from '@rails/ujs';
import { debounce } from '@modules/custom';

onmount('[data-js-revenue-forecast-chart]', function () {
  const { url } = this.dataset;
  const chartWrapper = this.querySelector('[data-js-chart]');

  function drawChart(data) {
    const dataTable = new google.visualization.DataTable();
    dataTable.addColumn('string', '');
    data[0].forEach((row) => {
      dataTable.addColumn('number', row);
    });
    dataTable.addRows(data[1]);
    const options = {
      isStacked: true,
      legend: {
        position: 'none'
      },
      seriesType: 'bars',
      series: {
        0: { type: 'line', targetAxisIndex: 1, lineDashStyle: [10, 2] }
      },
      vAxes: {
        0: { title: i18n.t('js.revenue_forecast.revenue_forecast'), format: 'currency' },
        1: { title: i18n.t('js.revenue_forecast.cumulative_revenue_forecast'), format: 'currency' }
      }
    };

    const chart = new (google.visualization.ComboChart)(chartWrapper);
    chart.draw(dataTable, options);
  }

  function getChartData() {
    Rails.ajax({
      url,
      type: 'GET',
      dataType: 'json',
      success: (data) => drawChart(data.data)
    });
  }
  this.getChartData = debounce(getChartData, 250);

  google.charts.load('current', { packages: ['corechart'], language: 'nl' });
  google.charts.setOnLoadCallback(getChartData);

  window.addEventListener('resize', this.getChartData);
}, function () {
  window.removeEventListener('resize', this.getChartData);
});
