import onmount from 'onmount';
import { showFormErrors } from '@modules/form-responses';

onmount('[data-js-criterium-edit]', function () {
  const form = this.querySelector('form');

  /* FUNCTIONS */
  function formErrorListener(event) {
    if (event.target === form) showFormErrors(event);
  }

  /* EVENT HANDLERS */
  if (form) {
    form.addEventListener('ajax:error', formErrorListener);
  }
});
