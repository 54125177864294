/* global Turbo */

import { Controller } from '@hotwired/stimulus';
import { inputContentWidth } from '@modules/text';
import createChip from '@components/chip';

export default class extends Controller {
  static targets = ['query', 'input', 'chip', 'blankslate', 'submit', 'results', 'excludeFromQuery'];

  static values = { pasteUrl: String };

  chipTargetConnected() {
    this.syncChipsWithInput();
  }

  chipTargetDisconnected() {
    this.syncChipsWithInput();
  }

  syncChipsWithInput() {
    const chips = Array.from(this.chipTargets).reduce((result, { dataset: { id, type } }) => {
      if (id !== 'null') result.push({ id, type });
      return result;
    }, []);
    const serializedChips = chips.length > 0 ? JSON.stringify(chips) : '';

    if (this.hasExcludeFromQueryTarget) this.excludeFromQueryTarget.value = serializedChips;
    if (this.hasInputTarget) {
      this.inputTarget.value = serializedChips;
      const inputEvent = new Event('input', { bubbles: true });
      this.inputTarget.dispatchEvent(inputEvent);
    }
  }

  removeChip(event) {
    event.target.closest('[data-search-chips-target="chip"]').remove();
  }

  removeLastChip() {
    if (this.queryTarget.value !== '') return;

    const lastChip = this.queryTarget.previousElementSibling;
    if (lastChip) lastChip.remove();
  }

  createChip(name, email, type, id) {
    const classes = id ? ['chip-progress'] : ['chip-danger'];
    const chip = createChip({
      name, classes, dataset: { type, id, searchChipsTarget: 'chip' }, close: true, tooltip: email
    });
    this.queryTarget.insertAdjacentElement('beforebegin', chip);
    chip.scrollIntoView({ block: 'nearest', inline: 'nearest' });
  }

  addChip(event) {
    const listItem = event.target.closest('.list-item');
    const {
      name, email, type, id
    } = listItem.dataset;
    this.createChip(name, email, type, id);

    listItem.remove();
    this.clearQueryInput();
  }

  // Paste chips from the input field
  addChips(event) {
    if (this.queryTarget.getAttribute('disabled')) return;

    this.queryTarget.value = 'Loading...';
    this.queryTarget.setAttribute('disabled', 'disabled');

    const pastedContent = event.clipboardData.getData('text');
    const [queryContent, inputValue] = this.#parseContent(pastedContent);
    const queryParams = new URLSearchParams({ content: queryContent });
    if (this.hasExcludeFromQueryTarget) queryParams.append('excluded_members', this.excludeFromQueryTarget.value);
    const url = `${this.pasteUrlValue}?${queryParams.toString()}`;

    Turbo.fetch(url, {
      headers: { 'Content-Type': 'application/json', Accept: 'application/json' }
    })
      .then((res) => res.json())
      .then((results) => results.forEach((item) => this.createChip(item.name, item.email, item.type, item.id)))
      .catch((error) => console.error('Error fetching paste results:', error)) // eslint-disable-line no-console
      .finally(() => {
        this.queryTarget.removeAttribute('disabled');
        this.queryTarget.value = inputValue;
        this.queryTarget.focus();
      });
  }

  clearResults(e) {
    if (this.queryTarget.value === '') {
      if (e) e.stopImmediatePropagation();
      this.resultsTarget.innerHTML = '';
    }
  }

  clearQueryInput() {
    this.queryTarget.value = '';
    this.queryTarget.focus();
    this.clearResults();
  }

  setWidth() {
    this.queryTarget.style.width = `${inputContentWidth(this.queryTarget)}px`;
  }

  #parseContent(content) {
    return content
      .split(/,|\s/)
      .reduce(([queryTokens, inputTokens], string) => {
        if (this.#isEmail(string) || this.#isStudentNumber(string)) {
          return [[...queryTokens, string], inputTokens];
        }

        return [queryTokens, [...inputTokens, string]];
      }, [[], []])
      .map((e) => e.join(' '));
  }

  #isEmail(string) {
    return string.includes('@');
  }

  #isStudentNumber(string) {
    return /^\d+$/.test(string);
  }
}
