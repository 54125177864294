import onmount from 'onmount';
import { MDCSwitch } from '@material/switch/deprecated';

onmount('.mdc-switch, .mdc-switch-deprecated', function () {
  const input = this.querySelector('input[type=checkbox][role=switch]');

  if (!this.MDCSwitch) {
    this.MDCSwitch = new MDCSwitch(this);
    this.setAttribute('data-mdc-auto-init-state', 'initialized');
  }

  const disableTarget = function (target) {
    target.setAttribute('disabled', true);
  };

  const enableTarget = function (target) {
    target.removeAttribute('disabled');
  };

  function toggleElement() {
    let { checked } = this;
    if (this.hasAttribute('data-js-inverse-toggle')) checked = !checked;

    const func = (checked ? enableTarget : disableTarget);
    const targets = document.querySelectorAll(this.getAttribute('data-toggle-target'));
    targets.forEach((target) => func(target));
  }

  if (input.hasAttribute('data-toggle-target')) {
    input.addEventListener('change', toggleElement);
  }
});
