/* global i18n */

export default function dialog(title, warning, instruction, sentence) {
  return `
    <dialog class="dialog" data-dialog-target="dialog" data-dialog-open="true">
      <div class="dialog__container" data-controller="confirm-sentence" data-confirm-sentence-sentence-value="${sentence}">
        <div class="dialog__content">
          <div class="dialog__header">
            <div class="f3 text-title">${title}</div>
            <button type="button" data-action="dialog#close" class="button-icon">close</button>
          </div>
          <div class="d-flex flex-column gap-1">
            <div>${warning}</div>
          </div>

          <div class="d-flex flex-column gap-1">
            <div class="text-title">${instruction}*</div>
            <input data-action="input->confirm-sentence#updateSubmitDisabledState" data-confirm-sentence-target="input" required="required" autocomplete="off" class="text-field" autofocus="autofocus" type="text">
          </div>
        </div>
        <div class="d-flex gap-2 ml-auto">
          <button type="button" data-action="click->dialog#close" class="button-tertiary">${i18n.t('js.general.cancel')}</button>
          <input type="submit" data-action="click->dialog#close" name="commit" value="${i18n.t('js.general.confirm')}" class="button-primary" data-js-confirm data-disable-with="${i18n.t('js.general.confirm')}" disabled="disabled">
        </div>
      </div>
    </dialog>
  `;
}
