import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['image'];

  preview(event) {
    const imageUrl = event.target.dataset.url;
    this.imageTarget.src = imageUrl;
  }
}
