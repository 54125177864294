import onmount from 'onmount';

onmount('[data-js-all-group-klass-select]', function () {
  const klassInput = this.querySelector('[data-js-klass-id-field]');
  const groupInput = this.querySelector('[data-js-group-id-field]');
  const extraTimeInput = this.querySelector('[data-js-extra-time-field]');

  function clearInputs() {
    klassInput.value = '';
    groupInput.value = '';
    if (extraTimeInput) extraTimeInput.value = false;
  }

  function handleOptionClick(e) {
    const selectedOption = e.target.closest('[data-js-option-group]');
    if (!selectedOption) return;

    clearInputs();

    const { jsOptionGroup, klassId, groupId } = selectedOption.dataset;
    if (jsOptionGroup === 'group') {
      groupInput.value = groupId;
    } else if (jsOptionGroup === 'klass') {
      klassInput.value = klassId;
    } else if (jsOptionGroup === 'extra_time') {
      extraTimeInput.value = true;
    }
  }

  this.addEventListener('click', handleOptionClick);
});
