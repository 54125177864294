import onmount from 'onmount';

onmount('[data-js-questions]', function () {
  const self = this;
  const { enumerateQuestions } = this.dataset;

  function numberToLetter(number) {
    let str = '';

    const multiples = Math.ceil(number / 26);
    const charAtCode = number - ((multiples - 1) * 26);

    if (multiples > 1) str += String.fromCharCode((multiples - 1) + 64);
    str += String.fromCharCode(charAtCode + 64);

    return str;
  }

  function updateSubquestionPositionsWithNumbers() {
    const subquestions = self.querySelectorAll('[data-js-subquestion]');

    subquestions.forEach((subquestion, index) => {
      const position = subquestion.querySelector('[data-js-subquestion-position]');
      if (position) position.innerText = index + 1;
    });
  }

  function updateSubquestionPositionsWithLetters(subquestions, subquestionsCount) {
    subquestions.forEach((subquestion, index) => {
      const position = subquestion.querySelector('[data-js-subquestion-position]');
      if (!position) return;

      if (subquestionsCount > 1) {
        position.innerText = numberToLetter(index + 1).toLowerCase();
      } else {
        position.innerText = '';
      }
    });
  }

  function updateQuestionPositions(type) {
    const questions = self.querySelectorAll('[data-js-question]');
    let questionPosition = 0;

    questions.forEach((question) => {
      const position = question.querySelector('[data-js-question-position]');
      if (!position) return;

      if (type === 'none') {
        position.innerText = '';
        return;
      }

      const subquestions = question.querySelectorAll('[data-js-subquestion]');
      const subquestionsCount = subquestions.length;

      if (subquestionsCount > 0) {
        questionPosition += 1;
        position.innerText = questionPosition;
      } else {
        position.innerText = '';
      }
      updateSubquestionPositionsWithLetters(subquestions, subquestionsCount);
    });
  }

  function updatePositions() {
    if (enumerateQuestions === 'lettered') {
      updateQuestionPositions('number');
    } else if (enumerateQuestions === 'numbered') {
      updateQuestionPositions('none');
      updateSubquestionPositionsWithNumbers();
    }
  }
  this.updatePositions = updatePositions;

  [
    'questionCreated', 'questionUpdated', 'questionDestroyed',
    'subquestionCreated', 'subquestionUpdated', 'subquestionDestroyed',
    'subelementCreated', 'subelementUpdated', 'subelementDestroyed'
  ].forEach((evt) => {
    document.addEventListener(evt, this.updatePositions);
  });
}, function () {
  [
    'questionCreated', 'questionUpdated', 'questionDestroyed',
    'subquestionCreated', 'subquestionUpdated', 'subquestionDestroyed',
    'subelementCreated', 'subelementUpdated', 'subelementDestroyed'
  ].forEach((evt) => {
    document.removeEventListener(evt, this.updatePositions);
  });
});
