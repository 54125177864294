/* global Redactor */

(function ($R) {
  $R.add('plugin', 'list', {
    init(app) {
      this.app = app;
      this.toolbar = app.toolbar;
      this.inspector = app.inspector;
      this.selection = app.selection;
    },
    start() {
      this.olButton = this.toolbar.getButton('ol');
      this.ulButton = this.toolbar.getButton('ul');
      [this.olButton, this.ulButton].forEach((button) => {
        button.nodes[0].addEventListener('click', () => this.app.broadcast('toggle', button));
      });
    },
    onchanged() { this.updateButtonStates(); },
    onclick() { this.updateButtonStates(); },
    onkeyup() { this.updateButtonStates(); },
    onblur() {
      this.deactivateButtons();
    },
    updateButtonStates() {
      const element = this.selection.getElement();
      const data = this.inspector.parse(element);

      if (data.isList()) {
        this.app.broadcast('formatlist', true);

        const closestList = data.el.closest('ul, ol');

        if (closestList.tagName === 'UL') {
          this.activateUlButton();
          this.deactivateOlButton();
        } else if (closestList.tagName === 'OL') {
          this.activateOlButton();
          this.deactivateUlButton();
        }
      } else {
        this.app.broadcast('formatlist', false);
        this.deactivateButtons();
      }
    },
    activateUlButton() {
      this.ulButton.addClass('re-button--active');
    },
    activateOlButton() {
      this.olButton.addClass('re-button--active');
    },
    deactivateUlButton() {
      this.ulButton.removeClass('re-button--active');
    },
    deactivateOlButton() {
      this.olButton.removeClass('re-button--active');
    },
    activateButtons() {
      this.activateUlButton();
      this.activateOlButton();
    },
    deactivateButtons() {
      this.deactivateUlButton();
      this.deactivateOlButton();
    }
  });
}(Redactor));
