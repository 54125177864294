import onmount from 'onmount';
import Rails from '@rails/ujs';

onmount('[data-js-review-requests-new]', function () {
  const search = this.querySelector('[data-js-client-search-field]');
  const { url, lazyloadEvent } = this.dataset;

  function request() {
    Rails.ajax({
      url,
      type: 'GET',
      dataType: 'script',
      success: () => {
        search.init();
        search.focus();
      }
    });
  }
  this.request = request;

  document.addEventListener(lazyloadEvent, this.request);
}, function () {
  const { lazyloadEvent } = this.dataset;
  if (lazyloadEvent) document.removeEventListener(lazyloadEvent, this.request);
});
