import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['alphanumeric', 'dropdown', 'numeric', 'content'];

  static values = { gapType: String, gradingType: String };

  update({ detail: { value } }) {
    this.gapType = value;
    this.updateVisibility();
  }

  updateVisibility() {
    this[this.gapType]();
  }

  alphanumeric() {
    this.hideElements([...this.dropdownTargets, ...this.numericTargets]);
    this.showElements(this.alphanumericTargets);
    this.convertToAlphaNumericInputs();
  }

  dropdown() {
    this.hideElements([...this.alphanumericTargets, ...this.numericTargets]);
    this.convertToAlphaNumericInputs();

    if (this.gradingTypeValue === 'automatic_scoring') {
      this.showElements(this.dropdownTargets);
    } else {
      this.hideElements(this.dropdownTargets);
    }
  }

  numeric() {
    this.hideElements([...this.alphanumericTargets, ...this.dropdownTargets]);
    this.showElements(this.numericTargets);
    this.convertToNumericInputs();
  }

  convertToNumericInputs() {
    this.contentTargets.forEach((target) => target.setAttribute('type', 'number'));
  }

  convertToAlphaNumericInputs() {
    this.contentTargets.forEach((target) => target.setAttribute('type', 'text'));
  }

  hideElements(collection) {
    collection.forEach((item) => item.classList.add('d-none'));
  }

  showElements(collection) {
    collection.forEach((item) => item.classList.remove('d-none'));
  }
}
