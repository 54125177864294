export const redactorOptions = {
  plugins: [
    'fontcolor', 'table', 'imagemanager', 'filemanager', 'equation', 'audio', 'twemoji', 'handle', 'video',
    'specialchars', 'alignment'
  ],
  buttons: [
    'html', 'bold', 'italic', 'underline', 'sub', 'sup', 'format', 'link', 'image', 'file',
    'ol', 'ul', 'indent', 'outdent', 'undo', 'redo'
  ]
};
