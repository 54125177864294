import { I18n } from 'i18n-js';
import translations from '../i18n/translations.json';

const i18nEl = document.querySelector('[data-js-i18n]');
const { defaultLocale, locale } = i18nEl.dataset;

const i18n = new I18n(translations);
i18n.defaultLocale = defaultLocale;
i18n.locale = locale;
i18n.locales.register('nl_vo', ['nl', 'en']);

window.i18n = i18n;
export default i18n;
