import onmount from 'onmount';
import Rails from '@rails/ujs';

onmount('[data-js-generate-password]', function () {
  const self = this;
  const checkbox = this.querySelector('.mdc-checkbox');
  const passwordInput = this.querySelector('[data-js-set-password] input');

  function generatePassword() {
    const charset = '23456789ABCDEFGHJKLMNPRSTUVWXYZabcdefghijkmnopqrstuvwxyz';
    const ar = [...Array(8)];

    return ar.map(() => charset.charAt(Math.floor(Math.random() * charset.length))).join('');
  }

  function handleCheckboxChanged() {
    if (this.MDCCheckbox.checked) {
      passwordInput.value = generatePassword();
    }
    Rails.fire(self.closest('form'), 'submit');
  }

  checkbox.addEventListener('checkboxChanged', handleCheckboxChanged);
});
