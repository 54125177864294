import onmount from 'onmount';

onmount('[data-js-chat-panel]', function () {
  const form = this.querySelector('form');
  const input = this.querySelector('[data-js-message-input]');
  const messages = this.querySelector('[data-js-messages]');

  function groupMessages() {
    let previousMessage = {};

    messages.querySelectorAll('[data-js-chat-item]').forEach((message) => {
      const { className, senderId } = message.dataset;
      if (previousMessage.senderId === senderId && previousMessage.className === className) {
        const senderName = message.querySelector('[data-js-sender-name]');
        if (senderName) senderName.remove();
      }

      previousMessage = { className, senderId };
    });
  }

  function scrollToBottom() {
    messages.scrollTop = messages.scrollHeight;
  }

  function addMessage(id, className, html) {
    const message = messages.querySelector(`[data-${className}-id='${id}']`);
    if (message) return;

    messages.insertAdjacentHTML('beforeend', html);
    groupMessages();
    scrollToBottom();
  }

  function handleNewMessage(e) {
    const data = e.detail[0];
    addMessage(data.message_id, data.class_name, data.html);
  }

  function handleFormSuccess(e) {
    input.value = '';
    handleNewMessage(e);
  }

  if (form) form.addEventListener('ajax:success', handleFormSuccess);
  this.addEventListener('chat:message', handleNewMessage);

  groupMessages();
  setTimeout(scrollToBottom, 10);
});
