import Rails from '@rails/ujs';
import { Controller } from '@hotwired/stimulus';
import { debounce } from '@modules/custom';
import { useMutation } from 'stimulus-use';

// Connects to data-controller="remote-request"
export default class extends Controller {
  connect() {
    useMutation(this, { attributes: true });
  }

  sendRequest() {
    const { url } = this.element.dataset;
    Rails.ajax({ url, type: 'GET', dataType: 'script' });
  }

  mutate() {
    debounce(this.sendRequest());
  }
}
