import onmount from 'onmount';
import { MDCTextField } from '@material/textfield';

onmount('[data-js-answers] [data-js-answer]', function () {
  const position = this.querySelector('[data-js-answer-position-wrapper]');
  if (position && !position.MDCTextField) {
    position.MDCTextField = new MDCTextField(position);
    position.setAttribute('data-mdc-auto-init-state', 'initialized');
  }
});
