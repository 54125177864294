import { Controller } from '@hotwired/stimulus';
import { useDebounce } from 'stimulus-use';

// Connects to data-controller="quiz-layout"
export default class extends Controller {
  static debounces = [
    'setMainColor', 'setTextColor', 'setPrimaryButtonBackground', 'setPrimaryButtonColor', 'setImagePreview'
  ];

  static targets = [
    'mainColor', 'textColor', 'primaryButtonBackground', 'primaryButtonColor',
    'bar', 'image', 'button'
  ];

  connect() {
    this.setMainColor();
    this.setTextColor();
    this.setPrimaryButtonBackground();
    this.setPrimaryButtonColor();
    useDebounce(this, { wait: 250 });
  }

  setMainColor() {
    this.barTarget.style.setProperty('background-color', this.mainColorTarget.value, 'important');
  }

  setTextColor() {
    this.barTarget.style.setProperty('color', this.textColorTarget.value, 'important');
  }

  setPrimaryButtonBackground() {
    this.buttonTarget.style.setProperty('background-color', this.primaryButtonBackgroundTarget.value, 'important');
    this.buttonTarget.style.setProperty('border-color', this.primaryButtonBackgroundTarget.value, 'important');
  }

  setPrimaryButtonColor() {
    this.buttonTarget.style.setProperty('color', this.primaryButtonColorTarget.value, 'important');
  }

  setImagePreview(e) {
    this.imageTarget.innerHTML = '';

    if (e.target.files.length) {
      const img = document.createElement('img');
      img.setAttribute('src', URL.createObjectURL(e.target.files[0]));
      img.style.height = '48px';
      this.imageTarget.append(img);
    }
  }
}
