import onmount from 'onmount';
import { debounce } from '@modules/custom';

onmount('[data-js-horizontal-scroll]', function () {
  const SCROLL_DIRECTIONS = { left: -1, right: 1 };

  const leftButton = this.querySelector('[data-js-button-scroll-left]');
  const rightButton = this.querySelector('[data-js-button-scroll-right]');
  const scrollable = this.querySelector('[data-js-scrollable]');

  function handleScroll() {
    leftButton.classList.toggle('d-none', scrollable.scrollLeft <= 0);
    rightButton.classList.toggle('d-none', (scrollable.scrollLeft + scrollable.clientWidth) >= scrollable.scrollWidth);
  }

  function handleClick(direction) {
    scrollable.scrollLeft += (direction * scrollable.clientWidth);
  }

  rightButton.addEventListener('click', () => handleClick(SCROLL_DIRECTIONS.right));
  leftButton.addEventListener('click', () => handleClick(SCROLL_DIRECTIONS.left));
  scrollable.addEventListener('scroll', debounce(handleScroll, 100));

  handleScroll();
});
