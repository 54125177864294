function hide(e) {
  if (e.classList.contains('d-none')) return;

  e.classList.add('d-none');
}

function show(e) {
  if (!e.classList.contains('d-none')) return;

  e.classList.remove('d-none');
}

function hasChildren(section) {
  return section.children.length;
}

export function updateSeparators(top, bottom, sections) {
  const [topSection, middleSection, bottomSection] = [...sections];

  if (hasChildren(topSection) && hasChildren(middleSection)) {
    show(top);
  } else {
    hide(top);
  }

  if (hasChildren(bottomSection)) {
    show(bottom);
  } else {
    hide(bottom);
  }

  if (!hasChildren(middleSection)) {
    hide(top);
  }
}
