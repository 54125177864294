/* global i18n */

import onmount from 'onmount';
import Rails from '@rails/ujs';
import { MDCTab } from '@material/tab';
import { rotateImage } from '@modules/rotate-image';
import { loadDocReader } from '@modules/docreader';

onmount('[data-js-file-tab]', function () {
  const self = this;
  const docreaderPanel = document.querySelector('[data-js-docreader-panel]');
  const pdfPanel = document.querySelector('[data-js-pdf-panel]');
  const videoPanel = document.querySelector('[data-js-video-panel]');
  const imagePanel = document.querySelector('[data-js-image-panel]');
  const scriptPanel = document.querySelector('[data-js-script-panel]');
  const noPreviewPanel = document.querySelector('[data-js-no-preview-panel]');

  if (!this.MDCTab) {
    this.MDCTab = new MDCTab(this);
    this.setAttribute('data-mdc-auto-init-state', 'initialized');
  }

  function quizPanel() {
    return document.querySelector('[data-js-quiz-panel]');
  }

  function hideVideoPanel() {
    videoPanel.querySelector('video').pause();
    videoPanel.classList.add('d-none');
  }

  function hidePanels() {
    if (quizPanel()) quizPanel().classList.add('d-none');
    if (pdfPanel) pdfPanel.classList.add('d-none');
    if (videoPanel) hideVideoPanel();
    if (imagePanel) imagePanel.classList.add('d-none');
    if (scriptPanel) scriptPanel.classList.add('d-none');
    if (noPreviewPanel) noPreviewPanel.classList.add('d-none');
    if (docreaderPanel) docreaderPanel.classList.add('d-none');
  }

  function setLanguage(editor) {
    const runner = document.querySelector('[data-js-runner]');
    if (runner) {
      const mode = editor.session.$modeId;
      const language = mode.substr(mode.lastIndexOf('/') + 1);
      const customEvent = new CustomEvent('ace-editor:change-language', {
        detail: { language }
      });
      runner.dispatchEvent(customEvent);
    }
  }

  function docreaderSupported(fileType) {
    const supportedFileTypes = [
      '.odp',
      '.ods',
      '.odt',
      '.epub',
      '.xls',
      '.xlsx',
      '.pps',
      '.ppt',
      '.pptx',
      '.doc',
      '.docx',
      '.pdf',
      '.rtf'
    ];
    return docreaderPanel !== undefined && supportedFileTypes.includes(fileType);
  }

  function setVideoDownloadable(videoEl, downloadable) {
    if (downloadable === 'false') {
      videoEl.setAttribute('controlslist', 'nodownload');
    } else {
      videoEl.removeAttribute('controlslist');
    }
  }

  function imageFailedToLoad() {
    imagePanel.classList.add('d-none');
    noPreviewPanel.classList.remove('d-none');
    noPreviewPanel.querySelector('[data-js-link]').setAttribute('href', this.src);
    const image = imagePanel.querySelector('img');
    image.setAttribute('alt', '');
    image.setAttribute('src', 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7');
  }

  function handleFileClick() {
    self.MDCTab.activate();
    const {
      uploadId, url, rotation, downloadable, fileExtension
    } = this.dataset;
    let page = JSON.parse(this.dataset.page || '0');
    if (Array.isArray(page)) [page] = page;

    hidePanels();

    if (this.dataset.fileType !== 'quiz' && document.querySelector('[data-js-split-screen-tab]') && quizPanel()) {
      quizPanel().classList.remove('d-none');
    }

    if (docreaderPanel && docreaderSupported(fileExtension)) {
      loadDocReader(url);
      return;
    }

    switch (this.dataset.fileType) {
      case 'quiz':
        if (quizPanel()) quizPanel().classList.remove('d-none');
        break;
      case 'pdf': {
        if (pdfPanel) pdfPanel.classList.remove('d-none');
        const requestPdfEvent = new CustomEvent('pdf:show', {
          detail: {
            id: uploadId,
            url,
            downloadable,
            page
          }
        });
        document.dispatchEvent(requestPdfEvent);
        break;
      }
      case 'video': {
        videoPanel.classList.remove('d-none');
        const videoEl = videoPanel.querySelector('video');
        videoEl.disablePictureInPicture = true;
        const currentSrc = videoEl.getAttribute('src');
        if (currentSrc !== url) {
          setVideoDownloadable(videoEl, downloadable);
          videoEl.setAttribute('src', url);
          videoEl.load();
        }
        break;
      }
      case 'image': {
        imagePanel.classList.remove('d-none');
        const img = imagePanel.querySelector('img');
        img.onerror = imageFailedToLoad;
        if (img.getAttribute('src') !== url) img.setAttribute('src', url);
        img.setAttribute('data-upload-id', uploadId);

        const imageRotation = sessionStorage.getItem(`rotation-${uploadId}`) || rotation;
        const rotationWrapper = imagePanel.querySelector('[data-js-rotation-wrapper]');
        rotationWrapper.setAttribute('data-rotation', imageRotation);
        rotationWrapper.setAttribute('data-upload-id', uploadId);
        rotateImage(img, imagePanel.querySelector('[data-js-image-wrapper]'), parseInt(imageRotation, 10));
        break;
      }
      case 'script': {
        scriptPanel.classList.remove('d-none');
        const editor = scriptPanel.querySelector('[data-js-ace-editor]').aceEditor;
        if (!editor) {
          setTimeout(handleFileClick.bind(this), 100);
          return;
        }

        Rails.ajax({
          url,
          type: 'GET',
          success: (data) => {
            editor.getSession().setValue(data);
            setLanguage(editor);
          },
          error: () => {
            editor.getSession().setValue(i18n.t('js.uploads.not_found'));
          }
        });
        break;
      }
      case 'unknown':
        noPreviewPanel.classList.remove('d-none');
        noPreviewPanel.querySelector('[data-js-link]').setAttribute('href', url);
        break;
      default:
        break;
    }
  }

  this.addEventListener('click', handleFileClick);

  if (this.MDCTab.active) {
    this.click();
    this.blur();
  }
});
