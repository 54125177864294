/* global Channels */

import onmount from 'onmount';
import consumer from '../consumer';

onmount('[data-js-assignments-chats-channel]', function () {
  const self = this;
  const chats = document.querySelector('[data-js-chats]');
  const search = document.querySelector("[data-js-client-search-field='[data-js-chats]']");
  let chatResponses;

  function createPanelMessage(panel, data) {
    if (panel.querySelector(`[data-${data.class_name}-id='${data.message_id}']`)) return;

    const customEvent = new CustomEvent('chat:message', { detail: [data] });
    panel.dispatchEvent(customEvent);
  }

  function updateChats(data, panel) {
    const chat = chats.querySelector(`[data-js-chat][data-result-id='${data.result_id}']`);
    if (chat) chat.remove();

    chats.insertAdjacentHTML('afterbegin', data.chat_html);
    if (panel) {
      const newChat = chats.querySelector(`[data-js-chat][data-result-id='${data.result_id}']`);
      newChat.classList.add('box--active');
      newChat.classList.remove('box--highlight');
    }

    search.init();
  }

  function handleChatMessage(data) {
    const panel = document.querySelector(`[data-js-chat-panel][data-result-id='${data.result_id}']`);
    if (panel) {
      createPanelMessage(panel, data);
      if (!data.read_by_examinator) chatResponses.perform('mark_chat_as_read', { result_id: data.result_id });
    } else if (!data.read_by_examinator) {
      self.classList.add('indicator');
    }
    if (chats) updateChats(data, panel);
  }

  function handleAnnouncement(data) {
    const panel = document.querySelector('[data-js-chat-panel]');
    if (!panel) return;

    createPanelMessage(panel, data);
  }

  chatResponses = consumer.subscriptions.create({
    channel: 'Assignments::ChatsChannel',
    assignment_id: this.dataset.assignmentId
  }, {
    received(data) {
      if (data.class_name === 'chatmessage') {
        handleChatMessage(data);
      } else {
        handleAnnouncement(data);
      }
    }
  });

  Channels.chatResponses = chatResponses;
}, () => {
  Channels.chatResponses.unsubscribe();
});
