import { Controller } from '@hotwired/stimulus';
import { useThrottle } from 'stimulus-use';

export default class extends Controller {
  static targets = ['element', 'button', 'buttonLabel'];

  static values = {
    offset: { type: Number, default: 0 }
  };

  static useThrottle = ['updateButtonVisibilityAndAction'];

  currentTarget = null;

  connect() {
    useThrottle(this);
    this.updateButtonVisibilityAndAction();
  }

  updateButtonVisibilityAndAction() {
    const panelBottom = this.element.scrollTop + this.element.offsetHeight;
    this.currentTarget = this.elementTargets.find((target) => target.offsetTop > panelBottom);

    if (this.currentTarget) {
      this.buttonLabelTarget.textContent = this.currentTarget.dataset.scrollButtonLabel;
      this.buttonTarget.classList.remove('invisible');
    } else {
      this.buttonTarget.classList.add('invisible');
    }
  }

  scrollToItem() {
    if (this.currentTarget) {
      const offsetPosition = this.currentTarget.offsetTop - this.offsetValue;
      this.element.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  }
}
