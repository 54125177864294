import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['fixedPositionButton', 'fixedPositionField', 'positionField'];

  update() {
    if (this.isPinned()) {
      this.unpin();
    } else {
      this.pin();
    }
  }

  updateFixedPosition(e) {
    if (this.isPinned()) {
      this.fixedPositionFieldTarget.value = e.target.value;
    }
  }

  isPinned() {
    return Boolean(this.fixedPositionFieldTarget.value);
  }

  pin() {
    this.fixedPositionFieldTarget.value = this.positionFieldTarget.value;
    this.fixedPositionButtonTarget.classList.add('material-icons-filled');
  }

  unpin() {
    this.fixedPositionFieldTarget.value = '';
    this.fixedPositionButtonTarget.classList.remove('material-icons-filled');
  }
}
