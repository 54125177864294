export function goToNextError(skip) {
  if (!skip) return;

  let url;
  const href = skip.getAttribute('href');
  if (href.includes('http')) {
    url = new URL(href);
  } else {
    url = new URL(window.location.origin + href);
  }

  let offset = parseInt(url.searchParams.get('offset') || '0', 10);
  if (offset < 0) offset = 0;
  url.searchParams.set('offset', offset - 1);

  skip.setAttribute('href', url.toString());
  skip.click();
}
