import onmount from 'onmount';
import Rails from '@rails/ujs';
import { serialize } from '@modules/custom';

onmount('[data-js-generate-solution]', function () {
  const self = this;
  const { url } = this.dataset;
  const form = this.closest('[data-js-equation]');
  const method = form.querySelector('[data-js-toggle-equation-operation] [data-js-select-input]');
  const equation = form.querySelector('[data-js-equation-input]');
  const variables = form.querySelector('[data-js-equation-symbols]');
  const solutionWrapper = form.querySelector('[data-js-solution]');
  const answer = form.querySelector('[data-js-equation-answer]');

  function showSolution(valid, solution) {
    if (valid) {
      solutionWrapper.innerHTML = `<span data-js-katex>$$${solution}$$</span>`;
    } else {
      solutionWrapper.innerHTML = `<span>${solution}</span>`;
    }
    onmount();
  }

  function getSolution() {
    Rails.disableElement(self);
    const params = {
      operation: method.value,
      equation: equation.value,
      symbols: variables.value
    };

    Rails.ajax({
      url,
      type: 'GET',
      data: serialize(params),
      success: (data) => {
        showSolution(data.valid, data.solution);
        answer.value = data.solution;

        Rails.enableElement(self);
      }
    });
  }

  self.addEventListener('click', getSolution);
});
