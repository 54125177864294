/* PDFAnnotate */

import onmount from 'onmount';
import Rails from '@rails/ujs';
import renderIndicators from '@modules/render-indicators';
import getQuestionPositions from '@modules/question-positions';
import { debounceWithArgs } from '@modules/custom';

onmount('[data-js-pdf-panel]', function () {
  const self = this;
  const {
    assignmentType,
    assignmentGradingMethod,
    identifierId,
    skipFirstPage
  } = this.dataset;
  const viewOnly = this.hasAttribute('data-js-view-only');
  const iframe = this.querySelector('iframe');
  const retry = this.querySelector('[data-js-retry-presigned-url]');
  const preloadPdf = {};

  let canDownload;
  let PDFViewerApplication;
  let PDFViewerApplicationOptions;
  let worker;
  let pdfjsLib;
  let loadScreen;

  function getText(pdf) {
    const maxPages = pdf._pdfInfo.numPages; // eslint-disable-line no-underscore-dangle
    const countPromises = [];

    let j = 1;
    while (j <= maxPages) {
      const page = pdf.getPage(j);

      countPromises.push(page.then((p) => (
        p.getTextContent({ normalizeWhitespace: true }).then((text) => (
          text.items.map((s) => s.str).join('')
        ))
      )));
      j += 1;
    }

    return Promise.all(countPromises).then((texts) => texts.join(''));
  }

  function wordCount() {
    const { pdfDocument } = PDFViewerApplication.pdfViewer;
    try {
      getText(pdfDocument).then((text) => {
        const count = text.trim().length ? text.match(/\S+/g).length : '';
        const totalCountEl = document.querySelector('.wordcount-container #total-count');
        if (totalCountEl) totalCountEl.innerText = count;
      });
    } catch (err) {
      console.error(err.toString()); // eslint-disable-line
    }
  }

  function handleTextSelection() {
    const selectedCountEl = document.querySelector('.wordcount-container .selected');
    if (!selectedCountEl) return;

    iframe.contentDocument.addEventListener('mouseup', () => {
      const selection = iframe.contentWindow.getSelection().toString();
      if (selection === '') {
        selectedCountEl.classList.add('hide');
      } else {
        const count = selection.match(/\w+/g).length;
        selectedCountEl.querySelector('#selected-count').innerText = count;
        selectedCountEl.classList.remove('hide');
      }
    });
  }

  function renderDocumentFeatures() {
    if (assignmentType === 'Assessment' || assignmentType === 'Quiz') {
      wordCount();
      handleTextSelection();
    }
  }

  function preloadNextPdf() {
    if (!window.location.href.indexOf('review')) return;
    if (assignmentGradingMethod !== 'review') return;

    const url = window.location.href.replace('review', 'preload_pdf');

    Rails.ajax({
      url,
      type: 'GET',
      success: (data) => {
        if (data && data.url) {
          preloadPdf.id = data.id;
          preloadPdf.loadingTask = pdfjsLib.getDocument({ url: data.url, worker, disableRange: true });
          preloadPdf.loadingTask.promise.catch(() => {
            preloadPdf.loadingTask.id = null;
          });
        }
      }
    });
  }

  function handleDocumentLoad() {
    const scale = localStorage.getItem('pdf-scale');
    if (scale) PDFViewerApplication.pdfViewer.currentScaleValue = scale;

    const buttons = iframe.contentDocument.querySelectorAll('#downloadButton, #secondaryDownload');
    const display = (canDownload ? 'block' : 'none');
    buttons.forEach((button) => {
      button.style.display = display;
    });

    preloadNextPdf();
    renderDocumentFeatures();
  }

  function handleSubquestionClick() {
    if (this.classList.contains('active')) return;
    const cursorButton = iframe.contentDocument.querySelector('#annotate #cursorButton');
    if (cursorButton && !cursorButton.classList.contains('active')) return;
    if (iframe.contentDocument.querySelector('#viewer #pdf-annotate-edit-overlay')) return;

    const { url } = this.dataset;
    Rails.ajax({
      url,
      type: 'GET',
      dataType: 'script'
    });
  }

  function displayQuestionBox(page) {
    return new Promise((resolve) => {
      const key = `grading-positions-${identifierId}`;
      getQuestionPositions(key, identifierId).then((data) => {
        renderIndicators(iframe, page, data, 0, handleSubquestionClick);
        resolve(true);
      });
    });
  }

  function removeActiveQuestionBox() {
    const indicators = iframe.contentDocument.querySelectorAll('.textLayer .indicator');
    indicators.forEach((indicator) => {
      indicator.classList.remove('active');
    });
  }

  function setActiveQuestionBox(page) {
    const { subquestionId } = document.querySelector('[data-js-review-ids]').dataset;
    const pageEl = iframe.contentDocument.querySelector(`.page[data-page-number='${page}']`);
    if (!pageEl) return;

    const activeIndicator = pageEl.querySelector(`.textLayer .indicator[data-id="${subquestionId}"]`);
    if (activeIndicator) {
      removeActiveQuestionBox();
      activeIndicator.classList.add('active');
    }
  }

  const renderAnnotations = debounceWithArgs((pageNumber) => {
    try {
      const { UI } = iframe.contentWindow.PDFAnnotate;
      UI.createPage(pageNumber);
      UI.renderPage(pageNumber);
    } catch (err) {
      console.error(err.toString()); // eslint-disable-line
    }
  }, 100);

  function handlePageRendered(e) {
    if (e.pageNumber === 1 && assignmentType === 'Exam' && skipFirstPage === 'true') {
      e.source.div.querySelector('canvas').hidden = true;
      iframe.contentDocument.querySelector('#sidebarContent').classList.add('hide-first-page');
    }
    renderAnnotations(e.pageNumber);
  }

  function handleTextLayerRendered(e) {
    if (['Exam', 'BubbleSheet'].includes(assignmentType)) {
      displayQuestionBox(e.pageNumber).then(() => {
        setActiveQuestionBox(e.pageNumber);
      });
    }
  }

  function scrollToQuestion() {
    const rotate = PDFViewerApplication.pdfViewer._pagesRotation; // eslint-disable-line no-underscore-dangle
    const pageEl = iframe.contentDocument.querySelector('.page');
    if (!pageEl) return;

    const height = pageEl.clientHeight;

    const upload = document.querySelector('[data-js-file-tab].mdc-tab--active');
    const page = parseInt(JSON.parse(upload.dataset.page || '0'), 10);
    const start = parseFloat(upload.dataset.positionStart) - 1.5;

    const scroll = ((page - 1) * height) + (rotate % 180 === 0 ? (height * start) / 100 : 0);
    iframe.contentDocument.querySelector('#viewerContainer').scrollTo(0, scroll);
  }

  function bindEventListeners() {
    if (!PDFViewerApplication.initialized) {
      return setTimeout(bindEventListeners, 1);
    }

    PDFViewerApplication.eventBus.on('documentloaded', () => {
      handleDocumentLoad();
    });

    PDFViewerApplication.eventBus.on('pagerendered', (e) => {
      handlePageRendered(e);
    });

    PDFViewerApplication.eventBus.on('textlayerrendered', (e) => {
      handleTextLayerRendered(e);
    });

    PDFViewerApplication.eventBus.on('pagesloaded', () => {
      if (assignmentType === 'Exam') scrollToQuestion();
    });

    PDFViewerApplication.eventBus.on('scalechanging', (e) => {
      localStorage.setItem('pdf-scale', e.value || e.scale);
    });

    PDFViewerApplication.eventBus.on('switchcursortool', () => {
      const cursor = iframe.contentDocument.querySelector('#annotate #cursorButton');
      if (cursor) cursor.click();
    });

    return true;
  }

  function loadDocument(id, url, page = 1) {
    loadScreen.style.display = 'flex';
    retry.classList.add('d-none');

    PDFViewerApplication.initialBookmark = `page=${page}`;
    PDFViewerApplicationOptions.set('documentId', id);

    PDFViewerApplication.setTitleUsingUrl(decodeURIComponent(url));
    const loadingTask = pdfjsLib.getDocument({ url, worker, disableRange: true });
    PDFViewerApplication.pdfLoadingTask = loadingTask;
    PDFViewerApplication.loadingBar.show();

    loadingTask.onProgress = (data) => {
      PDFViewerApplication.progress(data.loaded / data.total);
    };

    loadingTask.promise.then((doc) => {
      if (PDFViewerApplicationOptions.get('documentId') !== id) return;
      loadScreen.style.display = 'none';
      PDFViewerApplication.load(doc);
    }).catch((e) => {
      PDFViewerApplication.loadingBar.hide();
      PDFViewerApplication.close();
      if (e.name === 'InvalidPDFException') {
        PDFViewerApplication.error(e.name, {
          message: e.message
        });
      } else {
        retry.classList.remove('d-none');
        throw (e);
      }
    });
  }

  function handleRetryClick() {
    const uploadId = PDFViewerApplicationOptions.get('documentId');
    Rails.ajax({
      url: `/uploads/${uploadId}/presigned_url`,
      type: 'GET',
      success: (data) => {
        loadDocument(uploadId, data.url);
      }
    });
  }

  function init() {
    const { url } = iframe.dataset;
    ({ PDFViewerApplication, PDFViewerApplicationOptions } = iframe.contentWindow);

    if (!PDFViewerApplication ||
        !PDFViewerApplication.initialized ||
        iframe.contentDocument.readyState !== 'complete') {
      setTimeout(init, 100);
      return;
    }
    if (self.initialized) return;
    self.initialized = true;

    ({ pdfjsLib } = iframe.contentWindow);
    loadScreen = iframe.contentDocument.querySelector('[data-js-load-screen]');
    pdfjsLib.GlobalWorkerOptions.workerSrc = PDFViewerApplicationOptions.get('workerSrc');
    worker = new pdfjsLib.PDFWorker('worker1');

    if (iframe.dataset.disableRange) PDFViewerApplicationOptions.set('disableRange', true);

    try {
      iframe.contentWindow.initAnnotateToolbar(viewOnly);
      iframe.contentWindow.initAdapter(viewOnly);
    } catch (err) {
      console.error(err.toString()); // eslint-disable-line
    }

    bindEventListeners();
    if (url) loadDocument(url);
  }

  function handleShowDocument(e) {
    if (!PDFViewerApplication ||
        !PDFViewerApplication.initialized ||
        iframe.contentDocument.readyState !== 'complete') {
      init();
      setTimeout(handleShowDocument, 100, e);
      return;
    }

    const {
      id,
      downloadable,
      page
    } = e.detail;
    let { url } = e.detail;

    if (!url) {
      PDFViewerApplication.close();
      return;
    }
    if (url.includes('&amp;')) {
      url = url.replaceAll('&amp;', '&');
    }
    if (PDFViewerApplicationOptions.get('documentId') === id) {
      if (assignmentType === 'Exam') scrollToQuestion();
      if (['Exam', 'BubbleSheet'].includes(assignmentType)) {
        displayQuestionBox(page).then(() => {
          setActiveQuestionBox(page);
        });
      }
      return;
    }

    if (preloadPdf.id === id) {
      PDFViewerApplication.loadingBar.show();
      loadScreen.style.display = 'flex';

      preloadPdf.loadingTask.promise.then((doc) => {
        loadScreen.style.display = 'none';
        PDFViewerApplication.load(doc);
      }).catch(() => {
        PDFViewerApplication.close();
        PDFViewerApplication.loadingBar.hide();
      });
    } else {
      if (preloadPdf.loadingTask) preloadPdf.loadingTask.destroy();
      canDownload = (String(downloadable) === 'true');
      loadDocument(id, url, page);
    }
  }
  this.handleShowDocument = handleShowDocument;

  iframe.addEventListener('load', init);
  document.addEventListener('pdf:show', this.handleShowDocument);
  if (retry) retry.addEventListener('click', handleRetryClick);
}, function () {
  document.removeEventListener('pdf:show', this.handleShowDocument);
});
