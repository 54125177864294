import { Controller } from '@hotwired/stimulus';
import getQuestionPositions from '@modules/question-positions';
import renderIndicators from '@modules/render-indicators';
import { debounceWithArgs } from '@modules/custom';

export default class extends Controller {
  static targets = ['iframe'];

  static values = {
    assignmentId: Number,
    identifierId: Number,
    pagesCount: Number,
    url: String
  };

  initialize() {
    this.PDFViewerApplication = null;
  }

  connect() {
    this.displayQuestionBox = debounceWithArgs(this.displayQuestionBox.bind(this), 100);
    const iframeDoc = this.iframeTarget.contentDocument || this.iframeTarget.contentWindow.document;
    if (iframeDoc.readyState === 'complete' || iframeDoc.readyState === 'interactive') {
      this.initPDFJS();
    } else {
      this.iframeTarget.addEventListener('load', this.initPDFJS.bind(this));
    }
  }

  urlValueChanged() {
    localStorage.removeItem(`preview-positions-${this.identifierIdValue}`);
    if (this.PDFViewerApplication) this.loadDocument();
  }

  initPDFJS() {
    let PDFViewerApplicationOptions;
    ({
      PDFViewerApplication: this.PDFViewerApplication,
      PDFViewerApplicationOptions
    } = this.iframeTarget.contentWindow);

    if (!this.PDFViewerApplication || !this.PDFViewerApplication.initialized) {
      setTimeout(this.initPDFJS.bind(this), 100);
      return;
    }

    if (this.iframeTarget.dataset.disableRange) PDFViewerApplicationOptions.set('disableRange', true);

    this.bindEventListeners();
    this.loadDocument();
  }

  bindEventListeners() {
    this.PDFViewerApplication.eventBus.on('documentloaded', () => {
      const scale = localStorage.getItem('pdf-scale');
      if (scale) this.PDFViewerApplication.pdfViewer.currentScaleValue = scale;
    });

    this.PDFViewerApplication.eventBus.on('textlayerrendered', (e) => {
      this.displayQuestionBox(e.pageNumber);
    });

    this.PDFViewerApplication.eventBus.on('pagechanging', (e) => {
      localStorage.setItem(`generator/${this.assignmentIdValue}/page`, e.pageNumber);
    });

    this.PDFViewerApplication.eventBus.on('scalechanging', (e) => {
      localStorage.setItem('pdf-scale', e.value);
    });
  }

  loadDocument() {
    if (!this.urlValue) return;

    const page = parseFloat(localStorage.getItem(`generator/${this.assignmentIdValue}/page`) || 1);
    this.PDFViewerApplication.initialBookmark = `page=${page}`;
    this.PDFViewerApplication.setTitleUsingUrl(decodeURIComponent(this.urlValue));
    this.PDFViewerApplication.open({ url: this.urlValue });
  }

  displayQuestionBox(page) {
    const positionsKey = `preview-positions-${this.identifierIdValue}`;
    const pageOffset = this.PDFViewerApplication.pdfViewer.pdfDocument._pdfInfo.numPages - this.pagesCountValue; // eslint-disable-line no-underscore-dangle
    getQuestionPositions(positionsKey, this.identifierIdValue).then((data) => {
      renderIndicators(this.iframeTarget, page, data, pageOffset, this.handleSubquestionClick);
    });
  }

  handleSubquestionClick() {
    const subquestionId = this.dataset.id;
    const subquestion = document.querySelector(`[data-js-subquestion][data-subquestion-id='${subquestionId}']`);
    if (subquestion) subquestion.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
}
