import onmount from 'onmount';
import Rails from '@rails/ujs';

onmount('[data-js-custom-role-select]', function () {
  const self = this;
  const form = this.closest('form');
  const submit = self.getAttribute('data-custom-submit') === 'true';
  const roleIdInput = this.parentElement.querySelector('[data-js-role-id-input]');
  const input = this.querySelector('[data-js-select-input]');

  function handleInputChange() {
    const selected = self.querySelector('.mdc-deprecated-list-item--selected');
    const { roleId } = selected.dataset;

    roleIdInput.value = roleId;
    if (submit) Rails.fire(form, 'submit');
  }

  function init() {
    let selectedIndex = 0;

    const selected = self.querySelector(`.mdc-deprecated-list-item--selected[data-role-id='${roleIdInput.value}']`);
    if (selected) selectedIndex = [...self.querySelectorAll('.mdc-deprecated-list-item')].indexOf(selected);

    self.MDCSelect.setSelectedIndex(selectedIndex || 0);

    [...self.querySelectorAll('.mdc-deprecated-list-item')].forEach((item, index) => {
      if (index !== selectedIndex) {
        item.classList.remove('mdc-deprecated-list-item--selected');
      }
    });
  }

  init();
  input.addEventListener('change', handleInputChange);
});
