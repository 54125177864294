import '@kollegorna/cocoon-vanilla-js';
import onmount from 'onmount';
import mdcAutoInit from '@material/auto-init';

onmount('[data-js-seb-form] [data-js-cocoon-add]', function () {
  function handleAdd() {
    onmount();
    mdcAutoInit();
  }

  function handleRemove(e) {
    const removedItem = e.detail;
    removedItem.classList.add('d-none');
    removedItem.querySelectorAll('textarea, input').forEach((input) => {
      input.removeAttribute('required');
    });
  }

  this.addEventListener('cocoon:after-insert', handleAdd);
  this.addEventListener('cocoon:after-remove', handleRemove);
});
