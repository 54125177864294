import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['element'];

  static values = {
    offset: { type: Number, default: 0 }
  };

  connect() {
    if (this.hasElementTarget) {
      this.element.scrollTop = this.elementTarget.offsetTop - this.offsetValue;
      this.elementTarget.classList.add('light-up');
    }
  }
}
