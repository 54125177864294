import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { url: String };

  connect() {
    if (this.urlValue) { this.download(); }
  }

  download() {
    const link = document.createElement('a');
    link.href = this.urlValue;
    link.download = '';
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    this.element.remove();
  }
}
