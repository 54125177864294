/* global i18n */

import onmount from 'onmount';
import Rails from '@rails/ujs';
import { throttle } from '@modules/custom';

onmount('[data-js-process-status]', function () {
  const identifierErrorsButton = document.querySelector('[data-js-identifier-errors-button]');

  function getCurrentAssignmentIds() {
    return [...document.querySelectorAll('[data-js-assignment]')].map((assignment) => assignment.dataset.assignmentId);
  }

  function checkForNewAssignments() {
    const url = new URL(`${window.location.origin}${window.location.pathname}/load_new_assignments`);
    url.searchParams.set('assignment_ids', getCurrentAssignmentIds());

    Rails.ajax({
      url: url.toString(),
      type: 'GET',
      dataType: 'script'
    });
  }

  function processAssignment(data, processing) {
    const assignment = document.querySelector(`[data-js-assignment][data-assignment-id='${data.id}']`);
    assignment.querySelector('[data-js-scans]').innerText = data.pages;
    assignment.querySelector('[data-js-results]').innerText = data.complete;

    if (data.errors || data.duplicate) {
      assignment.querySelector('[data-js-issues]').classList.remove('d-none');
    } else {
      assignment.querySelector('[data-js-issues]').classList.add('d-none');
    }

    if (!processing && data.missing > 0) {
      assignment.querySelector('[data-js-missing]').innerText = i18n.t('js.scans.missing', { count: data.missing });
      assignment.querySelector('[data-js-missing]').classList.remove('d-none');
    } else {
      assignment.querySelector('[data-js-missing]').classList.add('d-none');
    }
  }

  function uploadInProgress() {
    return document.querySelector('[data-js-dropzone]').dropzone.getUploadingFiles().length;
  }

  function getProcessStatus(loadNewAssignment = true) {
    const url = new URL(`${window.location.origin}${window.location.pathname}/process_status`);
    url.searchParams.set('assignment_ids', getCurrentAssignmentIds());

    Rails.ajax({
      url: url.toString(),
      type: 'GET',
      dataType: 'json',
      success: (data) => {
        if (data.errors > 0) {
          identifierErrorsButton.classList.remove('d-none');
        } else {
          identifierErrorsButton.classList.add('d-none');
        }

        data.assignments.forEach((assignment) => {
          processAssignment(assignment, data.processing);
        });

        if (data.new_assignment && loadNewAssignment) checkForNewAssignments();

        if (data.processing || uploadInProgress()) {
          setTimeout(getProcessStatus, 10000);
        }
      }
    });
  }

  function handleFileUploaded() {
    getProcessStatus();
  }
  this.handleFileUploaded = throttle(handleFileUploaded, 5000);

  setTimeout(getProcessStatus, 10000);

  document.addEventListener('dropzone:fileUploaded', this.handleFileUploaded);
}, function () {
  document.removeEventListener('dropzone:fileUploaded', this.handleFileUploaded);
});
