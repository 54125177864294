import onmount from 'onmount';

onmount('[data-js-delete-assignment-scans]', function () {
  const input = this.querySelector('[data-js-assignment-name]');
  const assignmentName = input.getAttribute('data-js-assignment-name');
  const confirmButton = this.querySelector('[data-js-confirm-delete]');

  function handleInputChange() {
    if (this.value.toLowerCase().replace(/\s/g, '') === assignmentName.toLowerCase().replace(/\s/g, '')) {
      confirmButton.removeAttribute('disabled');
    } else {
      confirmButton.setAttribute('disabled', true);
    }
  }

  input.addEventListener('input', handleInputChange);
  input.addEventListener('paste', handleInputChange);
});
