import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="select-button"
export default class extends Controller {
  static classes = ['selected'];

  static targets = ['button', 'input'];

  connect() {
    if (this.hasInputTarget) {
      let target = this.findButtonByValue(this.inputTarget.value);
      target = target || this.buttonTarget;

      this.select({ currentTarget: target, params: { value: target.dataset.selectButtonValueParam } });
    }
  }

  findButtonByValue(value) {
    return this.buttonTargets.find((el) => el.dataset.selectButtonValueParam === value);
  }

  select({ currentTarget, params: { value } }) {
    if (currentTarget.classList.contains(this.selectedClass)) return;

    this.deselectAll();
    currentTarget.classList.add(this.selectedClass);

    if (this.hasInputTarget) this.inputTarget.value = value;
    this.dispatch('select', { detail: { value } });
  }

  deselectAll() {
    this.buttonTargets.forEach((el) => el.classList.remove(this.selectedClass));
  }
}
