/* global i18n */

import onmount from 'onmount';

onmount('[data-js-time-ago]', function () {
  const self = this;
  this.updateTimeout = null;
  let timeAgo;

  let { locale } = i18n;
  if (locale === 'nl_vo') locale = 'nl';

  function setTime() {
    const { time, prefix } = self.dataset;
    if (!time) return;

    const date = new Date(time);
    if (prefix) {
      self.innerHTML = `${prefix} ${timeAgo.format(date)}`;
    } else {
      self.innerHTML = timeAgo.format(date);
    }

    clearTimeout(self.updateTimeout);
    self.updateTimeout = setTimeout(setTime, 60 * 1000);
  }

  function freeze() {
    clearTimeout(self.updateTimeout);
  }

  function restart() {
    self.setAttribute('data-time', new Date());
    setTime();
  }

  function init(TimeAgo, languages) {
    languages.forEach((lang) => TimeAgo.addLocale(lang));
    timeAgo = new TimeAgo(locale);

    setTime();
  }

  Promise.all([
    import(/* webpackChunkName: "timeagojs" */ 'javascript-time-ago'),
    import(/* webpackChunkName: "timeagojs" */ 'javascript-time-ago/locale/en'),
    import(/* webpackChunkName: "timeagojs" */ 'javascript-time-ago/locale/nl'),
    import(/* webpackChunkName: "timeagojs" */ 'javascript-time-ago/locale/de'),
    import(/* webpackChunkName: "timeagojs" */ 'javascript-time-ago/locale/fr'),
    import(/* webpackChunkName: "timeagojs" */ 'javascript-time-ago/locale/pt')
  ]).then(([module, en, nl, de, fr, pt]) => {
    init(module.default, [en.default, nl.default, de.default, fr.default, pt.default]); // eslint-disable-line max-len
  }).catch((error) => console.log(error)); // eslint-disable-line no-console

  self.addEventListener('timeago:freeze', freeze);
  self.addEventListener('timeago:restart', restart);
}, function () {
  clearTimeout(this.updateTimeout);
});
