/* global Redactor */

(function ($R) {
  $R.add('plugin', 'record', {
    translations: {
      en: {
        record: 'Record',
        'error-accessing-microphone': 'Error accessing the microphone.',
        save: 'Save'
      }
    },
    init(app) {
      this.app = app;
      this.toolbar = app.toolbar;
      this.lang = app.lang;
      this.opts = app.opts;
      this.insertion = app.insertion;

      this.stream = null;
      this.mediaRecorder = null;
      this.chunks = [];
      this.isRecording = false;
      this.stopTimeout = null;
    },
    start() {
      const buttonData = {
        title: this.lang.get('record')
      };

      const $button = this.toolbar.addButtonAfter('ul', 'record', buttonData);
      $button.setIcon('<i class="material-icons">mic</i>');

      [this.button] = $button.nodes;
      [this.icon] = $button.$icon.nodes;

      this.button.addEventListener('click', this.toggleRecording.bind(this));
    },
    async toggleRecording() {
      if (this.isRecording) {
        this.debouncedStopRecording();
      } else {
        await this.startRecording();
      }
    },
    async startRecording() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        this.stream = stream;
        this.mediaRecorder = new MediaRecorder(stream);
        this.chunks = [];
        this.isRecording = true;

        this.mediaRecorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            this.chunks.push(event.data);
          }
        };

        this.mediaRecorder.onstop = this.handleRecordingStop.bind(this);

        this.mediaRecorder.start();
        setTimeout(() => {
          this.icon.textContent = 'graphic_eq';
          this.button.classList.add('redactor-button-active');
        }, 300);
      } catch (error) {
        alert(this.lang.get('error-accessing-microphone'));
      }
    },
    debouncedStopRecording() {
      clearTimeout(this.stopTimeout);
      this.stopTimeout = setTimeout(() => this.stopRecording(), 300);
    },
    stopRecording() {
      if (!this.isRecording) return;

      this.isRecording = false;
      if (this.mediaRecorder && this.mediaRecorder.state === 'recording') {
        this.mediaRecorder.stop();
      }
      if (this.stream) {
        this.stream.getTracks().forEach((track) => track.stop());
      }
      this.icon.textContent = 'mic';
      this.button.classList.remove('redactor-button-active');
    },
    handleRecordingStop() {
      const audioBlob = new Blob(this.chunks, { type: 'audio/ogg; codecs=opus' });
      const file = new File([audioBlob], 'recording.ogg', { type: 'audio/ogg' });
      const options = {
        url: this.opts.fileUpload,
        event: false,
        files: [file],
        name: 'audio'
      };
      this.app.api('module.upload.send', options);
    },
    onupload: {
      audio: {
        complete(response) {
          this.insertAudio(response['file-0'].url);
        }
      }
    },
    insertAudio(url) {
      const $wrapper = this.app.component.create('audio');
      const $audio = $R.dom('<audio>');

      $audio.attr('src', url);
      $audio.attr('controls', true);
      $audio.attr('controlsList', 'nodownload');

      $wrapper.append($audio);
      this.insertion.insertHtml($wrapper);
    }
  });
}(Redactor));
