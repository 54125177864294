import onmount from 'onmount';
import Cookies from 'js-cookie';

onmount('[data-js-cookies]', function () {
  const self = this;
  const cookiesAllowed = Cookies.get('allow_cookies') === 'yes';
  const cookiesNotice = self.querySelector('[data-js-cookies-notice]');

  function enableGoogleAnalytics() {
    const tagManagerScript = document.createElement('script');
    tagManagerScript.innerText = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':" +
      "new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0]," +
      "j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=" +
      "'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);" +
      "})(window,document,'script','dataLayer','GTM-MRDQ2XS');";

    document.head.appendChild(tagManagerScript);
  }

  function allowCookies(allow) {
    if (allow) {
      Cookies.set('allow_cookies', 'yes', { expires: 365 });
      enableGoogleAnalytics();
    } else {
      Cookies.set('allow_cookies', 'no', { expires: 365 });
    }
    cookiesNotice.remove();
  }

  if (cookiesAllowed) {
    enableGoogleAnalytics();
  } else if (cookiesNotice) {
    self.querySelector('[data-js-accept]').addEventListener('click', () => { allowCookies(true); });
    self.querySelector('[data-js-decline]').addEventListener('click', () => { allowCookies(false); });
  }
});
