import onmount from 'onmount';

onmount('[data-js-reuse-template]', () => {
  const selectMenu = document.querySelector('[data-js-template]');

  function showOptions() {
    document.querySelector('[data-js-options').classList.remove('d-none');
  }

  function hideOptions() {
    document.querySelector('[data-js-options').classList.add('d-none');
  }

  function handleTemplateChange() {
    const { value } = document.querySelector('[data-js-template] [data-js-select-input]');
    if (value) {
      hideOptions();
    } else {
      showOptions();
    }
  }

  selectMenu.addEventListener('change', handleTemplateChange);
});
