function dispatchImageAddedEvent(previewWrapper, preview) {
  const customEvent = new CustomEvent('imageAdded', {
    detail: { element: preview }
  });
  previewWrapper.dispatchEvent(customEvent);
}

export function showPreview(previewWrapper, file, data) {
  if (!previewWrapper) return;

  previewWrapper.classList.remove('d-none');
  const previewUrl = URL.createObjectURL(file);
  let preview;

  if (file.type.includes('image')) {
    preview = document.createElement('img');
    preview.src = previewUrl;

    if (previewWrapper.querySelector('[data-js-rotation-wrapper]')) {
      previewWrapper.querySelector('[data-js-rotation-wrapper]').classList.remove('d-none');
      preview.classList.add('responsive-img');
    } else {
      preview.style.height = '100%';
      preview.style.width = '100%';
    }

    if (previewWrapper.hasAttribute('data-js-enlarge-images')) {
      dispatchImageAddedEvent(previewWrapper, preview);
    }
  } else if (file.type.includes('video')) {
    preview = document.createElement('video');
    preview.id = 'video';
    preview.src = previewUrl;
    preview.type = file.type;
    preview.controls = true;
    previewWrapper.querySelector('[data-js-rotation-wrapper]').classList.add('d-none');
  } else {
    previewWrapper.classList.add('d-none');
  }

  previewWrapper.querySelector('[data-js-image-wrapper]').innerHTML = '';
  previewWrapper.querySelector('[data-js-image-wrapper]').append(preview);

  if (previewWrapper.querySelector('[data-js-rotation-wrapper]')) {
    previewWrapper.querySelector('[data-js-rotation-wrapper]').setAttribute('data-upload-id', data.id);
    previewWrapper.querySelector('[data-js-rotation-wrapper]').setAttribute('data-rotation', 0);
  }
}

export function hidePreview(previewWrapper) {
  if (!previewWrapper) return;

  previewWrapper.classList.add('d-none');
  previewWrapper.querySelector('[data-js-image-wrapper]').innerHTML = '';

  if (previewWrapper.querySelector('[data-js-rotation-wrapper]')) {
    previewWrapper.querySelector('[data-js-rotation-wrapper]').classList.add('d-none');
    previewWrapper.querySelector('[data-js-rotation-wrapper]').removeAttribute('data-upload-id');
    previewWrapper.querySelector('[data-js-rotation-wrapper]').setAttribute('data-rotation', 0);
  }
}
