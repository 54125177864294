import { MDCTextField } from '@material/textfield';
import { MDCSelect } from '@material-overwrites/mdc.select';

function materialComponents() {
  return {
    MDCTextField,
    MDCSelect
  };
}

export function initialize(el) {
  const type = el.getAttribute('data-mdc-auto-init');
  if (!el[type]) {
    const MdcClass = materialComponents()[type];
    el[type] = new MdcClass(el);
    el.setAttribute('data-mdc-auto-init-state', 'initialized');
  }

  return el[type];
}
