import onmount from 'onmount';
import Fuse from 'fuse.js';
import { debounce } from '@modules/custom';

onmount('[data-js-client-search-field]', function () {
  const self = this;
  const { sortResults } = this.dataset;
  const input = this.querySelector('input');
  const reset = this.querySelector('[data-js-reset]');
  const noResults = this.parentElement.querySelector('[data-js-no-results]');

  let search;
  let target;
  let collection;
  let groups;

  function searchArray() {
    const array = [];
    let keys = [];

    collection.forEach((item, index) => {
      const obj = { id: index };
      item.querySelectorAll('[data-js-searchable-field]').forEach((field, idx) => {
        obj[`key${idx}`] = field.innerText.trim();
      });

      array.push(obj);
      if (Object.keys(obj).length > keys.length) keys = Object.keys(obj);
    });

    return { keys, array };
  }

  function showCollection() {
    target.classList.remove('d-none');
    if (noResults) {
      noResults.classList.add('d-none');
    }
  }

  function hideCollection() {
    target.classList.add('d-none');
    if (noResults) {
      noResults.classList.remove('d-none');
    }
  }

  function updateGroupVisibility() {
    groups.forEach((item) => {
      if (item.querySelector('[data-js-searchable-item]:not(.d-none)')) {
        item.classList.remove('d-none');
      } else {
        item.classList.add('d-none');
      }
    });
  }

  function inputChange() {
    showCollection();

    if (input.value.trim().length > 0) {
      collection.forEach((el) => el.classList.add('d-none'));

      const matches = search.fuse.search(input.value);

      matches.forEach((match) => {
        const el = collection[match.refIndex];
        el.classList.remove('d-none');
        if (sortResults) target.appendChild(el);
      });

      if (matches.length === 0) hideCollection();
      if (reset) reset.classList.remove('d-none');
    } else {
      collection.forEach((el) => {
        el.classList.remove('d-none');
        if (sortResults) target.appendChild(el);
      });
      if (reset) reset.classList.add('d-none');
    }

    if (groups.length > 0) {
      updateGroupVisibility();
    }
  }

  function resetSearch() {
    input.value = '';
    inputChange();
    input.focus();
  }

  function refresh() {
    if (!search) return;

    collection = target.querySelectorAll('[data-js-searchable-item]');
    search.fuse.setCollection(searchArray().array);
  }

  function init() {
    target = document.querySelector(self.dataset.jsClientSearchField);
    if (!target) return;

    collection = target.querySelectorAll('[data-js-searchable-item]');
    if (collection.length <= 0) return;

    groups = target.querySelectorAll('[data-js-searchable-field-group]');

    const { array, keys } = searchArray();
    const options = {
      keys,
      isCaseSensitive: false,
      threshold: 0.1,
      ignoreLocation: true,
      minMatchCharLength: 1
    };

    search = { fuse: new Fuse(array, options) };

    if (input.value.trim().length > 0) inputChange();

    input.addEventListener('input', debounce(inputChange, 250));
    input.addEventListener('paste', debounce(inputChange, 250));

    if (reset) {
      reset.addEventListener('click', resetSearch);
    }
  }
  // bind to call on search-field from js.erb
  this.init = init;
  this.refresh = refresh;

  init();
});
