import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="choice-scoring-method"
export default class extends Controller {
  static outlets = ['feedback-counter'];

  static targets = [
    'maximumPoints', 'gradingTypeField', 'partialScoringField', 'choice', 'pointsFieldWrapper', 'feedbackMistake',
    'bounds'
  ];

  connect() {
    this.updatePointsFieldVisibility();
  }

  update({ params: { gradingType, partialScoring } }) {
    this.gradingTypeFieldTarget.value = gradingType;
    this.partialScoringFieldTarget.value = partialScoring;

    this.updatePointsFieldVisibility();
    this.feedbackCounterOutlet.update();

    this.dispatch('scoringMethodChanged', { detail: { value: gradingType } });
  }

  showAutomaticScoringFields() {
    this.boundsTarget.classList.add('d-none');
    this.hidePointsFields();
    this.showChoiceSelectButtons();
    if (this.partialScoringFieldTarget.value === 'false') this.hideFeedbackMistakeField();
  }

  showCustomScoringFields() {
    this.boundsTarget.classList.remove('d-none');
    this.showPointsFields();
    this.hideChoiceSelectButtons();
    this.showFeedbackMistakeField();
  }

  updatePointsFieldVisibility() {
    if (this.gradingTypeFieldTarget.value === 'automatic_scoring') {
      this.showAutomaticScoringFields();
    } else {
      this.showCustomScoringFields();
    }
  }

  showPointsFields() {
    this.pointsFieldWrapperTargets.forEach((el) => el.classList.remove('d-none'));
  }

  hidePointsFields() {
    this.pointsFieldWrapperTargets.forEach((el) => el.classList.add('d-none'));
  }

  showChoiceSelectButtons() {
    this.choiceTargets.forEach((el) => el.classList.remove('d-none'));
  }

  hideChoiceSelectButtons() {
    this.choiceTargets.forEach((el) => el.classList.add('d-none'));
  }

  showFeedbackMistakeField() {
    this.feedbackMistakeTarget.classList.remove('d-none');
  }

  hideFeedbackMistakeField() {
    this.feedbackMistakeTarget.classList.add('d-none');
  }
}
