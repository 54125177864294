import { newComponent } from './commands/create';

export function importSvg(tool, svgString) {
  if (!svgString) return;

  const parser = new DOMParser();
  const svg = parser.parseFromString(svgString, 'image/svg+xml').documentElement;

  const viewBox = svg.getAttribute('viewBox');
  if (viewBox) tool.element.setAttribute('viewBox', viewBox);

  const re = /.*\((-?.*) (-?.*)\)/;
  [...svg.children].forEach((el) => {
    const component = newComponent(
      el.tagName,
      el.getAttribute('id'),
      {},
      el.getAttribute('stroke'),
      el.getAttribute('stroke-width')
    );

    component.element = el;
    component.path = el.getAttribute('d');

    const transform = el.getAttribute('transform');
    if (transform) {
      const match = transform.match(re);
      if (match) component.transform = { x: Number(match[1]), y: Number(match[2]) };
    }

    tool.Objects.push(component);
    tool.selectManager.wrapper.before(component.element);
  });
}
