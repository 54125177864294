import onmount from 'onmount';

onmount('[data-js-response-rules]', function () {
  function handleResponseRuleAdd() {
    onmount();
  }

  function handleResponseRuleRemove(e) {
    const removedItem = e.detail;
    removedItem.classList.add('d-none');
    removedItem.querySelector("input[required='required']").removeAttribute('required');
    removedItem.querySelector('.mdc-select--required').classList.remove('mdc-select--required');
  }

  this.addEventListener('cocoon:after-insert', handleResponseRuleAdd);
  this.addEventListener('cocoon:after-remove', handleResponseRuleRemove);
});
