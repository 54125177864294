/* global Redactor */
(function ($R) {
  $R.add('plugin', 'filemanager', {
    translations: {
      en: {
        choose: 'Choose'
      }
    },
    init(app) {
      this.app = app;
      this.lang = app.lang;
      this.opts = app.opts;
      this.opts.fileManagerUrl = null;

      if (typeof this.opts.fileManagerJson === 'function') {
        this.opts.fileManagerUrl = this.opts.fileManagerJson(this);
      } else {
        this.opts.fileManagerUrl = this.opts.fileManagerJson;
      }
    },

    // messages
    onmodal: {
      file: {
        open($modal) {
          if (!this.opts.fileManagerUrl) return;

          this.load($modal);
        }
      }
    },

    // private
    load($modal) {
      const $body = $modal.getBody();

      this.$box = $R.dom('<div>');
      this.$box.attr('data-title', this.lang.get('choose'));
      this.$box.addClass('redactor-modal-tab');
      this.$box.hide();
      this.$box.css({
        overflow: 'auto',
        height: '300px',
        'line-height': 1
      });

      $body.append(this.$box);

      $R.ajax.get({
        url: this.opts.fileManagerUrl,
        success: this.parse.bind(this)
      });
    },
    parse(data) {
      const $ul = $R.dom("<ul id='redactor-filemanager-list'>");
      data.forEach((obj) => {
        const objData = {
          id: obj.id,
          url: obj.download_url,
          name: obj.file_name,
          size: obj.file_size
        };

        const $li = $R.dom('<li>');
        const $item = $R.dom('<a>');
        $item.attr('href', '#');
        $item.addClass('redactor-file-manager-link');
        $item.attr('data-params', encodeURI(JSON.stringify(objData)));
        $item.text(obj.file_name);
        $item.on('click', this.insert.bind(this));

        const $size = $R.dom('<span>');
        $size.addClass('r-file-size');
        $size.text(`(${obj.file_size})`);
        $item.append($size);

        $li.append($item);
        $ul.append($li);
      });

      this.$box.append($ul);
    },
    insert(e) {
      e.preventDefault();

      const $el = $R.dom(e.target).closest('.redactor-file-manager-link');
      const data = JSON.parse(decodeURI($el.attr('data-params')));

      this.app.api('module.file.insert', { file: data });
    }
  });
}(Redactor));
