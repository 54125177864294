import { Controller } from '@hotwired/stimulus';
import { addToUrlParams } from '@modules/url_helpers';
import { useDebounce } from 'stimulus-use';

// Connects to data-controller="remote-url-params"
export default class extends Controller {
  static debounces = ['update'];

  static targets = ['input'];

  connect() {
    useDebounce(this, { wait: 250 });
  }

  update({ params: { column } }) {
    const { url } = this.element.dataset;
    const changedUrl = addToUrlParams(new URL(url), column, this.inputTarget.value);

    this.element.setAttribute('data-url', changedUrl);
  }
}
