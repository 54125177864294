/* global i18n, Turbo */

import { Controller } from '@hotwired/stimulus';
import { Uploader } from '@modules/active_storage/uploader';
import { convertPdfToJpg } from '@modules/active_storage/pdf-to-jpg-converter';
import snackbar from '@components/snackbar';

export default class extends Controller {
  static targets = ['table', 'blankslate'];

  static values = {
    uploader: String,
    recentlyUploadedFiles: Array,
    allowedFileTypes: {
      type: Array,
      default: ['application/pdf', 'application/zip', 'application/x-zip', 'application/x-zip-compressed']
    }
  };

  initialize() {
    this.recentlyUploadedFiles = this.recentlyUploadedFilesValue;
  }

  upload(e) {
    const { files } = e.target;
    Array.from(files).forEach((file) => this.uploadFile(e.target, file));
    e.target.value = '';
  }

  uploadFile(input, file) {
    if (this.recentlyUploadedFiles.includes(file.name)) {
      const confirmed = window.confirm(i18n.t('js.page_collections.file_already_uploaded', { filename: file.name }));
      if (!confirmed) return false;
    } else {
      this.recentlyUploadedFiles = [file.name].concat(this.recentlyUploadedFiles);
    }

    const url = input.dataset.directUploadUrl;
    const previewEl = this.directUploadInitialize(file);
    if (!this.allowedFileTypesValue.includes(file.type)) {
      return this.directUploadError(previewEl, i18n.t('js.page_collections.file_type_not_supported'));
    }

    const uploader = new Uploader(previewEl, file, url);
    uploader.start().then((blob) => {
      this.createPageCollection(input.form.action, blob.signed_id);
    }).catch(() => {
      this.directUploadError(previewEl);
    });
  }

  convertPdfToJpgOrSubmit(e) {
    const file = e.target.files[0];
    if (!file) return; // No file selected

    if (file.type !== 'application/pdf') {
      e.target.form.requestSubmit();
      return;
    }

    snackbar(i18n.t('js.pages.converting_pdf_to_jpg'));
    convertPdfToJpg(file).then((blob) => {
      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(blob);
      e.target.files = dataTransfer.files;
      e.target.form.requestSubmit();
    }).catch(() => snackbar(i18n.t('js.pages.converting_failed'), true));
  }

  createPageCollection(url, signedId) {
    Turbo.fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        accept: 'text/vnd.turbo-stream.html',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({ page_collection: { file: signedId } })
    })
      .then((res) => res.text())
      .then((html) => {
        Turbo.renderStreamMessage(html);
      });
  }

  directUploadInitialize(file) {
    if (this.hasBlankslateTarget) this.blankslateTarget.remove();
    this.tableTarget.insertAdjacentHTML('afterbegin', `
      <div id="${file.name}" class="box mb-3">
        <div class="box box-filled p-3 d-flex flex-justify-between flex-items-center" data-turbo-permanent>
          <div class="flex-column">
            <div>
              <span class="f4 text-bold">${file.name}</span>
            </div>
            <div class="d-flex flex-items-center">${i18n.t('js.page_collections.uploading')}</div>
          </div>
          <div class="d-flex flex-items-end flex-column">
            <div class="progress-bar d-block my-3" data-progress-bar>
              <span class="progress" style="width: 0%" data-progress></span>
            </div>
          </div>
        </div>
      </div>
    `);
    return this.tableTarget.querySelector(`[id="${file.name}"]`);
  }

  directUploadError(element, message = i18n.t('js.page_collections.upload_failed')) {
    element.querySelector('[data-progress-bar]').outerHTML =
      `<div class="flex-column">${message}</div>`;
    element.querySelector('.box').classList.add('box--danger');
  }
}
