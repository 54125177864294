import onmount from 'onmount';
import Cookies from 'js-cookie';

onmount('[data-js-login]', function () {
  const self = this;
  const showSchools = self.querySelector('[data-js-show-schools]');
  const schools = document.querySelector('[data-js-schools]');
  const resetLogin = document.querySelector('[data-js-reset-login]');
  const ssoReset = document.querySelector('[data-js-sso-reset]');

  function handleShowSchoolsClick() {
    self.classList.add('d-none');
    schools.classList.remove('d-none');
  }

  function resetLoginClick() {
    schools.classList.add('d-none');
    self.classList.remove('d-none');
  }

  function ssoLoginClick() {
    Cookies.set('sso_name', this.dataset.name, { expires: 365 });
    Cookies.set('sso_method', this.form.method, { expires: 365 });
  }

  function ssoResetClick() {
    Cookies.remove('sso_name');
    Cookies.remove('sso_method');

    document.querySelector('[data-js-sso-login]').classList.add('d-none');
    self.classList.remove('d-none');
  }

  if (resetLogin) resetLogin.addEventListener('mousedown', resetLoginClick);
  if (showSchools) showSchools.addEventListener('mouseup', handleShowSchoolsClick);
  if (ssoReset) { ssoReset.addEventListener('mousedown', ssoResetClick); }

  document.querySelectorAll('[data-js-select-sso]').forEach((sso) => {
    sso.addEventListener('mouseup', ssoLoginClick);
  });
});
