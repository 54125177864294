/* global i18n, Channels */

import snackbar from '@components/snackbar';
import { throttle } from '@modules/custom';
import onmount from 'onmount';
import consumer from './consumer';

onmount('[data-js-dashboard-live]', () => {
  const handleResponse = throttle((data) => {
    if (data.online_user_count_ans) {
      const onlineUserEvent = new CustomEvent('updateOnlineUsersData', {
        detail: { ansUsers: data.online_user_count_ans, wootsUsers: data.online_user_count_woots }
      });
      document.dispatchEvent(onlineUserEvent);
    }
    if (data.task_count) {
      const tasksEvent = new CustomEvent('updateTasksData', {
        detail: { tasksCount: data.task_count }
      });
      document.dispatchEvent(tasksEvent);
    }
    if (data.throughput && data.waiting_time) {
      const taskPerformanceEvent = new CustomEvent('updateTaskPerformanceData', {
        detail: {
          throughput: data.throughput,
          waitingTime: data.waiting_time,
          waitingTimeInWords: data.waiting_time_in_words
        }
      });
      document.dispatchEvent(taskPerformanceEvent);
    }
    if (data.number_of_servers) {
      const serversEvent = new CustomEvent('updateServersCount', {
        detail: { serversCount: data.number_of_servers }
      });
      document.dispatchEvent(serversEvent);
    }
  }, 1000, true);

  const dashboard = consumer.subscriptions.create({
    channel: 'DashboardChannel'
  }, {
    disconnected() {
      snackbar(i18n.t('js.general.connection_lost'));
    },

    received(data) {
      handleResponse(data);
    }
  });

  Channels.dashboard = dashboard;
});
