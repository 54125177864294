import mdcAutoInit from '@material/auto-init';
import { MDCCheckbox } from '@material/checkbox';
import { MDCChipSet } from '@material/chips/deprecated';
import { MDCDataTable } from '@material/data-table';
import { MDCDialog } from '@material/dialog';
import { MDCDrawer } from '@material/drawer';
import { MDCFormField } from '@material/form-field';
import { MDCLinearProgress } from '@material/linear-progress';
import { MDCList } from '@material/list';
import { MDCMenu } from '@material-overwrites/mdc.menu';
import { MDCRadio } from '@material/radio';
import { MDCRipple } from '@material/ripple';
import { MDCSelect } from '@material-overwrites/mdc.select';
import { MDCSlider } from '@material/slider';
import { MDCSnackbar } from '@material/snackbar';
import { MDCSwitch } from '@material/switch/deprecated';
import { MDCTab } from '@material/tab';
import { MDCTabBar } from '@material/tab-bar';
import { MDCTabScroller } from '@material/tab-scroller';
import { MDCTextField } from '@material/textfield';
import { MDCTopAppBar } from '@material/top-app-bar';
import { MDCTooltip } from '@material/tooltip';

mdcAutoInit.register('MDCCheckbox', MDCCheckbox);
mdcAutoInit.register('MDCChipSet', MDCChipSet);
mdcAutoInit.register('MDCDataTable', MDCDataTable);
mdcAutoInit.register('MDCDialog', MDCDialog);
mdcAutoInit.register('MDCDrawer', MDCDrawer);
mdcAutoInit.register('MDCFormField', MDCFormField);
mdcAutoInit.register('MDCLinearProgress', MDCLinearProgress);
mdcAutoInit.register('MDCList', MDCList);
mdcAutoInit.register('MDCMenu', MDCMenu);
mdcAutoInit.register('MDCRadio', MDCRadio);
mdcAutoInit.register('MDCRipple', MDCRipple);
mdcAutoInit.register('MDCSelect', MDCSelect);
mdcAutoInit.register('MDCSlider', MDCSlider);
mdcAutoInit.register('MDCSnackbar', MDCSnackbar);
mdcAutoInit.register('MDCSwitch', MDCSwitch);
mdcAutoInit.register('MDCTab', MDCTab);
mdcAutoInit.register('MDCTabBar', MDCTabBar);
mdcAutoInit.register('MDCTabScroller', MDCTabScroller);
mdcAutoInit.register('MDCTextField', MDCTextField);
mdcAutoInit.register('MDCTopAppBar', MDCTopAppBar);
mdcAutoInit.register('MDCTooltip', MDCTooltip);

window.mdcAutoInit = mdcAutoInit;
