import onmount from 'onmount';
import { initialize } from '@modules/material-initialize';

onmount('[data-js-plan-assignment]', function () {
  const courses = this.querySelector('[data-js-course-collection]');
  const courseFields = this.querySelector('[data-js-course-fields]');
  const newCourseRadio = this.querySelector('[data-js-new-course] input');
  const courseNameField = courseFields.querySelector('[data-js-course-name]');

  function setDisabled(disabled) {
    [...courseFields.querySelectorAll('[data-js-course-field]')].forEach((el) => {
      const mdcComponent = initialize(el);
      mdcComponent.disabled = disabled;
    });
  }

  function showCourseFields() {
    courseFields.classList.remove('d-none');
    setDisabled(false);
    courseNameField.focus();
  }

  function hideCourseFields() {
    courseFields.classList.add('d-none');
    setDisabled(true);
  }

  function handleCourseSelect() {
    if (newCourseRadio && newCourseRadio.checked) {
      showCourseFields();
    } else {
      hideCourseFields();
    }
  }

  courses.addEventListener('change', handleCourseSelect);
  handleCourseSelect();
});
