import onmount from 'onmount';

onmount('[data-js-adjustment-form]', function () {
  const self = this;
  const newButton = document.querySelector('[data-js-adjustment-new]');
  const cancel = this.querySelector('[data-js-adjustment-cancel]');
  const pointsInput = this.querySelector('[data-js-adjustment-points]');

  function handleCancelAdjustmentClick() {
    newButton.classList.remove('d-none');
    self.remove();
  }

  if (cancel) cancel.addEventListener('click', handleCancelAdjustmentClick);
  pointsInput.focus();
});
