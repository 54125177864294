import { Controller } from '@hotwired/stimulus';
import { install, uninstall } from '@github/hotkey';
import { useMutation } from 'stimulus-use';

export default class extends Controller {
  connect() {
    useMutation(this, { element: document.body, attributeFilter: ['data-hotkeys-disabled'] });
    this.toggleHotkey();
  }

  disconnect() {
    uninstall(this.element);
  }

  mutate() {
    this.toggleHotkey();
  }

  toggleHotkey() {
    const { hotkeysDisabled } = document.body.dataset;
    const disabled = ['always', 'temporary'].includes(hotkeysDisabled);

    if (disabled) {
      uninstall(this.element);
      this.element.setAttribute('data-tooltip-target', 'disabled');
    } else {
      install(this.element);
      this.element.removeAttribute('data-tooltip-target');
    }
  }
}
