import onmount from 'onmount';

onmount('[data-js-subelement-edit]', function () {
  const youtubeUrl = this.querySelector('[data-js-subelement-youtube-url]');

  /* FUNCTIONS */
  function handleYoutubeUrlChange() {
    const url = youtubeUrl.value;
    const youtubeIdRegex = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(youtubeIdRegex);

    if (match && match[2].length !== 11) return;

    const youtubeId = match[2];
    youtubeUrl.value = `https://www.youtube.com/embed/${youtubeId}`;
  }

  /* EVENT HANDLERS */
  if (youtubeUrl) youtubeUrl.addEventListener('input', handleYoutubeUrlChange);
});
