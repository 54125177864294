/* global Redactor */

(function ($R) {
  $R.add('plugin', 'video', {
    init(app) {
      this.app = app;
      this.lang = app.lang;
      this.opts = app.opts;
      this.component = app.component;
      this.insertion = app.insertion;
      this.inspector = app.inspector;
    },

    oncontextbar(e, contextbar) {
      const data = this.inspector.parse(e.target);
      if (data.isComponentType('video')) {
        const node = data.getComponent();
        const buttons = {
          remove: {
            title: this.lang.get('delete'),
            api: 'plugin.video.remove',
            args: node
          }
        };

        contextbar.set(e, node, buttons, 'bottom');
      }
    },

    // public
    start() {},
    remove(node) {
      this.component.remove(node);
    }
  });
}(Redactor));
