import onmount from 'onmount';
import { orderChoicesBasedOnArray, arrayWithChoiceContent } from '@modules/choice-ordering';

onmount('[data-js-choice-actions]', function () {
  const subquestion = this.closest('[data-js-subquestion]');
  const shuffle = this.querySelector('[data-js-shuffle-choices]');
  const sort = this.querySelector('[data-js-sort-choices]');
  const negativePoints = this.querySelector('[data-js-negative-points-choices]');

  function handleShuffleChoices() {
    const choiceArray = arrayWithChoiceContent(subquestion);

    choiceArray.sort(() => 0.5 - Math.random());

    orderChoicesBasedOnArray(subquestion, choiceArray);
  }

  function handleSortChoices() {
    const choiceArray = arrayWithChoiceContent(subquestion);

    choiceArray.sort((a, b) => {
      const valA = a.value.toLowerCase();
      const valB = b.value.toLowerCase();

      if (valA === '?') {
        return 1;
      }
      if (valB === '?') {
        return -1;
      }
      if (valA < valB) {
        return -1;
      }
      if (valA > valB) {
        return 1;
      }
      return 0;
    });
    orderChoicesBasedOnArray(subquestion, choiceArray);
  }

  function setQuestionMark(textarea) {
    if (textarea.redactor) {
      textarea.redactor.source.setCode('<div>?</div>');
      subquestion.querySelector('[data-js-zero-bound]').checked = false;
    } else {
      setTimeout(setQuestionMark, 100, textarea);
    }
  }

  function handleNegativePointsChoices() {
    const subquestionPoints = subquestion.querySelector('[data-js-subquestion-points]').value;

    [...subquestion.querySelectorAll('[data-js-choices] [data-js-choice]:not(.d-none)')].forEach((choice) => {
      const textarea = choice.querySelector('[data-js-redactor]');
      if (textarea.redactor.source.getCode() === '<div>?</div>') {
        choice.classList.add('d-none');
        choice.querySelector('.remove_fields').click();
      }
    });

    const array = [];
    [...subquestion.querySelectorAll('[data-js-choices] [data-js-choice]:not(.d-none)')].forEach((choice) => {
      const textarea = choice.querySelector('[data-js-redactor]');
      return array.push({
        value: textarea.redactor.source.getCode(),
        pointsInput: choice.querySelector('[data-js-choice-points]')
      });
    });

    let correctOptionsCount;
    if (subquestion.querySelector('[data-js-start-with-zero]').checked) {
      correctOptionsCount = array.filter((choice) => choice.pointsInput.value > 0).length;
    } else {
      correctOptionsCount = array.filter((choice) => choice.pointsInput.value >= 0).length;
    }

    const fraction = subquestionPoints / (array.length - correctOptionsCount);

    for (let i = 0; i < array.length; i += 1) {
      const choice = array[i];

      if (parseFloat(choice.pointsInput.value) <= 0) {
        choice.pointsInput.numeric.set(-fraction);
      }
    }

    subquestion.querySelector('[data-js-add-choice]').click();
    const textarea = subquestion.querySelector('[data-js-choices] [data-js-choice]:last-child [data-js-redactor]');
    setQuestionMark(textarea);
  }

  shuffle.addEventListener('click', handleShuffleChoices);
  sort.addEventListener('click', handleSortChoices);
  negativePoints.addEventListener('click', handleNegativePointsChoices);
});
