export default function buildDialog(content, actions, dataAttributes = '') {
  return `
  <div class='mdc-dialog'
       data-cy='confirm-dialog'
       data-mdc-dialog
       data-mdc-dialog-open
       data-mdc-dialog-remove-on-close
       ${dataAttributes}>
    <div class='mdc-dialog__container'>
      <div class='mdc-dialog__surface'
        role='alertdialog'
        aria-modal='true'
        aria-labelledby='my-dialog-title'
        aria-describedby='my-dialog-content'>
        <div class='mdc-dialog__content text-color' id='my-dialog-content'>
          ${content}
        </div>
        <div class='mdc-dialog__actions border-none'>
          ${actions}
        </div>
      </div>
    </div>
    <div class='mdc-dialog__scrim'>
      <button class='material-icons mdc-icon-button white float-right text-semi-bold'
              aria-label='Close'
              data-mdc-dialog-action="close">
        close
      </button>
    </div>
  </div>
  `;
}
