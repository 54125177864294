import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['item'];

  up(event) {
    event.preventDefault();

    this.#moveSelectionTo(this.selectedItem?.previousElementSibling);
  }

  down(event) {
    event.preventDefault();

    this.#moveSelectionTo(this.selectedItem?.nextElementSibling);
  }

  click(event) {
    event.preventDefault();

    this.selectedItem?.click();
  }

  submit(event) {
    event.preventDefault();

    this.selectedItem?.submit();
  }

  get selectedItem() {
    return this.itemTargets.find((element) => element.classList.contains('list-item--selected'));
  }

  #moveSelectionTo(item) {
    if (!item) return;

    this.selectedItem.classList.remove('list-item--selected');
    item.classList.add('list-item--selected');
    item.scrollIntoView({ block: 'nearest', inline: 'nearest' });
  }
}
