import onmount from 'onmount';
import Rails from '@rails/ujs';
import { displayUpdatingStatus } from '@modules/quiz/saving';
import { lockSubmission, unlockSubmission } from '@modules/quiz/locking';
import { updateActiveSubmission } from '@modules/quiz/navigating';

onmount('[data-js-response="fill"]', function () {
  const self = this;
  const form = this.closest('form');
  const redactorContent = this.querySelector('[data-js-redactor-content]');
  const { submissionId } = this.closest('[data-js-quiz-subquestion]').dataset;

  function handleFocus() {
    lockSubmission.call(self);

    updateActiveSubmission(submissionId);
  }

  function handleBlur() {
    unlockSubmission.call(self);

    if (form.getAttribute('data-saved') === 'false') {
      form.setAttribute('data-saved', true);
      displayUpdatingStatus(form);
      Rails.fire(form, 'submit');
    }
  }

  function handleContentChanged() {
    form.setAttribute('data-saved', false);
  }

  function addInputEventHandlers(input) {
    input.addEventListener('focus', handleFocus);
    input.addEventListener('blur', handleBlur);
    input.addEventListener('input', handleContentChanged);
  }

  function addSelectEventHandlers(select) {
    select.MDCSelect.listen('MDCSelect:change', handleContentChanged);
  }

  function addEventHandlers(red) {
    red.callback.add('focus', handleFocus);
    red.callback.add('blur', handleBlur);
    red.callback.add('inserted', handleContentChanged);
    red.callback.add('keyup', handleContentChanged);
  }

  function waitForRedactor() {
    if (self.querySelector('.redactor-in')) {
      self.querySelectorAll('[data-js-redactor]').forEach((el) => {
        addEventHandlers(el.redactor);
      });
      return;
    }
    setTimeout(waitForRedactor, 100);
  }

  function init() {
    self.querySelectorAll('input').forEach((input) => {
      addInputEventHandlers(input);
    });

    self.querySelectorAll('.mdc-select').forEach((select) => {
      addSelectEventHandlers(select);
    });
  }

  waitForRedactor();
  init();
  if (redactorContent) redactorContent.addEventListener('highlightjs:initialized', init);
});
