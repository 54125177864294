import { formatNumber } from '../helpers';
import { Component } from './component';

export class Text extends Component {
  constructor(...args) {
    super(...args);
    this.element = document.createElementNS('http://www.w3.org/2000/svg', 'g');
    this.transform = this.origin;
  }

  init() {
    this.element.setAttribute('id', this.id);
    this.element.setAttribute(
      'transform',
      `translate(${formatNumber(this.origin.x)} ${formatNumber(this.origin.y)})`
    );
    this.addLine(0);

    return this;
  }

  formatLines() {
    [...this.element.children].forEach((el, idx) => {
      el.setAttribute('y', idx > 0 ? 19 * idx : 0);
    });
  }

  addLine(index, text = '') {
    const line = document.createElementNS('http://www.w3.org/2000/svg', 'text');
    line.textContent = text;

    if (index > 0) {
      line.setAttribute('y', 19 * index);
      this.element.children[index - 1].insertAdjacentElement('afterend', line);
    } else {
      this.element.append(line);
    }
    this.formatLines();
  }

  removeLine(index) {
    const line = this.element.children[index];
    if (!line.textContent) {
      line.remove();
    } else if (line.previousElementSibling) {
      line.previousElementSibling.textContent += line.textContent;
      line.remove();
    }
    this.formatLines();
  }

  isEmpty() {
    return this.element.textContent.length === 0;
  }
}
