import onmount from 'onmount';

onmount('[data-js-expandable-list]', function () {
  const trigger = this.querySelector('[data-js-expand]');
  const hiddenElements = this.querySelectorAll('[data-js-expandable-item]');

  function handleClick() {
    hiddenElements.forEach((e) => e.classList.remove('d-none'));
    trigger.remove();
  }

  if (trigger) trigger.addEventListener('click', handleClick);
});
