function scrollToItem(redactor) {
  const currentScroll = redactor.$list.scrollTop();
  const activePosition = redactor.$list.children('.selected').position().top - currentScroll;
  const listHeight = redactor.$list.height();

  if (activePosition < 0) {
    redactor.$list.scrollTop(currentScroll + activePosition);
  } else if (activePosition > listHeight - 42) {
    redactor.$list.scrollTop(currentScroll + activePosition - listHeight + 42);
  }
}

function selectItem(redactor, item) {
  item.parent().children().removeClass('selected');
  item.addClass('selected');
  scrollToItem(redactor, item);
}

function moveUpList(redactor) {
  const current = redactor.$list.find('a.selected');
  let prev = current.prevElement();
  if (!prev.length) { prev = redactor.$list.children().last(); }
  selectItem(redactor, prev);
}

function moveDownList(redactor) {
  const current = redactor.$list.find('a.selected');
  let next = current.nextElement();
  if (!next.length) { next = redactor.$list.children().first(); }
  selectItem(redactor, next);
}

function hasActiveItem(redactor) {
  return (redactor.$list && redactor.$list.children('.selected').length);
}

export function handleEvents(e) {
  const key = (e && e.which);

  if (!e || e.type === 'click' || key === this.keycodes.ESC || key === this.keycodes.SPACE) {
    this.hide();
    return;
  }

  if (!this.isShown()) {
    return;
  }

  switch (key) {
    case this.keycodes.UP:
      e.preventDefault();
      moveUpList(this);
      break;

    case this.keycodes.DOWN:
      e.preventDefault();
      moveDownList(this);
      break;

    case this.keycodes.ENTER:
      if (hasActiveItem(this)) {
        e.preventDefault();
        this.$list.children('.selected').click();
        this.hide();
      }
      break;

    default:
      break;
  }
}
