import { Move } from './commands/move';
import { UpdateStyling } from './commands/update_styling';
import { Destroy } from './commands/destroy';

export class SelectManager {
  constructor(tool) {
    this.tool = tool;
    this.transform = { x: 0, y: 0 };
    this.selectedObjects = [];
    this.clickPosition = undefined;
    this.selected = false;

    this.wrapper = document.createElementNS('http://www.w3.org/2000/svg', 'g');
    this.rectangle = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
    this.deleteBtn = undefined;
  }

  init() {
    this.tool.element.append(this.wrapper);
    this.tool.element.append(this.rectangle);

    this.wrapper.setAttribute('data-js-selection-wrapper', '');
    this.wrapper.setAttribute('data-cy', 'selection-wrapper');

    this.rectangle.setAttribute('stroke', 'rgb(80, 200, 255)');
    this.rectangle.setAttribute('fill', 'transparent');
    this.rectangle.setAttribute('stroke-width', 2);
    this.rectangle.setAttribute('data-js-selection', '');
    this.rectangle.setAttribute('data-cy', 'selection');

    this.tool.element.insertAdjacentHTML('beforeEnd', `
      <g class='d-none' stroke='none' fill='none' data-js-close data-cy="drawing-delete-selection">
        <circle fill='#FFFFFF' cx='12' cy='12' r='10'></circle>
        <path d='M12,2 C6.47,2 2,6.47 2,12 C2,17.53 6.47,22 12,22 C17.53,22 22,17.53 22,12 C22,6.47 17.53,2 12,2 Z
                 M17,15.59 L15.59,17 L12,13.41 L8.41,17 L7,15.59 L10.59,12 L7,8.41 L8.41,7 L12,10.59 L15.59,7
                 L17,8.41 L13.41,12 L17,15.59 Z' fill='#000000' fill-rule='nonzero'></path>
        <polygon points='0 0 24 0 24 24 0 24'></polygon>
      </g>`);
    this.deleteBtn = this.tool.element.querySelector('[data-js-close]');
    this.deleteBtn.addEventListener('pointerdown', this.deleteSelection.bind(this));

    return this;
  }

  pointerdown(e) {
    let { target } = e;

    if (target.tagName === 'text') {
      target = target.parentElement;

      this.deselect();
      this.selectComponent(target);
    }

    this.clickPosition = { x: e.posX, y: e.posY };
    this.deleteBtn.classList.add('d-none');

    if (target.hasAttribute('data-js-selection')) {
      this.selected = true;
      return;
    }

    this.deselect();
  }

  pointermove(e) {
    if (this.selected) {
      this.transform = { x: (e.posX - this.clickPosition.x), y: (e.posY - this.clickPosition.y) };
      this.rectangle.setAttribute('transform', `translate(${this.transform.x} ${this.transform.y})`);
      this.wrapper.setAttribute('transform', `translate(${this.transform.x} ${this.transform.y})`);
    } else {
      const anchorX = (e.posX < this.clickPosition.x ? e.posX : this.clickPosition.x);
      const anchorY = (e.posY < this.clickPosition.y ? e.posY : this.clickPosition.y);

      this.width = Math.abs(e.posX - this.clickPosition.x);
      this.height = Math.abs(e.posY - this.clickPosition.y);

      this.setDimensions(anchorX, anchorY, this.width, this.height);
    }
  }

  pointerup() {
    if (this.selected && this.selectedObjects.length > 0) {
      this.transformSelection(this.selectedObjects);
      this.selected = false;
    } else {
      for (let i = 0; i < this.tool.Objects.length; i += 1) {
        const obj = this.tool.Objects[i];
        if (obj && !obj.isEmpty() && this.insideSelectionRectangle(obj.element.getBoundingClientRect())) {
          this.selectObject(obj);
        }
      }

      const wrapRect = this.wrapper.getBoundingClientRect();
      const relativePos = this.tool.calcRelativePosition(wrapRect.x, wrapRect.y);

      this.setDimensions(
        relativePos.x - 10 + document.body.scrollLeft,
        relativePos.y - 10 + document.body.scrollTop,
        (this.selectedObjects.length > 0 ? wrapRect.width + 20 : 0),
        (this.selectedObjects.length > 0 ? wrapRect.height + 20 : 0)
      );
    }
    this.clickPosition = { x: 0, y: 0 };
  }

  selectObject(obj) {
    this.selectedObjects.push(obj);
    this.wrapper.append(obj.element);
  }

  selectComponent(element) {
    element.setAttribute('data-js-selection', '');

    const component = this.tool.Objects.find((obj) => obj && obj.element === element);
    this.selectObject(component);

    const wrapRect = this.wrapper.getBoundingClientRect();
    const relativePos = this.tool.calcRelativePosition(wrapRect.x, wrapRect.y);

    this.setDimensions(
      relativePos.x - 10 + document.body.scrollLeft,
      relativePos.y - 10 + document.body.scrollTop,
      (this.selectedObjects.length > 0 ? wrapRect.width + 20 : 0),
      (this.selectedObjects.length > 0 ? wrapRect.height + 20 : 0)
    );
  }

  insideSelectionRectangle(objectRect) {
    const selectionRect = this.rectangle.getBoundingClientRect();

    return (
      (objectRect.x + objectRect.width) < (selectionRect.x + selectionRect.width + 10) &&
      (objectRect.x) > (selectionRect.x - 10) &&
      (objectRect.y) > (selectionRect.y - 10) &&
      (objectRect.y + objectRect.height) < (selectionRect.y + selectionRect.height + 10)
    );
  }

  setDimensions(x, y, width, height) {
    this.rectangle.setAttribute('x', x);
    this.rectangle.setAttribute('y', y);
    this.rectangle.setAttribute('width', width);
    this.rectangle.setAttribute('height', height);

    if (this.selectedObjects.length > 0) {
      this.deleteBtn.classList.remove('d-none');
      this.deleteBtn.setAttribute('transform', `translate(${x + width - 12} ${y - 12})`);
    }
  }

  transformSelection(objects) {
    const command = new Move(this.tool, objects, this.transform);
    command.execute();
    this.tool.commands.push(command);

    this.wrapper.setAttribute('transform', '');
    this.transform = { x: 0, y: 0 };
    this.rectangle.setAttribute('transform', '');

    const wrapRect = this.wrapper.getBoundingClientRect();
    const relativePos = this.tool.calcRelativePosition(wrapRect.x, wrapRect.y);

    this.setDimensions(
      relativePos.x - 10 + document.body.scrollLeft,
      relativePos.y - 10 + document.body.scrollTop,
      wrapRect.width + 20,
      wrapRect.height + 20
    );
  }

  styleSelection(attrName, value) {
    if (this.selectedObjects.length <= 0) return;

    const command = new UpdateStyling(
      this.tool,
      this.selectedObjects,
      { attrName, value }
    );
    command.execute();
    this.tool.commands.push(command);
  }

  deleteSelection() {
    this.deleteBtn.classList.add('d-none');

    const command = new Destroy(this.tool, this.selectedObjects);
    command.execute();
    this.tool.commands.push(command);

    this.selectedObjects = [];
    this.selected = false;
    this.setDimensions(0, 0, 0, 0);
  }

  deselect() {
    if (this.selectedObjects.length <= 0) return;

    for (let i = 0; i < this.selectedObjects.length; i += 1) {
      const obj = this.selectedObjects[i];
      this.wrapper.before(obj.element);
    }

    this.selectedObjects = [];
    this.selected = false;
    this.deleteBtn.classList.add('d-none');
    this.setDimensions(0, 0, 0, 0);
  }
}
