import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['source', 'destination'];

  syncToDestination() {
    const { value } = this.sourceTarget;
    if (this.destinationTarget.pattern) {
      const regex = new RegExp(this.destinationTarget.pattern);
      const parsedValue = value.match(regex);
      this.destinationTarget.value = parsedValue;
    } else {
      this.destinationTarget.value = value;
    }

    this.destinationTarget.form.dispatchEvent(new Event('input'));
  }
}
