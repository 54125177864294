/* global i18n, google */

import onmount from 'onmount';

onmount('[data-js-rank-correlation]', function () {
  const { correlationData } = this.dataset;
  const chartWrapper = this.querySelector('[data-js-chart]');

  function drawRankCorrelationChart() {
    const data = google.visualization.arrayToDataTable(JSON.parse(correlationData));
    const options = {
      backgroundColor: 'transparent',
      colors: ['#5830FF'],
      vAxis: {
        title: i18n.t('js.analytics.rank_correlation.average_score'),
        minValue: 0,
        ticks: [0.00, 0.25, 0.50, 0.75, 1.00]
      },
      hAxis: {
        title: i18n.t('js.analytics.rank_correlation.quintile_group')
      },
      chartArea: {
        width: '70%',
        height: '70%'
      },
      legend: {
        position: 'none'
      }
    };

    const chart = new (google.visualization.LineChart)(chartWrapper);
    chart.draw(data, options);
  }

  function init() {
    google.charts.load('current', { packages: ['corechart'] });
    google.charts.setOnLoadCallback(drawRankCorrelationChart);
  }

  init();
});
