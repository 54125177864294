import pdfjs from '@vendor/pdfjs/pdfjs';

function convertCanvasToJpg(canvas, fileName) {
  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      if (!blob) return;
      const jpgFile = new File([blob], fileName.replace('.pdf', '-converted.jpg'), { type: 'image/jpeg' });
      resolve(jpgFile);
    }, 'image/jpeg', 0.9);
  });
}

async function renderPageToCanvas(page, canvas, viewport) {
  const context = canvas.getContext('2d');
  const renderContext = { canvasContext: context, viewport };
  await page.render(renderContext).promise;
}

function createCanvas(viewport) {
  const canvas = document.createElement('canvas');
  canvas.width = viewport.width;
  canvas.height = viewport.height;
  return canvas;
}

async function processPdfToJpg(pdfData, fileName) {
  const pdf = await pdfjs.getDocument({ data: pdfData }).promise;
  const page = await pdf.getPage(1);

  const scale = 300 / 72; // Convert 72 DPI to 300 DPI
  const viewport = page.getViewport({ scale });

  const canvas = createCanvas(viewport);
  await renderPageToCanvas(page, canvas, viewport);

  return convertCanvasToJpg(canvas, fileName);
}

export async function convertPdfToJpg(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = async function () {
      try {
        const pdfData = new Uint8Array(this.result);
        const jpgFile = await processPdfToJpg(pdfData, file.name);
        resolve(jpgFile);
      } catch (error) {
        reject(error);
      }
    };
    reader.readAsArrayBuffer(file);
  });
}
