import { Command } from './command';
import { Line } from '../components/line';
import { Path } from '../components/path';
import { Text } from '../components/text';

export function newComponent(type, ...args) {
  switch (type) {
    case 'line':
      return new Line(...args);
    case 'path':
      return new Path(...args);
    case 'text':
    case 'g':
      return new Text(...args);
    default:
      return new Path(...args);
  }
}

export class Create extends Command {
  constructor(tool, position) {
    super(tool);
    this.position = position;

    this.component = undefined;
  }

  execute() {
    this.component = newComponent(
      this.tool.mode,
      this.tool.Objects.length,
      this.position,
      this.tool.strokeColor,
      this.tool.strokeWidth
    ).init();

    this.tool.Objects.push(this.component);
    this.tool.selectManager.wrapper.before(this.component.element);
  }

  undo() {
    const component = this.tool.Objects.pop();
    component.element.remove();
    this.tool.dispatchEvent();
  }

  redo() {
    this.tool.Objects.push(this.component);
    this.tool.selectManager.wrapper.before(this.component.element);
    this.tool.dispatchEvent();
  }
}
