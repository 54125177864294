/* global i18n */

import onmount from 'onmount';
import { getRedactor } from '@redactor/helpers';

export function initRedactorDialog(sourceRedactor, options) {
  const sourceTextarea = sourceRedactor.element.getElement();
  const trigger = this.parentElement.querySelector('[data-js-redactor-dialog-trigger]');
  const randomId = Math.floor(Math.random() * 999999);

  let dialog = null;
  let dialogRedactor = null;

  function buildTextarea() {
    const { dataset } = sourceTextarea.get();

    const wrapper = document.createElement('div');
    const textarea = document.createElement('textarea');
    textarea.setAttribute('id', `textarea-${randomId}`);
    Object.keys(dataset).forEach((key) => {
      textarea.dataset[key] = dataset[key];
    });
    wrapper.appendChild(textarea);
    return wrapper.innerHTML;
  }

  function buildDialog() {
    dialog = document.createElement('div');
    dialog.innerHTML =
      `<div id='dialog-${randomId}'
            class='mdc-dialog redactor-dialog'
            data-mdc-dialog
            data-mdc-dialog-open
            data-mdc-dialog-remove-on-close>` +
        "<div class='mdc-dialog__container'>" +
          "<div class='mdc-dialog__surface p-4 gap-4'" +
            "role='alertdialog'" +
            "aria-dialog='true'" +
            `aria-describedby='dialog-content-${randomId}'>` +

            `<div class='mdc-dialog__content p-0' id='dialog-content-${randomId}'>` +
              `${buildTextarea()}` +
            '</div>' +
            "<div class='ml-auto d-flex gap-2'>" +
              "<button type='button' class='button-tertiary' data-mdc-dialog-action='close'>" +
                `${i18n.t('js.general.cancel')}` +
              '</button>' +
              "<button type='button' class='button-secondary' data-mdc-dialog-action='accept'>" +
                `${i18n.t('js.general.insert')}` +
              '</button>' +
            '</div>' +
          '</div>' +
        '</div>' +
      '</div>';

    document.body.append(dialog);
  }

  function copyContent(source, destination) {
    const content = source.source.getCode();
    destination.source.setCode(content);
    destination.editor.app.broadcast('hardsync');
  }

  function addEventHandlers() {
    const dialogEl = document.getElementById(`dialog-${randomId}`);
    dialogEl.querySelector('[data-mdc-dialog-action="accept"]').addEventListener('click', () => {
      copyContent(dialogRedactor, sourceRedactor);

      const sourceChangedEvent = new CustomEvent('redactor:sourceChanged');
      sourceRedactor.rootElement.dispatchEvent(sourceChangedEvent);
    });
  }

  function initRedactor() {
    const dialogEl = document.getElementById(`dialog-${randomId}`);
    const textarea = dialogEl.querySelector(`#textarea-${randomId}`);

    getRedactor().then((Redactor) => {
      dialogRedactor = Redactor(textarea, {
        plugins: options.plugins,
        buttons: options.buttons,
        focusEnd: true,
        lang: options.lang
      });
      copyContent(sourceRedactor, dialogRedactor);
    });
  }

  function initDialog() {
    buildDialog();
    addEventHandlers();
    initRedactor();
    onmount();
  }

  if (trigger) trigger.addEventListener('click', initDialog);
}
