import onmount from 'onmount';
import { MDCDialog } from '@material/dialog';

onmount('[data-js-quiz-settings]', function () {
  const self = this;
  if (!this.MDCDialog) {
    this.MDCDialog = new MDCDialog(this);
    this.setAttribute('data-mdc-auto-init-state', 'initialized');
  }

  const trigger = document.querySelector('[data-js-quiz-settings-trigger]');

  function handleTriggerClick() {
    self.MDCDialog.open();
  }

  trigger.addEventListener('click', handleTriggerClick);
});
