/* global Turbo */

import { Controller } from '@hotwired/stimulus';
import { serialize } from '@modules/custom';

export default class extends Controller {
  static targets = ['redactor', 'optionList', 'gradingType'];

  static values = { url: String };

  get({ currentTarget }) {
    currentTarget.disabled = true;
    this.redactorTarget.redactor.plugin.fill.saveOffset();

    Turbo.fetch(this.buildUrl(), {
      headers: { 'Content-Type': 'application/json', accept: 'text/vnd.turbo-stream.html,' }
    })
      .then((res) => res.text())
      .then((html) => {
        Turbo.renderStreamMessage(html);
        currentTarget.disabled = false;
      });
  }

  buildUrl() {
    return `${this.urlValue}?${this.searchParams()}`;
  }

  searchParams() {
    const position = this.optionListTargets.length + 1;
    const params = { grading_type: this.gradingTypeTarget.value, option_list: { position } };

    const selection = this.redactorTarget.redactor.selection.getText();
    if (selection) {
      params.option_list.name = selection;
      params.option_list.option_items_attributes = { 0: { content: selection } };
    }
    return serialize(params);
  }
}
