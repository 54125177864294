import onmount from 'onmount';
import Rails from '@rails/ujs';

onmount('[data-js-confirm-click]', function () {
  const { confirm } = this.dataset;

  function handleClick(e) {
    if (this.hasAttribute('data-confirm') && !Rails.confirm(confirm, this)) e.stopPropagation();
  }

  this.addEventListener('click', handleClick);
});
