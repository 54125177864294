/* global i18n */

import onmount from 'onmount';

onmount('[data-js-import-file]', function () {
  const form = this.closest('form[enctype="multipart/form-data"]');
  const fileField = form.querySelector('input[type="file"]');
  const nameEl = this.querySelector('[data-js-file-name]');
  const submitButton = document.querySelector('[data-js-file-submit]');

  function preventAutomatics(evt) {
    evt.preventDefault();
    evt.stopPropagation();
  }

  function submitForm() {
    const { files } = fileField;

    if (nameEl) {
      const text = (files.length > 1 ? i18n.t('js.uploads.files_selected', { count: files.length }) : files[0].name);
      nameEl.innerHTML = text;
      if (submitButton) submitButton.disabled = false;
    } else {
      form.submit();
    }
  }

  function changeFile(evt) {
    const droppedFiles = evt.dataTransfer.files;
    fileField.files = droppedFiles;
    submitForm();
  }

  function init() {
    const eventsToPreventAutomatics = ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'];
    eventsToPreventAutomatics.forEach((event) => {
      form.addEventListener(event, preventAutomatics);
    });

    form.addEventListener('drop', changeFile);
    fileField.addEventListener('change', submitForm);
  }

  init();
});
