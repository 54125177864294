import onmount from 'onmount';

onmount('[data-js-toggle-coding-tests]', function () {
  const form = this.closest('form');
  const codingTestsToggle = this;

  function toggleCodingTests() {
    const target = form.querySelector('[data-js-coding-tests-setup]');
    if (this.MDCSelect.value === 'auto') {
      target.classList.remove('d-none');
    } else {
      target.classList.add('d-none');
    }
  }

  codingTestsToggle.addEventListener('change', toggleCodingTests);
});
