export function arrowNavigation(e) {
  if (!['ArrowUp', 'ArrowDown', 'Tab', 'Enter', 'Escape'].includes(e.key)) return;
  if (!this.querySelector('.mdc-deprecated-list-item:not(.d-none)')) return;

  const current = this.querySelector('.mdc-deprecated-list-item--focused');
  if (!current && e.key === 'Tab') return;

  let prev;
  let next;
  let list;

  if (this.classList.contains('mdc-deprecated-list')) {
    list = this;
  } else {
    list = this.querySelector('.mdc-deprecated-list');
  }
  if (list.classList.contains('d-none')) return;

  if (e.key === 'ArrowUp' || (e.shiftKey && e.key === 'Tab')) {
    e.preventDefault();
    if (current) prev = current.previousElementSibling;
    if (!prev) prev = this.querySelector('.mdc-deprecated-list-item:last-child');
    if (current) current.classList.remove('mdc-deprecated-list-item--focused');
    while (prev.classList.contains('d-none')) {
      prev = prev.previousElementSibling;
      if (!prev) prev = this.querySelector('.mdc-deprecated-list-item:last-child');
    }
    prev.classList.add('mdc-deprecated-list-item--focused');
  } else if (e.key === 'ArrowDown' || e.key === 'Tab') {
    e.preventDefault();
    if (current) next = current.nextElementSibling;
    if (!next) next = this.querySelector('.mdc-deprecated-list-item:first-child');
    if (current) current.classList.remove('mdc-deprecated-list-item--focused');
    while (next.classList.contains('d-none')) {
      next = next.nextElementSibling;
      if (!next) next = this.querySelector('.mdc-deprecated-list-item:first-child');
    }
    next.classList.add('mdc-deprecated-list-item--focused');
  } else if (e.key === 'Enter') {
    e.preventDefault();
    if (!current) return;

    current.click();
    const menuEl = list.parentElement.MDCMenu;
    if (menuEl) {
      list.parentElement.MDCMenu.menuSurface.close(true);
    } else {
      list.classList.add('d-none');
    }
  } else if (e.key === 'Escape') {
    const menuEl = list.parentElement.MDCMenu;
    if (menuEl) {
      list.parentElement.MDCMenu.menuSurface.close(true);
    } else {
      list.classList.add('d-none');
    }
    return;
  }

  const currentScroll = list.scrollTop;
  const currentOffset = this.querySelector('.mdc-deprecated-list .mdc-deprecated-list-item--focused').offsetTop;
  const activePosition = currentOffset - currentScroll;
  const collectionHeight = list.offsetHeight;
  const itemHeight = this.querySelector('.mdc-deprecated-list .mdc-deprecated-list-item--focused').offsetHeight;
  if (activePosition < 0) {
    list.scrollTop = currentScroll + activePosition;
  } else if (activePosition > collectionHeight - itemHeight) {
    list.scrollTop = currentScroll + activePosition - (collectionHeight - itemHeight);
  }
}
