import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    getter: { type: String, default: 'value' },
    intial: { type: String }
  };

  connect() {
    this.initialValue ||= this.element[this.getterValue];
  }

  reconnect() {
    this.initialValue = this.element[this.getterValue];
  }

  update() {
    if (this.initialValue !== this.element[this.getterValue]) {
      this.element.setAttribute('data-unsaved-changes-warning-target', 'item');
    } else {
      delete this.element.dataset.unsavedChangesWarningTarget;
    }
  }

  changed() {
    this.element.setAttribute('data-unsaved-changes-warning-target', 'item');
  }
}
