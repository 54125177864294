export function updateAnsweredSubmissions(data) {
  if (data.answered) {
    data.answered.forEach((submissionId) => {
      const submissionTile = document.querySelector(`[data-js-go-to][data-submission-id='${submissionId}']`);

      if (submissionTile) {
        submissionTile.setAttribute('data-answered-submission', true);
        submissionTile.classList.add('subquestion-selector-item--answered');
      }
    });
  }

  if (data.unanswered) {
    data.unanswered.forEach((submissionId) => {
      const submissionTile = document.querySelector(`[data-js-go-to][data-submission-id='${submissionId}']`);
      if (submissionTile) {
        submissionTile.setAttribute('data-answered-submission', false);
        submissionTile.classList.remove('subquestion-selector-item--answered');
      }
    });
  }
}

export function syncDrawer() {
  const syncEvent = new CustomEvent('drawer:sync');
  const drawer = document.querySelector('[data-js-subsets-drawer]');

  if (drawer) drawer.dispatchEvent(syncEvent);
}

export function updateFlaggedSubmission(submissionId, flagged) {
  const flag = document.querySelector(`[data-js-flag][data-submission-id='${submissionId}']`);
  const submissionTile = document.querySelector(`[data-js-go-to][data-submission-id='${submissionId}']`);

  if (flagged === 'true') {
    if (flag) flag.querySelector('[data-js-bookmark]').innerText = 'bookmark';
    if (submissionTile) submissionTile.classList.add('indicator');
  } else {
    if (flag) flag.querySelector('[data-js-bookmark]').innerText = 'bookmark_border';
    if (submissionTile) submissionTile.classList.remove('indicator');
  }
  syncDrawer();
}

export function updateActiveSubmission(submissionId) {
  const activeTiles = document.querySelectorAll('[data-js-go-to].subquestion-selector-item--current');
  const submissionTile = document.querySelector(`[data-js-go-to][data-submission-id='${submissionId}']`);

  activeTiles.forEach((tile) => {
    tile.classList.remove('subquestion-selector-item--current');
    tile.setAttribute('data-active-tile', false);
  });

  if (submissionTile) {
    submissionTile.classList.add('subquestion-selector-item--current');
    submissionTile.setAttribute('data-active-tile', true);
  }
}
