import { Controller } from '@hotwired/stimulus';
import { useDebounce } from 'stimulus-use';

export default class extends Controller {
  static debounces = ['debouncedSubmit'];

  connect() {
    useDebounce(this, { wait: 250 });
  }

  debouncedSubmit() {
    this.element.requestSubmit();
  }
}
