import onmount from 'onmount';

onmount('[data-js-sort-event]', () => {
  function handleChoiceMoved(e) {
    const choices = e.detail.evt.item.closest('[data-js-choices]');
    const changeEvent = new CustomEvent('position:change');

    choices.querySelectorAll('[data-js-choice]').forEach((choice, index) => {
      const positionInput = choice.querySelector('[data-js-choice-position]');
      positionInput.value = (index + 1);

      positionInput.dispatchEvent(changeEvent);
    });
  }
  this.handleChoiceMoved = handleChoiceMoved;

  document.addEventListener('choiceMoved', this.handleChoiceMoved);
}, function () {
  document.removeEventListener('choiceMoved', this.handleChoiceMoved);
});
