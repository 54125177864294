import { SelectManager } from '@modules/drawing/select_manager';
import { TextManager } from '@modules/drawing/text_manager';
import { importSvg } from '@modules/drawing/import_svg';
import { Create } from '@modules/drawing/commands/create';
import { Destroy } from '@modules/drawing/commands/destroy';

export class Schets {
  constructor(element) {
    this.element = element;
    this.selectManager = new SelectManager(this).init();
    this.textManager = new TextManager(this).init();

    this.Objects = [];

    this.mode = 'path';
    this.strokeColor = 'rgb(0,0,0)';
    this.strokeWidth = 1;

    this.activePointerId = null;

    this.commands = [];
    this.undoneCommands = [];
    this.handler = this;
  }

  init(svgString) {
    this.handleMouseEvents = this.handleMouseEvents.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);

    if (!this.element.hasAttribute('disabled')) {
      this.element.addEventListener('pointerdown', this.handleMouseEvents);
      this.element.addEventListener('touchmove', (e) => e.preventDefault());
      document.body.addEventListener('keydown', this.handleKeyDown);
    }

    importSvg(this, svgString);
    this.bindButtons();

    if (!this.element.previousElementSibling) this.element.classList.remove('position-absolute');

    return this;
  }

  handleKeyDown(e) {
    const func = this.handler.keydown;
    if (func) {
      func.bind(this.handler)(e);
    }
  }

  handleMouseEvents(e) {
    if (this.activePointerId !== null && e.pointerId !== this.activePointerId) return;
    if (e.type !== 'pointerdown' && this.activePointerId === null) return;

    if (e.type === 'pointerdown') {
      this.activePointerId = e.pointerId;

      document.addEventListener('pointermove', this.handleMouseEvents);
      document.addEventListener('pointerup', this.handleMouseEvents);
    } else if (e.type === 'pointerup') {
      this.activePointerId = null;

      document.removeEventListener('pointermove', this.handleMouseEvents);
      document.removeEventListener('pointerup', this.handleMouseEvents);
    }

    const position = this.calcRelativePosition(e.clientX, e.clientY);
    e.posX = position.x;
    e.posY = position.y;

    const func = this.handler[e.type];
    if (func) {
      func.bind(this.handler)(e);
    }
  }

  calcRelativePosition(x, y) {
    const rect = this.element.getBoundingClientRect();
    return { x: x - rect.x, y: y - rect.y };
  }

  pointerdown(e) {
    const command = new Create(this, { x: e.posX, y: e.posY });
    command.execute();
    this.commands.push(command);
  }

  pointermove(e) {
    if (this.activePointerId === null) return;
    this.Objects[this.Objects.length - 1].addPoint({ x: e.posX, y: e.posY });
  }

  pointerup(e) {
    this.Objects[this.Objects.length - 1].endPath({ x: e.posX, y: e.posY });
    this.dispatchEvent();
  }

  bindButtons() {
    const self = this;
    const parent = this.element.closest('[data-js-drawing]');

    // modes
    parent.querySelectorAll('[data-js-mode]').forEach((modeBtn) => {
      modeBtn.addEventListener('click', function () {
        const mode = this.getAttribute('data-js-mode');

        parent.querySelector('[data-js-mode].mdc-button--filled').classList.remove('mdc-button--filled');
        this.classList.add('mdc-button--filled');

        self.mode = mode;

        switch (self.mode) {
          case 'text':
            self.handler = self.textManager;
            self.selectManager.deselect();
            break;
          case 'select':
            self.handler = self.selectManager;
            self.textManager.reset();
            break;
          default:
            self.handler = self;
            self.selectManager.deselect();
            self.textManager.reset();
            break;
        }
      }, false);
    });

    // clear
    parent.querySelector('[data-js-clear]').addEventListener('click', () => {
      self.clear();
    }, false);

    // color
    parent.querySelectorAll('[data-js-color]').forEach((colorBtn) => {
      colorBtn.addEventListener('click', () => {
        const { color } = colorBtn.style;

        self.strokeColor = color;
        self.selectManager.styleSelection('strokeColor', color);
        parent.querySelector('[data-js-color-preview]').style.color = color;
      }, false);
    });

    // thickness
    parent.querySelectorAll('[data-js-width]').forEach((widthBtn) => {
      widthBtn.addEventListener('click', () => {
        const width = widthBtn.querySelector('hr').style.height;

        self.strokeWidth = width;
        self.selectManager.styleSelection('strokeWidth', width);
      }, false);
    });

    // undo/redo
    parent.querySelector('[data-js-undo]').addEventListener('click', this.undo.bind(this), false);
    parent.querySelector('[data-js-redo]').addEventListener('click', this.redo.bind(this), false);
  }

  clear() {
    if (!this.Objects.filter(Boolean).length) return;

    this.selectManager.deselect();
    this.textManager.reset();

    const command = new Destroy(this, this.Objects.slice());
    command.execute();
    this.commands.push(command);
  }

  undo(e) {
    if (this.commands.length <= 0) return;

    this.selectManager.deselect();
    this.textManager.reset();

    const command = this.commands.pop();
    command.undo();
    this.undoneCommands.push(command);

    if (this.commands.length <= 0) {
      e.target.closest('[data-js-undo]').disabled = true;
    }
    e.target.closest('[data-js-drawing]').querySelector('[data-js-redo]').disabled = false;
  }

  redo(e) {
    if (this.undoneCommands.length <= 0) return;

    this.selectManager.deselect();
    this.textManager.reset();

    const command = this.undoneCommands.pop();
    command.redo();
    this.commands.push(command);

    if (this.undoneCommands.length <= 0) {
      e.target.closest('[data-js-redo]').disabled = true;
    }
    e.target.closest('[data-js-drawing]').querySelector('[data-js-undo]').disabled = false;
  }

  toBlob() {
    this.selectManager.deselect();

    const rect = this.element.getBoundingClientRect();
    this.element.setAttribute('width', rect.width);
    this.element.setAttribute('height', rect.height);

    this.element.querySelectorAll('g, rect, [data-js-close]').forEach((element) => element.remove());

    return new Blob([this.element.outerHTML], { type: 'image/svg+xml' });
  }

  value() {
    const duplicate = this.element.cloneNode(false);
    this.element.querySelectorAll('[id]').forEach((el) => duplicate.insertAdjacentHTML('beforeend', el.outerHTML));
    duplicate.setAttribute('viewBox', `0 0 ${this.element.clientWidth} ${this.element.clientHeight}`);

    return duplicate.outerHTML;
  }

  dispatchEvent() {
    const changeEvent = new CustomEvent('drawing:changed');
    this.element.dispatchEvent(changeEvent);
  }

  removeEventListeners() {
    document.body.removeEventListener('keydown', this.handleKeyDown);
  }
}
