import { Controller } from '@hotwired/stimulus';
import { MDCDataTable } from '@material/data-table';

export default class extends Controller {
  static targets = ['table', 'link', 'form'];

  addParamsToUrl(url, selectedRowIds) {
    url.searchParams.set('row_ids', selectedRowIds);
    return url.toString();
  }

  addParamsToForm(form, selectedRowIds) {
    let input = form.elements.row_ids;

    if (input) {
      input.value = selectedRowIds;
    } else {
      input = document.createElement('input');
      input.type = 'hidden';
      input.name = 'row_ids';
      input.value = selectedRowIds;
      form.appendChild(input);
    }
  }

  updateTargetUrls() {
    const selectedRowIds = this.tableTarget.MDCDataTable.getSelectedRowIds();
    this.linkTargets.forEach((link) => {
      const url = new URL(link.href);
      link.href = this.addParamsToUrl(url, selectedRowIds);
    });
    this.formTargets.forEach((form) => {
      this.addParamsToForm(form, selectedRowIds);
    });
  }

  enableTableActions() {
    this.linkTargets.forEach((link) => {
      link.classList.remove('mdc-deprecated-list-item--disabled');
    });
    this.formTargets.forEach((form) => {
      const button = form.querySelector('button');
      button.removeAttribute('disabled');
      button.classList.remove('mdc-deprecated-list-item--disabled');
    });
  }

  disableTableActions() {
    this.linkTargets.forEach((link) => {
      link.classList.add('mdc-deprecated-list-item--disabled');
    });
    this.formTargets.forEach((form) => {
      const button = form.querySelector('button');
      button.setAttribute('disabled', true);
      button.classList.add('mdc-deprecated-list-item--disabled');
    });
  }

  handleRowSelection() {
    const selectedCount = this.tableTarget.MDCDataTable.foundation.adapter.getSelectedRowCount();

    this.updateTargetUrls();

    if (selectedCount) {
      this.enableTableActions();
    } else {
      this.disableTableActions();
    }
  }

  connect() {
    if (!this.tableTarget.MDCDataTable) {
      this.tableTarget.MDCDataTable = new MDCDataTable(this.tableTarget);
      this.tableTarget.setAttribute('data-mdc-auto-init-state', 'initialized');
    }

    this.tableTarget.MDCDataTable.listen('MDCDataTable:rowSelectionChanged', this.handleRowSelection.bind(this));
    this.tableTarget.MDCDataTable.listen('MDCDataTable:selectedAll', this.handleRowSelection.bind(this));
    this.tableTarget.MDCDataTable.listen('MDCDataTable:unselectedAll', this.handleRowSelection.bind(this));

    this.disableTableActions();
  }
}
