export function loadDocReader(documentUrl) {
  const self = document.querySelector('[data-js-docreader-panel]');
  const iframe = self.querySelector('iframe');
  const { url } = self.dataset;

  self.classList.remove('d-none');
  const docreaderUrl = url + encodeURIComponent(documentUrl);
  if (docreaderUrl === iframe.src) return;

  iframe.src = docreaderUrl;
}
