import Rails from '@rails/ujs';
import { Controller } from '@hotwired/stimulus';
import { useIntersection } from 'stimulus-use';

export default class extends Controller {
  connect() {
    const { clickStrategy } = this.element.dataset;

    if (clickStrategy === 'lazy') {
      useIntersection(this);
    } else {
      const url = this.element.href;

      Rails.ajax({
        url,
        type: 'GET',
        dataType: 'script'
      });
    }
  }

  appear() {
    this.element.click();
  }
}
