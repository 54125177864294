import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['status', 'script'];

  static values = { questionId: Number, url: String };

  run() {
    const script = this.scriptTarget.value;
    if (!script.trim().length) return;

    fetch(this.urlValue, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ script, seeds: [this.#seed()] })
    })
      .then((response) => response.json())
      .then((data) => {
        this.statusTarget.innerText = data.message;
      })
      .catch((error) => {
        this.statusTarget.innerText = error.message;
      });
  }

  dispatchEvent(e) {
    const { fetchResponse } = e.detail;
    if (!fetchResponse.response.ok) return;

    const event = new CustomEvent(
      'codeEditorUpdated',
      { detail: { questionId: this.questionIdValue } }
    );
    document.dispatchEvent(event);
  }

  #seed() {
    return Math.floor(Math.random() * 1000);
  }
}
