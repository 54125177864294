import { Controller } from '@hotwired/stimulus';
import { createFocusTrap } from 'focus-trap';
import { resetHotkeys } from '@modules/custom';

// Connects to data-controller="side-panel"
export default class extends Controller {
  static targets = ['aside', 'panel', 'scrim'];

  connect() {
    if (this.hasPanelTarget) {
      this.initializeFocusTrap();
      document.addEventListener('keydown', this.handleKeyDown.bind(this));
    }
  }

  panelTargetConnected() {
    this.initializeFocusTrap();
  }

  initializeFocusTrap() {
    this.focusTrap = createFocusTrap(this.panelTarget, { allowOutsideClick: true });
  }

  open(e) {
    this.previousHotkeysDisabled = document.body.dataset.hotkeysDisabled;
    document.body.dataset.hotkeysDisabled = 'temporary';

    this.expandPanel();
    this.focusTrap.activate();
    this.setTurboPermanence(e.params.turboPermanence);
    if (e.params.scrim) this.showScrim();
  }

  close() {
    resetHotkeys(this);

    this.collapsePanel();
    this.focusTrap.deactivate();
    this.unsetTurboPermanence();
    this.hideScrim();

    this.dispatch('closed');
  }

  expandPanel() {
    this.panelTarget.style.width = '600px';
  }

  collapsePanel() {
    this.panelTarget.style.width = '0';
  }

  showScrim() {
    this.scrimTarget.style.display = 'block';
  }

  hideScrim() {
    this.scrimTarget.style.display = 'none';
  }

  handleKeyDown(event) {
    if (event.key === 'Escape') {
      this.close();
    }
  }

  setTurboPermanence(attr) {
    this.asideTarget.setAttribute(attr, '');
  }

  unsetTurboPermanence() {
    this.asideTarget.removeAttribute('data-turbo-permanent');
    this.asideTarget.removeAttribute('data-turbo-temporary');
  }
}
