export function handleTimeChange(e) {
  const values = e.target.value.split(':');
  const flatpickr = e.target._flatpickr; // eslint-disable-line no-underscore-dangle

  const hourEl = flatpickr.timeContainer.querySelector('.flatpickr-hour');
  const minuteEl = flatpickr.timeContainer.querySelector('.flatpickr-minute');

  hourEl.value = values[0] || '00';
  minuteEl.value = values[1] || '00';
}
