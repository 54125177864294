import onmount from 'onmount';
import { showFormErrors } from '@modules/form-responses';

onmount('[data-js-subquestion-edit]', function () {
  const self = this;
  const form = this.querySelector('form');
  const predefinedAnswerToggle = this.querySelector('[data-js-toggle-predefined-answer]');
  const predefinedAnswerInput = this.querySelector('[data-predefined-answer-input]');
  const wordLimitToggle = this.querySelector('[data-js-toggle-word-limit]');
  const variableToggle = this.querySelector('[data-js-toggle-variable]');
  const copyKprimeColumnsButton = this.querySelector('[data-js-copy-kprime-columns]');

  /* FUNCTIONS */
  function formErrorListener(event) {
    if (event.target === form) showFormErrors(event);
  }

  function togglePredefinedAnswer() {
    const target = self.querySelector(this.dataset.jsTogglePredefinedAnswer);
    if (this.checked) {
      target.classList.remove('d-none');
    } else {
      target.classList.add('d-none');
      predefinedAnswerInput.value = '';
    }
  }

  function toggleWordLimit() {
    const target = self.querySelector(this.dataset.jsToggleWordLimit);
    if (this.checked) {
      target.classList.remove('d-none');
    } else {
      target.querySelector('input').numeric.set(0);
      target.classList.add('d-none');
    }
  }

  function toggleVariable() {
    const target = self.querySelector(this.dataset.jsToggleVariable);
    const numericValue = self.querySelector('[data-js-numeric-value]');
    const variableSelect = target.querySelector('.mdc-select');

    if (this.checked) {
      target.classList.remove('d-none');
      numericValue.classList.add('d-none');
    } else {
      target.classList.add('d-none');
      numericValue.classList.remove('d-none');
      variableSelect.MDCSelect.setValue('');
    }
  }

  function setColumnAttributes(element, name, fixedPosition) {
    element.querySelector('[data-js-redactor]').value = name;

    const fixedPositionEl = element.querySelector('[data-js-number-field]');
    if (fixedPositionEl.numeric) {
      fixedPositionEl.numeric.set(fixedPosition);
    } else {
      fixedPositionEl.closest('.mdc-text-field').MDCTextField.value = fixedPosition || '';
    }
  }

  function copyKprimeColumns() {
    const columns = copyKprimeColumnsButton.querySelectorAll('[data-js-kprime-column]');
    const addColumnBtn = self.querySelector('[data-js-add-match-column]');
    columns.forEach((column) => {
      addColumnBtn.click();
      const element = self.querySelector('[data-js-match-column]:last-child');

      setColumnAttributes(element, column.innerHTML, column.dataset.fixedPosition);
    });

    copyKprimeColumnsButton.classList.add('d-none');
  }

  /* EVENT HANDLERS */
  if (form) {
    form.addEventListener('ajax:error', formErrorListener);
  }
  if (predefinedAnswerToggle) predefinedAnswerToggle.addEventListener('change', togglePredefinedAnswer);
  if (wordLimitToggle) wordLimitToggle.addEventListener('change', toggleWordLimit);
  if (variableToggle) variableToggle.addEventListener('change', toggleVariable);
  if (copyKprimeColumnsButton) copyKprimeColumnsButton.addEventListener('click', copyKprimeColumns);
});
