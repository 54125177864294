/* global i18n */

import onmount from 'onmount';
import snackbar from '@components/snackbar';
import Rails from '@rails/ujs';
import Glider from 'glider-js/glider';
import { goToNextError } from '@modules/scans/navigating';

onmount('[data-js-error-duplicate]', function () {
  const self = this;
  const dataTableEl = this.querySelector('.mdc-data-table');
  const skip = this.querySelector('[data-js-skip]');
  const gliderWrappers = this.querySelectorAll('[data-js-glider-wrapper]');
  const deleteUploadButtons = this.querySelectorAll('[data-js-delete-upload]');
  const deleteUploadsButton = this.querySelector('[data-js-delete-uploads]');
  const duplicateButton = this.querySelector('[data-js-duplicate]');

  function removeCurrentSlide(slide) {
    const gliderEl = slide.closest('[data-js-glider]');
    gliderEl.glider.removeItem(gliderEl.glider.slide);
  }

  function showMissingPageWarnings() {
    const pageArray = [...self.querySelectorAll('tbody tr')].map((page) => (
      page.querySelectorAll('img').length
    ));

    const maxPages = Math.max.apply(null, pageArray);

    self.querySelectorAll('tbody tr').forEach((row) => {
      if (row.querySelectorAll('img').length < maxPages) {
        row.querySelector('[data-js-missing-page-warning]').classList.remove('d-none');
      } else {
        row.querySelector('[data-js-missing-page-warning]').classList.add('d-none');
      }
    });
  }

  function handleSlideChange(e) {
    const activeSlide = this.slides[e.detail.slide];
    const { uploadId, fileName, studentNumber } = activeSlide.querySelector('img').dataset;
    const row = activeSlide.closest('[data-js-duplicate-row]');
    const editUrl = `${window.location.pathname}/${uploadId}/error_identifier_dialog?return_to=duplicate`;

    row.setAttribute('data-row-id', uploadId);
    row.querySelector('[data-js-filename').innerText = fileName;
    row.querySelector('[data-js-student-number]').innerText = studentNumber || i18n.t('js.scans.unknown');
    row.querySelector('[data-js-edit-upload]').setAttribute('href', editUrl);
  }

  function handleSkipSuccess(e) {
    const [, , xhr] = e.detail;

    if (xhr.status === 204) {
      snackbar(i18n.t('js.scans.no_more_errors'));
      self.MDCDialog.close();
    }
  }

  function gliderOptions(gliderEl) {
    return {
      arrows: {
        next: gliderEl.querySelector('[data-js-glider-next]'),
        prev: gliderEl.querySelector('[data-js-glider-prev]')
      },
      draggable: false,
      exactWidth: true,
      itemWidth: 100,
      skipTrack: true,
      slidesToShow: 1
    };
  }

  function handleUploadDeleted() {
    removeCurrentSlide(this.closest('.glider-slide'));
    snackbar(i18n.t('js.general.successfully_deleted'));

    showMissingPageWarnings();
  }

  function handleUploadsDeleted() {
    const selectedRowIds = dataTableEl.MDCDataTable.getSelectedRowIds();

    selectedRowIds.forEach((rowId) => {
      const row = self.querySelector(`[data-row-id='${rowId}']`);
      removeCurrentSlide(row.querySelector('.glider-slide'));
    });

    showMissingPageWarnings();
    snackbar(i18n.t('js.general.successfully_deleted'));
  }

  function allExamsComplete() {
    const pageArray = [...self.querySelectorAll('tbody tr')].map((page) => (
      page.querySelectorAll('img').length
    ));

    const pageSet = new Set(pageArray);
    return pageSet.size === 1;
  }

  function handleDuplicateClick(e) {
    e.preventDefault();
    e.stopPropagation();

    if (allExamsComplete()) {
      const selectedRowIds = dataTableEl.MDCDataTable.getSelectedRowIds();

      const url = new URL(window.location.origin + this.dataset.url);
      url.searchParams.set('row_ids', selectedRowIds);

      Rails.ajax({
        url: url.toString(),
        type: 'POST',
        dataType: 'script',
        success: () => {
          goToNextError(skip);
        },
        error: () => {
          snackbar(i18n.t('js.scans.merge_failed'));
        }
      });
    } else {
      snackbar(i18n.t('js.scans.scans_missing'));
    }
  }

  gliderWrappers.forEach((gliderWrapper) => {
    const gliderEl = gliderWrapper.querySelector('[data-js-glider]');
    gliderEl.glider = new Glider(gliderEl, gliderOptions(gliderWrapper));
    gliderEl.addEventListener('glider-slide-visible', handleSlideChange.bind(gliderEl.glider));
  });

  deleteUploadButtons.forEach((deleteButton) => {
    deleteButton.addEventListener('ajax:success', handleUploadDeleted);
  });

  if (deleteUploadsButton) deleteUploadsButton.addEventListener('ajax:success', handleUploadsDeleted);

  duplicateButton.addEventListener('click', handleDuplicateClick);

  if (skip) skip.addEventListener('ajax:success', handleSkipSuccess);

  showMissingPageWarnings();
});
