import onmount from 'onmount';

onmount('body', function () {
  const self = this;

  function renderNotice(e) {
    const response = e.detail[0];
    if (!response.getResponseHeader('flash-message')) return;
    const isAlert = response.getResponseHeader('flash-type') === 'alert';
    const actionLink = response.getResponseHeader('flash-action-link') !== null ?
      `data-action-link=${response.getResponseHeader('flash-action-link')}` : '';
    const actionLabel = response.getResponseHeader('flash-action-label') !== null ?
      `data-action-label=${response.getResponseHeader('flash-action-label')}` : '';

    const str = `
      <div class='d-none' data-js-notice ${(isAlert ? 'data-alert' : '')} ${actionLink} ${actionLabel}>
          ${response.getResponseHeader('flash-message')}
      </div>`;

    self.insertAdjacentHTML('beforeend', str);
    onmount();
  }

  self.addEventListener('ajax:complete', renderNotice);
});
