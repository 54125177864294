import onmount from 'onmount';
import Rails from '@rails/ujs';

onmount('[data-js-pending-with-partial]', function () {
  const self = this;
  this.timeoutId = null;
  const { url, lazyloadEvent } = this.dataset;
  const withoutTimeout = this.hasAttribute('data-without-timeout');
  let retryCount = 0;

  function pollStatus() {
    Rails.ajax({
      url,
      type: 'GET',
      dataType: 'json',
      success: (data) => {
        if (data.status === 'done') {
          self.innerHTML = data.html;
        } else if (data.status === 'pending') {
          retryCount += 1;
          const retryTime = Math.min(2000 * retryCount, 15000);
          this.timeoutId = setTimeout(pollStatus, retryTime);
        }
      }
    });
  }

  function init() {
    if (withoutTimeout) return pollStatus();

    return setTimeout(pollStatus, 5000);
  }
  this.init = init;

  if (lazyloadEvent) {
    document.addEventListener(lazyloadEvent, this.init);
  } else {
    init();
  }
}, function () {
  const { lazyloadEvent } = this.dataset;
  if (lazyloadEvent) document.removeEventListener(lazyloadEvent, this.init);
  clearTimeout(this.timeoutId);
});
