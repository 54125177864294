import onmount from 'onmount';
import Rails from '@rails/ujs';
import { debounce } from '@modules/custom';

onmount('[data-enable-after]', function () {
  const self = this;
  const timeout = this.dataset.enableAfter;

  function enable() {
    Rails.enableElement(self);
  }

  this.addEventListener('click', debounce(enable, timeout));
});
