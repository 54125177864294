import onmount from 'onmount';
import { displayUpdatingStatus, submitOrSetPending } from '@modules/quiz/saving';
import { updateActiveSubmission } from '@modules/quiz/navigating';
import { debounce } from '@modules/custom';

onmount('[data-js-response="choice"]', function () {
  const form = this.closest('form');
  const { submissionId } = this.closest('[data-js-quiz-subquestion]').dataset;
  const choiceType = this.getAttribute('data-choice-type');
  const choices = this.querySelectorAll('[data-js-choices] input[type="radio"], input[type="checkbox"]');

  function handleInputChanged() {
    updateActiveSubmission(submissionId);
    form.setAttribute('data-saved', false);
    displayUpdatingStatus(form);

    if (choiceType === 'radio') {
      choices.forEach((choice) => {
        if (choice !== this) choice.checked = false;
      });
    }

    submitOrSetPending(form);
  }

  function addInputEventHandlers(input) {
    input.addEventListener('change', debounce(handleInputChanged, 250));
  }

  choices.forEach((input) => {
    addInputEventHandlers(input);
  });
});
