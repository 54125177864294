/* global i18n */

function buildCloseButton(name) {
  const closeButton = document.createElement('button');
  closeButton.type = 'button';
  closeButton.classList.add('chip__icon__trailing');
  closeButton.textContent = 'close';
  closeButton.setAttribute('aria-label', `${i18n.t('js.general.remove')} ${name}`);
  closeButton.dataset.action = 'click->search-chips#removeChip click->interaction#remove';
  return closeButton;
}

function buildTooltip(text) {
  const tooltip = document.createElement('span');
  tooltip.classList.add('tooltip-text');
  tooltip.textContent = text;
  return tooltip;
}

export default function createChip({
  name, classes = [], dataset = {}, close = true, tooltip = null
}) {
  const chip = document.createElement('span');
  chip.classList.add('chip');
  classes.forEach((className) => chip.classList.add(className));
  chip.textContent = name;
  dataset.controller += ' tooltip interaction';
  Object.entries(dataset).forEach(([key, value]) => {
    chip.dataset[key] = value;
  });

  chip.setAttribute('data-turbo-temporary', 'true');

  if (close) chip.appendChild(buildCloseButton(name));
  if (tooltip) chip.appendChild(buildTooltip(tooltip));

  return chip;
}
