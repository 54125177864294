import 'katex/dist/katex.js'; // eslint-disable-line import/no-unresolved, import/extensions
import 'katex/dist/contrib/mhchem.js'; // eslint-disable-line import/no-unresolved, import/extensions
import renderMathInElement from '@vendor/render-math-in-element';

const katexOptions = {
  delimiters:
  [
    { left: '$$$', right: '$$$', display: true },
    { left: '$$', right: '$$', display: false },
    { left: '\\[', right: '\\]', display: true },
    { left: '\\(', right: '\\)', display: false }
  ],
  ignoredTags: ['script', 'noscript', 'style', 'input', 'textarea', 'pre', 'code'],
  ignoredClasses: ['redactor-in'],
  minRuleThickness: 0.06,
  unicodeTextInMathMode: true,
  macros: {
    '\\placeholder': '\\Box'
  }
};

function renderKatexNode(node) {
  try {
    renderMathInElement(node, katexOptions);
  } catch (e) {
    console.warn(e); // eslint-disable-line no-console
  }
}

export function renderKatex(nodes) {
  if (nodes && nodes.length > 1) {
    for (let i = 0; i < nodes.length; i += 1) {
      renderKatexNode(nodes[i]);
    }
  } else {
    renderKatexNode(nodes);
  }
}
