/* global webpackOnmount */

import onmount from 'onmount';
import Rails from '@rails/ujs';

onmount('[data-js-remote-tooltip]', function () {
  const self = this;
  const { url } = this.dataset;
  const tooltip = document.querySelector('[data-js-remote-tooltip-target]');
  let requested = false;
  let entered = false;
  let content;

  function calculateTop(top, height) {
    const tooltipHeight = tooltip.getBoundingClientRect().height;
    const scrolledTop = top + window.scrollY;

    if (scrolledTop - tooltipHeight <= 0 || scrolledTop - tooltipHeight <= window.scrollY) {
      return scrolledTop + height + 5;
    }

    return scrolledTop - tooltipHeight - 5;
  }

  function showTooltip() {
    tooltip.innerHTML = content;
    webpackOnmount();
    tooltip.classList.remove('d-none');
    const { top, left, height } = self.getBoundingClientRect();

    tooltip.style.top = `${calculateTop(top, height)}px`;
    tooltip.style.left = `${left}px`;
  }

  function hideTooltip() {
    tooltip.innerHTML = '';
    tooltip.classList.add('d-none');
  }

  function request() {
    Rails.ajax({
      url,
      type: 'GET',
      dataType: 'script',
      success: (data) => {
        content = data.html;
        if (entered) showTooltip();
      }
    });
    requested = true;
  }

  function handleMouseEnter() {
    entered = true;
    if (!requested) {
      request();
    } else if (content) {
      showTooltip();
    }
  }

  function handleMouseLeave() {
    entered = false;
    if (!content) return;

    hideTooltip();
  }

  this.addEventListener('mouseenter', handleMouseEnter);
  this.addEventListener('mouseleave', handleMouseLeave);
});
