import onmount from 'onmount';
import Rails from '@rails/ujs';

onmount('[data-js-pending]', function () {
  const self = this;
  this.timeoutId = null;
  const { url } = this.dataset;
  let retryCount = 0;

  function retry() {
    retryCount += 1;
    const retryTime = Math.min(2000 * retryCount, 15000);
    self.timeoutId = setTimeout(pollStatus, retryTime); // eslint-disable-line no-use-before-define
  }

  function pollStatus() {
    Rails.ajax({
      url,
      type: 'GET',
      dataType: 'json',
      success: (data) => {
        if (data.status === 'ready' || data.status === 'success' ||
           (data.url !== undefined && data.status !== 'pending')) {
          window.location.href = data.url;
        } else if (data.status === 'pending') {
          retry();
        }
      },
      error: () => {
        retry();
      }
    });
  }

  setTimeout(pollStatus, 1000);
}, function () {
  clearTimeout(this.timeoutId);
});
