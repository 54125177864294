import onmount from 'onmount';

onmount('[data-js-uploads-index]', function () {
  const self = this;
  const requiredFiles = [...this.querySelectorAll('[data-js-required-file]')];

  let uploadsCount = this.querySelectorAll('[data-js-upload][data-upload-id]').length;
  const submitBtn = this.querySelector('[data-js-submit]');

  function handleFileUpload(e) {
    uploadsCount += 1;

    const reqEl = requiredFiles.find((el) => el.dataset.fileName.toLowerCase() === e.detail.file_name.toLowerCase());
    if (reqEl) reqEl.classList.add('d-none');

    submitBtn.removeAttribute('disabled');
  }
  this.handleFileUpload = handleFileUpload;

  function handleFileDestroy(e) {
    uploadsCount -= 1;

    const fileName = e.detail.file_name.toLowerCase();
    const reqEl = requiredFiles.find((el) => el.dataset.fileName.toLowerCase() === fileName);

    if (reqEl) {
      const uploads = [...self.querySelectorAll('[data-js-upload][data-upload-id]')];
      const duplicateFile = uploads.find((el) => el.dataset.uploadFileName.toLowerCase() === fileName);

      if (!duplicateFile) reqEl.classList.remove('d-none');
    }

    if (uploadsCount === 0) submitBtn.setAttribute('disabled', 'disabled');
  }
  this.handleFileDestroy = handleFileDestroy;

  document.addEventListener('dropzone:fileUploaded', this.handleFileUpload);
  document.addEventListener('uploadDestroyed', this.handleFileDestroy);
}, function () {
  document.removeEventListener('dropzone:fileUploaded', this.handleFileUpload);
  document.removeEventListener('uploadDestroyed', this.handleFileDestroy);
});
