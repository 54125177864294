import onmount from 'onmount';

onmount('.mdc-checkbox-group', function () {
  const self = this;
  const checkboxes = this.querySelectorAll('input[type=checkbox]');

  function setRequired() {
    if (self.querySelector('input[type=checkbox]:checked')) return;

    checkboxes.forEach((checkbox) => {
      checkbox.setAttribute('required', true);
    });
  }

  function removeRequired() {
    checkboxes.forEach((checkbox) => {
      checkbox.removeAttribute('required');
    });
  }

  function toggleRequired(e) {
    if (e.target.checked) {
      removeRequired();
    } else {
      setRequired();
    }
  }

  if (this.hasAttribute('data-required')) {
    checkboxes.forEach((checkbox) => {
      checkbox.addEventListener('change', toggleRequired);
    });

    if (this.querySelector('input[type=checkbox]:checked')) {
      removeRequired();
    } else {
      setRequired();
    }
  }
});
