import onmount from 'onmount';
import { MDCMenu } from '@material-overwrites/mdc.menu';

onmount('.mdc-menu-surface--anchor', function () {
  const menuEl = this.querySelector('.mdc-menu');
  if (!menuEl) return;

  if (!menuEl.MDCMenu) {
    menuEl.MDCMenu = new MDCMenu(menuEl);
    menuEl.setAttribute('data-mdc-auto-init-state', 'initialized');
  }
  const keepOpen = this.hasAttribute('data-mdc-menu-keep-open');
  const trigger = this.querySelector('.mdc-menu-trigger');
  const belowTrigger = menuEl.hasAttribute('data-mdc-menu-below-trigger');
  const unfoldLeft = menuEl.hasAttribute('data-mdc-menu-unfold-left');
  const unfoldBelow = menuEl.hasAttribute('data-mdc-menu-unfold-below');
  const unfoldEvent = menuEl.getAttribute('data-unfold-event');
  const focusNone = menuEl.hasAttribute('data-focus-none');

  function notifyOpen() {
    const openEvent = new CustomEvent('menu:opened');
    menuEl.dispatchEvent(openEvent);
  }

  function closeMenu(e) {
    if (!e.target.hasAttribute('data-js-close-menu')) return;

    menuEl.MDCMenu.open = false;
    e.stopImmediatePropagation();
    e.preventDefault();
  }

  function openMenu() {
    if (menuEl.classList.contains('mdc-menu-surface--open')) return;

    menuEl.MDCMenu.open = true;

    notifyOpen();
    if (belowTrigger) menuEl.MDCMenu.setAnchorCorner(1);
  }

  function closeSurface(skipRestoreFocus) {
    if (keepOpen) return;

    menuEl.MDCMenu.menuSurface.close(skipRestoreFocus);
  }

  function handleMenuSurfaceOpened() {
    if (menuEl.querySelector('.mdc-slider')) {
      menuEl.querySelectorAll('.mdc-slider').forEach((slider) => {
        if (slider.MDCSlider) slider.MDCSlider.layout();
      });
    }
    if (focusNone) return false;

    if (menuEl.querySelector('.mdc-text-field__input')) {
      return menuEl.querySelector('.mdc-text-field__input').focus();
    }

    return this.adapter.focusItemAtIndex(0);
  }

  function getoriginCornerLeft() {
    return 12;
  }

  function getoriginCornerBottom() {
    return 8;
  }

  menuEl.MDCMenu.foundation.adapter.closeSurface = closeSurface;
  menuEl.MDCMenu.foundation.handleMenuSurfaceOpened = handleMenuSurfaceOpened;
  if (unfoldLeft) {
    menuEl.MDCMenu.menuSurface.foundation.getoriginCorner = getoriginCornerLeft;
  } else if (unfoldBelow) {
    menuEl.MDCMenu.menuSurface.foundation.getoriginCorner = getoriginCornerBottom;
  }
  this.addEventListener('click', closeMenu);

  if (unfoldEvent) {
    menuEl.addEventListener(unfoldEvent, openMenu);
  } else {
    trigger.addEventListener('click', openMenu);
  }
});
