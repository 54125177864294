import onmount from 'onmount';
import { MDCTabBar } from '@material/tab-bar';

onmount('[data-js-split-screen-tab]', function () {
  const self = this;
  const rightPanel = this.querySelector('[data-js-right-panel]');
  const quizPanel = this.querySelector('[data-js-quiz-panel]');
  const leftPanel = this.querySelector('[data-js-left-panel]');
  const handle = this.querySelector('[data-js-handle]');
  const noAttachments = this.querySelector('[data-js-no-attachments]');

  let quizTab = this.querySelector("[data-js-file-tab][data-file-type='quiz']");
  let splitScreenBtn = this.querySelector('[data-js-toggle-splitscreen]');
  let splitScreenActive = true;

  function hidePanels() {
    if (rightPanel) rightPanel.classList.add('d-none');
    if (leftPanel) leftPanel.classList.add('d-none');
  }

  function togglePanels(activeTab) {
    if (splitScreenActive) return;

    hidePanels();
    if (activeTab.dataset.fileType === 'quiz') {
      leftPanel.classList.remove('d-none');
    } else {
      rightPanel.classList.remove('d-none');
    }
  }

  function showFirstAttachment() {
    if (self.querySelector('[data-js-file-tab][data-upload-id].mdc-tab--active')) return;

    const fileTab = self.querySelector('[data-js-file-tab][data-upload-id]');
    if (fileTab) self.querySelector('[data-js-file-tab][data-upload-id]').click();
  }

  function handleTabInteraction(e) {
    const activeTab = this.MDCTabBar.tabList[e.detail.index].root;
    togglePanels(activeTab);
  }

  function toggleSplitScreenButton() {
    if (splitScreenActive) {
      splitScreenBtn.childNodes[1].textContent = 'fullscreen';
    } else {
      splitScreenBtn.childNodes[1].textContent = 'vertical_split';
      if (!document.querySelector('[data-js-file-tab][data-upload-id]')) splitScreenBtn.disabled = true;
    }
  }

  function toggleSplitScreen() {
    if (splitScreenActive) {
      handle.classList.add('d-none');
      leftPanel.style.width = '100%';
      quizTab.disabled = false;
      splitScreenActive = false;
      quizTab.click();
    } else {
      showFirstAttachment();
      quizPanel.classList.remove('d-none');
      leftPanel.classList.remove('d-none');
      handle.classList.remove('d-none');
      leftPanel.style.width = '';
      quizTab.disabled = true;
      splitScreenActive = true;
    }
    toggleSplitScreenButton();
  }

  function toggleNoAttachmentsPanel() {
    if (document.querySelector('[data-js-file-tab][data-upload-id]')) {
      noAttachments.classList.add('d-none');
    } else {
      noAttachments.classList.remove('d-none');
    }
  }

  function init() {
    const tabBar = self.querySelector('.mdc-tab-bar');
    if (!tabBar.MDCTabBar) {
      tabBar.MDCTabBar = new MDCTabBar(tabBar);
      tabBar.setAttribute('data-mdc-auto-init-state', 'initialized');
    }

    tabBar.MDCTabBar.listen('MDCTabBar:activated', handleTabInteraction);
    quizTab = self.querySelector('[data-js-file-tab][data-file-type="quiz"]');

    splitScreenBtn = self.querySelector('[data-js-toggle-splitscreen]');
    splitScreenBtn.addEventListener('click', toggleSplitScreen);
    if (noAttachments) toggleNoAttachmentsPanel();
  }

  function setActiveTab(uploadId) {
    const tabBar = self.querySelector('.mdc-tab-bar');

    let tab = tabBar.querySelector(`[data-js-file-tab][data-upload-id='${uploadId}']`);
    if (!tab) tab = tabBar.querySelector('[data-js-file-tab][data-upload-id]');

    if (tab) {
      tabBar.querySelector('.mdc-tab--active').classList.remove('mdc-tab--active');
      tab.classList.add('mdc-tab--active');
    }

    togglePanels(tabBar.querySelector('.mdc-tab--active'));
  }

  function enableQuizTab() {
    const activeTab = document.querySelector('[data-js-file-tab].mdc-tab--active');
    if (splitScreenActive && activeTab.hasAttribute('data-upload-id')) return;

    quizTab.disabled = false;
  }

  function refreshTabs(e) {
    const activeUploadId = self.querySelector('[data-js-file-tab].mdc-tab--active').getAttribute('data-upload-id');

    self.querySelector('[data-js-file-tab-bar]').outerHTML = e.detail.html;

    init();

    if (activeUploadId || splitScreenActive) setActiveTab(activeUploadId);

    toggleSplitScreenButton();
    enableQuizTab();
  }

  init();
  setActiveTab();
  enableQuizTab();
  self.addEventListener('subset:show', refreshTabs);
});
