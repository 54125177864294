import { Controller } from '@hotwired/stimulus';
import Cookies from 'js-cookie';

export default class extends Controller {
  toggle() {
    const { toggleTarget } = this.element.dataset;
    const element = document.querySelector(toggleTarget);
    if (!element) return;

    const cookieName = toggleTarget.replace('#', '');
    const hidden = element.classList.toggle('d-none');
    Cookies.set(cookieName, hidden ? 'hidden' : 'show', { expires: 365 });
  }
}
