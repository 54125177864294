/* global i18n */
import onmount from 'onmount';
import Rails from '@rails/ujs';

onmount('[data-js-question]', function () {
  const self = this;
  const { questionId } = this.dataset;
  const subquestionCount = this.querySelector('[data-js-subquestion-count]');
  const subquestionPoints = this.querySelector('[data-js-question-points]');

  function updateNumberOfSubquestions() {
    const numberOfSubquestions = self.querySelectorAll('[data-js-subquestion]').length;
    subquestionCount.innerText = i18n.t('js.questions.subquestions', { count: numberOfSubquestions });
  }
  this.updateNumberOfSubquestions = updateNumberOfSubquestions;

  function updatePoints() {
    let totalPoints = 0;
    [...self.querySelectorAll('[data-js-subquestion]')].forEach((subquestion) => {
      const { points } = subquestion.dataset;
      totalPoints += parseFloat(points || 0);
    });
    subquestionPoints.innerText = i18n.t('js.questions.points', { count: totalPoints });
  }
  this.updatePoints = updatePoints;

  function listenForSynchronizableChanges(e) {
    if (window.location.href.includes('repo')) return;

    const changedQuestionId = e.detail.questionId;
    if (changedQuestionId !== questionId) return;

    const question = document.querySelector(`[data-js-question][data-question-id="${questionId}"]`);
    if (!question.querySelector('[data-js-synchronizable-question]')) return;

    Rails.ajax({
      url: `/sync/questions/${questionId}`,
      type: 'GET',
      dataType: 'script'
    });
  }
  this.listenForSynchronizableChanges = listenForSynchronizableChanges;

  ['subquestionCreated', 'subquestionUpdated', 'subquestionDestroyed'].forEach((evt) => {
    document.addEventListener(evt, this.updateNumberOfSubquestions);
    document.addEventListener(evt, this.updatePoints);
  });

  [
    'subquestionCreated', 'subquestionUpdated', 'subquestionDestroyed',
    'subelementCreated', 'subelementUpdated', 'subelementDestroyed'
  ].forEach((evt) => {
    document.addEventListener(evt, this.listenForSynchronizableChanges);
  });
}, function () {
  ['subquestionCreated', 'subquestionUpdated', 'subquestionDestroyed'].forEach((evt) => {
    document.removeEventListener(evt, this.updateNumberOfSubquestions);
    document.removeEventListener(evt, this.updatePoints);
  });

  [
    'subquestionCreated', 'subquestionUpdated', 'subquestionDestroyed',
    'subelementCreated', 'subelementUpdated', 'subelementDestroyed'
  ].forEach((evt) => {
    document.removeEventListener(evt, this.listenForSynchronizableChanges);
  });
});
