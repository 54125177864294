import onmount from 'onmount';

onmount('[data-js-select-language]', function () {
  const input = this.querySelector('[data-js-select-input]');
  const form = this.closest('form');
  const editor = form.querySelector('[data-js-ace-editor]');
  const runner = form.querySelector('[data-js-runner]');

  function changeCodingLanguage() {
    const customEvent = new CustomEvent('ace-editor:change-language', {
      detail: { language: input.value }
    });
    editor.dispatchEvent(customEvent);
    if (runner) runner.dispatchEvent(customEvent);
  }

  input.addEventListener('change', changeCodingLanguage);
});
