import onmount from 'onmount';
import Rails from '@rails/ujs';

onmount('[data-js-filter]', function () {
  const url = this.dataset.jsFilter;
  const trigger = this.querySelector('.mdc-menu-trigger');
  const menu = this.querySelector('.mdc-menu');
  const search = this.querySelector('[data-js-client-search-field]');

  function handleClick() {
    if (!url) return;

    if (!menu.querySelector('.mdc-linear-progress')) return;

    Rails.ajax({
      url,
      type: 'GET',
      dataType: 'json',
      success: (data) => {
        if (data.html) {
          menu.querySelector('.mdc-deprecated-list').outerHTML = data.html;
          onmount();

          if (!search) return;

          search.init();
          search.focus();
        }
      }
    });
  }

  if (trigger) { trigger.addEventListener('click', handleClick); }
});
