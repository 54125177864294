import { DirectUpload } from '@rails/activestorage';

class Uploader {
  constructor(element, file, url) {
    this.element = element;
    this.upload = new DirectUpload(file, url, this);
  }

  start() {
    return new Promise((resolve, reject) => {
      this.upload.create((error, blob) => {
        if (error) {
          reject(error);
        } else {
          resolve(blob);
        }
      });
    });
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener('progress', (event) => this.directUploadDidProgress(event));
  }

  directUploadDidProgress(event) {
    const progress = ((event.loaded / event.total) * 100).toFixed(1);
    this.element.querySelector('[data-progress]').style.width = `${progress}%`;
  }
}

export { Uploader };
