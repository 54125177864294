import onmount from 'onmount';
import Rails from '@rails/ujs';

onmount('[data-js-repo-assignment-questions]', function () {
  function handleRepoAssignmentQuestionsLinkSuccess(e) {
    if (!e.target.closest('[data-js-repo-assignments-question-action]')) return;

    Rails.ajax({
      url: window.location.href,
      type: 'GET',
      dataType: 'script'
    });
  }

  this.addEventListener('ajax:success', handleRepoAssignmentQuestionsLinkSuccess);
});
