import onmount from 'onmount';

onmount('[data-js-collapsible]', function () {
  const self = this;
  const content = this.parentElement.querySelector('[data-js-collapsible-content]');
  const indicator = this.querySelector('[data-js-collapsible-indicator]');
  const { openedText, closedText } = this.dataset;
  let opened = indicator.textContent === openedText;

  function open() {
    content.classList.remove('d-none');
    indicator.innerHTML = openedText;
    opened = true;
    self.classList.add('collapsible-open');
  }

  function close() {
    content.classList.add('d-none');
    indicator.innerHTML = closedText;
    opened = false;
    self.classList.remove('collapsible-open');
  }

  function toggle(e) {
    if (e.target.nodeName === 'A' && !e.target.href.endsWith('#!')) return;

    if (opened) {
      close();
    } else {
      open();
    }
  }

  self.addEventListener('click', toggle);
});
