/* global i18n, $R */

import snackbar from '@components/snackbar';

function showBaseWarning(form, messages) {
  const panel = document.createElement('div');
  panel.classList.add('box', 'box--danger');
  panel.innerHTML = messages.join(', ');

  form.insertBefore(panel, form.firstChild);
}

function parseData(data) {
  try {
    return JSON.parse(data);
  } catch (error) {
    return console.error(error); // eslint-disable-line no-console
  }
}

function clearMessages(form) {
  while (form.parentElement.querySelector('.box--danger')) {
    form.parentElement.querySelector('.box--danger').remove();
  }
}

function clearCustomValidity() {
  this.setCustomValidity('');
  this.reportValidity();
}

export function showValidationMessages(data, form) {
  if (data && typeof data === 'string') {
    data = parseData(data); // eslint-disable-line no-param-reassign
  }

  clearMessages(form);

  Object.entries(data).forEach(([attribute, messages]) => {
    console.log(`${attribute} - ${messages.join(', ')}`); // eslint-disable-line no-console
    if (attribute === 'base') {
      showBaseWarning(form, messages);
      return;
    }
    let name = attribute;
    if (name.includes('encrypted_')) name = name.replace('encrypted_', '');

    const field = form.querySelector(`[name*='[${name}]']`) ||
                  form.querySelector(`[name*='[${name}_id]']`) ||
                  form.querySelector(`[name*='${name}']`) ||
                  form.querySelector(`[name*='${name}_id']`);
    if (!field) return;

    if (field.hasAttribute('data-js-redactor')) {
      const redactor = $R(field);
      redactor.statusbar.add('warning', messages.join(', '));
      redactor.container.getElement().addClass('invalid');
      return;
    }
    if (field.hasAttribute('data-js-ace-editor')) {
      const { aceEditor } = field;
      aceEditor.session.setAnnotations([{
        row: 0, column: 0, text: messages.join(', '), type: 'error'
      }]);
      return;
    }
    if (field.closest('.mdc-select')) {
      const select = field.closest('.mdc-select');
      const helperText = select.nextElementSibling;
      if (helperText && helperText.classList.contains('mdc-select-helper-text')) helperText.remove();
      select.insertAdjacentHTML('afterend', `<p class='mdc-select-helper-text'>${messages.join(', ')}</p>`);
      return;
    }
    if (typeof field._flatpickr !== 'undefined') { // eslint-disable-line
      field.setAttribute('visibility', 'hidden');
      field.style.width = '1px';
      field.removeAttribute('type');
    }

    if (typeof field.setCustomValidity !== 'undefined') {
      field.setCustomValidity(messages.join(', '));
      field.reportValidity();
      field.addEventListener('input', clearCustomValidity);
    }

    if (field.closest('.mdc-text-field')) {
      field.closest('.mdc-text-field').classList.add('mdc-text-field--invalid');
    }
  });
}

export const showFormErrors = function (event) {
  const [data, , xhr] = event.detail;

  if (xhr.status === 401) {
    snackbar(i18n.t('js.general.session_timeout'), true);
  } else if (xhr.status === 408) {
    snackbar(i18n.t('js.general.request_timeout'), true);
  } else if (xhr.status === 403) {
    snackbar(i18n.t('js.general.no_permission'), true);
  } else if (xhr.status === 422) {
    showValidationMessages(data, event.target);
  }
};
