import onmount from 'onmount';

onmount('[data-js-user-menu]', function () {
  const self = this;
  const toggleLanguage = this.querySelectorAll('[data-js-toggle-language]');
  const menuItems = this.querySelectorAll('.mdc-deprecated-list-item');

  function handleClick() {
    menuItems.forEach((item) => item.classList.toggle('d-none'));
    self.querySelector('.mdc-deprecated-list-item:not(.d-none)').focus();
  }

  toggleLanguage.forEach((item) => item.addEventListener('click', handleClick));
});
