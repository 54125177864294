import onmount from 'onmount';

onmount('[data-js-toggle-forced-navigation-warning]', function () {
  function setShowWarningParams(checked) {
    document.querySelectorAll('[data-js-go-to], [data-js-next], [data-js-prev]').forEach((btn) => {
      const form = btn.closest('form');
      const url = new URL(form.action);
      url.searchParams.set('overwrite_block_warning', !checked);

      form.setAttribute('action', url);
    });
  }

  function handleInputChange() {
    document.querySelectorAll('[data-js-toggle-forced-navigation-warning]').forEach((checkbox) => {
      checkbox.checked = this.checked;
    });

    setShowWarningParams(this.checked);
  }

  this.addEventListener('change', handleInputChange);
});
