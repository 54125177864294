import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  open(e) {
    const { src } = e.target;
    const dialog = this.#buildDialog(src);
    document.getElementById('dialog').innerHTML = dialog;
  }

  #buildDialog(src) {
    return `
      <dialog class="dialog" data-dialog-target="dialog" data-dialog-open="true">
        <div class="dialog__container" style="width: 90vw; height: 90vh;">
          <div class="dialog__content">
            <div class="dialog__header">
              <button type="button" data-action="dialog#close" class="button-icon ml-auto">close</button>
            </div>
            <img class="responsive-img" src="${src}">
          </div>
        </div>
      </dialog>
    `;
  }
}
