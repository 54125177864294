import onmount from 'onmount';
import Rails from '@rails/ujs';
import { debounce } from '@modules/custom';

onmount('[data-js-progress-dialog-content]', function () {
  const self = this;
  const input = this.querySelector('[data-js-go-to-student]');

  function handleInputChange() {
    const value = parseInt(this.value, 10);
    let page = Math.ceil(value / 10);
    if (Number.isNaN(page) || page <= 0) page = 1;

    const pagyLink = document.querySelector('[data-js-progress-dialog-actions] a.mdc-data-table__pagination-button');
    const pagyUrl = pagyLink.getAttribute('href');
    const url = new URL(pagyUrl);
    url.searchParams.set('page', page);

    input.disabled = true;
    Rails.ajax({
      url: url.toString(),
      type: 'GET',
      dataType: 'script',
      success: () => {
        input.disabled = false;
      }
    });
  }

  function handleSubmissionChange(e) {
    const currentSubquestionSelector = self.querySelector('.subquestion-selector-item--current');
    if (currentSubquestionSelector) {
      currentSubquestionSelector.classList.remove('subquestion-selector-item--current');
      currentSubquestionSelector.setAttribute('data-active-tile', false);
    }

    const { submissionId } = e.detail;
    const item = self.querySelector(`[data-js-pagination-item][data-submission-id='${submissionId}']`);
    if (item) {
      item.classList.add('subquestion-selector-item--current');
      item.setAttribute('data-active-tile', true);
    }
  }
  this.handleSubmissionChange = handleSubmissionChange;

  input.addEventListener('change', debounce(handleInputChange, 250));
  document.addEventListener('grading:submissionChangedEvent', this.handleSubmissionChange);
}, function () {
  document.removeEventListener('grading:submissionChangedEvent', this.handleSubmissionChange);
});
